import React from 'react';
import classNames from 'classnames/bind';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import styles from './RequirementName.module.scss';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { useContext } from 'react';
import { GlobalStateProject } from '~/ProjectState/projectState';
import { useEffect } from 'react';
import * as listProjectService from '~/services/listProjectService';
import { dispatchProject } from '~/redux/actions/projectAction';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import api from '~/utils/main';



const cx = classNames.bind(styles);

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 15,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};


const RequirementName = ({ openDialogRequireName, setOpenDialogRequireName, setProjectID, getListRequireCheckLists }) => {
    const state = useContext(GlobalStateProject);
    // const [projects] = state.projectsAPI.projects;
    const projects = useSelector((state) => state.project);
    const [projectId, setProjectId] = useState(null);
    const [showProjects, setShowProjects] = useState([]);
    const [requirementName, setRequirementName] = useState('');
    const [validProject, setValidProject] = useState(false);
    const [validRequirementName, setValidRequirementName] = useState(false);





    const [isMyTask, setIsMyTask] = state.projectsAPI.isMyTask;
    const [isOverDue, setIsOverDue] = state.projectsAPI.isOverDue;

    const handleCloseCreateProjectDialog = () => {
        setOpenDialogRequireName(false);
        setValidProject(false);
        setValidRequirementName(false);
    };


    const handleChangeProject = (id) => {
        const numberProjectID = Number(id);
        setProjectId(numberProjectID);
        setValidProject(false);

    };


    const handleChangeRequirementName = (e) => {
        setRequirementName(e.target.value);
        setValidRequirementName(false);
    };


    const handleAddRowRequire = async () => {


        if (projectId === null || projectId === '' || projectId === 'undefined') {
            setValidProject(true);
            return;
        }

        if (requirementName === '' || requirementName === null) {
            setValidRequirementName(true);
            return;
        }

        const body = {
            projectId: projectId,
            checklistName: requirementName
        }

        const res = await toast.promise(api.post(`check-list/create-checklist`, body), {
            pending: 'Create requirement name pending',
            success: 'Create requirement name successfully',
            error: 'Create requirement name failed',
        });

        if (res.status === 204) {
            setOpenDialogRequireName(false);
            setValidProject(false);
            setValidRequirementName(false);
            setRequirementName('');
            setProjectID(projectId);
            getListRequireCheckLists();
        }
    }

    return (
        <div>
            <BootstrapDialog
                open={openDialogRequireName}
                onClose={handleCloseCreateProjectDialog}
                maxWidth="sm"
                fullWidth
                aria-labelledby="customized-dialog-title"
            >
                <BootstrapDialogTitle
                    id="customized-dialog-title"
                    onClose={handleCloseCreateProjectDialog}
                    className={cx('custom-title-control')}
                >
                    <div className={cx('dialog-add-require')}>
                        <h2>Create Requirement Name</h2>
                    </div>
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <div className={cx('dialog-add-require-content')}>
                        <div className={cx("mb-4 mt-4 row")}>
                            <label htmlFor="milestone-project" className={cx('form-label col-sm-3 text-end')}>
                                Project  <i className={cx('hn-color-red')}>*</i>
                            </label>
                            <div className={cx('col-sm-8')}>
                                <select
                                    className="form-select form-select-lg mb-3"
                                    aria-label=".form-select-lg example"
                                    id="add-row-project"
                                    onChange={(e) => handleChangeProject(e.target.value)}
                                    autoFocus
                                >
                                    <option value="no-project">Select project</option>
                                    {projects.map((project) => (
                                        <option
                                            value={project.projectId}
                                            key={project.projectId}
                                        >
                                            {project.projectName}
                                        </option>
                                    ))}
                                </select>
                                {validProject ? (
                                    <span className={cx('error-message')}>No project yet</span>
                                ) : (
                                    ''
                                )}
                            </div>
                        </div>
                        <div className={cx("mb-4 mt-4 row")}>
                            <label className={cx('form-label col-sm-3 text-end')}>
                                Requirement <i className={cx('hn-color-red')}>*</i>
                            </label>
                            <div className={cx('col-sm-8')}>
                                <input
                                    type="text"
                                    className={cx('form-control form-control-lg')}
                                    value={requirementName}
                                    onChange={(e) => handleChangeRequirementName(e)}
                                ></input>
                                {validRequirementName ? (
                                    <span className={cx('error-message')}>The requirement name must not be blank</span>
                                ) : (
                                    ''
                                )}
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions className={cx('create-project-action')}>
                    <button onClick={handleCloseCreateProjectDialog} className={cx('hn-btn-cancel')} size="medium">
                        Cancel
                    </button>
                    <button
                        type="submit"
                        onClick={handleAddRowRequire}
                        className={cx('hn-btn-submit')}
                        size="medium"
                    >
                        Add
                    </button>
                </DialogActions>
            </BootstrapDialog>
        </div>
    )
}

export default RequirementName