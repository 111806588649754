const callToWindowApp = (action, data) => {
    // merge properties
    var msgObject = {
        strAction: action,
        ...data,
    };

    var json = JSON.stringify(msgObject);
    window.chrome.webview?.postMessage(json);
};

const convertStatus = (status) => {
    let statusTask = "";
    switch (status) {
        case 1:
            statusTask = "New";
            break;
        case 2:
            statusTask = "In progress";
            break;
        case 3:
            statusTask = "Closed";
            break;
        case 4:
            statusTask = "In progress";
            break;
        case 5:
            statusTask = "Resolved";
            break;
        case 7:
            statusTask = "Testing";
            break;
        case 8:
            statusTask = "Fix bug";
            break;
        case 9:
            statusTask = "Test Done";
            break;
        case 10:
            statusTask = "Pending";
            break;
        default:
            statusTask = "";
            break;
    }
    return statusTask;
}

export { callToWindowApp, convertStatus };
