import { useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw } from 'draft-js';
import { convertToHTML, convertFromHTML } from 'draft-convert';

function EditDescription(props) {
    const { name, onChange, setEditContent, id, editContent } = props;

    const [nameState, setNameState] = useState(EditorState.createWithContent(convertFromHTML(name)));

    function handleEditForm(state) {
        setNameState(state);
        setEditContent(convertToHTML(state.getCurrentContent()));
    }

    return (
        <Editor
            editorState={nameState}
            onEditorStateChange={handleEditForm}
            wrapperClassName={`wrapper-form__edit wrapper-form__edit-description`}
            editorClassName={`form-edit_comment form-edit-description `}
            toolbarClassName="toolbar-class"
            placeholder="Edit description...."
        />
    );
}

export default EditDescription;
