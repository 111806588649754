import classNames from 'classnames/bind';
import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
// import styles from '../../CreateTask/CreateTask.module.scss';
import styles from '../Milestone.module.scss';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { convertToRaw, EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { useContext, useRef, useState } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import axios from 'axios';
import draftToHtml from 'draftjs-to-html';
import { toast } from 'react-toastify';
import { GlobalStateProject } from '~/ProjectState/projectState';
import api from '~/utils/main';

const cx = classNames.bind(styles);

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 15,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

function CreateMilestoneDialog({ openDialogCreateMilestone, setOpenDialogCreateMilestone }) {
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const token = useSelector((state) => state.token);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const projects = useSelector((state) => state.project);
    const [idProject, setIdproject] = useState(projects.length !== 0 ? projects[0].projectId : '');
    const [title, setTitle] = useState('');
    const state = useContext(GlobalStateProject);
    const [callback, setCallback] = state.projectsAPI.callback;

    const inputTitle = useRef();
    const [valueTitle, setValueTitle] = useState(false);
    const [valueStartDate, setValueStartDate] = useState(false);
    const [valueEndDate, setValueEndDate] = useState(false);

    const handleClose = () => {
        setOpenDialogCreateMilestone(false);
        // setIdproject('');
    };

    const onEditorStateChange = (editorState) => {
        return setEditorState(editorState);
    };

    const handleChangeProject = (e) => {
        setIdproject(e.target.value);
    };

    const handleChangeTitle = (e) => {
        setValueTitle(false);
        setTitle(e.target.value);
    };

    const handleChangeDateStart = (e) => {
        setValueStartDate(false);
        const value = moment(new Date(e.target.value)).format('YYYY-MM-DD');
        setStartDate(value);
    };

    const handleChangeDateEnd = (e) => {
        setValueEndDate(false);
        const value = moment(new Date(e.target.value)).format('YYYY-MM-DD');
        setEndDate(value);
    };

    const handleCreateMilestone = async (e) => {
        e.preventDefault();
        // setEndDate(value);

        if (title === '') {
            setValueTitle(true);
            inputTitle.current.focus();
            return;
        }

        if (startDate === null) {
            setValueStartDate(true);
            return;
        }

        if (endDate === null) {
            setValueEndDate(true);
            return;
        }

        const body = {
            projectId: idProject,
            title: title,
            description: draftToHtml(convertToRaw(editorState.getCurrentContent())),
            startDate: startDate,
            endDate: endDate,
        };

        const res = await toast.promise(api.post(`milestones`, body), {
            pending: 'Create Milestone pending',
            success: 'Create Milestone successfully',
            error: 'Create Milestone failed',
        });

        if (res.status === 201) {
            setOpenDialogCreateMilestone(false);
            setTitle('');
            setEditorState(EditorState.createEmpty());
            setCallback(!callback);
        }
    };

    return (
        <div className={cx('header-create-task')}>
            <form>
                <BootstrapDialog
                    open={openDialogCreateMilestone}
                    onClose={handleClose}
                    maxWidth="1700px"
                    minwidth="1500px"
                    fullWidth
                    aria-labelledby="customized-dialog-title"
                >
                    <BootstrapDialogTitle
                        id="customized-dialog-title"
                        onClose={handleClose}
                        className={cx('custom-title-control')}
                    >
                        <div className={cx('dialog-create-task-title')}>
                            <h2>Create Milestone</h2>
                        </div>
                    </BootstrapDialogTitle>
                    <DialogContent dividers className={cx('move-project-content')}>
                        <div className={cx('dialog-create-milestone')}>

                            <div className={cx('row')}>
                                <div className={cx('col-12 col-md-6 mb-3')}>
                                    <div className={cx('form-group row')}>
                                        <label htmlFor="milestone-project" className="col-lg-4 col-xxl-2 col-form-label">
                                            Project
                                        </label>
                                        <div className="col-lg-8 col-xxl-10">
                                            <select
                                                className="form-select form-select-lg mb-3"
                                                aria-label=".form-select-lg example"
                                                id="milestone-project"
                                                onChange={(e) => handleChangeProject(e)}
                                            >
                                                {projects.map((project) => (
                                                    <option key={project.projectId} value={project.projectId}>
                                                        {project.projectName}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>

                                    </div>
                                </div>
                                <div className={cx('col-12 col-md-6 mb-3')}>
                                    <div className={cx('form-group row')}>
                                        <label htmlFor="milestone-title" className="col-lg-4 col-xxl-2 col-form-label">
                                            Title<i className={cx('hn-color-red')}>*</i>
                                        </label>
                                        <div className="col-lg-8 col-xxl-10">
                                            <input
                                                ref={inputTitle}
                                                type="text"
                                                className="form-control form-control-lg"
                                                id="milestone-title"
                                                value={title}
                                                onChange={(e) => handleChangeTitle(e)}
                                            ></input>
                                            {valueTitle ? (
                                                <span className={cx('error-message')}>The title must not be blank</span>
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={cx('row')}>
                                <div className={cx('col-12 col-md-6 mb-3')}>
                                    <div className={cx('form-group row')}>
                                        <label htmlFor="milestone-project-start-date" className="col-lg-4 col-xxl-2 col-form-label">
                                            Start Date<i className={cx('hn-color-red')}>*</i>
                                        </label>
                                        <div className="col-lg-8 col-xxl-10">
                                            <input
                                                type="date"
                                                className="form-control form-control-lg mb-3"
                                                id="milestone-project-start-date"
                                                // min={moment(new Date()).format('YYYY/MM/DD').replaceAll('/', '-')}
                                                onChange={(e) => handleChangeDateStart(e)}
                                            ></input>
                                            {valueStartDate ? (
                                                <span className={cx('error-message')}>
                                                    The start date must not be blank
                                                </span>
                                            ) : (
                                                ''
                                            )}
                                        </div>

                                    </div>
                                </div>
                                <div className={cx('col-12 col-md-6 mb-3')}>
                                    <div className={cx('form-group row')}>
                                        <label htmlFor="milestone-project-end-date" className="col-lg-4 col-xxl-2 col-form-label">
                                            End Date<i className={cx('hn-color-red')}>*</i>
                                        </label>
                                        <div className="col-lg-8 col-xxl-10">
                                            <input
                                                type="date"
                                                className="form-control form-control-lg mb-3"
                                                id="milestone-project-end-date"
                                                min={moment(new Date()).format('YYYY/MM/DD').replaceAll('/', '-')}
                                                onChange={(e) => handleChangeDateEnd(e)}
                                                onKeyDown={(e) => {
                                                    e.preventDefault();
                                                }}
                                            ></input>
                                            {valueEndDate ? (
                                                <span className={cx('error-message')}>The end date must not be blank</span>
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mb-3 mt-3">
                                <label htmlFor="milestone-description" className="form-label">
                                    Description
                                </label>
                                <div className={cx('milestone-des-tool-link')}>
                                    <Editor
                                        editorState={editorState}
                                        onEditorStateChange={onEditorStateChange}
                                        wrapperClassName="wrapper-class"
                                        editorClassName="editor-class"
                                        toolbarClassName="toolbar-class custom-des-tool-link"
                                        placeholder="Enter your description...."
                                    />
                                </div>

                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions className={cx('create-task-action')}>
                        <button onClick={handleClose} className={cx('hn-btn-cancel')} size="medium">
                            Cancel
                        </button>
                        <button
                            type="submit"
                            onClick={handleCreateMilestone}
                            className={cx('hn-btn-submit')}
                            size="medium"
                        >
                            Add
                        </button>
                    </DialogActions>
                </BootstrapDialog>
            </form>
        </div>
    );
}

export default CreateMilestoneDialog;
