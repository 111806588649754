import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import styles from './Project.module.scss';
import { MdAddCircle } from 'react-icons/md';
import iconProject from '../../assets/images/icons/folder.png';
import { Button, Pagination } from '@mui/material';
import { AiOutlineDelete, AiOutlineEdit, AiOutlineUserAdd, AiOutlineUserDelete } from 'react-icons/ai';
import CreateProjectDialog from './component/CreateProject/CreateProjectDialog';
import axios from 'axios';
import { useSelector } from 'react-redux';
import Moment from 'moment';
import PaginationProject from './component/PaginationProject/PaginationProject';
import AddUserProject from './component/AddUserProject/AddUserProject';
import RemoveUserProject from './component/RemoveUserProject/RemoveUserProject';
import EditProject from './component/EditProject/EditProject';
import api from '~/utils/main';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import InformationProject from './component/InformationProject/InformationProject';
import ReactLoading from 'react-loading';

const cx = classNames.bind(styles);

const Project = () => {
    const [listProject, setListProject] = useState([]);
    const [openDialogCreateProject, setOpenDialogCreateProject] = useState(false);
    const [openDialogAddUser, setOpenDialogAddUser] = useState(false);
    const [openDialogRemoveUser, setOpenDialogRemoveUser] = useState(false);
    const [openDialogEditProject, setOpenDialogEditProject] = useState(false);
    const [openDialogInformationProject, setOpenDialogInformationProject] = useState(false);
    const [idProject, setIdProject] = useState('');
    const [loading, setLoading] = useState(false);
    const auth = useSelector((state) => state.auth);

    let [page, setPage] = useState(1);
    const PER_PAGE = 11;

    const count = Math.ceil(listProject.length / PER_PAGE);
    const _DATA = PaginationProject(listProject, PER_PAGE);


    const handleCreateProject = () => {
        setOpenDialogCreateProject(true);
    };

    useEffect(() => {
        getListProject();
    }, [idProject]);

    const getListProject = async () => {

        setLoading(true);
        const response = await api.get(`projects`);
        if (response.data.status === 'success') {
            setListProject(response.data.data);
            setLoading(false);
        }
    };

    const handleAddUserProject = (id) => {
        setIdProject(id);
        setOpenDialogAddUser(true);
    };

    const handleRemoveUserProject = (id) => {
        setIdProject(id);
        setOpenDialogRemoveUser(true);
    };

    const handleRemoveProject = async (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'Delete project',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            dangerMode: true,
        }).then((result) => {
            if (result.isConfirmed) {
                toast.promise(
                    api.delete(`projects/${id}`).then(() => {
                        getListProject();
                    }),
                    {
                        pending: 'delete to project is pending',
                        success: 'delete to project successfully',
                        error: 'Report task failed',
                    },
                );
            }
        });
    };

    const handleEditProject = (id) => {
        setIdProject(id);
        setOpenDialogEditProject(true);
    };

    const handleChange = (e, p) => {
        setPage(p);
        _DATA.jump(p);
    };

    const handleInformationProject = (id) => {
        setIdProject(id);
        setOpenDialogInformationProject(true);
    };

    return (
        <div className={cx('wrapper')}>
            <div className={cx('row')}>
                <div className={cx('col-12 col-sm-6 col-md-4 col-lg-6 col-xl-4 col-xxl-3', 'mb-4')}>
                    <div className={cx('project-cart-create', 'project-new')} onClick={handleCreateProject}>
                        <MdAddCircle className={cx('project-icon-create')} />
                        <p className={cx('project-txt-create-new')}>Create New Project</p>
                    </div>
                </div>

                {loading === true ? (
                    <ReactLoading
                        type="spin"
                        color="#0052cc"
                        height={'50px'}
                        width={'50px'}
                    />
                ) : (
                    <>
                        {_DATA.currentData().map((listProject, index) => {
                            return (
                                <div
                                    className={cx('col-12 col-sm-6 col-md-4 col-lg-6 col-xl-4 col-xxl-3', 'mb-4')}
                                    key={index}
                                >
                                    <div className={cx('project-cart-create', 'project-created')}>
                                        <div
                                            onClick={(e) => handleInformationProject(listProject.id)}
                                            className={cx('project-cart-content')}
                                        >
                                            <h3 className={cx('project-name')}>{listProject.name}</h3>
                                            <div className={cx('row', 'mt-2')}>
                                                <div className={cx('col-6')}>
                                                    <div className={cx('project-img-project')}>
                                                        <img src={iconProject} alt="" />
                                                    </div>
                                                    <p className={cx('project-short-name')}>{listProject.shortName}</p>
                                                </div>
                                                <div className={cx('col-6')}>
                                                    <p>
                                                        <b>{listProject.totalUser}</b>&nbsp;User(s)
                                                    </p>
                                                    <p>
                                                        <b>{listProject.totalTask}</b>&nbsp;Task(s)
                                                    </p>
                                                    <p>
                                                        <b>{listProject.totalHour}</b>&nbsp;Hour Spent(s)
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <hr className={cx('project-hr')} />
                                        <div className={cx('project-cart-footer')}>
                                            <p className={cx('project-date-create')}>
                                                <b>Created date:</b>&nbsp;
                                                {Moment(listProject.dtCreated).format('DD-MM-YYYY')}
                                            </p>
                                            {listProject.isActive === 1 ? (
                                                <p className={cx('project-active')}>Active</p>
                                            ) : (
                                                <p className={cx('project-deactive')}>Deactive</p>
                                            )}
                                        </div>
                                        {(auth.users.userType === 2 || auth.users.userType === 4) && (
                                            <div className={cx('project-control')}>
                                                <div className={cx('row')}>
                                                    <div className={cx('col-6 col-sm-8 col-xl-8')}>
                                                        <Button
                                                            variant="text"
                                                            className={cx('project-button-control')}
                                                            startIcon={<AiOutlineUserAdd />}
                                                            onClick={(e) => handleAddUserProject(listProject.id)}
                                                        >
                                                            Add User
                                                        </Button>
                                                    </div>
                                                    <div className={cx('col-6 col-sm-4 col-xl-4', 'res-control-project')}>
                                                        <Button
                                                            variant="text"
                                                            className={cx('project-button-control')}
                                                            startIcon={<AiOutlineEdit />}
                                                            onClick={(e) => handleEditProject(listProject.id)}
                                                        >
                                                            Add Note
                                                        </Button>
                                                    </div>
                                                </div>
                                                <div className={cx('row')}>
                                                    <div className={cx('col-6 col-sm-8 col-xl-8')}>
                                                        {listProject.totalUser != 0 ? (
                                                            <Button
                                                                variant="text"
                                                                className={cx('project-button-control')}
                                                                startIcon={<AiOutlineUserDelete />}
                                                                onClick={(e) => handleRemoveUserProject(listProject.id)}
                                                            >
                                                                Remove User
                                                            </Button>
                                                        ) : null}
                                                    </div>
                                                    <div className={cx('col-6 col-sm-4 col-xl-4', 'res-control-project')}>
                                                        <Button
                                                            variant="text"
                                                            className={cx('project-button-control')}
                                                            startIcon={<AiOutlineDelete />}
                                                            onClick={(e) => handleRemoveProject(listProject.id)}
                                                        >
                                                            Delete
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                        {(auth.users.userType === 1 || auth.users.userType === 3) && (
                                            <div className={cx('project-control')}>
                                                <Button
                                                    variant="text"
                                                    className={cx('project-button-control', 'btn-edit-center')}
                                                    startIcon={<AiOutlineEdit />}
                                                    onClick={(e) => handleEditProject(listProject.id)}
                                                >
                                                    Add Note
                                                </Button>
                                            </div>
                                        )}

                                    </div>
                                </div>
                            )
                        })}
                    </>
                )}


                {/* <div className={cx('col-12 col-sm-6 col-md-4 col-lg-6 col-xl-4 col-xxl-3', 'mb-4')}>
                    <span className={cx('project-txt-notice')}>You have not created any project yet.</span>
                </div> */}


                <div className={cx('project-pagination')}>
                    <Pagination
                        count={count}
                        size="medium"
                        page={page}
                        variant="outlined"
                        onChange={handleChange}
                        color="primary"
                    />
                </div>
            </div>
            <CreateProjectDialog
                openDialogCreateProject={openDialogCreateProject}
                setOpenDialogCreateProject={setOpenDialogCreateProject}
                listProject={listProject}
                getListProject={getListProject}
            />
            <AddUserProject
                openDialogAddUser={openDialogAddUser}
                setOpenDialogAddUser={setOpenDialogAddUser}
                idProject={idProject}
                setIdProject={setIdProject}
                getListProject={getListProject}
            />
            <RemoveUserProject
                openDialogRemoveUser={openDialogRemoveUser}
                setOpenDialogRemoveUser={setOpenDialogRemoveUser}
                idProject={idProject}
                setIdProject={setIdProject}
            />
            <EditProject
                openDialogEditProject={openDialogEditProject}
                setOpenDialogEditProject={setOpenDialogEditProject}
                idProject={idProject}
                getListProject={getListProject}
            />
            <InformationProject
                openDialogInformationProject={openDialogInformationProject}
                setOpenDialogInformationProject={setOpenDialogInformationProject}
                idProject={idProject}
                getListProject={getListProject}
            />
        </div>
    );
};

export default Project;
