import classNames from 'classnames/bind';

import styles from './Button.module.scss';

const cx = classNames.bind(styles);

function Button({
    className,
    leftIcon = false,
    rightIcon = false,
    cursorText = false,
    onClick,
    radius8px = false,
    mr8 = false,
    pd4 = false,
    fs1rem = false,
    fs12px = false,
    minWidth72px = false,
    fontWeight600 = false,
    padding2And8 = false,
    children,
    btnCustomItemStatus = false,
    ...passProps
}) {
    let Comp = 'button';

    let props = {
        onClick,
        ...passProps,
    };

    const classes = cx('wrapper', {
        [className]: className,
        leftIcon,
        rightIcon,
        radius8px,
        mr8,
        pd4,
        minWidth72px,
        fs12px,
        fs1rem,
        btnCustomItemStatus,
        fontWeight600,
        cursorText,
        padding2And8,
    });
    return (
        <Comp className={classes} {...props}>
            {leftIcon && <span className={cx('icon')}>{leftIcon}</span>}
            <span className={cx('title', 'spText-title')}>{children}</span>
            {rightIcon && <span className={cx('icon')}>{rightIcon}</span>}
        </Comp>
    );
}

export default Button;
