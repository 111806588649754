import React from 'react';
import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './reducers/';
import { Provider } from 'react-redux';

const store = configureStore({ reducer: rootReducer });

function DataUserProvider({ children }) {
    return <Provider store={store}>{children}</Provider>;
}

export default DataUserProvider;
