import React, { useRef, useState } from 'react';
import classNames from 'classnames/bind';
import styles from './Milestone.module.scss';
import CreateMilestoneDialog from './component/CreateMilestoneDialog';
import CreateTaskChild from '../Home/components/CreateTaskChild/CreateTaskChild';
import { Switch } from '@mui/material';
import { HiOutlineClock } from 'react-icons/hi';
import { MdDateRange } from 'react-icons/md';
import { AiOutlineClockCircle, AiOutlineDownCircle, AiOutlineMessage, AiOutlineUser } from 'react-icons/ai';
import { BsPencilSquare } from 'react-icons/bs';
import { FiMessageCircle } from 'react-icons/fi';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import api from '~/utils/main';
import MilestoneTask from './component/MilestoneTask/MilestoneTask';
import MilestoneControlTask from './component/MilestoneTask/MilestoneControlTask';
import styled from 'styled-components';
import { DragDropContext } from 'react-beautiful-dnd';
import Column from './Column';

import * as mileStonesService from '~/services/mileStonesService';
import { useContext } from 'react';
import { GlobalStateProject } from '~/ProjectState/projectState';
import Pagination from './component/Pagination';

const cx = classNames.bind(styles);

// const Container = styled.div`
//     padding-top: 20px;
// `;

const Container = styled.div`
    display: flex;
`;

const ListGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 8px;
`;

const Milestone = () => {
    const state = useContext(GlobalStateProject);
    const [projectId, setProjectId] = state.projectsAPI.projectId;
    const [openDialogCreateMilestone, setOpenDialogCreateMilestone] = useState(false);
    const [checkedSwitch, setCheckedSwitch] = useState('list');
    const [lists, setLists] = useState([]);
    const [pageSize, setPageSize] = useState(4);
    const [page, setPage] = useState(1);
    const [milestoneActive, setMilestoneActive] = useState(0);

    const ref = useRef();

    const handleCreateMilestone = () => {
        setOpenDialogCreateMilestone(true);
    };

    const handleChangeViewList = (e) => {
        setCheckedSwitch('list');
        if (!e.target.checked) {
            setCheckedSwitch('kanban');
        }
    };

    const handleChangeViewKanban = (e) => {
        setCheckedSwitch('kanban');
        if (!e.target.checked) {
            setCheckedSwitch('list');
        }
    };

    const getAllMilestones = async () => {
        // const res = await toast.promise(api.get(`${process.env.REACT_APP_BASE_URL}milestones`), {
        //     pending: 'Get milestone is pending',
        //     success: 'Get milestone successfully',
        //     error: 'Report task failed',
        // });
        // console.log('res data');
        // console.log(res);
    };

    // useEffect(() => {
    //     getAllMilestones();
    // }, []);

    // useEffect(() => {
    //     const checkIfClickedOutside = (e) => {
    //         if (ref.current && !ref.current.contains(e.target)) {
    //             setDisplayItemEdit('block');
    //             setDisplayInputEdit('none');
    //         }
    //     };
    //     document.addEventListener('mousedown', checkIfClickedOutside);
    //     return () => {
    //         document.removeEventListener('mousedown', checkIfClickedOutside);
    //     };
    // }, []);

    useEffect(() => {
        const getData = async () => {
            const res = await mileStonesService.getDataMilestones(projectId, milestoneActive, pageSize, page);

            if (res.data.status === 'success') {
                setLists(res.data.data);
            }
        };
        getData();
    }, [projectId, page]);

    const moveMilestone = async (taskId, milestoneId) => {
        const res = await mileStonesService.moveMilestone(taskId, milestoneId);
    };

    const onDragEnd = (result) => {
        const { destination, source, draggableId } = result;

        if (!destination) {
            return;
        }
        if (destination.droppableId === source.droppableId) {
            return;
        }

        const newLists = { ...lists };

        const [reorderedItem] = newLists.listMilestone.filter((milestone) => milestone.id == source.droppableId);
        const [itemDrop] = reorderedItem.easycaseMilestones.splice(source.index, 1);

        // Add dropped item
        const [destinationItem] = newLists.listMilestone.filter((milestone) => milestone.id == destination.droppableId);
        destinationItem.easycaseMilestones.splice(destination.index, 0, itemDrop);

        setLists(newLists);
        moveMilestone(+draggableId, +destination.droppableId);
    };

    return (
        <div className={cx('wrapper')}>
            <div className={cx('row')}>
                <div className={cx('col-12 col-sm-8')}>
                    <div className={cx('row')}>
                        <div className={cx('milestone-switch')}>
                            <Switch
                                checked={checkedSwitch === 'list'}
                                onChange={(e) => handleChangeViewList(e)}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />{' '}
                            <label className={cx('switch-text')}>Active</label>
                        </div>
                        <div className={cx('kanban-view-wrapper', 'milestone-switch')}>
                            <Switch
                                checked={checkedSwitch === 'kanban'}
                                onChange={(e) => handleChangeViewKanban(e)}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                            <label className={cx('switch-text')}>Completed</label>
                        </div>
                    </div>
                </div>
                <div className={cx('col-12 col-sm-4', 'text-sm-end', 'text-start', 'pe-0')}>
                    <button
                        type="text"
                        onClick={handleCreateMilestone}
                        className={cx('btn-create-milestone')}
                        size="medium"
                    >
                        Create Milestone
                    </button>
                </div>
            </div>
            {/* <div className={cx('row')}>
                <div className={cx('col-3')}>
                    <div className={cx('glo-milestone-col')}>
                        <div className={cx('glo-milestone-header')}>
                            <div className={cx('milestone-header-cnt')} style={{ display: displayItemEdit }}>
                                <div className={cx('row')}>
                                    <div className={cx('col-8')}>
                                        <div className={cx('row', 'glo-milestone-header-title')}>
                                            <div>IVE_Phase</div>
                                            <BsPencilSquare
                                                className={cx('milestone-task-icon')}
                                                onClick={handleEditTitle}
                                            />
                                            <MilestoneTask />
                                        </div>
                                    </div>
                                    <div className={cx('col-4', 'text-end')}>51</div>
                                </div>
                            </div>
                            <div style={{ display: displayInputEdit }} ref={ref}>
                                <input
                                    type="text"
                                    className="form-control form-control-lg"
                                    defaultValue="IVE_Phase"
                                    onKeyPress={handleKeyPressInput}
                                />
                            </div>
                            <div className={cx('row')}>
                                <small>
                                    <MdDateRange className={cx('table-content-title__icon')} />{' '}
                                    <small>Sep 11,2020(Late by 464 days)</small>
                                </small>
                            </div>
                            <div className={cx('row', 'glo-close-color')}>
                                <div className={cx('glo-kanbanBug-completed', 'glo-center')}>
                                    <div className={cx('glo-kanbanBug-completed-background')}>
                                        <div
                                            className={cx('glo-kanbanBug-completed-background-color')}
                                            style={{
                                                background: '#0052cc',
                                                width: 30 + '%',
                                                height: '5px',
                                            }}
                                        ></div>
                                    </div>
                                </div>
                                <small className={cx('glo-item-work__value')}>
                                    <small>
                                        <span>{30}% Closed</span>
                                    </small>
                                </small>
                            </div>
                        </div>
                        <div className={cx('glo-milestone-body')}>
                            <div className={cx('glo-milestone-body-item')}>
                                <div>
                                    <div>#143: 20200818_STD_SDR_0012</div>
                                    <div className={cx('row', 'glo-icon-mesgin')}>
                                        <div className={cx('col-6')}>
                                            <small>
                                                <small>
                                                    <MdDateRange className={cx('table-content-title__icon')} />{' '}
                                                    2022/08/08
                                                </small>
                                            </small>
                                        </div>
                                        <div className={cx('col-6')}>
                                            <small>
                                                <small>
                                                    <AiOutlineMessage /> 5
                                                </small>
                                            </small>
                                        </div>
                                    </div>
                                </div>
                                <div className={cx('row')}>
                                    <div className={cx('col-3')}>
                                        <small>
                                            <small>
                                                <label>Close</label>
                                            </small>
                                        </small>
                                    </div>
                                    <div className={cx('col-9', 'milestone-item-right')}>
                                        <MilestoneControlTask />
                                        <small>
                                            <AiOutlineUser />
                                            <small>Nguyễn Văn A</small>
                                        </small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={cx('col-3')}></div>
                <div className={cx('col-3')}></div>
            </div> */}

            {/* <Container>
            <DragDropContext onDragEnd={onDragEnd}>
                <ListGrid>
                    {initialData.columnOrder.map((columnId) => {
                        const column = initialData.columns[columnId];
                        const tasks = column.taskIds.map((list) => list);

                        return <Column key={column.id} column={column} tasks={tasks} />;
                    })}
                </ListGrid>
            </DragDropContext>
            </Container> */}
            <div className={cx('wrapper-milestone-column')}>
                <div className={cx('milestone-scroll')}>
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Container>
                            <ListGrid>
                                {lists?.listMilestone?.map((column) => {
                                    return <Column key={column.id} column={column} />;
                                })}
                            </ListGrid>
                        </Container>
                    </DragDropContext>
                </div>
            </div>

            {lists.length !== 0 && (
                <div className={cx('d-flex', 'justify-content-end', 'my-3')}>
                    <Pagination itemsPerPage={lists?.milestonePageTotal} setPage={setPage} page={page} />
                </div>
            )}

            <CreateMilestoneDialog
                openDialogCreateMilestone={openDialogCreateMilestone}
                setOpenDialogCreateMilestone={setOpenDialogCreateMilestone}
            />
        </div>
    );
};

export default Milestone;
