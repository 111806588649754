import { useContext, useEffect, useState } from 'react';
import Pagination from '@mui/material/Pagination';
import { GlobalStateProject } from '~/ProjectState/projectState';

function Panigation({ itemsPerPage, setPage, page }) {
    // const [page, setPage] = state.projectsAPI.page;

    const handleChange = (value) => {
        setPage(value);
    };

    return (
        <Pagination
            count={itemsPerPage}
            variant="outlined"
            color="primary"
            page={page}
            onChange={(e, value) => handleChange(value)}
        />
    );
}

export default Panigation;
