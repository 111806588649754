import React, { useLayoutEffect, useState } from 'react';
import styles from './RequirementChecklist.module.scss';
import classNames from 'classnames/bind';
import { BsCheck2Square } from 'react-icons/bs';
import {
    MdCheckCircleOutline,
    MdHistory,
    MdOutlineCheckBoxOutlineBlank,
    MdOutlineStickyNote2,
    MdUpdate,
} from 'react-icons/md';
import routesConfig from '~/config/routes';
import { Link, useLocation } from 'react-router-dom';
import RequirementName from './component/RequirementName/RequirementName';
import { useNavigate } from 'react-router-dom';
import CreateRequirementCheckList from './component/CreateRequirementCheckList';
import { useEffect } from 'react';
import api from '~/utils/main';
import { Fragment } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useContext } from 'react';
import { GlobalStateProject } from '~/ProjectState/projectState';
import Moment from 'moment';
import { TbAlignJustified } from 'react-icons/tb';
import { AiOutlinePullRequest } from 'react-icons/ai';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useRef } from 'react';
import { Tooltip } from '@mui/material';
import { GlobalScrollRequirementChecklist } from '~/RequirementCheckListScroll/RequirementCheckListScroll';
import * as requirementService from '~/services/requirementService';
import Swal from 'sweetalert2';

const cx = classNames.bind(styles);

const RequirementChecklist = () => {
    const [requirementChkList, setRequirementChkList] = useState([]);
    const [openDialogRequireName, setOpenDialogRequireName] = useState(false);

    const [projectID, setProjectID] = useState(null);

    const state = useContext(GlobalStateProject);
    const [projectId] = state.projectsAPI.projectId;
    const [offsetRqm, setOffsetRqm] = state.projectsAPI.offsetRqm;

    const [countItemRequire, setCountItemRequire] = useState(0);
    const [totalCount, setTotalCount] = useState(null);
    const { scrollTop, setScrollTop } = useContext(GlobalScrollRequirementChecklist);

    const refScroll = useRef();
    const navigate = useNavigate();

    const handleCreateRequirementName = () => {
        setOpenDialogRequireName(true);
    };

    // scroll
    useEffect(() => {
        const countItemRequire = () => {
            let count = 0;
            requirementChkList.map((ele, idx) => {
                return (count += ele.listRequirements.length);
            });
            setCountItemRequire(count);
        };
        countItemRequire();
        document.querySelector('#scrollableDiv').scrollTop = scrollTop;
    }, [requirementChkList]);

    useEffect(() => {
        if (offsetRqm !== 0) {
            async function fetDataRequire() {
                const response = await getData();
                const elementEnd = requirementChkList[requirementChkList.length - 1];
                const elementEndFetchData = response.data.data[response.data.data.length - 1];
                if (elementEnd.projectId === elementEndFetchData.projectId) {
                    const rs = [...requirementChkList];
                    elementEndFetchData.listRequirements.map((e) => {
                        rs[rs.length - 1].listRequirements.push(e);
                    });

                    const filterProjectExits = response.data.data.splice(1);
                    setRequirementChkList(rs, filterProjectExits);
                } else {
                    setRequirementChkList([...requirementChkList, ...response.data.data]);
                }
            }
            fetDataRequire();
        }
    }, [offsetRqm]);

    //GET DATA

    // useEffect(() => {
    //     if (projectId != null) {
    //         getListRequireCheckLists();

    //     }
    // }, [projectId]);

    const handleScroll = (e) => {
        const scrollDemo = document.querySelector('#scrollableDiv');
        setScrollTop(scrollDemo.scrollTop);
    };

    useEffect(() => {
        getListRequireCheckLists();

        return () => setOffsetRqm(0);
    }, [projectId]);

    const getData = async () => {
        const res = await api.get(`check-list/projects`, {
            params: {
                projectIds: projectId,
                ...(offsetRqm ? { Offset: offsetRqm } : {}),
            },
        });
        return res;
    };

    const getListRequireCheckLists = async () => {
        const res = await getData();
        if (res.data.status === 'success') {
            setRequirementChkList(res.data.data);
            setTotalCount(res.data.data[0].totalCount);
        }
    };

    const fetchData = () => {
        setTimeout(() => {
            setOffsetRqm(offsetRqm + 5);
        }, 500);
    };

    // useEffect(() => {

    // }, [requirementChkList]);

    const handleClickEditRequire = (projectID, projectName, checklistName, checklistId) => {
        navigate(routesConfig.createRequirementChecklist, {
            state: {
                projectID: projectID,
                projectName: projectName,
                checklistName: checklistName,
                checklistId: checklistId,
            },
        });
    };

    const handleClickDeleteRequireName = async (checklistId) => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You want to delete requirement?',
            icon: 'warning',
            width: '400px',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            dangerMode: true,
        }).then(async (result) => {
            if (result.isConfirmed) {
                const res = await requirementService.deleteCheckListRequirement(checklistId);
                if (res.status === 204) {
                    getListRequireCheckLists();
                }
            }
        });
    };

    return (
        <div className={cx('wrapper')}>
            <div className={cx('header-page-requirement')}>
                <div className={cx('row')}>
                    <div className="col-8 col-sm-9">
                        <p className={cx('head-title')}>Requirement Checklist</p>
                    </div>
                    <div className="col-4 col-sm-3 text-end">
                        <button
                            type="text"
                            onClick={handleCreateRequirementName}
                            className={cx('btn-create-requirement')}
                            size="medium"
                        >
                            Create Requirement Name
                        </button>
                    </div>
                </div>
            </div>
            <div className={cx('require-res-table-scroll')}>
                <div className={cx('require-res-table')}>
                    <div className={cx('table-requirement-checklist', 'mt-4')}>
                        <div className={cx('table-content-head')}>
                            <div className={cx('row', 'table-content-head-title')}>
                                <div className={cx('thead-content-no', 'text-center', 'font-weight-600')}>No.</div>
                                <div className={cx('thead-content-classification', 'text-center', 'font-weight-600')}>
                                    Classification <TbAlignJustified />
                                </div>
                                <div className={cx('thead-content-requirement', 'text-center', 'font-weight-600')}>
                                    Requirement <AiOutlinePullRequest />
                                </div>
                                <div className={cx('thead-content-task', 'text-center', 'font-weight-600')}>Task #</div>
                                <div className={cx('thead-content-check', 'font-weight-600')}>
                                    Check <MdCheckCircleOutline />
                                </div>
                                <div className={cx('thead-content-memo', 'text-center', 'font-weight-600')}>
                                    Memo <MdOutlineStickyNote2 />
                                </div>
                                <div className={cx('thead-content-creator', 'font-weight-600')}>Creator</div>
                                <div className={cx('thead-content-last-update', 'font-weight-600')}>
                                    Last Update <MdUpdate />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        id="scrollableDiv"
                        style={{ height: 720, overflow: 'auto' }}
                        ref={refScroll}
                        onScroll={(e) => handleScroll(e)}
                    >
                        <InfiniteScroll
                            dataLength={requirementChkList.length}
                            next={fetchData}
                            hasMore={countItemRequire >= totalCount ? false : true}
                            loader={<h4>Loading...</h4>}
                            // height={720}
                            scrollableTarget="scrollableDiv"
                            // initialScrollY={50}
                            endMessage={
                                offsetRqm >= 20 && (
                                    <p style={{ textAlign: 'center' }}>
                                        <b>Yay! You have seen it all</b>
                                    </p>
                                )
                            }
                        >
                            <div>
                                {requirementChkList?.map((require) => {
                                    return (
                                        <div key={uuidv4()}>
                                            <div className={cx('my-3')}>
                                                <h3>{require.projectName}</h3>
                                            </div>

                                            {require?.listRequirements.map((lsRequireName, idx) => {
                                                return (
                                                    <Fragment key={uuidv4()}>
                                                        <div className={cx('head-project', 'my-3')}>
                                                            <p className={cx('rqm-project-requirementname')}>
                                                                {lsRequireName.checklistName}
                                                            </p>
                                                            {/* <Link
                                                        to={routesConfig.createRequirementChecklist}
                                                        state={{
                                                            projectID: require.projectId,
                                                            projectName: require.projectName,
                                                            checklistName: lsRequireName.checklistName,
                                                            checklistId: lsRequireName.checklistId,
                                                        }}
                                                    >
                                                        <button className={cx('btn-add-require-chklist')} onClick={() => handleClickEditRequire(require.projectId, require.projectName, lsRequireName.checklistName, lsRequireName.checklistId)}>Edit</button>
                                                    </Link> */}

                                                            <button
                                                                className={cx('btn-add-require-chklist')}
                                                                id={`${idx}edit`}
                                                                onClick={() =>
                                                                    handleClickEditRequire(
                                                                        require.projectId,
                                                                        require.projectName,
                                                                        lsRequireName.checklistName,
                                                                        lsRequireName.checklistId,
                                                                    )
                                                                }
                                                            >
                                                                Edit
                                                            </button>
                                                            <button
                                                                className={cx('btn-delete-require-chklist')}
                                                                id={`${idx}edit`}
                                                                onClick={() =>
                                                                    handleClickDeleteRequireName(
                                                                        lsRequireName.checklistId,
                                                                    )
                                                                }
                                                            >
                                                                Delete
                                                            </button>
                                                        </div>
                                                        <div className={cx('table-border')}>
                                                            {lsRequireName?.checklistItems?.map((requireList) => {
                                                                return (
                                                                    <div
                                                                        className={cx(
                                                                            'table-content-body-data',
                                                                            'row',
                                                                            'glo-row',
                                                                        )}
                                                                        key={uuidv4()}
                                                                    >
                                                                        <div
                                                                            className={cx(
                                                                                'tbl-content-no',
                                                                                'border-right',
                                                                                'border-left',
                                                                            )}
                                                                        >
                                                                            {requireList?.number?.map(
                                                                                (dataID, indexTask) => {
                                                                                    return (
                                                                                        <div
                                                                                            className={cx(
                                                                                                'item-padd',
                                                                                                'border-top',
                                                                                            )}
                                                                                            style={
                                                                                                requireList?.listTasks[
                                                                                                    indexTask
                                                                                                ]?.length > 0
                                                                                                    ? {
                                                                                                        height:
                                                                                                            `${requireList
                                                                                                                ?.listTasks[
                                                                                                                indexTask
                                                                                                            ]
                                                                                                                ?.length *
                                                                                                            24 +
                                                                                                            21
                                                                                                            }` + 'px',
                                                                                                    }
                                                                                                    : {}
                                                                                            }
                                                                                            key={uuidv4()}
                                                                                        >
                                                                                            {dataID}
                                                                                        </div>
                                                                                    );
                                                                                },
                                                                            )}
                                                                        </div>
                                                                        <div
                                                                            className={cx(
                                                                                'tbl-content-classification',
                                                                                'border-right',
                                                                            )}
                                                                        >
                                                                            {requireList?.classification?.map(
                                                                                (dataClassi, idx) => {
                                                                                    return (
                                                                                        <div
                                                                                            key={idx}
                                                                                            className={cx(
                                                                                                'border-top',
                                                                                                'item-padd',
                                                                                                'height-100-percent',
                                                                                            )}
                                                                                        >
                                                                                            {dataClassi === '' ||
                                                                                                dataClassi === null ? (
                                                                                                <span>&nbsp;</span>
                                                                                            ) : (
                                                                                                <span
                                                                                                    className={cx(
                                                                                                        'text-ellip',
                                                                                                    )}
                                                                                                >
                                                                                                    {dataClassi}
                                                                                                </span>
                                                                                            )}
                                                                                        </div>
                                                                                    );
                                                                                },
                                                                            )}
                                                                        </div>
                                                                        <div
                                                                            className={cx(
                                                                                'tbl-content-requirement',
                                                                                'border-right',
                                                                            )}
                                                                        >
                                                                            {requireList?.requirement?.map(
                                                                                (dataRequirement, indexTask) => {
                                                                                    return (
                                                                                        <div
                                                                                            className={cx(
                                                                                                'border-top',
                                                                                                'item-padd',
                                                                                            )}
                                                                                            style={
                                                                                                requireList?.listTasks[
                                                                                                    indexTask
                                                                                                ]?.length > 0
                                                                                                    ? {
                                                                                                        height:
                                                                                                            `${requireList
                                                                                                                ?.listTasks[
                                                                                                                indexTask
                                                                                                            ]
                                                                                                                ?.length *
                                                                                                            24 +
                                                                                                            21
                                                                                                            }` + 'px',
                                                                                                    }
                                                                                                    : {}
                                                                                            }
                                                                                            key={uuidv4()}
                                                                                        >
                                                                                            <Tooltip
                                                                                                arrow
                                                                                                title={
                                                                                                    <span
                                                                                                        style={{
                                                                                                            fontSize:
                                                                                                                '1.4rem',
                                                                                                        }}
                                                                                                        placement="bottom-start"
                                                                                                    >
                                                                                                        {
                                                                                                            dataRequirement
                                                                                                        }
                                                                                                    </span>
                                                                                                }
                                                                                            >
                                                                                                {dataRequirement ===
                                                                                                    '' ||
                                                                                                    dataRequirement ===
                                                                                                    null ? (
                                                                                                    <span>&nbsp;</span>
                                                                                                ) : (
                                                                                                    <span
                                                                                                        className={cx(
                                                                                                            'text-ellip',
                                                                                                        )}
                                                                                                    >
                                                                                                        {
                                                                                                            dataRequirement
                                                                                                        }
                                                                                                    </span>
                                                                                                )}
                                                                                            </Tooltip>
                                                                                        </div>
                                                                                    );
                                                                                },
                                                                            )}
                                                                        </div>
                                                                        <div
                                                                            className={cx(
                                                                                'tbl-content-task',
                                                                                'border-right',
                                                                            )}
                                                                        >
                                                                            {requireList?.listTasks?.map(
                                                                                (dataTask, idx) => {
                                                                                    return (
                                                                                        <Fragment key={uuidv4()}>
                                                                                            {dataTask.length !== 0 ? (
                                                                                                <div
                                                                                                    className={cx(
                                                                                                        'border-top',
                                                                                                        'item-padd',
                                                                                                    )}
                                                                                                >
                                                                                                    <span
                                                                                                        className={cx(
                                                                                                            'width-task-100',
                                                                                                        )}
                                                                                                    >
                                                                                                        {dataTask?.map(
                                                                                                            (
                                                                                                                task,
                                                                                                                idx,
                                                                                                            ) => {
                                                                                                                return (
                                                                                                                    <div
                                                                                                                        key={
                                                                                                                            idx
                                                                                                                        }
                                                                                                                        className={cx(
                                                                                                                            'row',
                                                                                                                        )}
                                                                                                                    >
                                                                                                                        <span
                                                                                                                            className={cx(
                                                                                                                                'task-require-ckl',
                                                                                                                                'text-ellip',
                                                                                                                            )}
                                                                                                                            key={uuidv4()}
                                                                                                                        >
                                                                                                                            #&nbsp;
                                                                                                                            <Link
                                                                                                                                to={`/task/${task.taskId}`}
                                                                                                                                target="_blank"
                                                                                                                                className={cx(
                                                                                                                                    'item-task-link',
                                                                                                                                )}
                                                                                                                            >
                                                                                                                                {
                                                                                                                                    task.taskId
                                                                                                                                }
                                                                                                                            </Link>
                                                                                                                            &nbsp;
                                                                                                                            {
                                                                                                                                task.status
                                                                                                                            }
                                                                                                                            <i>
                                                                                                                                ,
                                                                                                                            </i>
                                                                                                                            &nbsp;
                                                                                                                        </span>
                                                                                                                    </div>
                                                                                                                );
                                                                                                            },
                                                                                                        )}
                                                                                                    </span>
                                                                                                </div>
                                                                                            ) : (
                                                                                                <div
                                                                                                    className={cx(
                                                                                                        'border-top',
                                                                                                        'item-padd',
                                                                                                    )}
                                                                                                >
                                                                                                    <span
                                                                                                        className={cx(
                                                                                                            'task-require-ckl',
                                                                                                        )}
                                                                                                    >
                                                                                                        &nbsp;
                                                                                                    </span>
                                                                                                </div>
                                                                                            )}
                                                                                        </Fragment>
                                                                                    );
                                                                                },
                                                                            )}
                                                                        </div>
                                                                        <div
                                                                            className={cx(
                                                                                'tbl-content-check',
                                                                                'border-right',
                                                                            )}
                                                                        >
                                                                            {requireList?.isCheck?.map(
                                                                                (dataCheckDone, indexTask) => {
                                                                                    return (
                                                                                        <Fragment key={uuidv4()}>
                                                                                            {dataCheckDone ? (
                                                                                                <div
                                                                                                    className={cx(
                                                                                                        'border-top',
                                                                                                        'item-padd',
                                                                                                        'contentCenter',
                                                                                                        'height-item-check',
                                                                                                    )}
                                                                                                    style={
                                                                                                        requireList
                                                                                                            ?.listTasks[
                                                                                                            indexTask
                                                                                                        ]?.length > 0
                                                                                                            ? {
                                                                                                                height:
                                                                                                                    `${requireList
                                                                                                                        ?.listTasks[
                                                                                                                        indexTask
                                                                                                                    ]
                                                                                                                        ?.length *
                                                                                                                    24 +
                                                                                                                    21
                                                                                                                    }` +
                                                                                                                    'px',
                                                                                                            }
                                                                                                            : {}
                                                                                                    }
                                                                                                >
                                                                                                    <span>
                                                                                                        <BsCheck2Square
                                                                                                            className={cx(
                                                                                                                'icons-check',
                                                                                                                'text-center',
                                                                                                            )}
                                                                                                        />
                                                                                                    </span>
                                                                                                </div>
                                                                                            ) : (
                                                                                                <div
                                                                                                    className={cx(
                                                                                                        'border-top',
                                                                                                        'item-padd',
                                                                                                        'contentCenter',
                                                                                                        'height-item-check',
                                                                                                    )}
                                                                                                    style={
                                                                                                        requireList
                                                                                                            ?.listTasks[
                                                                                                            indexTask
                                                                                                        ]?.length > 0
                                                                                                            ? {
                                                                                                                height:
                                                                                                                    `${requireList
                                                                                                                        ?.listTasks[
                                                                                                                        indexTask
                                                                                                                    ]
                                                                                                                        ?.length *
                                                                                                                    24 +
                                                                                                                    21
                                                                                                                    }` +
                                                                                                                    'px',
                                                                                                            }
                                                                                                            : {}
                                                                                                    }
                                                                                                >
                                                                                                    <span>
                                                                                                        <MdOutlineCheckBoxOutlineBlank
                                                                                                            className={cx(
                                                                                                                'icons-uncheck',
                                                                                                                'text-center',
                                                                                                            )}
                                                                                                        />
                                                                                                    </span>
                                                                                                </div>
                                                                                            )}
                                                                                        </Fragment>
                                                                                    );
                                                                                },
                                                                            )}
                                                                        </div>

                                                                        <div
                                                                            className={cx(
                                                                                'tbl-content-memo',
                                                                                'border-right',
                                                                            )}
                                                                        >
                                                                            {requireList.memo.map(
                                                                                (dataMemo, indexTask) => {
                                                                                    return (
                                                                                        <div
                                                                                            className={cx(
                                                                                                'border-top',
                                                                                                'item-padd',
                                                                                                'height-item-memo',
                                                                                            )}
                                                                                            key={uuidv4()}
                                                                                            style={
                                                                                                requireList?.listTasks[
                                                                                                    indexTask
                                                                                                ]?.length > 0
                                                                                                    ? {
                                                                                                        height:
                                                                                                            `${requireList
                                                                                                                ?.listTasks[
                                                                                                                indexTask
                                                                                                            ]
                                                                                                                ?.length *
                                                                                                            24 +
                                                                                                            21
                                                                                                            }` + 'px',
                                                                                                    }
                                                                                                    : {}
                                                                                            }
                                                                                        >
                                                                                            <Tooltip
                                                                                                arrow
                                                                                                title={
                                                                                                    <span
                                                                                                        style={{
                                                                                                            fontSize:
                                                                                                                '1.4rem',
                                                                                                        }}
                                                                                                        arrow
                                                                                                    >
                                                                                                        {dataMemo}
                                                                                                    </span>
                                                                                                }
                                                                                            >
                                                                                                {dataMemo === '' ||
                                                                                                    dataMemo === null ? (
                                                                                                    <span>&nbsp;</span>
                                                                                                ) : (
                                                                                                    <span
                                                                                                        className={cx(
                                                                                                            'text-ellip',
                                                                                                        )}
                                                                                                    >
                                                                                                        {dataMemo}
                                                                                                    </span>
                                                                                                )}
                                                                                            </Tooltip>
                                                                                        </div>
                                                                                    );
                                                                                },
                                                                            )}
                                                                        </div>
                                                                        {/* creator */}
                                                                        <div
                                                                            className={cx(
                                                                                'tbl-content-creator',
                                                                                'border-right',
                                                                            )}
                                                                        >
                                                                            {requireList.memo.map(
                                                                                (dataMemo, indexTask) => {
                                                                                    return (
                                                                                        <div
                                                                                            className={cx(
                                                                                                'border-top',
                                                                                                'item-padd',
                                                                                                'height-item-memo',
                                                                                            )}
                                                                                            key={uuidv4()}
                                                                                            style={
                                                                                                requireList?.listTasks[
                                                                                                    indexTask
                                                                                                ]?.length > 0
                                                                                                    ? {
                                                                                                        height:
                                                                                                            `${requireList
                                                                                                                ?.listTasks[
                                                                                                                indexTask
                                                                                                            ]
                                                                                                                ?.length *
                                                                                                            24 +
                                                                                                            21
                                                                                                            }` + 'px',
                                                                                                    }
                                                                                                    : {}
                                                                                            }
                                                                                        >
                                                                                            <Tooltip
                                                                                                arrow
                                                                                                title={
                                                                                                    <span
                                                                                                        style={{
                                                                                                            fontSize:
                                                                                                                '1.4rem',
                                                                                                        }}
                                                                                                        arrow
                                                                                                    >
                                                                                                        {dataMemo}
                                                                                                    </span>
                                                                                                }
                                                                                            >
                                                                                                {dataMemo === '' ||
                                                                                                    dataMemo === null ? (
                                                                                                    <span>&nbsp;</span>
                                                                                                ) : (
                                                                                                    <span
                                                                                                        className={cx(
                                                                                                            'text-ellip',
                                                                                                        )}
                                                                                                    >
                                                                                                        {dataMemo}
                                                                                                    </span>
                                                                                                )}
                                                                                            </Tooltip>
                                                                                        </div>
                                                                                    );
                                                                                },
                                                                            )}
                                                                        </div>
                                                                        <div
                                                                            className={cx(
                                                                                'tbl-content-last-update',
                                                                                'border-right',
                                                                            )}
                                                                        >
                                                                            {requireList?.dtUpdated?.map(
                                                                                (dataLastUpdate, indexTask) => {
                                                                                    return (
                                                                                        <div
                                                                                            className={cx(
                                                                                                'border-top',
                                                                                                'item-padd',
                                                                                                'height-item-lastUpdate',
                                                                                            )}
                                                                                            style={
                                                                                                requireList?.listTasks[
                                                                                                    indexTask
                                                                                                ]?.length > 0
                                                                                                    ? {
                                                                                                        height:
                                                                                                            `${requireList
                                                                                                                ?.listTasks[
                                                                                                                indexTask
                                                                                                            ]
                                                                                                                ?.length *
                                                                                                            24 +
                                                                                                            21
                                                                                                            }` + 'px',
                                                                                                    }
                                                                                                    : {}
                                                                                            }
                                                                                            key={uuidv4()}
                                                                                        >
                                                                                            {/* {dataLastUpdate} */}
                                                                                            {dataLastUpdate === '' ||
                                                                                                dataLastUpdate === null ? (
                                                                                                <span>&nbsp;</span>
                                                                                            ) : (
                                                                                                <span>
                                                                                                    {Moment(
                                                                                                        dataLastUpdate,
                                                                                                    ).format(
                                                                                                        'DD-MM-YYYY',
                                                                                                    )}
                                                                                                </span>
                                                                                            )}
                                                                                        </div>
                                                                                    );
                                                                                },
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    </Fragment>
                                                );
                                            })}
                                        </div>
                                    );
                                })}
                            </div>
                        </InfiniteScroll>
                    </div>
                </div>
            </div>

            <RequirementName
                openDialogRequireName={openDialogRequireName}
                setOpenDialogRequireName={setOpenDialogRequireName}
                projectID={projectID}
                setProjectID={setProjectID}
                getListRequireCheckLists={getListRequireCheckLists}
            />
        </div>
    );
};

export default RequirementChecklist;
