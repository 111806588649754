import React from 'react';
import classNames from 'classnames/bind';
import styles from './ReviewMember.module.scss';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import routesConfig from '~/config/routes';
import * as reviewMemberService from '~/services/reviewMemberService';
import Moment from 'moment';
import { useRef } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import DialogResultReview from './component/DialogResultReview/DialogResultReview';
import DialogNotifyReview from './component/DialogNotifyReview/DialogNotifyReview';
import DialogCreateQuestions from './component/DialogCreateQuestions/DialogCreateQuestions';
import { TiTick } from 'react-icons/ti';
const cx = classNames.bind(styles);

const ReviewMember = () => {
    const [listAllReview, setListAllReview] = useState([]);
    const [openDialogResultRv, setOpenDialogResultRv] = useState(false);
    const [openDialogNotifyRv, setOpenDialogNotifyRv] = useState(false);
    const [openDialogCreateQuestion, setOpenDialogCreateQuestion] = useState(false);
    const [loadingData, setLoadingData] = useState(false);
    const [monthRv, setMonthRv] = useState('');
    const [yearRv, setYearRv] = useState('');

    const [searchName, setSearchName] = useState('');
    const [reviewID, setReviewID] = useState(0);
    const [nameRv, setNameRv] = useState('');

    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(11);

    const [countItemRequire, setCountItemRequire] = useState(0);
    const [totalCount, setTotalCount] = useState(null);

    const refScroll = useRef();

    const navigate = useNavigate();
    const auth = useSelector((state) => state.auth);

    useEffect(() => {
        const countItemRequire = () => {
            let count = 0;
            count += listAllReview.length;
            setCountItemRequire(count);
        };
        countItemRequire();
    }, [listAllReview]);

    useEffect(() => {
        if (offset !== 0) {
            async function fetchData() {
                const resresponse = await getData();
                setListAllReview(listAllReview.concat(resresponse.data.data.listUserReview));
            }
            fetchData();
        }
    }, [offset]);

    const getData = async () => {
        const params = {
            Month: monthRv,
            Year: yearRv,
            ...(offset ? { Offset: offset } : {}),
            ...(limit ? { Limit: limit } : {}),
            ...(searchName ? { SearchName: searchName } : {}),
        };
        const res = await reviewMemberService.getListAllReviewOfMember(params);
        return res;
    };

    const getListAllReview = async () => {
        const res = await getData();
        if (res.status === 200) {
            setListAllReview(res.data.data.listUserReview);
            setTotalCount(res.data.data.totalCount);
        }
    };

    const fetchData = () => {
        setTimeout(() => {
            setOffset(offset + 11);
        }, 500);
    };

    const handleSearchTimeReview = () => {
        getListAllReview();
    };

    const handleChangeMonth = (e) => {
        setMonthRv(e.target.value);
    };

    const handleChangeYear = (e) => {
        setYearRv(e.target.value);
    };

    //start seart
    useEffect(() => {
        if (
            monthRv !== 'undefined' &&
            monthRv !== '' &&
            yearRv !== '' &&
            yearRv !== 'undefined' &&
            monthRv !== null &&
            yearRv !== null
        ) {
            getListAllReview();
        }
    }, [searchName]);

    const getKeyEnterSearchUser = async (e) => {
        if (e.key === 'Enter') {
            getListAllReview();
        }
    };

    const handleSearchFilterUser = (e) => {
        const searchUser = e.target.value;
        if (!searchName.startsWith(' ')) {
            setOffset(0);
            setSearchName(searchUser);
        }

        if (searchName === '') {
            getListAllReview();
        }
    };
    //end search

    //detail
    const handleClickViewReview = async (id, userName) => {
        // navigate(`/detail-review/${id}`, {
        //     state: { ReviewId: id, UserName: userName, EmailUser: emailUser },
        // });
        setOpenDialogResultRv(true);
        setReviewID(id);
        setNameRv(userName);
    };

    const handleNotifyReview = async () => {
        setOpenDialogNotifyRv(true);
    };

    const handleOpenDialogCreateQuestion = () => {
        setOpenDialogCreateQuestion(true);
    };

    return (
        <div className={cx('wrapper-review-list')}>
            <div className={cx('review-form-head')}>
                <p className={cx('mt-4', 'mb-4', 'review-form-title')}>Review Form</p>
            </div>
            <div className={cx('review-form-content')}>
                <div className={cx('rev-role-check my-3')}>
                    <div className={cx('row', 'align-items-center')}>
                        <div className={cx('col-12 col-xl-4')}>
                            <div className={cx('row')}>
                                <label className={cx('col-sm-3 col-form-label')}>Review time:</label>
                                <div className={cx('col-sm-4')}>
                                    <div className={cx('row')}>
                                        <div className={cx('row')}>
                                            <label htmlFor="monthReview" className={cx('col-sm-6', 'col-form-label')}>
                                                Month
                                            </label>
                                            <div className="col-sm-6">
                                                <input
                                                    type="number"
                                                    className={cx('form-control', 'form-control-lg')}
                                                    id="monthReview"
                                                    min="1"
                                                    max="12"
                                                    value={monthRv}
                                                    onChange={handleChangeMonth}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={cx('col-sm-3')}>
                                    <div className={cx('row')}>
                                        <label htmlFor="yearReview" className={cx('col-sm-4', 'col-form-label')}>
                                            Year
                                        </label>
                                        <div className="col-sm-8">
                                            <input
                                                type="number"
                                                className={cx('form-control', 'form-control-lg')}
                                                id="yearReview"
                                                min="2017"
                                                value={yearRv}
                                                onChange={handleChangeYear}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className={cx('col-sm-2')}>
                                    <button
                                        type="submit"
                                        onClick={handleSearchTimeReview}
                                        className={cx('hn-btn-submit')}
                                        size="medium"
                                    >
                                        Search
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className={cx('col-12 col-xl-8', 'text-end')}>
                            <button
                                onClick={handleOpenDialogCreateQuestion}
                                className={cx('btn-create-questions')}
                                size="medium"
                            >
                                Create Questions
                            </button>
                            <button onClick={handleNotifyReview} className={cx('hn-btn-submit')} size="medium">
                                Notifications
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className={cx('review-list-content')}>
                <div className={cx('rev-search')}>
                    <div className={cx('row')}>
                        <div className={cx('col-xl-9')}>
                            <h2 className={cx('rev-title')}>List of review results</h2>
                        </div>
                        <div className={cx('col-xl-3', 'justify-content-end')}>
                            <div className={cx('form-group', 'row', 'hn-align-center')}>
                                <label htmlFor="search-user" className={cx('lbl-search', 'col-sm-2')}>
                                    Search:
                                </label>
                                <div className={cx('col-lg-9 col-xl-6 col-xxl-10')}>
                                    <input
                                        id="search-user"
                                        type="search"
                                        placeholder="Search user..."
                                        className={cx('form-control', 'form-control-lg')}
                                        value={searchName}
                                        onChange={handleSearchFilterUser}
                                        onKeyDown={getKeyEnterSearchUser}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={cx('list-user-table-content')}>
                    <div className={cx('list-user_table')}>
                        <div className={cx('list-user_title-table')}>
                            {/* <th className={cx('list-user_title-table-id')}>No</th> */}
                            <div className={cx('table-head-username')}>User Name</div>
                            <div className={cx('table-head-email')}>Email</div>
                            <div className={cx('table-head-member-rv')}>Member Reviewed</div>
                            <div className={cx('table-head-leader-rv')}>Leader Reviewed</div>
                            <div className={cx('table-head-review-date')}>Review Date</div>
                            <div className={cx('table-head-action')}>Action</div>
                        </div>

                        <div id="scrollableDiv" style={{ height: 550, overflow: 'auto' }} ref={refScroll}>
                            <InfiniteScroll
                                dataLength={listAllReview.length}
                                next={fetchData}
                                hasMore={countItemRequire >= totalCount || searchName !== '' ? false : true}
                                loader={<h4>Loading...</h4>}
                                scrollableTarget="scrollableDiv"
                            >
                                <div>
                                    {listAllReview.length === 0 ? (
                                        <p className={cx('text-danger', 'text-center')}>No data available in table</p>
                                    ) : (
                                        <>
                                            {listAllReview?.map((lstReview, index) => {
                                                return (
                                                    <div className={cx('list-user_item-body-tr')} key={index}>
                                                        <div className={cx('tbl-body-username')}>
                                                            <span
                                                                dangerouslySetInnerHTML={{
                                                                    __html: lstReview.userName,
                                                                }}
                                                            />
                                                        </div>
                                                        <div className={cx('tbl-body-email')}>
                                                            {lstReview.emailUser}
                                                        </div>
                                                        <div className={cx('tbl-body-member-rv')}>
                                                            {/* <input type="checkbox" name="" defaultChecked={lstReview.memberReviewed} /> */}
                                                            {lstReview.memberReviewed ? (
                                                                <TiTick style={{ color: '#45ba13' }} />
                                                            ) : (
                                                                ''
                                                            )}
                                                        </div>
                                                        <div className={cx('tbl-body-leader-rv')}>
                                                            {/* <input
                                                                type="checkbox"
                                                                name=""
                                                                defaultChecked={lstReview.leaderReviewed}
                                                            /> */}
                                                            {lstReview.leaderReviewed ? (
                                                                <TiTick style={{ color: '#45ba13' }} />
                                                            ) : (
                                                                ''
                                                            )}
                                                        </div>
                                                        <div className={cx('tbl-body-review-date')}>
                                                            {Moment(lstReview.reviewCreated).format('DD-MM-YYYY')}
                                                        </div>
                                                        <div className={cx('tbl-body-action')}>
                                                            <button
                                                                className={cx('btn-view-review')}
                                                                onClick={() =>
                                                                    handleClickViewReview(
                                                                        lstReview.id,
                                                                        lstReview.userName,
                                                                    )
                                                                }
                                                            >
                                                                View
                                                            </button>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </>
                                    )}
                                </div>
                            </InfiniteScroll>
                        </div>
                    </div>
                </div>
            </div>
            <DialogResultReview
                openDialogResultRv={openDialogResultRv}
                setOpenDialogResultRv={setOpenDialogResultRv}
                nameRv={nameRv}
                reviewID={reviewID}
            />
            <DialogNotifyReview openDialogNotifyRv={openDialogNotifyRv} setOpenDialogNotifyRv={setOpenDialogNotifyRv} />
            <DialogCreateQuestions
                openDialogCreateQuestion={openDialogCreateQuestion}
                setOpenDialogCreateQuestion={setOpenDialogCreateQuestion}
            />
        </div>
    );
};

export default ReviewMember;
