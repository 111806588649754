import axios from 'axios';
// import * as request from '~/utils/httpRequest';
import api from '~/utils/main';

export const login = async (email, password, emailInput = '', passwordInput = '') => {
    try {
        const res = await api.post(
            `admin/login`,
            {
                userId: emailInput || email,
                password: passwordInput || password,
            },
            { headers: { 'Content-Type': 'application/json' }, withCredentials: true },
        );

        return res.data;
    } catch (error) {
        return error.response;
    }
};

export const resetPassword = async (email) => {
    try {
        const res = await api.post(`admin/user/confirm-mail-forget-password?email=${email}`);

        return res.data;
    } catch (error) {
        return error.response;
    }
};

export const updatePassword = async (body) => {
    try {
        const res = await api.put(`admin/user/password`, body);

        return res.data;
    } catch (error) {
        return error.response;
    }
};
