import { toast } from 'react-toastify';
import api from '~/utils/main';

export const getDataDetailTask = async (idTask, isNoToast = false, offset = 0) => {
    try {
        let res;
        const params = {
            ...(offset ? { Offset: offset } : {}),
        };

        if (isNoToast === false) {
            res = await toast.promise(api.get(`task/${idTask}`, { params }), {
                pending: 'Open task is pending',
                success: 'Open task successful',
                error: 'Open task failed',
            });
        } else {
            res = await api.get(`task/${idTask}`, { params });
        }
        return res;
    } catch (error) {
        console.log(error);
    }
};

export const getDataTaskChild = async (idTask, projectIdTask, uniqId = false, typeSort = '', isTypeSort = '') => {
    try {
        let params;
        if (uniqId === true && idTask.length > 12) {
            params = {
                uniqId: idTask,
                projectId: projectIdTask,
            };
        } else {
            params = {
                id: idTask,
                projectId: projectIdTask,
                ...(isTypeSort ? { SortType: isTypeSort } : {}),
                ...(typeSort === 'assign' ? { IsSortAssignTo: typeSort === 'assign' ? true : false } : {}),
                ...(typeSort === 'status' ? { IsSortStatus: typeSort === 'status' ? true : false } : {}),
            };
        }
        const res = await api.get(`task/${idTask}/references`, {
            params,
        });
        return res;
    } catch (error) {
        console.log(error);
    }
};

export const getInitInfoTask = async (idTask) => {
    try {
        const params = {
            easycaseId: idTask,
        };
        const res = await api.get(`task/init-update`, {
            params,
        });
        return res;
    } catch (error) {
        console.log(error);
    }
};

export const createPathFile = async (idTask, pathFile) => {
    try {
        const body = {
            taskId: idTask,
            pathFile,
        };
        const res = await toast.promise(api.post(`task/create-path-file/${idTask}`, body), {
            pending: 'Create path file task is pending',
            success: 'Create path file task successfully',
            error: 'Create path file task failed',
        });
        return res;
    } catch (error) {
        console.log(error);
    }
};

export const updatePathFile = async (idTask, pathFile) => {
    try {
        const body = {
            taskId: idTask,
            pathFile,
        };
        const res = await toast.promise(api.patch(`task/update-path-file/${idTask}`, body), {
            pending: 'Update path file task is pending',
            success: 'Update path file task successfully',
            error: 'Update path file task failed',
        });
        return res;
    } catch (error) {
        console.log(error);
    }
};

export const updatePoint = async (taskId, point) => {
    try {
        const body = {
            taskId,
            point,
        };
        const res = await api.patch(`task/update-point-task/${taskId}`, body);
        return res;
    } catch (error) {
        console.log(error);
    }
};
