import { useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw } from 'draft-js';
import { convertToHTML, convertFromHTML } from 'draft-convert';

function EditFormKanBan(props) {
    const { name, onChange, setEditContent, id, editContent } = props;

    const [nameState, setNameState] = useState(EditorState.createWithContent(convertFromHTML(name)));

    function handleEditForm(state) {
        setNameState(state);
        setEditContent(convertToHTML(state.getCurrentContent()));
    }
    return (
        <Editor
            editorState={nameState}
            onEditorStateChange={handleEditForm}
            wrapperClassName={`wrapper-form__edit display-wrapper wrapper-form__edit${id}`}
            editorClassName={`form-edit_comment form-edit${id}`}
            toolbarClassName="toolbar-class"
            placeholder="Edit your comment...."
        />
    );
}

export default EditFormKanBan;
