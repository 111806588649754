import api from '~/utils/main';

export const logout = async () => {
    try {
        const res = await api.post(`admin/me/logout`);

        return res;
    } catch (error) {
        return error.response;
    }
};
