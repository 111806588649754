import { useState, useRef, useEffect } from 'react';
import classNames from 'classnames/bind';
import styles from './Login.module.scss';
import Logo from '../../assets/images/logo/glotech.png';
import ReactLoading from 'react-loading';
import { dispatchLogin } from '~/redux/actions/authAction';
import { dispatchToken } from '~/redux/actions/tokenAction';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { callToWindowApp } from '~/common/common';
import * as LoginService from '~/services/loginService';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { SpaRounded } from '@mui/icons-material';
const cx = classNames.bind(styles);

const initialState = {
    email: '',
    password: '',
    err: '',
    success: '',
};

function Login() {
    const [user, setUser] = useState(initialState);
    const [loading, setLoading] = useState(false);
    const [isForgotPassword, setIsForgotPassword] = useState(false);
    const [emailForgotPw, setEmailForgotPw] = useState('');
    const [code, setCode] = useState('');
    const [isRedirectEnterCode, setIsRedirectEnterCode] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [msgSentCode, setMsgSentCode] = useState('');
    const [msgUpdatePassWordSuccess, setMsgUpdatePassWordSuccess] = useState('');

    const dispatch = useDispatch();

    const { email, password, err, success } = user;
    let navigate = useNavigate();

    const inputEmail = useRef();
    const inputPassword = useRef();
    const inputEmailForgotPw = useRef();
    const inputCode = useRef();

    const auth = useSelector((state) => state.auth);
    const { isLogged } = auth;

    useEffect(() => {
        if (isLogged && localStorage.getItem('firstLogin')) {
            navigate('/');
        }
    }, []);

    useEffect(() => {
        inputEmailForgotPw.current?.focus();
        inputEmail.current?.focus();
        inputCode.current?.focus();
    }, [isForgotPassword, isRedirectEnterCode]);

    const handleInputEmail = (value) => {
        msgUpdatePassWordSuccess && setMsgUpdatePassWordSuccess('');
        setUser({ ...user, email: value, err: '', success: '' });
    };

    const handleInputPassword = (value) => {
        msgUpdatePassWordSuccess && setMsgUpdatePassWordSuccess('');
        setUser({ ...user, password: value, err: '', success: '' });
    };

    // login
    const handleSubmitLogin = async (e) => {
        e.preventDefault();
        const emailInput = inputEmail.current.value;
        const passwordInput = inputPassword.current.value;

        setLoading(true);
        const result = await LoginService.login(email, password, emailInput, passwordInput);

        if (result.status === 400) {
            setUser({ email: '', password: '', err: result.data.errorMessage, success: '' });
            setLoading(false);
            inputEmail.current.focus();
        } else {
            const { id, userName, accessToken, userEmail, userType } = result.result;
            setUser({ ...user, success: result.success });
            localStorage.setItem('user', JSON.stringify({ id, userName, userEmail, userType }));
            localStorage.setItem('accessToken', accessToken);
            dispatch(dispatchToken(accessToken));
            dispatch(dispatchLogin({ id, userName, userEmail, userType }));
            navigate(-1);
            toast.success(`Login successfully! 🚀`, {
                position: 'bottom-left',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setLoading(false);
            callToWindowApp('CallAtLogin', []);
        }
    };

    const handleForgotPassword = () => {
        setIsForgotPassword(true);
    };

    const handleBackLogin = () => {
        setIsForgotPassword(false);
    };

    const handleBackResetPassword = () => {
        setIsRedirectEnterCode(false);
        setIsForgotPassword(true);
    };

    const handleInputEmailForgotPw = (value) => {
        setEmailForgotPw(value);
    };

    const handleInputChangeCode = (value) => {
        setCode(value);
        setMsgSentCode('');
    };

    const handleSendCodeResetPassword = async (e) => {
        e.preventDefault();

        setLoading(true);
        const res = await LoginService.resetPassword(emailForgotPw);

        if (res.success) {
            setLoading(false);
            setIsRedirectEnterCode(true);
            setMsgSentCode('The code has been sent to your email');
        }
    };

    const handleChangeNewPassword = (value) => {
        setNewPassword(value);
    };

    const handleUpdatePassword = async (e) => {
        e.preventDefault();

        const body = {
            email: emailForgotPw,
            password: newPassword,
            verifyCode: code,
        };

        setLoading(true);
        const res = await LoginService.updatePassword(body);

        if (res.success) {
            setLoading(false);
            setIsRedirectEnterCode(false);
            setIsForgotPassword(false);
            setCode('');
            setNewPassword('');
            setEmailForgotPw('');
            setMsgUpdatePassWordSuccess('New password has been updated');
        }
    };

    return (
        <div className={cx('login-page')}>
            <div className={cx('wrapper-login')}>
                <div className={cx('login-container')}>
                    <img src={Logo} alt="logo" className={cx('image-logo')} />

                    {isForgotPassword ? (
                        isRedirectEnterCode ? (
                            <>
                                <form onSubmit={handleUpdatePassword}>
                                    <div className={cx('login-title')}>
                                        <h2>Verify code</h2>
                                    </div>
                                    <div className="mb-4">
                                        <input
                                            ref={inputCode}
                                            value={code}
                                            type="number"
                                            className={cx('form-control form-control-lg', 'wrapper-input')}
                                            // id="inputUsername"
                                            placeholder="Enter code"
                                            autoFocus
                                            onChange={(e) => handleInputChangeCode(e.target.value)}
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <input
                                            // ref={inputCode}
                                            value={newPassword}
                                            type="text"
                                            className={cx('form-control form-control-lg', 'wrapper-input')}
                                            // id="inputUsername"
                                            placeholder="Enter new password"
                                            autoFocus
                                            onChange={(e) => handleChangeNewPassword(e.target.value)}
                                        />
                                    </div>
                                    <div className="row">
                                        <div className={cx('wrapper-message')}>
                                            {err && <p className={cx('error-message')}>{err}</p>}
                                            {msgSentCode && <span className={cx('message-sent')}>{msgSentCode}</span>}
                                        </div>
                                    </div>
                                    <div className={cx('wrapper-btn-submit')}>
                                        <button
                                            type="submit"
                                            id="btn-submit-login"
                                            className={cx('btn', 'btn-lg', 'btn-login')}
                                            onClick={handleUpdatePassword}
                                        >
                                            Update Password
                                        </button>
                                    </div>
                                    {loading && (
                                        <ReactLoading
                                            className="d-flex justify-content-center"
                                            type="bubbles"
                                            color="#ccc"
                                            height={'40px'}
                                            width={'100%'}
                                        />
                                    )}
                                </form>
                                <div className={cx('forgot-password')}>
                                    <span onClick={handleBackResetPassword}>Reset Password</span>
                                </div>
                            </>
                        ) : (
                            <>
                                <form onSubmit={handleSendCodeResetPassword}>
                                    <div className={cx('login-title')}>
                                        <h2>Reset Password</h2>
                                    </div>
                                    <div className="mb-4">
                                        <input
                                            ref={inputEmailForgotPw}
                                            value={emailForgotPw}
                                            type="email"
                                            className={cx('form-control form-control-lg', 'wrapper-input')}
                                            // id="inputUsername"
                                            placeholder="Email"
                                            autoFocus
                                            onChange={(e) => handleInputEmailForgotPw(e.target.value)}
                                        />
                                    </div>
                                    <div className="row">
                                        <div className={cx('wrapper-message')}>
                                            {err && <p className={cx('error-message')}>{err}</p>}
                                        </div>
                                    </div>
                                    <div className={cx('wrapper-btn-submit')}>
                                        <button
                                            type="submit"
                                            id="btn-submit-login"
                                            className={cx('btn', 'btn-lg', 'btn-login')}
                                            onClick={handleSendCodeResetPassword}
                                        >
                                            Send a code
                                        </button>
                                    </div>
                                    {loading && (
                                        <ReactLoading
                                            className="d-flex justify-content-center"
                                            type="bubbles"
                                            color="#ccc"
                                            height={'40px'}
                                            width={'100%'}
                                        />
                                    )}
                                </form>
                                <div className={cx('forgot-password')}>
                                    <span onClick={handleBackLogin}>Login</span>
                                </div>
                            </>
                        )
                    ) : (
                        <>
                            <form onSubmit={handleSubmitLogin}>
                                <div className={cx('login-title')}>
                                    <h2>Login</h2>
                                </div>
                                <div className="mb-4">
                                    <input
                                        ref={inputEmail}
                                        value={email}
                                        type="email"
                                        className={cx('form-control form-control-lg', 'wrapper-input')}
                                        id="inputUsername"
                                        placeholder="Email"
                                        autoFocus
                                        onChange={(e) => handleInputEmail(e.target.value)}
                                    />
                                </div>
                                <div>
                                    <input
                                        type="password"
                                        ref={inputPassword}
                                        value={password}
                                        className={cx('form-control form-control-lg', 'wrapper-input')}
                                        id="inputPassword"
                                        placeholder="Password"
                                        onChange={(e) => handleInputPassword(e.target.value)}
                                    />
                                </div>
                                <div className="row">
                                    <div className={cx('wrapper-message')}>
                                        {err && <p className={cx('error-message')}>{err}</p>}
                                        {msgUpdatePassWordSuccess && (
                                            <span className={cx('message-sent')}>{msgUpdatePassWordSuccess}</span>
                                        )}
                                    </div>
                                </div>
                                <div className={cx('wrapper-btn-submit')}>
                                    <button
                                        type="submit"
                                        id="btn-submit-login"
                                        className={cx('btn', 'btn-lg', 'btn-login')}
                                    >
                                        Login
                                    </button>
                                </div>
                                {loading && (
                                    <ReactLoading
                                        className="d-flex justify-content-center"
                                        type="bubbles"
                                        color="#ccc"
                                        height={'40px'}
                                        width={'100%'}
                                    />
                                )}
                            </form>
                            <div className={cx('forgot-password')}>
                                <span onClick={handleForgotPassword}>Forgot Password</span>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Login;
