import classNames from 'classnames/bind';
import { Dropdown } from 'react-bootstrap';
import { MdKeyboardArrowDown } from 'react-icons/md';
import styles from './MenuHeader.module.scss';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { GlobalStateProject } from '~/ProjectState/projectState';
import Button from '~/components/Button';
import { useRef } from 'react';
import useDebounce from '~/hooks/useDebounce';
// import { GlobalStateTask } from '~/MeTaskState/MeTaskState';

const cx = classNames.bind(styles);

function MenuHeader({ listProject }) {
    const navigate = useNavigate();
    const ref = useRef();
    // const [showProjects, setShowProjects] = useState([]);
    const state = useContext(GlobalStateProject);
    const [projectId, setProjectId] = state.projectsAPI.projectId;
    const [offset, setOffset] = state.projectsAPI.offset;
    const [offsetRqm, setOffsetRqm] = state.projectsAPI.offsetRqm;
    // const [nbShowProjects, setNbShowProjects] = useState(7);
    const [checkClickBtnProject, setCheckClickBtnProject] = useState(false);

    // useEffect(() => {
    //     const getProject = () => {
    //         const result = listProject.filter((item) => projectId.includes(item.projectId));

    //         setShowProjects(result);
    //     };

    //     getProject();
    // }, [projectId]);

    const handleListProject = (id, projectName) => {
        if (projectId.includes(id)) {
            setProjectId(projectId.filter((projectId) => projectId !== id));
        } else {
            setProjectId([...projectId, id]);
        }
        setOffset(0);
        setOffsetRqm(0);
    };

    function getTotalCountAll() {
        return listProject.reduce(function (a, b) {
            return a + b.count;
        }, 0);
    }

    const checkActiveAll = () => {
        const newArr = [];
        listProject.map((project) => {
            if (!projectId.includes(project.projectId)) {
                newArr.push(project.projectId);
            }
            return newArr;
        });
        if (newArr.length === 0) {
            return true;
        } else {
            return false;
        }
    };

    const handleListAll = async () => {
        if (projectId.length === 0) return;
        if (checkActiveAll() === true) return;

        setProjectId([]);
        // await setProjectIdMeTask('');
        // if (window.location.pathname !== '/') {
        //     navigate('/');
        // }
        toast.success(`Welcome to all project! 🚀`, {
            position: 'bottom-left',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    const clickBtnProject = () => {
        setCheckClickBtnProject(!checkClickBtnProject);
    };

    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            if (checkClickBtnProject && ref.current && !ref.current.contains(e.target)) {
                setCheckClickBtnProject(false);
            }
        };

        document.addEventListener('mousedown', checkIfClickedOutside);

        return () => {
            document.removeEventListener('mousedown', checkIfClickedOutside);
        };
    }, [checkClickBtnProject]);

    // window.addEventListener('resize', (event) => {
    //     if (window.innerWidth > 1500) {
    //         setNbShowProjects(7);
    //     }
    //     if (window.innerWidth >= 1001 && window.innerWidth <= 1500) {
    //         setNbShowProjects(3);
    //     }
    // });
    // useEffect(() => {
    //     if (window.innerWidth > 1500) {
    //         setNbShowProjects(7);
    //     }
    //     if (window.innerWidth >= 1001 && window.innerWidth <= 1500) {
    //         setNbShowProjects(3);
    //     }
    // }, []);

    return (
        <>
            {/* <Dropdown className={cx('menu-horizontal-header__btn')}>
                <Dropdown.Toggle className={cx('dropdown-toggle')} id="dropdown-basic">
                    <div className={cx('menu-horizontal-header__btn-item')}>
                        <span>Your work</span>
                        <MdKeyboardArrowDown className={cx('menu-header-icon')} />
                    </div>
                </Dropdown.Toggle>

                <Dropdown.Menu></Dropdown.Menu>
            </Dropdown> */}

            <div
                className={cx('menu-horizontal-header__btn', 'menu-horizontal-header__btn-active', 'glo-display-flex')}
                ref={ref}
            >
                <div>
                    <Button className={cx('dropdown-toggle')} id="dropdown-basic" onClick={clickBtnProject}>
                        <span className={cx('name-active')}>Projects</span>
                        <MdKeyboardArrowDown className={cx('menu-header-icon', 'icon-active')} />
                    </Button>
                </div>
                <div className={cx('glo-position-absolute')}>
                    {checkClickBtnProject && (
                        <div className={cx('menu-dropdown-projects', 'glo-border-project')}>
                            <div className={cx('glo-test')}>
                                <Button
                                    className={cx(
                                        'dropdown-item',
                                        projectId === '' ? 'dropdown-item_projectActive' : '',
                                        'glo-btn-item-project',
                                    )}
                                    onClick={handleListAll}
                                >
                                    <input
                                        type="checkbox"
                                        id={`checkbox-project-all`}
                                        checked={projectId.length === 0 || checkActiveAll()}
                                    />
                                    <label htmlFor="checkbox-project-all" className={cx('checkbox-project-text')}>
                                        All ({getTotalCountAll()})
                                    </label>
                                </Button>
                                {listProject.map((project, idx) => (
                                    <div className={cx('dropdown-item')} key={idx}>
                                        <div
                                            onClick={() => handleListProject(project.projectId, project.projectName)}
                                            className={cx('glo-btn-item-project')}
                                        >
                                            <input
                                                type="checkbox"
                                                id={`checkbox-project-${project.projectId}`}
                                                checked={projectId.includes(project.projectId)}
                                            />
                                            <label
                                                // htmlFor={`checkbox-project-${project.projectId}`}
                                                onClick={() =>
                                                    handleListProject(project.projectId, project.projectName)
                                                }
                                                className={cx(
                                                    'checkbox-project-text',
                                                    projectId.includes(project.projectId)
                                                        ? 'dropdown-item_projectActive'
                                                        : '',
                                                )}
                                            >
                                                {project.projectName} ({project.count})
                                            </label>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            </div>

            {/* <div className={cx('glo-btn-project-mobile')}>
                {showProjects.slice(0, nbShowProjects)?.map((project) => (
                    <button key={project.projectId} className={cx('btn-project')}>
                        {project.projectName}
                    </button>
                ))}
                {showProjects.length > nbShowProjects && (
                    <button className={cx('btn-project', 'btn-project-number')}>
                        +{showProjects.length - nbShowProjects}
                    </button>
                )}
            </div> */}
        </>
    );
}

export default MenuHeader;
