import classNames from 'classnames/bind';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React, { useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import styles from '../FormQuestion/FormQuestion.module.scss';
import { useSelector } from 'react-redux';
import * as reviewMemberService from '~/services/reviewMemberService';
import api from '~/utils/main';
import { MdDelete, MdOutlineAdd, MdOutlineAddCircleOutline } from 'react-icons/md';
import Swal from 'sweetalert2';
import * as userService from '~/services/userService';
import { FaRegEdit } from 'react-icons/fa';
import { AiOutlineCloseCircle, AiOutlineDelete } from 'react-icons/ai';
import { FiEdit2 } from 'react-icons/fi';
import { Fragment } from 'react';

const cx = classNames.bind(styles);

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 15,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const DialogCreateQuestions = (props) => {
    const { openDialogCreateQuestion, setOpenDialogCreateQuestion } = props;
    const [groupQuestions, setGroupQuestions] = useState([]);
    const [groupQuestionsClone, setGroupQuestionsClone] = useState([]);
    const [questionsNew, setQuestionsNew] = useState({});
    const [groupQuestionsNew, setGroupQuestionsNew] = useState([]);
    const [listIdQuestionChecked, setListIdQuestionChecked] = useState([]);
    const [infoEditGroupQuestion, setinfoEditGroupQuestion] = useState(null);
    const [infoUpdateQs, setInfoUpdateQs] = useState([]);
    const [updateOrderNumberQuestion, setUpdateOrderNumberQuestion] = useState([]);

    const handleCloseDialogViewReview = () => {
        setOpenDialogCreateQuestion(false);
        setQuestionsNew([]);
        setGroupQuestionsNew([]);
        setUpdateOrderNumberQuestion([]);
        setInfoUpdateQs([]);
        setGroupQuestions([]);
    };

    const getGroupQs = async () => {
        const res = await reviewMemberService.getGroupQuestions();
        setGroupQuestions(res.data.data);
        setGroupQuestionsClone(JSON.parse(JSON.stringify(res.data.data)));
    };
    useEffect(() => {
        getGroupQs();
    }, [openDialogCreateQuestion]);

    const handleAddQuestion = (groupQs) => {
        const groupId = groupQs.id;
        const maxOrderNumber = groupQs.questionReviews.reduce((max, item) => {
            return item.orderNumber > max ? item.orderNumber : max;
        }, 0);

        if (questionsNew[groupId]) {
            const maxOrderNumberGroupNew = questionsNew[groupId].reduce((max, item) => {
                return item.orderNumber > max ? item.orderNumber : max;
            }, 0);

            const maxOrder = questionsNew[groupId].length !== 0 ? maxOrderNumberGroupNew : maxOrderNumber;

            const clone = [
                ...questionsNew[groupId],
                {
                    name: '',
                    isShowInput: true,
                    groupId,
                    orderNumber: maxOrder + 1,
                    isShowInputLevel: true,
                    fromLevel: '',
                    toLevel: '',
                },
            ];

            setQuestionsNew({ ...questionsNew, [groupId]: clone });
        } else {
            setQuestionsNew({
                ...questionsNew,
                [groupId]: [
                    {
                        name: '',
                        isShowInput: true,
                        groupId,
                        orderNumber: maxOrderNumber + 1,
                        isShowInputLevel: true,
                        fromLevel: '',
                        toLevel: '',
                    },
                ],
            });
        }
    };

    const handleDeleteGrQuestion = (groupId) => {
        Swal.fire({
            title: 'Are you sure?',
            text: `You want to delete group questions ?`,
            icon: 'warning',
            width: '360px',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'OK',
        }).then(async (result) => {
            if (result.isConfirmed) {
                const params = {
                    groupQuestionId: groupId,
                };
                const res = await userService.deleteGroupQuestions(params);

                if (res.status === 204) {
                    getGroupQs();
                    Swal.fire('Success!', `You want to delete group questions successfully`, 'success');
                } else {
                    Swal.fire('Failure!', `${res}`, 'error');
                }
            }
        });
    };

    const handleChangeQuestion = (e, index, indexGroup) => {
        const clone = [...questionsNew[indexGroup]];

        clone[index].name = e.target.value;

        setQuestionsNew({ ...questionsNew, [indexGroup]: clone });
    };

    const handleChangeFromLevel = (e, index, indexGroup) => {
        const clone = [...questionsNew[indexGroup]];

        clone[index].fromLevel = e.target.value;

        setQuestionsNew({ ...questionsNew, [indexGroup]: clone });
    };

    const handleChangeToLevel = (e, index, indexGroup) => {
        const clone = [...questionsNew[indexGroup]];

        clone[index].toLevel = e.target.value;

        setQuestionsNew({ ...questionsNew, [indexGroup]: clone });
    };

    const handleAddGroupQs = () => {
        setGroupQuestionsNew([...groupQuestionsNew, { name: '', isShowInput: true }]);
    };

    const handleApplyQuestion = async (e, index, indexGroup, idGroup, qs) => {
        if (e.key === 'Enter') {
            const body = {
                QuestionGroupId: idGroup,
                Questions: [{ OrderNumber: qs.orderNumber, Question: e.target.value }],
            };
            const res = await reviewMemberService.createQuestion(body);

            if (res.status === 204) {
                const clone = [...questionsNew[indexGroup]];

                clone.splice(index, 1);
                // clone[index].isShowInput = false;
                setQuestionsNew({ ...questionsNew, [indexGroup]: clone });
                getGroupQs();
            }
        }
    };

    const handleChangeNameGroupQs = (e, index) => {
        const clone = [...groupQuestionsNew];

        clone[index].name = e.target.value;
        setGroupQuestionsNew(clone);
    };

    const handleApplyNameGroupQs = async (e, index) => {
        if (e.key === 'Enter') {
            const body = {
                QuestionNames: [e.target.value],
            };
            const res = await reviewMemberService.createGroupQs(body);

            if (res.status === 204) {
                const clone = [...groupQuestionsNew];

                clone.splice(index, 1);
                setGroupQuestionsNew(clone);
                getGroupQs();
            }
        }
    };

    const handleApplyDataForm = async () => {
        const inputCreateGroupNotNull = [];
        groupQuestionsNew.map((ele) => ele.name !== '' && inputCreateGroupNotNull.push(ele.name));
        if (inputCreateGroupNotNull.length !== 0) {
            const body = {
                QuestionNames: inputCreateGroupNotNull,
            };

            await reviewMemberService.createGroupQs(body);
        }

        for (const id in questionsNew) {
            const listQs = questionsNew[id];

            const convertedData = listQs.map((item) => {
                return {
                    Question: item.name,
                    OrderNumber: item.orderNumber,
                    FromLevel: item.fromLevel,
                    ToLevel: item.toLevel,
                };
            });

            const body = {
                QuestionGroupId: id,
                Questions: convertedData,
            };

            await reviewMemberService.createQuestion(body);

            // await Promise.all(
            //     listQs.map(async (qs) => {
            //         const body = {
            //             QuestionGroupId: qs.groupId,
            //             Questions: [{ OrderNumber: qs.orderNumber, Question: qs.name }],
            //         };
            //         await reviewMemberService.createQuestion(body);
            //     }),
            // );
        }

        getGroupQs();
        handleCloseDialogViewReview();
    };

    const handleCheckedQuestion = (e, id) => {
        setListIdQuestionChecked([...listIdQuestionChecked, id]);

        if (listIdQuestionChecked.includes(id)) {
            setListIdQuestionChecked(listIdQuestionChecked.filter((e) => e !== id));
        }
    };

    const handleDeleteQuestions = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: `You want to delete questions ?`,
            icon: 'warning',
            width: '360px',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'OK',
        }).then(async (result) => {
            if (result.isConfirmed) {
                const params = {
                    Questions: listIdQuestionChecked,
                };
                const res = await userService.deleteQuestions(params);

                if (res.status === 204) {
                    getGroupQs();
                    setListIdQuestionChecked([]);
                    Swal.fire('Success!', `You want to delete questions successfully`, 'success');
                } else {
                    Swal.fire('Failure!', `${res}`, 'error');
                }
            }
        });
    };

    const handleUpdateOrderNumber = async () => {
        const convertedData = infoUpdateQs.reduce((result, item) => {
            const existingGroup = result.find((group) => group.groupQuestionId === item.groupQuestionId);
            if (existingGroup) {
                existingGroup.ListInfoQuestion.push({
                    QuestionId: item.id,
                    OrderNumber: item.orderNumber,
                });
            } else {
                result.push({
                    GroupQuestionId: item.groupQuestionId,
                    ListInfoQuestion: [
                        {
                            QuestionId: item.id,
                            OrderNumber: item.orderNumber,
                            Question: item.question,
                            FromLevel: item.fromLevel?.toUpperCase(),
                            ToLevel: item.toLevel?.toUpperCase(),
                        },
                    ],
                });
            }
            return result;
        }, []);

        const body = {
            ListUpdateOrderNumber: convertedData,
        };
        const res = await userService.updateOrderNumber(body);

        if (res.status === 204) {
            getGroupQs();
            setUpdateOrderNumberQuestion([]);
            setInfoUpdateQs([]);
        }
    };

    const handleEditGrQuestion = (groupQuestion) => {
        setinfoEditGroupQuestion(groupQuestion);
    };

    const handleChangeInfoEditGroup = (e) => {
        setinfoEditGroupQuestion({ ...infoEditGroupQuestion, questionName: e.target.value });
    };

    const handleApplyNewGroupQs = async (e, idGroup) => {
        const value = e.target.value;
        const data = {
            Id: idGroup,
            GroupQuestionName: value,
        };
        if (e.key === 'Enter') {
            const res = await userService.updateGroupQuestions(data);

            if (res.status === 204) {
                setinfoEditGroupQuestion(null);
                getGroupQs();
            }
        }
    };

    const handleApplyUpdateQs = async (e, idQs) => {
        const value = e.target.value;
        const data = {
            Id: idQs,
            Question: value,
        };
        if (e.key === 'Enter') {
            const res = await userService.updateQuestions(data);

            if (res.status === 204) {
                setInfoUpdateQs(null);
                getGroupQs();
            }
        }
    };

    const handleUpdateQuestion = (qs) => {
        setInfoUpdateQs([...infoUpdateQs, qs]);
    };

    const handleChangeInfoEditQs = (e, id, idx) => {
        const value = e.target.value;
        const clone = [...infoUpdateQs];

        const findIndex = clone.findIndex((ele) => ele.id === id);

        clone[findIndex].question = value;
        setInfoUpdateQs(clone);
    };

    const handleChangeInfoFromLevelEditQs = (e, id, idx) => {
        const value = e.target.value;
        const clone = [...infoUpdateQs];

        const findIndex = clone.findIndex((ele) => ele.id === id);

        clone[findIndex].fromLevel = value;
        setInfoUpdateQs(clone);
    };

    const handleChangeInfoToLevelEditQs = (e, id, idx) => {
        const value = e.target.value;
        const clone = [...infoUpdateQs];

        const findIndex = clone.findIndex((ele) => ele.id === id);

        clone[findIndex].toLevel = value;
        setInfoUpdateQs(clone);
    };

    const handleChangeOrderNumber = (e, id, indexParent, index, groupId) => {
        const cloneGroupQs = [...groupQuestions];

        const question = cloneGroupQs[indexParent].questionReviews.find((ele) => ele.id === id);
        question.orderNumber = +e.target.value;

        const valueOrderNumberInit = groupQuestionsClone[indexParent].questionReviews[index].orderNumber;

        if (valueOrderNumberInit !== +e.target.value) {
            setUpdateOrderNumberQuestion([
                ...updateOrderNumberQuestion,
                { QuestionId: id, OrderNumber: +e.target.value, GroupId: groupId },
            ]);
        } else {
            //review
            setUpdateOrderNumberQuestion((prevState) => prevState.filter((ele) => ele.QuestionId !== id));
        }

        setGroupQuestions(cloneGroupQs);
    };

    const handleDeleteLevel = (index, indexGroup) => {
        const clone = [...questionsNew[indexGroup]];

        clone[index].isShowInputLevel = false;
        clone[index].fromLevel = '';
        clone[index].toLevel = '';

        setQuestionsNew({ ...questionsNew, [indexGroup]: clone });
    };

    return (
        <div className={cx('header-create-task')}>
            <form>
                <BootstrapDialog
                    open={openDialogCreateQuestion}
                    onClose={handleCloseDialogViewReview}
                    // maxWidth="xl"
                    maxWidth="1700px"
                    minwidth="1500px"
                    fullWidth
                    aria-labelledby="customized-dialog-title"
                >
                    <BootstrapDialogTitle
                        id="customized-dialog-title"
                        onClose={handleCloseDialogViewReview}
                        className={cx('custom-title-control')}
                    >
                        <div className={cx('dialog-create-project-title')}>
                            <h2>Create Questions Review</h2>
                        </div>
                    </BootstrapDialogTitle>
                    <DialogContent dividers>
                        <div className={cx('rev-dialog-fill')}>
                            {groupQuestions?.map((groupQs, idxParent) => (
                                <Fragment key={groupQs.id}>
                                    <div className={cx('group-questions')}>
                                        <span>{idxParent + 1}. </span>
                                        {infoEditGroupQuestion && infoEditGroupQuestion.id === groupQs.id ? (
                                            <input
                                                value={infoEditGroupQuestion.questionName}
                                                autoFocus
                                                className={cx('input-group-question')}
                                                onChange={handleChangeInfoEditGroup}
                                                onKeyDown={(e) => handleApplyNewGroupQs(e, groupQs.id)}
                                            />
                                        ) : (
                                            `${groupQs.questionName}`
                                        )}
                                        <MdOutlineAddCircleOutline
                                            className={cx('btn-icon-add')}
                                            style={{ fontSize: '18px' }}
                                            onClick={() => handleAddQuestion(groupQs)}
                                        />
                                        <FaRegEdit
                                            className={cx('btn-icon-edit')}
                                            onClick={() => handleEditGrQuestion(groupQs)}
                                        />
                                        <AiOutlineDelete
                                            className={cx('btn-icon-delete')}
                                            onClick={() => handleDeleteGrQuestion(groupQs.id)}
                                        />
                                    </div>
                                    <ul className={cx('wrap-questions')}>
                                        {groupQs.questionReviews.map((qs, idx) => (
                                            <div key={qs.id} className={cx('wrap-question')}>
                                                {infoUpdateQs.some((ele) => ele.id === qs.id) ? (
                                                    <>
                                                        <input
                                                            type="number"
                                                            value={qs.orderNumber}
                                                            className={cx('input-order-number')}
                                                            onChange={(e) =>
                                                                handleChangeOrderNumber(
                                                                    e,
                                                                    qs.id,
                                                                    idxParent,
                                                                    idx,
                                                                    groupQs.id,
                                                                )
                                                            }
                                                        />
                                                        <div className={cx('wrap-level')}>
                                                            <span>Level from: </span>
                                                            <input
                                                                type="text"
                                                                value={qs.fromLevel}
                                                                className={cx('from-level')}
                                                                onChange={(e) =>
                                                                    handleChangeInfoFromLevelEditQs(e, qs.id)
                                                                }
                                                                autoFocus
                                                            />
                                                            <span>Level to: </span>
                                                            <input
                                                                type="text"
                                                                value={qs.toLevel}
                                                                className={cx('to-level')}
                                                                onChange={(e) =>
                                                                    handleChangeInfoToLevelEditQs(e, qs.id)
                                                                }
                                                            />
                                                        </div>
                                                        <input
                                                            value={qs.question}
                                                            className={cx('input-group-question')}
                                                            onChange={(e) => handleChangeInfoEditQs(e, qs.id)}
                                                            // onKeyDown={(e) => handleApplyUpdateQs(e, qs.id)}
                                                            autoFocus
                                                        />
                                                    </>
                                                ) : (
                                                    <>
                                                        <span style={{ marginRight: '8px' }}>{qs.orderNumber}.</span>
                                                        {/* <input
                                                            type="number"
                                                            value={qs.orderNumber}
                                                            className={cx('input-order-number')}
                                                            onChange={(e) =>
                                                                handleChangeOrderNumber(
                                                                    e,
                                                                    qs.id,
                                                                    idxParent,
                                                                    idx,
                                                                    groupQs.id,
                                                                )
                                                            }
                                                        /> */}
                                                        <input
                                                            type="checkbox"
                                                            id={`checkbox-question-${qs.id}`}
                                                            onChange={(e) => handleCheckedQuestion(e, qs.id)}
                                                        />
                                                        <label
                                                            style={{ marginLeft: '8px' }}
                                                            htmlFor={`checkbox-question-${qs.id}`}
                                                        >
                                                            {qs.question}
                                                        </label>
                                                        {qs.fromLevel && qs.toLevel && (
                                                            <span
                                                                style={{ marginLeft: '8px', color: '#0052cc' }}
                                                            >{`(${qs.fromLevel} => ${qs.toLevel})`}</span>
                                                        )}

                                                        <FiEdit2
                                                            className={cx('btn-icon-edit')}
                                                            style={{ marginLeft: '8px' }}
                                                            onClick={() => handleUpdateQuestion(qs)}
                                                        />
                                                    </>
                                                )}
                                            </div>
                                        ))}
                                    </ul>

                                    <ul className={cx('wrap-questions')}>
                                        {questionsNew[idxParent + 1]?.map((ele, index) =>
                                            ele.isShowInput ? (
                                                <div className={cx('wrap-questions-new')}>
                                                    {/* <input
                                                        type="number"
                                                        value={ele.orderNumber}
                                                        className={cx('input-order-number')}
                                                        // onChange={(e) => handleChangeOrderNumber(e, qs.id, idx)}
                                                    /> */}
                                                    {ele.isShowInputLevel && (
                                                        <>
                                                            <div className={cx('wrap-level')}>
                                                                <span>Level from: </span>
                                                                <input
                                                                    type="text"
                                                                    value={ele.fromLevel}
                                                                    className={cx('from-level')}
                                                                    onChange={(e) =>
                                                                        handleChangeFromLevel(e, index, idxParent + 1)
                                                                    }
                                                                    autoFocus
                                                                />
                                                                <span>Level to: </span>
                                                                <input
                                                                    type="text"
                                                                    value={ele.toLevel}
                                                                    className={cx('to-level')}
                                                                    onChange={(e) =>
                                                                        handleChangeToLevel(e, index, idxParent + 1)
                                                                    }
                                                                />
                                                            </div>
                                                            <div className={cx('wrap-level-icon')}>
                                                                <Tooltip
                                                                    title={
                                                                        <span style={{ fontSize: '1.4rem' }}>
                                                                            Delete Level
                                                                        </span>
                                                                    }
                                                                >
                                                                    <span
                                                                        className={cx('wrap-level-icon__close')}
                                                                        onClick={() =>
                                                                            handleDeleteLevel(index, idxParent + 1)
                                                                        }
                                                                    >
                                                                        <AiOutlineCloseCircle fontSize={'20'} />
                                                                    </span>
                                                                </Tooltip>
                                                            </div>
                                                        </>
                                                    )}

                                                    <input
                                                        className={cx(
                                                            'form-control form-control-lg',
                                                            'input-new-questions',
                                                        )}
                                                        value={ele.name}
                                                        key={index}
                                                        onChange={(e) => handleChangeQuestion(e, index, idxParent + 1)}
                                                        onKeyDown={(e) =>
                                                            handleApplyQuestion(
                                                                e,
                                                                index,
                                                                idxParent + 1,
                                                                groupQs.id,
                                                                ele,
                                                            )
                                                        }
                                                        style={!ele.isShowInputLevel ? { width: '100%' } : {}}
                                                    />
                                                </div>
                                            ) : (
                                                <>
                                                    {/* <input
                                                        type="number"
                                                        value={ele.orderNumber}
                                                        className={cx('input-order-number')}
                                                        // onChange={(e) => handleChangeOrderNumber(e, qs.id, idx)}
                                                    /> */}
                                                    <div key={index}>
                                                        <input
                                                            type="checkbox"
                                                            id={`checkbox-question-${index}`}
                                                            onChange={(e) => handleCheckedQuestion(e, index)}
                                                        />
                                                        <label
                                                            style={{ marginLeft: '8px' }}
                                                            htmlFor={`checkbox-question-${index}`}
                                                        >
                                                            {ele.name}
                                                        </label>
                                                    </div>
                                                </>
                                            ),
                                        )}
                                    </ul>
                                </Fragment>
                            ))}
                        </div>

                        {groupQuestionsNew?.map(
                            (group, index) =>
                                group.isShowInput && (
                                    <input
                                        className={cx('form-control form-control-lg', 'input-new-questions')}
                                        key={index}
                                        value={group.name}
                                        autoFocus
                                        onChange={(e) => handleChangeNameGroupQs(e, index)}
                                        onKeyDown={(e) => handleApplyNameGroupQs(e, index)}
                                    />
                                ),
                        )}
                    </DialogContent>
                    <DialogActions className={cx('review-action')}>
                        <button
                            className={cx('btn-delete-questions')}
                            disabled={listIdQuestionChecked.length === 0}
                            onClick={handleDeleteQuestions}
                        >
                            Delete Question
                        </button>
                        <button className={cx('btn-create-questions')} size="medium" onClick={handleAddGroupQs}>
                            <MdOutlineAdd style={{ marginRight: '4px' }} />
                            Add Group Questions
                        </button>
                        <button
                            className={cx('hn-btn-submit')}
                            size="medium"
                            disabled={infoUpdateQs.length === 0}
                            onClick={handleUpdateOrderNumber}
                        >
                            Update
                        </button>
                        <button
                            onClick={handleApplyDataForm}
                            className={cx('hn-btn-submit')}
                            size="medium"
                            disabled={Object.keys(questionsNew).length === 0}
                        >
                            Create
                        </button>
                        <button onClick={handleCloseDialogViewReview} className={cx('btn-review-cancel')} size="medium">
                            Cancel
                        </button>
                    </DialogActions>
                </BootstrapDialog>
            </form>
        </div>
    );
};

export default DialogCreateQuestions;
