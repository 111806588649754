import { Box } from '@mui/material';
import styles from './Statistical.module.scss';
import classNames from 'classnames/bind';
import React, { useEffect, useState, useContext } from 'react';
import ReactLoading from 'react-loading';
import { convertStatus } from '../../common/common.js';
import * as projectService from '~/services/listProjectService';
import { GlobalStateProject } from '~/ProjectState/projectState';
import { BiCopy } from 'react-icons/bi';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useCallback } from 'react';
import Swal from 'sweetalert2';
import moment from 'moment';
import * as detailTaskService from '~/services/detailTaskService';
import DetailTask from '~/pages/Home/components/DetailTask';

const cx = classNames.bind(styles);

const WeekendReport = (props) => {
    const [projects, setProjects] = useState([]);
    const [loadingData, setLoadingData] = useState(false);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const state = useContext(GlobalStateProject);
    const [projectId] = state.projectsAPI.projectId;

    const [invalidStartDate, setInValidStartDate] = useState(false);
    const [invalidEndDate, setInvalidEndDate] = useState(false);
    const [copied, setCopied] = useState(false);
    const [open, setOpen] = useState(false);
    const [valuePicker, setValuePicker] = useState(null);
    const [taskId, setTaskId] = useState();
    const [dataTask, setDataTask] = useState([]);
    const [assignTo, setAssignTo] = useState('');
    const [statusDetailTask, setStatusDetailTask] = useState('');
    const [priorityTask, setPriorityTask] = useState('');
    const [projectIdTask, setProjectIdTask] = useState('');
    const [teamId] = state.projectsAPI.teamId;

    const getProjects = async (e) => {
        setLoadingData(true);
        e.preventDefault();
        let blockApi = false;
        if (startDate == undefined || startDate == '' || startDate == 'Invalid date') {
            setInValidStartDate(true);
            blockApi = true;
        } else {
            setInValidStartDate(false);
        }

        if (endDate == undefined || endDate == '' || endDate == 'Invalid date') {
            setInvalidEndDate(true);
            blockApi = true;
        } else {
            setInvalidEndDate(false);
        }

        if (blockApi == true) {
            setLoadingData(false);
            return;
        }

        const params = {
            ...(startDate ? { RequestFrom: startDate } : {}),
            ...(endDate ? { RequestTo: endDate } : {}),
            ...(teamId ? { TeamId: teamId } : {}),
            ...(projectId && projectId?.length !== 0 ? { ProjectIds: projectId } : {}),
        };

        const res = await projectService.getProjectsReport(params);
        if (res.data.status === 'success') {
            setProjects(res?.data?.data?.projectsDetail);
            setLoadingData(false);
        } else {
            setLoadingData(false);
        }
    };

    const handleChangeStartDate = (e) => {
        const value = moment(new Date(e.target.value)).format('YYYY-MM-DD');
        setStartDate(value);
        // setInValidStartDate(true);
    };

    const handleChangeEndDate = (e) => {
        const value = moment(new Date(e.target.value)).format('YYYY-MM-DD');
        setEndDate(value);
        // setInValidStartDate(true);
    };

    const formatTextCopy = (project) => {
        let arrayText = [];

        project?.map((status) => arrayText.push(status.title));

        let textCopy = arrayText?.join('\n');

        return textCopy;
    };

    const handleCopy = useCallback(() => {
        Swal.fire('Success!', `Copy link task successfully`, 'success');
    }, []);

    const onCopy = useCallback(() => {
        setCopied(true);
    }, []);

    const getFirstDayOfWeek = () => {
        // debugger;
        var now = new Date();
        var currentDay = now.getDay();
        var difference = currentDay - 1; // 0 represents Sunday

        now.setDate(now.getDate() - difference);

        return moment(now).format('YYYY-MM-DD');
    };

    const handleGetThisWeek = () => {
        let monday = getFirstDayOfWeek();
        setStartDate(monday);

        let today = moment(new Date()).format('YYYY-MM-DD');
        setEndDate(today);
    };

    async function handleOpenTask(id) {
        setOpen(true);
        setTaskId(id);
        const res = await detailTaskService.getDataDetailTask(id);
        if (res.data.status === 'success') {
            setDataTask(res.data.data);
            setAssignTo({ id: res.data.data.assignId, name: res.data.data.assignName });
            setStatusDetailTask({ id: res.data.data.statusId, title: res.data.data.status });
            setValuePicker(res.data.data.dueDate);
            setPriorityTask(res.data.data.priorityId);
            setProjectIdTask(res.data.data.projectId);
        }
    }

    return (
        <>
            <Box sx={{ width: '100%' }}>
                <div className={cx('wrapper')}>
                    <div className={cx('wrapper-list')}>
                        <div className={cx('mt-3', 'mb-3')}>
                            <div className={cx('row')}>
                                <div className={cx('col-xxl-1', 'res-title')}>
                                    <span className={cx('title-underlined')}>Statistic</span>
                                </div>
                                <div className={cx('col-xxl-9', 'mt-2', 'mt-lg-0', 'res-search')}>
                                    <div className={cx('row', 'mt-lg-2')}>
                                        <div
                                            className={cx('col-md-6 col-lg-6 col-xl-4 col-xxl-3', 'mt-lg-2', 'mt-xl-0')}
                                        >
                                            <div className={cx('form-group row')}>
                                                <label
                                                    htmlFor="start-date"
                                                    className="col-lg-5 col-xxl-5 col-form-label text-xl-end"
                                                >
                                                    Start Date
                                                </label>
                                                <div className={cx('col-lg-7 col-xxl-7')}>
                                                    <input
                                                        type="date"
                                                        className="form-control form-control-lg"
                                                        id="from-day"
                                                        value={startDate}
                                                        onChange={(e) => handleChangeStartDate(e)}
                                                        onKeyDown={(e) => {
                                                            e.preventDefault();
                                                        }}
                                                    ></input>
                                                    {invalidStartDate && (
                                                        <small className={cx('txt-notice', 'text-flex', 'mt-2')}>
                                                            Must pick the start date.
                                                        </small>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className={cx('col-md-6 col-lg-6 col-xl-4 col-xxl-3', 'mt-lg-2', 'mt-xl-0')}
                                        >
                                            <div className={cx('form-group row')}>
                                                <label
                                                    htmlFor="end-date"
                                                    className="col-lg-5 col-xxl-5 col-form-label text-xl-end"
                                                >
                                                    End Date
                                                </label>
                                                <div className={cx('col-lg-7 col-xxl-7')}>
                                                    <input
                                                        type="date"
                                                        className="form-control form-control-lg"
                                                        id="end-date"
                                                        value={endDate}
                                                        onChange={(e) => handleChangeEndDate(e)}
                                                        min={moment(startDate)
                                                            .format('YYYY/MM/DD')
                                                            .replaceAll('/', '-')}
                                                    ></input>
                                                    {invalidEndDate && (
                                                        <small className={cx('txt-notice', 'text-flex', 'mt-2')}>
                                                            Must pick the end date.
                                                        </small>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className={cx(
                                                'col-xl-6 col-xxl-1',
                                                'mt-3',
                                                'mt-lg-2',
                                                'mt-xxl-0',
                                                'text-start',
                                            )}
                                        >
                                            <button className={cx('btn-this-week')} onClick={handleGetThisWeek}>
                                                This week
                                            </button>
                                        </div>
                                        <div
                                            className={cx(
                                                'col-xl-6 col-xxl-1',
                                                'mt-3',
                                                'mt-lg-2',
                                                'mt-xxl-0',
                                                'text-start',
                                            )}
                                        >
                                            <button className={cx('btn-search-statistical')} onClick={getProjects}>
                                                Search
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={cx('statistical-table-content')}>
                            <div className={cx('statistical-table')}>
                                <div className={cx('statistical_table-thread')}>
                                    <div className={cx('list-statistical-username')}>Project</div>
                                    <div className={cx('list-statistical-level')}>Task Status</div>
                                    <div className={cx('list-statistical-task-quantity')}>Task List</div>
                                </div>
                                <div className={cx('statistical_table-tbody')}>
                                    {loadingData === true ? (
                                        <ReactLoading type="spin" color="#ccc" height={'50px'} width={'50px'} />
                                    ) : (
                                        <>
                                            {projects.length !== 0 ? (
                                                <>
                                                    {projects?.map((project, index) => {
                                                        return (
                                                            <div
                                                                className={cx('row', 'statis-body', 'glo-row')}
                                                                key={index}
                                                            >
                                                                <div className={cx('statis-name')}>
                                                                    <span
                                                                        dangerouslySetInnerHTML={{
                                                                            __html: project?.name,
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div className={cx('statis-total')}>
                                                                    {project?.detail.map((projectDetail, index) => {
                                                                        return (
                                                                            <div
                                                                                className={cx('flex', 'status-border')}
                                                                                key={index}
                                                                            >
                                                                                <div className={cx('statis-name')}>
                                                                                    {convertStatus(
                                                                                        projectDetail?.status,
                                                                                    )}
                                                                                </div>
                                                                                <div className={cx('statis-total')}>
                                                                                    {projectDetail?.detail.map(
                                                                                        (task, index) => {
                                                                                            return (
                                                                                                <div
                                                                                                    className={cx(
                                                                                                        'task-item',
                                                                                                    )}
                                                                                                    key={index}
                                                                                                    onClick={() =>
                                                                                                        handleOpenTask(
                                                                                                            task.id,
                                                                                                        )
                                                                                                    }
                                                                                                >
                                                                                                    {task?.title}
                                                                                                </div>
                                                                                            );
                                                                                        },
                                                                                    )}
                                                                                </div>
                                                                                {
                                                                                    <>
                                                                                        <CopyToClipboard
                                                                                            onCopy={onCopy}
                                                                                            text={formatTextCopy(
                                                                                                projectDetail?.detail,
                                                                                            )}
                                                                                            options={{
                                                                                                format: 'text/plain',
                                                                                            }}
                                                                                        >
                                                                                            <span
                                                                                                onClick={handleCopy}
                                                                                                className={cx(
                                                                                                    'icon-copy',
                                                                                                )}
                                                                                            >
                                                                                                <BiCopy fontSize={20} />
                                                                                            </span>
                                                                                        </CopyToClipboard>
                                                                                    </>
                                                                                }
                                                                            </div>
                                                                        );
                                                                    })}
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                </>
                                            ) : (
                                                <p className={cx('txt-notice')}>No data on selected duration.</p>
                                            )}
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Box>
            <DetailTask
                open={open}
                projectIdTask={projectIdTask}
                handleClickOpen={handleOpenTask}
                dataTask={dataTask}
                setOpen={setOpen}
                taskId={taskId}
                setDataTask={setDataTask}
                assignTo={assignTo}
                setAssignTo={setAssignTo}
                statusDetailTask={statusDetailTask}
                setStatusDetailTask={setStatusDetailTask}
                setValuePicker={setValuePicker}
                valuePicker={valuePicker}
                priorityTask={priorityTask}
                setPriorityTask={setPriorityTask}
            />
        </>
    );
};

export default WeekendReport;
