import { Droppable } from 'react-beautiful-dnd';
import styled from 'styled-components';
import styles from './Milestone.module.scss';
import classNames from 'classnames/bind';

import * as mileStonesService from '~/services/mileStonesService';
import Task from './Task';
import { HiOutlineClock } from 'react-icons/hi';
import { BsCheckCircleFill, BsFillBookmarksFill, BsPencilSquare } from 'react-icons/bs';
import { useRef, useState } from 'react';
import moment from 'moment';
import { useEffect } from 'react';

const cx = classNames.bind(styles);

const ColumnHeader = styled.div`
    padding: 12px;
    background: #f4f3f3;
`;

const ColumnHeaderCount = styled.div`
    width: 74%;
    min-width: 14%;
    justify-content: flex-end;
    display: flex;
    align-items: center;
`;
const ColumnHeaderWrapper = styled.div`
    justify-content: space-between;
    display: flex;
    align-items: center;
`;

const ColumnTime = styled.div`
    display: flex;
    width: calc(100% - 140px);
`;
const ColumnTimeText = styled.div`
    margin-left: 8px;
    font-size: 1.2rem;
`;

const ColumnName = styled.div`
    display: flex;
    align-items: center;
`;

const WrapperPercent = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 8px;
    align-items: center;
`;

const ColumnNameProject = styled.div`
    margin-left: '6px';
    max-width: 224px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const Container = styled.div`
    margin: 8px;
    border: 1px solid lightgrey;
    border-radius: 2px;
    width: 360px;
    min-height: 600px;
    box-shadow: 0 2px 6px 0 hsl(0deg 0% 0% / 20%);
    display: flex;
    flex-direction: column;
`;

const TaskList = styled.div`
    padding: 8px;
    transition: background-color 0.2s ease;
    background: #f4f3f3;
    flex-grow: 1;
    min-height: 400px;
    max-height: 600px;
    overflow-y: auto;
    overflow-x: hidden;
`;

function Column({ column, tasks }) {
    const [isShowInputName, setIsShowInputName] = useState(false);
    const inputRef = useRef();
    const [valueMilestone, setValueMilestone] = useState(column.title);

    useEffect(() => {
        if (isShowInputName === true) {
            document.getElementById(`input-title-${column.id}`).focus();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isShowInputName]);

    const handleEditTitle = () => {
        if (isShowInputName === true) {
            setIsShowInputName(false);
        } else {
            setIsShowInputName(true);
        }
    };

    const updateMilestone = async (milestoneId, title) => {
        await mileStonesService.updateMilestone(milestoneId, title);
    };

    const handleChangeTitle = (e) => {
        setValueMilestone(e.target.value);
    };

    const handleChangeName = (e) => {
        if (e.code === 'Enter') {
            updateMilestone(column.id, e.target.value);
            setIsShowInputName(false);
        }
    };

    return (

        <Container>
            {/* <Title>
                {column.title} {column.taskIds.length}
            </Title> */}
            <ColumnHeader>
                <ColumnHeaderWrapper>
                    <ColumnName>
                        <BsFillBookmarksFill style={{ color: '#65ba43' }} />
                        {isShowInputName === false && <ColumnNameProject>{valueMilestone}</ColumnNameProject>}
                        {isShowInputName === true && (
                            <input
                                id={`input-title-${column.id}`}
                                value={valueMilestone}
                                ref={inputRef}
                                onKeyPress={handleChangeName}
                                onChange={(e) => handleChangeTitle(e)}
                            />
                        )}
                        <BsPencilSquare className={cx('milestone-task-icon')} onClick={handleEditTitle} />
                        <BsCheckCircleFill className={cx('milestone-task-icon-check')} />
                    </ColumnName>
                    <ColumnHeaderCount>{column.easycaseMilestones.length}</ColumnHeaderCount>
                </ColumnHeaderWrapper>
                <WrapperPercent>
                    <ColumnTime>
                        <HiOutlineClock style={{ color: '#0052cc' }} />
                        <ColumnTimeText>{moment(column.created).format('LL')}</ColumnTimeText>
                    </ColumnTime>
                    <div className={cx('d-flex', 'glo-item-work__chart')}>
                        <div className={cx('glo-item-work__chart__background')}>
                            <div
                                className={cx('glo-item-work__chart__background__color')}
                                style={{
                                    background: '#0052cc',
                                    width: column.completed + '%',
                                    height: '6px',
                                }}
                            ></div>
                        </div>
                        <span style={{ marginLeft: '8px', fontSize: '1.4rem' }}>{column.completed + '%'}</span>
                    </div>
                </WrapperPercent>
            </ColumnHeader>
            <Droppable droppableId={`${column.id}`} type="TASK">
                {(provided, snapshot) => (
                    <TaskList
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={{
                            background: snapshot.isDraggingOver,
                            //     ? "lightblue"
                            //     : "lightgrey",
                            //   padding: 4,
                            //   width: 250,
                            //   minHeight: 500
                        }}
                    >
                        {column.easycaseMilestones?.map((task, index) => (
                            <Task key={task.id} task={task} index={index} />
                        ))}
                        {provided.placeholder}
                    </TaskList>
                )}
            </Droppable>
        </Container>

    );
}

export default Column;
