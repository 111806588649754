/* eslint-disable jsx-a11y/alt-text */
import classNames from 'classnames/bind';
import Dialog from '@mui/material/Dialog';
import React, { Fragment, memo, useCallback, useEffect, useState } from 'react';
import styles from './KanbanBug.module.scss';
import { BsFillBookmarkFill, BsLink45Deg } from 'react-icons/bs';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Button from '~/components/Button';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw } from 'draft-js';
import moment from 'moment';
import { MdAddCircleOutline, MdInsertDriveFile, MdKeyboardArrowUp, MdOutlineAddCircleOutline } from 'react-icons/md';
import { IoMdClose } from 'react-icons/io';
import Fancybox from './components/fancybox';
import api from '~/utils/main';
import draftToHtml from 'draftjs-to-html';
import Comment from './components/Comment';
import { AiOutlineDelete, AiTwotoneStar } from 'react-icons/ai';
import * as _ from 'lodash';
import { GlobalStateProject } from '~/ProjectState/projectState';
import { useContext } from 'react';
import { Tooltip } from '@mui/material';
import { VscEdit } from 'react-icons/vsc';
import FormCreatePathFile from '~/components/FormCreatePathFile';
import { callToWindowApp } from '~/common/common';
import FormUpdatePathFile from '~/components/FormUpdatePathFile';
import CopyToClipboard from 'react-copy-to-clipboard';
import Swal from 'sweetalert2';

const cx = classNames.bind(styles);

const listStatus = [
    { id: 1, name: 'New' },
    { id: 2, name: 'Fixing' },
    { id: 3, name: 'Fix Done' },
    { id: 4, name: 'Testing' },
    { id: 5, name: 'Test Fail' },
    { id: 6, name: 'Close' },
];
const imageTypeRegex = /image\/(png|jpg|jpeg)/gm;

const convertStatus = (status) => {
    switch (status) {
        case 1:
            status = 'New';
            break;
        case 2:
            status = 'Fixing';
            break;
        case 3:
            status = 'Fix Done';
            break;
        case 4:
            status = 'Testing';
            break;
        case 5:
            status = 'Test Fail';
            break;
        case 6:
            status = 'Close';
            break;
        default:
            status = 0;
            break;
    }
    return status;
};
function DetailBugDialog(props) {
    const {
        setOpen,
        openDetail,
        setOpenDetail,
        idTaskDetail,
        getDetailTask,
        setIdTaskDetail,
        detailId,
        setDetailId,
        detailTitle,
        detailDescription,
        detailStatus,
        setDetailStatus,
        detailIdUser,
        setDetailIdUser,
        users,
        setUsers,
        detailPicker,
        setDetailPicker,
        detailCompleted,
        setDetailCompleted,
        detailFile,
        setDetailFile,
        convertDataKanBan,
        listTaskChild,
        setListTaskChild,
        setAssingBug,
        assingBug,
        kanBanRefId,
        pathFile,
        easycaseId,
        statusId,
        refIdBug,
    } = props;

    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const token = useSelector((state) => state.token);

    const [viewImage, setViewImages] = useState([]);
    const [imageFiles, setImageFiles] = useState([]);
    const [files, setFiles] = useState([]);
    const [userInfor, setUserInfor] = useState();
    const [statusView, setStatusView] = useState('');

    const [assignToPost, setAssignToPost] = useState();
    const [pickerPost, setPickerPost] = useState(null);
    const [completedPost, setCompletedPost] = useState();
    const [filePost, setFilePost] = useState();
    const [checkDeleteFile, setCheckDeleteFile] = useState(false);
    const [checkAddFile, setCheckAddFile] = useState(false);
    const [checkAddImage, setCheckAddImage] = useState(false);
    const [filesDelete, setFilesDelete] = useState([]);
    const [addImageDB, setAddImageDB] = useState([]);
    const [addFileDB, setAddFileDB] = useState([]);
    const [inputList, setInputList] = useState([]);
    const [viewPostDataAssignBug, setViewPostDataAssignBug] = useState();
    const [viewDataAssignBug, setViewDataAssignBug] = useState();
    const [bugId, setBugId] = useState('');
    const state = useContext(GlobalStateProject);
    const [callback, setCallback] = state.projectsAPI.callback;

    const [checkAddAssingBug, setCheckAddAssingBug] = useState(false);

    const [checkDeleteImage, setCheckDeleteImage] = useState(false);

    const [valueComment, setValueComment] = useState(false);

    const [commentData, setCommentData] = useState([]);
    const [listAssingBugDelete, setListAssingBugDelete] = useState([]);
    const [openCreatePathFile, setOpenCreatePathFile] = useState(false);
    const [openUpdatePathFile, setOpenUpdatePathFile] = useState(false);
    const [callbackCreatePathFile, setCallbackCreatePathFile] = useState(false);
    const [valueCopy, setValueCopy] = useState('');
    const [copied, setCopied] = useState(false);
    const [valueStatus, setValueStatus] = useState(null);

    const handleClose = () => {
        setOpen(true);
        setOpenDetail(false);
    };

    const onEditorStateChange = (editorState) => {
        setValueComment(false);
        return setEditorState(editorState);
    };

    const handleAssignTo = async (e) => {
        setAssignToPost(e.target.value);
    };

    const handleChangeDate = (e) => {
        const value = moment(new Date(e.target.value)).format('YYYY/MM/DD');
        setPickerPost(value);
    };

    const handleInputCompleted = (e) => {
        if (e.target.value >= 100) {
            setCompletedPost(100);
        } else if (e.target.value < 0) {
            setCompletedPost(0);
        } else {
            setCompletedPost(e.target.value);
        }
    };

    const addFiles = async () => {
        var bodyFormData = new FormData();
        const listFiles = [...addImageDB, ...addFileDB];

        listFiles.forEach((file) => {
            bodyFormData.append('Files', file);
        });

        const res = await api.post(`checking-bug/${idTaskDetail}/files`, bodyFormData, {
            headers: {
                'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>',
            },
        });
        if (res.data.status === 'success') {
            // res.data.data.forEach((idItem) => {
            //     // if (idItem.type === 'png' || idItem.type === 'jpg' || idItem.type === 'jpeg') {
            //     //     // setDetailFile((prevState) => {
            //     //     //     const addImage = idItem;
            //     //     //     const imagesClone = prevState.images.concat(addImage);
            //     //     //     return { ...prevState, images: imagesClone };
            //     //     // });
            //     //     // setFilePost((prevState) => {
            //     //     //     const addImage = idItem;
            //     //     //     const imagesClone = prevState.images.concat(addImage);
            //     //     //     return { ...prevState, images: imagesClone };
            //     //     // });
            //     //     setViewImages([]);
            //     //     setCheckAddImage(false);
            //     // } else {
            //     //     // setDetailFile((prevState) => {
            //     //     //     const addFile = idItem;
            //     //     //     const fileClone = prevState.docs.concat(addFile);
            //     //     //     return { ...prevState, docs: fileClone };
            //     //     // });
            //     //     // setFilePost((prevState) => {
            //     //     //     const addFile = idItem;
            //     //     //     const fileClone = prevState.docs.concat(addFile);
            //     //     //     return { ...prevState, docs: fileClone };
            //     //     // });
            //     //     setFiles([]);
            //     //     setCheckAddFile(false);
            //     // }

            // });
            setViewImages([]);
            setCheckAddImage(false);
            setFiles([]);
            setCheckAddFile(false);
            setAddImageDB([]);
            setAddFileDB([]);
            setDetailFile([]);
            getDetailTask(idTaskDetail);
        }
    };

    const deleteFiles = async () => {
        const res = await api.delete(`checking-bug/files`, {
            params: { fileIds: [...filesDelete] },
            headers: {
                'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>',
            },
        });

        if (res.status === 204) {
            // if (checkDeleteImage) {
            //     // filesDelete.forEach((idItem) => {
            //     //     setDetailFile((prevState) => {
            //     //         const deteleImage = prevState.images.filter((item) => item.id !== idItem);
            //     //         return { ...prevState, images: deteleImage };
            //     //     });
            //     // });
            //     setCheckDeleteImage(false);
            // }
            // if (checkDeleteFile) {
            //     // filesDelete.forEach((idItem) => {
            //     //     setDetailFile((prevState) => {
            //     //         const deteleFiles = prevState.docs.filter((item) => item.id !== idItem);
            //     //         return { ...prevState, docs: deteleFiles };
            //     //     });
            //     // });
            //     setCheckDeleteFile(false);
            // }
            setCheckDeleteImage(false);
            setCheckDeleteFile(false);
            setFilesDelete([]);
            setDetailFile([]);
            getDetailTask(idTaskDetail);
        }
    };
    const convertDataTableAssing = (valueData) => {
        if (valueData !== null || valueData !== undefined) {
            const result1 = valueData.reduce(
                (acc, { nameTask, easycaseId, completed, nameAssignTo, title, status }) => {
                    acc[easycaseId] ??= {
                        easycaseId: easycaseId,
                        nameTask: nameTask,
                        status,
                        bugChild: [],
                    };
                    acc[easycaseId].bugChild.push({
                        easycaseId,
                        completed,
                        nameAssignTo,
                        title,
                        status,
                    });

                    return acc;
                },
                {},
            );

            setViewDataAssignBug(Object.values(result1));
        }
    };

    const handleReport = async () => {
        var arAssignBugView = viewPostDataAssignBug.map((item) => ({
            id: item.id,
            assignTo: item.assignTo,
            title: item.title,
            easycaseId: item.easycaseId,
        }));

        var arAssignBugAdd = inputList.map((item) => ({
            assignTo: item.assignTo,
            easycaseId: item.easycaseId,
            title: item.title ? item.title : detailTitle,
        }));

        const body = {
            title: detailTitle,
            label: null,
            description: detailDescription,
            assignTo: +assignToPost,
            dueDate: pickerPost,
            completed: completedPost,
            assignBugs: arAssignBugAdd,
            updateBugs: arAssignBugView,
            deleteBugChilds: listAssingBugDelete,
        };
        if (checkDeleteFile || checkDeleteImage) {
            deleteFiles();
        }
        if (checkAddFile || checkAddImage) {
            addFiles();
        }
        const res = await toast.promise(api.put(`checking-bug/${idTaskDetail}`, body), {
            pending: 'Update bug is pending',
            success: 'Update bug successfully',
            error: 'Report task failed',
        });

        if (valueStatus !== null) {
            const bodyUpdateStatus = {
                BugId: idTaskDetail,
                StatusId: valueStatus,
            };
            await api.patch(`checking-bug/status`, bodyUpdateStatus);
        }

        if (res.status === 204) {
            setListAssingBugDelete([]);
            setDetailFile([]);
            setViewImages([]);
            setCheckAddImage(false);
            setFiles([]);
            setCheckAddFile(false);
            setAddImageDB([]);
            setAddFileDB([]);
            setInputList([]);
            setFilePost([]);
            getDetailTask(idTaskDetail);
            convertDataKanBan();
            setCallback(!callback);
            setOpen(true);
        }
    };

    const getInformationUser = async (id) => {
        const res = await api.get(`admin/user/${id}`);
        if (res.data.success === true) {
            setUserInfor(res.data.result);
            return res.data.result;
        }
    };

    const getAllComment = async () => {
        const response = await api.get(`checking-bug/${idTaskDetail}/comment`).then((response) => {
            setCommentData(response.data.data);
        });
    };

    const handleCreateComment = async (e) => {
        e.preventDefault();
        if (draftToHtml(convertToRaw(editorState.getCurrentContent())) === '<p></p>\n') {
            setValueComment(true);
            return;
        }
        const body = {
            comment: draftToHtml(convertToRaw(editorState.getCurrentContent())),
        };
        const response = await api.post(`${process.env.REACT_APP_BASE_URL}checking-bug/${idTaskDetail}/comment`, body);
        if (response.data.status === 'success') {
            setCommentData((pre) => {
                pre.unshift(response.data.data);
                return pre;
            });
            getAllComment();
            setEditorState(EditorState.createEmpty());
        }
    };

    const convertDataAssingBug = (assingBug) => {
        const validDataViewAssingBug = [];
        const validDataChild = [];
        if (assingBug !== undefined || assingBug !== null) {
            assingBug?.forEach((data) => {
                var nameTask;
                var nameAssignTo;
                listTaskChild.forEach((dataChild) => {
                    if (data.easycaseId === dataChild.id) {
                        dataChild.selected = true;
                        nameTask = dataChild.title;
                    } else {
                        dataChild.selected = false;
                    }
                });
                users.forEach((dataUser) => {
                    if (data.assignTo === dataUser.id) {
                        dataUser.selected = true;
                        nameAssignTo = dataUser.name;
                    } else {
                        dataUser.selected = false;
                    }
                });
                validDataViewAssingBug.push({ ...data, nameTask: nameTask, nameAssignTo: nameAssignTo });
                //refId = 0 : cha
                if (data.refId !== 0) {
                    validDataChild.push({
                        ...data,
                        listTaskChild: _.cloneDeep(listTaskChild),
                        users: _.cloneDeep(users),
                    });
                }
            });
            convertDataTableAssing(validDataViewAssingBug);
        }

        setViewPostDataAssignBug(validDataChild);
    };

    useEffect(() => {
        if (idTaskDetail !== undefined) {
            setFilePost([]);
            setInputList([]);
            setViewImages([]);
            getInformationUser(detailIdUser);
            getAllComment();
            if (assingBug !== undefined || assingBug !== null) {
                convertDataAssingBug(assingBug);
            }

            setStatusView(convertStatus(detailStatus));
            setAssignToPost(detailIdUser);
            setPickerPost(detailPicker);
            setCompletedPost(detailCompleted);
            setFilePost(detailFile);
        }
    }, [idTaskDetail, openDetail, assingBug, detailFile]);

    const handleDeteleFilePost = async (id, type) => {
        setFilesDelete([...filesDelete, id]);
        if (type === 'image') {
            setCheckDeleteImage(true);
            setFilePost((prevState) => {
                const deteleImage = prevState.images.filter((item) => item.id !== id);
                return { ...prevState, images: deteleImage };
            });
        }
        if (type === 'file') {
            setCheckDeleteFile(true);
            setFilePost((prevState) => {
                const deteleFile = prevState.docs.filter((item) => item.id !== id);
                return { ...prevState, docs: deteleFile };
            });
        }
    };

    //add Images
    const changeHandleImage = (e) => {
        setCheckAddImage(true);
        setAddImageDB(e.target.files);

        const { files } = e.target;
        const validImageFiles = [];
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            if (file.type.match(imageTypeRegex)) {
                validImageFiles.push(file);
            }
        }

        if (validImageFiles.length) {
            setImageFiles(validImageFiles);
            return;
        }
    };

    //addFile
    const changeHandlerFile = (e) => {
        setCheckAddFile(true);
        setAddFileDB(e.target.files);
        const { files } = e.target;

        const validFiles = [];
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            validFiles.push(file);
        }
        if (validFiles.length) {
            setFiles(validFiles);
            return;
        }
    };

    useEffect(() => {
        const images = [],
            fileReaders = [];
        let isCancel = false;
        if (imageFiles.length) {
            imageFiles.forEach((file) => {
                const fileReader = new FileReader();
                fileReaders.push(fileReader);
                fileReader.onload = (e) => {
                    const { result } = e.target;
                    if (result) {
                        images.push(result);
                    }
                    if (images.length === imageFiles.length && !isCancel) {
                        setViewImages(images);
                    }
                };
                fileReader.readAsDataURL(file);
            });
        }
        return () => {
            isCancel = true;
            fileReaders.forEach((fileReader) => {
                if (fileReader.readyState === 1) {
                    fileReader.abort();
                }
            });
        };
    }, [imageFiles]);

    const clickButtonImages = () => {
        document.getElementById('list_file').click();
    };

    const clickButtonFile = () => {
        document.getElementById('filexcel').click();
    };

    const handleInputChange = (e, index, nameEvent) => {
        var list = _.cloneDeep(inputList);
        var currentObject = list[index];

        if (nameEvent === 'task') {
            var nameAssignBug = '';

            for (let index = 0; index < currentObject.listTaskChild.length; index++) {
                if (currentObject.listTaskChild[index].id === Number(e.target.value)) {
                    currentObject.listTaskChild[index].selected = true;
                    currentObject.easycaseId = Number(e.target.value);

                    nameAssignBug = currentObject.listTaskChild[index].assignName;
                } else {
                    currentObject.listTaskChild[index].selected = false;
                }
            }
            for (let index = 0; index < currentObject.users.length; index++) {
                if (currentObject.users[index].name === nameAssignBug) {
                    currentObject.users[index].selected = true;
                    currentObject.assignTo = currentObject.users[index].id;
                } else {
                    currentObject.users[index].selected = false;
                }
            }
        }

        if (nameEvent === 'bug') {
            for (let index = 0; index < currentObject.users.length; index++) {
                if (currentObject.users[index].id === Number(e.target.value)) {
                    currentObject.users[index].selected = true;

                    currentObject.assignTo = Number(e.target.value);
                } else {
                    currentObject.users[index].selected = false;
                }
            }
        }
        if (nameEvent === 'title') {
            currentObject.title = e.target.value;
        }
        setInputList(list);
    };

    const handleAddAssignTask = () => {
        listTaskChild.forEach((dataChild) => {
            dataChild.selected = false;
        });
        users.forEach((user) => {
            user.selected = false;
        });
        setInputList([
            ...inputList,
            {
                listTaskChild: _.cloneDeep(listTaskChild),
                users: _.cloneDeep(users),
                easycaseId: '',
                assignTo: '',
                title: '',
            },
        ]);
        setCheckAddAssingBug(true);
    };
    const handleRemoveClick = (index) => {
        const list = [...inputList];
        list.splice(index, 1);
        setInputList(list);
    };

    const handleInputViewChange = (e, index, nameEvent) => {
        var list = _.cloneDeep(viewPostDataAssignBug);
        var currentObject = list[index];

        if (nameEvent === 'task') {
            var nameAssignBug = '';

            for (let index = 0; index < currentObject.listTaskChild.length; index++) {
                if (currentObject.listTaskChild[index].id === Number(e.target.value)) {
                    currentObject.listTaskChild[index].selected = true;
                    currentObject.easycaseId = Number(e.target.value);

                    nameAssignBug = currentObject.listTaskChild[index].assignName;
                } else {
                    currentObject.listTaskChild[index].selected = false;
                }
            }
            for (let index = 0; index < currentObject.users.length; index++) {
                if (currentObject.users[index].name === nameAssignBug) {
                    currentObject.users[index].selected = true;
                    currentObject.assignTo = currentObject.users[index].id;
                } else {
                    currentObject.users[index].selected = false;
                }
            }
        }

        if (nameEvent === 'bug') {
            for (let index = 0; index < currentObject.users.length; index++) {
                if (currentObject.users[index].id === Number(e.target.value)) {
                    currentObject.users[index].selected = true;

                    currentObject.assignTo = Number(e.target.value);
                } else {
                    currentObject.users[index].selected = false;
                }
            }
        }
        if (nameEvent === 'title') {
            currentObject.title = e.target.value;
        }

        setViewPostDataAssignBug(list);
    };

    const handleRemoveViewClick = (index) => {
        var listIdDelete = [];
        const list = [...viewPostDataAssignBug];
        list.splice(index, 1);
        setViewPostDataAssignBug(list);

        viewPostDataAssignBug.map((data, ind) => {
            if (ind === index) {
                listIdDelete.push(data.id);
            }
        });
        setListAssingBugDelete((prevState) => {
            var newData = [...prevState, ...listIdDelete];
            return newData;
        });
    };

    const handleCreatePathFile = () => {
        setOpenCreatePathFile(true);
    };

    const handleCopyPathFile = (pathFile) => {
        var data = {
            strFileName: pathFile,
        };

        callToWindowApp('OpenFileOrFolder', data);
    };

    const onCopy = useCallback(() => {
        setCopied(true);
    }, []);

    const handleCopy = useCallback(() => {
        Swal.fire('Success!', `Copy link task successfully`, 'success');
    }, []);

    useEffect(() => {
        const getLink = () => {
            if (window.location.hostname.startsWith('localhost')) {
                const value = `http://${window.location.hostname}:${window.location.port}/bug/${idTaskDetail}`;
                setValueCopy(value);
            } else {
                const value = `http://${window.location.hostname}/bug/${idTaskDetail}`;
                setValueCopy(value);
            }
        };
        getLink();
    }, [idTaskDetail]);

    const handleChangeStatus = (e) => {
        setValueStatus(+e.target.value);
    };

    return (
        <>
            <Dialog
                open={openDetail}
                onClose={handleClose}
                maxWidth="1200px"
                minwidth="1200px"
                className={cx('glo-index-dialog', 'res-dialog')}
            >
                <div className={cx('glo-dialog', 'glo-dialog-kanban-detail')}>
                    <div className={cx('row', 'glo-wrapper')}>
                        <div className={cx('col-8', 'wrapper-form')}>
                            <div className={cx('wrapper-form-project', 'glo-text')}>
                                <BsFillBookmarkFill className={cx('wrapper-form__icon')} />
                                {detailTitle !== '' && (
                                    <span className={cx('wrapper-form__name')}>&nbsp;{detailTitle}</span>
                                )}
                            </div>
                        </div>
                        <div className={cx('col-4')}>
                            <div className={cx('d-flex justify-content-end', 'wrapper-icon')}>
                                {pathFile == null ? (
                                    <button className={cx('btn-icon-item')} onClick={handleCreatePathFile}>
                                        <MdOutlineAddCircleOutline style={{ fontSize: '16px' }} />
                                        <span style={{ fontSize: '16px', marginLeft: '6px' }}>Add File</span>
                                    </button>
                                ) : (
                                    <>
                                        <button
                                            className={cx('btn-icon-item')}
                                            onClick={() => handleCopyPathFile(pathFile)}
                                        >
                                            <MdInsertDriveFile style={{ fontSize: '16px' }} />
                                            <span style={{ fontSize: '16px', marginLeft: '6px' }}>Open File</span>
                                        </button>
                                        <Tooltip
                                            title={<span style={{ fontSize: '1.6rem' }}>Update File</span>}
                                            placement="bottom"
                                        >
                                            <button
                                                className={cx('btn-icon-item')}
                                                onClick={() => setOpenUpdatePathFile(true)}
                                            >
                                                <VscEdit style={{ fontSize: '16px' }} />
                                            </button>
                                        </Tooltip>
                                    </>
                                )}
                                <CopyToClipboard onCopy={onCopy} text={valueCopy} options={{ format: 'text/plain' }}>
                                    <button className={cx('btn-icon-item')} onClick={handleCopy}>
                                        <BsLink45Deg style={{ fontSize: '20px' }} />
                                        <span style={{ fontSize: '16px', marginLeft: '6px' }}>Copy link</span>
                                    </button>
                                </CopyToClipboard>
                                <button className={cx('btn-icon-item')} onClick={handleClose}>
                                    <IoMdClose className={cx('icon-item')} />
                                </button>
                            </div>
                        </div>
                    </div>
                    <div></div>
                    <div className={cx('row', 'glo-wrapper', 'glo-scroll')}>
                        <div className={cx('col-12 col-lg-8')}>
                            <div className={cx('wrapper-form__title')}>
                                <b>Title :</b> {detailTitle}
                            </div>

                            <div className={cx('mt-3')}>
                                <div className={cx('content-description', 'row', 'glo-row')}>
                                    <b style={{ width: 'unset', padding: '0px' }}>Description:</b>{' '}
                                    <span
                                        style={{ width: 'unset' }}
                                        dangerouslySetInnerHTML={{ __html: detailDescription }}
                                    />
                                </div>
                            </div>

                            <div className={cx('row', 'glo-row')}>
                                <div className={cx('col-12 col-xl-4', 'glo-padding-none', 'mt-3')}>
                                    <div className={cx('row', 'glo-row')}>
                                        <div className={cx('col-12 col-lg-3', 'glo-padding-none', 'text-align-center')}>
                                            <b>Assign to:</b>
                                        </div>
                                        <div className={cx('col-12 col-lg-9', 'glo-padding-none')}>
                                            <select
                                                className="form-select form-select-lg"
                                                onChange={(e) => handleAssignTo(e)}
                                            >
                                                {users.length !== 0 &&
                                                    users.map((user, idx) => {
                                                        return (
                                                            <option
                                                                // defaultValue={userInfor?.id === user?.id}
                                                                selected={userInfor?.id === user?.id}
                                                                value={user.id}
                                                                key={idx}
                                                                dangerouslySetInnerHTML={{
                                                                    __html: user.name,
                                                                }}
                                                            ></option>
                                                        );
                                                    })}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className={cx('col-12 col-xl-4', 'glo-padding-none', 'mt-3')}>
                                    <div className={cx('row', 'glo-row')}>
                                        <div
                                            className={cx(
                                                'col-12 col-lg-3',
                                                'glo-padding-none',
                                                'text-align-center',
                                                'text-pl-10',
                                                'text-center-res',
                                            )}
                                        >
                                            <b>Due Date:</b>
                                        </div>
                                        <div className={cx('col-12 col-lg-9', 'glo-padding-none')}>
                                            <input
                                                type="date"
                                                className="form-control form-control-lg"
                                                id="duedate"
                                                value={
                                                    pickerPost
                                                        ? moment(pickerPost).format('YYYY/MM/DD').replaceAll('/', '-')
                                                        : ''
                                                }
                                                min={moment(new Date()).format('YYYY/MM/DD').replaceAll('/', '-')}
                                                onChange={(e) => handleChangeDate(e)}
                                            ></input>
                                        </div>
                                    </div>
                                </div>
                                {refIdBug !== 0 && (
                                    <div className={cx('col-12 col-xl-4', 'glo-padding-none', 'mt-3')}>
                                        <div className={cx('row', 'glo-row')}>
                                            <div
                                                className={cx(
                                                    'col-12 col-lg-3',
                                                    'glo-padding-none',
                                                    'text-align-center',
                                                    'text-pl-10',
                                                    'text-center-res',
                                                )}
                                            >
                                                <b>Status:</b>
                                            </div>
                                            <div className={cx('col-12 col-lg-9', 'glo-padding-none')}>
                                                <select
                                                    className="form-select form-select-lg"
                                                    onChange={(e) => handleChangeStatus(e)}
                                                >
                                                    {listStatus.map((ele) => (
                                                        <option
                                                            key={ele.id}
                                                            value={ele.id}
                                                            selected={ele.id === statusId}
                                                        >
                                                            {ele.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {kanBanRefId !== 0 && (
                                    <div className={cx('col-12 col-xl-4', 'glo-padding-none', 'mt-3')}>
                                        <div className={cx('row', 'glo-row')}>
                                            <div
                                                className={cx(
                                                    'col-4',
                                                    'glo-padding-none',
                                                    'text-align-center',
                                                    'text-pl-10',
                                                    'text-completed',
                                                    'text-center-res',
                                                )}
                                            >
                                                <b>Completed:</b>
                                            </div>
                                            <div className={cx('col-7', 'glo-padding-none')}>
                                                <input
                                                    type="number"
                                                    id="idCompleted"
                                                    className={cx('input-completed')}
                                                    value={completedPost}
                                                    onChange={(e) => handleInputCompleted(e)}
                                                />
                                                <label>%</label>
                                            </div>
                                            <div className={cx('col-1', 'glo-padding-10')}></div>
                                        </div>
                                    </div>
                                )}
                            </div>

                            {assingBug !== null && (
                                <div>
                                    {viewPostDataAssignBug?.length > 0 && (
                                        <div className={cx('row')}>
                                            <div>
                                                <b>Assign Bug</b>
                                            </div>
                                            {viewPostDataAssignBug?.map((assignData, i) => {
                                                return (
                                                    <div className={cx('row', 'glo-row')} key={i}>
                                                        <div
                                                            className={cx(
                                                                'col-12 col-lg-4',
                                                                'glo-padding-none',
                                                                'mt-3',
                                                            )}
                                                        >
                                                            <div className={cx('row', 'glo-row')}>
                                                                <div
                                                                    className={cx(
                                                                        'col-12 col-lg-3',
                                                                        'glo-padding-none',
                                                                        'text-align-center',
                                                                    )}
                                                                >
                                                                    <label>Name Task</label>
                                                                </div>
                                                                <div
                                                                    className={cx(
                                                                        'col-12 col-lg-9',
                                                                        'glo-padding-none',
                                                                    )}
                                                                >
                                                                    <select
                                                                        className="form-select form-select-lg"
                                                                        onChange={(e) =>
                                                                            handleInputViewChange(e, i, 'task')
                                                                        }
                                                                        name="idTask"
                                                                        value={
                                                                            assignData.listTaskChild?.find(
                                                                                (child) => child.selected === true,
                                                                            )?.id
                                                                        }
                                                                    >
                                                                        {assignData?.listTaskChild.map((data) => {
                                                                            return (
                                                                                <option value={data.id} key={data.id}>
                                                                                    {data.title}
                                                                                </option>
                                                                            );
                                                                        })}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className={cx(
                                                                'col-12 col-lg-4',
                                                                'glo-padding-none',
                                                                'mt-3',
                                                            )}
                                                        >
                                                            <div className={cx('row', 'glo-row')}>
                                                                <div
                                                                    className={cx(
                                                                        'col-12 col-lg-3',
                                                                        'glo-padding-none',
                                                                        'text-align-center',
                                                                        'text-pl-10',
                                                                        'text-center-res',
                                                                    )}
                                                                >
                                                                    <label>Assign bug</label>
                                                                </div>
                                                                <div
                                                                    className={cx(
                                                                        'col-12 col-lg-9',
                                                                        'glo-padding-none',
                                                                    )}
                                                                >
                                                                    <select
                                                                        className="form-select form-select-lg"
                                                                        id={`nameAssignTask${i}`}
                                                                        onChange={(e) =>
                                                                            handleInputViewChange(e, i, 'bug')
                                                                        }
                                                                        name="assignTask"
                                                                        value={
                                                                            assignData.users?.find(
                                                                                (us) => us.selected === true,
                                                                            )?.id
                                                                        }
                                                                    >
                                                                        {assignData?.users.map((user) => {
                                                                            return (
                                                                                <option value={user.id} key={user.id}>
                                                                                    <span
                                                                                        dangerouslySetInnerHTML={{
                                                                                            __html: user.name,
                                                                                        }}
                                                                                    ></span>
                                                                                </option>
                                                                            );
                                                                        })}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className={cx(
                                                                'col-12 col-lg-4',
                                                                'glo-padding-none',
                                                                'mt-3',
                                                            )}
                                                        >
                                                            <div className={cx('row', 'glo-row')}>
                                                                <div
                                                                    className={cx(
                                                                        'col-12 col-lg-4',
                                                                        'glo-padding-none',
                                                                        'text-align-center',
                                                                        'text-pl-10',
                                                                        'text-center-res',
                                                                    )}
                                                                >
                                                                    <label>Title bug</label>
                                                                </div>
                                                                <div
                                                                    className={cx(
                                                                        'col-12 col-lg-7',
                                                                        'glo-padding-none',
                                                                    )}
                                                                >
                                                                    <input
                                                                        type="text"
                                                                        className="form-control form-control-lg"
                                                                        name="titleTask"
                                                                        value={assignData.title}
                                                                        onChange={(e) =>
                                                                            handleInputViewChange(e, i, 'title')
                                                                        }
                                                                    />
                                                                </div>
                                                                <div
                                                                    className={cx(
                                                                        'col-12 col-sm-1',
                                                                        'glo-padding-10',
                                                                        'res-delete-assign-bug',
                                                                    )}
                                                                >
                                                                    <AiOutlineDelete
                                                                        onClick={() => handleRemoveViewClick(i)}
                                                                        className={cx('glo-btn-delete-assing-bug-pc')}
                                                                    />
                                                                    <button
                                                                        onClick={() => handleRemoveViewClick(i)}
                                                                        className={cx(
                                                                            'btn-delete-text-assign-bug',
                                                                            'glo-btn-delete-assing-bug-mb',
                                                                        )}
                                                                        size="medium"
                                                                    >
                                                                        Delete assign bug
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    )}

                                    <div>
                                        <div className={cx('mt-4', 'row', 'mb-4')}>
                                            <label className={cx('lbl-assign-bug')}>
                                                <b>Add child bug</b>
                                            </label>
                                            <div className={cx('assign-bug-icon-add')}>
                                                <MdAddCircleOutline onClick={handleAddAssignTask} />
                                            </div>
                                        </div>
                                        <div>
                                            {inputList?.map((x, i) => {
                                                return (
                                                    <div key={i}>
                                                        <div className={cx('mt-0')}>
                                                            <div className={cx('row', 'glo-row')}>
                                                                <div
                                                                    className={cx(
                                                                        'col-12 col-lg-4',
                                                                        'glo-padding-none',
                                                                    )}
                                                                >
                                                                    <div className={cx('row', 'glo-row')}>
                                                                        <div
                                                                            className={cx(
                                                                                'col-12 col-lg-3',
                                                                                'glo-padding-none',
                                                                                'text-align-center',
                                                                            )}
                                                                        >
                                                                            <label
                                                                                htmlFor="hn-create-project"
                                                                                className={cx('form-label')}
                                                                            >
                                                                                Name Task
                                                                            </label>
                                                                        </div>
                                                                        <div
                                                                            className={cx(
                                                                                'col-12 col-lg-9',
                                                                                'glo-padding-none',
                                                                            )}
                                                                        >
                                                                            {x.listTaskChild?.length > 0 && (
                                                                                <select
                                                                                    className="form-select form-select-lg mb-3"
                                                                                    aria-label=".form-select-lg example"
                                                                                    name="idTask"
                                                                                    onChange={(e) =>
                                                                                        handleInputChange(e, i, 'task')
                                                                                    }
                                                                                    value={
                                                                                        x.listTaskChild?.find(
                                                                                            (child) =>
                                                                                                child.selected === true,
                                                                                        )?.id
                                                                                    }
                                                                                >
                                                                                    <option>----</option>
                                                                                    {x.listTaskChild.map((data) => {
                                                                                        return (
                                                                                            <option
                                                                                                value={data.id}
                                                                                                key={data.id}
                                                                                            >
                                                                                                {data.title}
                                                                                            </option>
                                                                                        );
                                                                                    })}
                                                                                </select>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className={cx(
                                                                        'col-12 col-lg-4',
                                                                        'glo-padding-none',
                                                                    )}
                                                                >
                                                                    <div className={cx('row', 'glo-row')}>
                                                                        <div
                                                                            className={cx(
                                                                                'col-12 col-lg-3',
                                                                                'glo-padding-none',
                                                                                'text-align-center',
                                                                                'text-center-res',
                                                                            )}
                                                                        >
                                                                            <label
                                                                                htmlFor="hn-create-project"
                                                                                className={cx(
                                                                                    'form-label',
                                                                                    'text-pl-10',
                                                                                )}
                                                                            >
                                                                                Assign bug
                                                                            </label>
                                                                        </div>
                                                                        <div
                                                                            className={cx(
                                                                                'col-12 col-lg-9',
                                                                                'glo-padding-none',
                                                                            )}
                                                                        >
                                                                            {x.users?.length > 0 && (
                                                                                <select
                                                                                    className="form-select form-select-lg mb-3"
                                                                                    id={`nameAssignBug${i}`}
                                                                                    name="assignBug"
                                                                                    onChange={(e) =>
                                                                                        handleInputChange(e, i, 'bug')
                                                                                    }
                                                                                    value={
                                                                                        x.users?.find(
                                                                                            (us) =>
                                                                                                us.selected === true,
                                                                                        )?.id
                                                                                    }
                                                                                >
                                                                                    <option>----</option>
                                                                                    {x.users?.length !== 0 &&
                                                                                        x.users?.map((user) => {
                                                                                            return (
                                                                                                <option
                                                                                                    value={user.id}
                                                                                                    key={user.id}
                                                                                                    dangerouslySetInnerHTML={{
                                                                                                        __html: user.name,
                                                                                                    }}
                                                                                                ></option>
                                                                                            );
                                                                                        })}
                                                                                </select>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className={cx(
                                                                        'col-12 col-lg-4',
                                                                        'glo-padding-none',
                                                                    )}
                                                                >
                                                                    <div className={cx('row', 'glo-row')}>
                                                                        <div
                                                                            className={cx(
                                                                                'col-12 col-lg-4',
                                                                                'glo-padding-none',
                                                                                'text-align-center',
                                                                                'text-center-res',
                                                                            )}
                                                                        >
                                                                            <label
                                                                                htmlFor="title-add-assign-bug"
                                                                                className={cx(
                                                                                    'form-label',
                                                                                    'text-pl-10',
                                                                                )}
                                                                            >
                                                                                Title bug
                                                                            </label>
                                                                        </div>
                                                                        <div
                                                                            className={cx(
                                                                                'col-12 col-lg-7',
                                                                                'glo-padding-none',
                                                                            )}
                                                                        >
                                                                            <input
                                                                                type="text"
                                                                                className="form-control form-control-lg"
                                                                                name="titleTask"
                                                                                value={x.titleTask}
                                                                                placeholder="Title Task"
                                                                                onChange={(e) =>
                                                                                    handleInputChange(e, i, 'title')
                                                                                }
                                                                            />
                                                                        </div>
                                                                        <div
                                                                            className={cx(
                                                                                'col-12 col-sm-1',
                                                                                'glo-padding-10',
                                                                                'res-delete-assign-bug',
                                                                            )}
                                                                        >
                                                                            <AiOutlineDelete
                                                                                onClick={() => handleRemoveClick(i)}
                                                                                className={cx(
                                                                                    'glo-btn-delete-assing-bug-pc',
                                                                                )}
                                                                            />
                                                                            <button
                                                                                onClick={() => handleRemoveClick(i)}
                                                                                className={cx(
                                                                                    'btn-delete-text-assign-bug',
                                                                                    'glo-btn-delete-assing-bug-mb',
                                                                                )}
                                                                                size="medium"
                                                                            >
                                                                                Delete assign bug
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div className={cx('d-flex justify-content-end mt-3')}>
                                <Button className="btn-form__save" onClick={() => handleReport()} radius8px>
                                    Post
                                </Button>
                                <Button className="btn-form__cancel" onClick={() => setOpenDetail(false)} radius8px>
                                    Cancel
                                </Button>
                            </div>

                            {/*------------------------- comment--------------------------- */}
                            <div className={cx('detail-bug-comments')}>
                                <div className={cx('glo-detail-margin-bottom')}>
                                    <div className="row">
                                        <div className="col-12">
                                            <h3 className={cx('form-comment__title', 'mt-2', 'mb-2')}>Comments</h3>
                                            <Editor
                                                editorState={editorState}
                                                onEditorStateChange={onEditorStateChange}
                                                wrapperClassName="wrapper-class res-description"
                                                editorClassName="editor-class"
                                                toolbarClassName="toolbar-class"
                                                placeholder="Enter your comment...."
                                            />
                                        </div>
                                    </div>

                                    <div className={cx(`comment-item-btn comment-item-btn__create mt-3`)}>
                                        <div className={cx('row')}>
                                            <div className={cx('col-10')}>
                                                {valueComment ? (
                                                    <span className={cx('error-message')}>Not yet commented</span>
                                                ) : null}
                                            </div>
                                            <div className={cx('col-2')}>
                                                <div className={cx('d-flex justify-content-end')}>
                                                    <Button
                                                        className={cx('btn-form__create')}
                                                        onClick={handleCreateComment}
                                                        radius8px
                                                    >
                                                        Save
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {commentData &&
                                    commentData.map((info, idx) => {
                                        return (
                                            <Comment
                                                key={idx}
                                                info={info}
                                                getAllComment={getAllComment}
                                                idTaskDetail={idTaskDetail}
                                            />
                                        );
                                    })}
                            </div>
                        </div>
                        <div className={cx('col-12 col-lg-4', 'glo-detail-margin-left', 'glo-diplay-block')}>
                            <div className={cx('mt-3')}>
                                <div className={cx('row', 'glo-row')}>
                                    <div className={cx('col-2', 'hn-detail_lbl-status', 'glo-no-padding')}>Status:</div>
                                    <div className={cx('col-2')}>
                                        <Button
                                            className={cx(
                                                statusView !== 0
                                                    ? `list-project__item-status__${statusView?.split(' ').join('')}`
                                                    : '',
                                            )}
                                            radius8px
                                        >
                                            {statusView}
                                        </Button>
                                    </div>
                                </div>
                            </div>

                            <div className={cx('mt-3')}>
                                <div className={cx('row ', 'glo-row')}>
                                    <div className={cx('glo-border', 'd-flex')}>
                                        <span className={cx('col-10', 'body-details__title')}>Details</span>
                                        <span className={cx('col-2', 'title-task__icon')}>
                                            <MdKeyboardArrowUp />
                                        </span>
                                    </div>
                                </div>
                                <div className={cx('row', 'glo-row')}>
                                    <div className={cx('glo-border-detail')}>
                                        <div className={cx('row d-flex', 'body-details__item')}>
                                            <span className={cx('col-4', 'body-details__title')}>Assignee</span>
                                            <span
                                                className={cx('col-8')}
                                                dangerouslySetInnerHTML={{ __html: userInfor?.userName }}
                                            />
                                        </div>
                                        <div className={cx('row d-flex', 'body-details__item')}>
                                            <span className={cx('col-4', 'body-details__title')}>Email</span>
                                            <span className={cx('col-8')}>{userInfor?.email}</span>
                                        </div>
                                        <div className={cx('row d-flex', 'body-details__item')}>
                                            <span className={cx('col-4', 'body-details__title')}>Due Date</span>
                                            <span className={cx('col-8')}>
                                                {detailPicker ? moment(detailPicker).format('DD/MM/YYYY') : 'None'}
                                            </span>
                                        </div>
                                        <div className={cx('row d-flex', 'body-details__item')}>
                                            <div className={cx('col-4', 'body-details__title')}>Completed</div>
                                            <div className={cx('col-8')}>
                                                <div className={cx('d-flex', 'row')}>
                                                    <div className={cx('glo-kanbanBug-completed', 'glo-center')}>
                                                        <div className={cx('glo-kanbanBug-completed-background')}>
                                                            <div
                                                                className={cx(
                                                                    'glo-kanbanBug-completed-background-color',
                                                                )}
                                                                style={{
                                                                    background: '#0052cc',
                                                                    width: detailCompleted + '%',
                                                                    height: '5px',
                                                                }}
                                                            ></div>
                                                        </div>
                                                    </div>
                                                    <span className={cx('glo-item-work__value')}>
                                                        {detailCompleted}%
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {assingBug !== null && (
                                        <div
                                            className={cx(
                                                'mt-4',
                                                'p-0',
                                                'glo-border-table-assign',
                                                'table_task_children',
                                            )}
                                        >
                                            <div className={cx('list_task_children-res')}>
                                                <div className={cx('glo-header-table-assign', 'row', 'glo-row')}>
                                                    <div className={cx('glo-table-assign-Task')}>Task</div>
                                                    <div className={cx('glo-group-child')}>
                                                        <div className={cx('row', 'glo-row')}>
                                                            <div className={cx('glo-table-assign-Bug')}>Bug</div>
                                                            <div className={cx('glo-table-assign-Assing')}>Assing</div>
                                                            <div className={cx('glo-table-assign-completed')}>
                                                                Completed
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={cx('glo-body')}>
                                                    {viewDataAssignBug?.map((data, idx) => {
                                                        return (
                                                            <div
                                                                key={idx}
                                                                className={cx(
                                                                    'row',
                                                                    'glo-row',
                                                                    'glo-table-body-assign-tr',
                                                                )}
                                                            >
                                                                <div
                                                                    className={cx(
                                                                        'glo-table-assign-Task',
                                                                        'glo-body-assign-Task',
                                                                        'row',
                                                                    )}
                                                                >
                                                                    <div
                                                                        className={cx(
                                                                            data.status !== 0
                                                                                ? `glo-status-color_${data.status}`
                                                                                : '',
                                                                        )}
                                                                    ></div>
                                                                    <div
                                                                        className={cx('glo-body-assign-Task-div')}
                                                                        title={data.nameTask}
                                                                    >
                                                                        <p
                                                                            className={cx(
                                                                                'glo-text-overflow',
                                                                                'glo-padding-bottom-none',
                                                                            )}
                                                                        >
                                                                            {data.nameTask}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className={cx('glo-group-child', 'p-0')}>
                                                                    {data.bugChild?.map((child, idx) => {
                                                                        return (
                                                                            <div
                                                                                className={cx(
                                                                                    'row',
                                                                                    'glo-row',
                                                                                    'glo-table-body-assign-tr',
                                                                                    'p-0',
                                                                                )}
                                                                                key={idx}
                                                                            >
                                                                                <div
                                                                                    className={cx(
                                                                                        data.status !== 0
                                                                                            ? `glo-status-color-child_${child.status}`
                                                                                            : '',
                                                                                    )}
                                                                                ></div>
                                                                                <div
                                                                                    className={cx(
                                                                                        'glo-table-assign-Bug',
                                                                                    )}
                                                                                    title={child.title}
                                                                                >
                                                                                    <p
                                                                                        className={cx(
                                                                                            'glo-text-overflow',
                                                                                            'mb-0',
                                                                                        )}
                                                                                    >
                                                                                        {child.title}
                                                                                    </p>
                                                                                </div>
                                                                                <div
                                                                                    className={cx(
                                                                                        'glo-table-assign-Assing',
                                                                                    )}
                                                                                    title={child.nameAssignTo}
                                                                                >
                                                                                    <p
                                                                                        dangerouslySetInnerHTML={{
                                                                                            __html: child.nameAssignTo,
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                                <div
                                                                                    className={cx(
                                                                                        'glo-table-assign-completed',
                                                                                    )}
                                                                                >
                                                                                    {child.completed}%
                                                                                </div>
                                                                            </div>
                                                                        );
                                                                    })}
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>

            <FormCreatePathFile
                bugId={idTaskDetail}
                openCreatePathFile={openCreatePathFile}
                setOpenCreatePathFile={setOpenCreatePathFile}
                getDetailTask={getDetailTask}
            />

            <FormUpdatePathFile
                openUpdatePathFile={openUpdatePathFile}
                setOpenUpdatePathFile={setOpenUpdatePathFile}
                setCallbackCreatePathFile={setCallbackCreatePathFile}
                callbackCreatePathFile={callbackCreatePathFile}
                pathFile={pathFile}
                bugId={idTaskDetail}
                getDetailTask={getDetailTask}
            />
        </>
    );
}

export default memo(DetailBugDialog);
