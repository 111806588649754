import { useContext, useEffect, useState } from 'react';
import Pagination from '@mui/material/Pagination';
import { GlobalStateProject } from '~/ProjectState/projectState';

function Panigation({ itemsPerPage }) {
    const state = useContext(GlobalStateProject);
    // const [page, setPage] = state.projectsAPI.page;
    const [offset, setOffset] = state.projectsAPI.offset;
    const [page, setPage] = state.projectsAPI.page;
    const [pageSize, setPageSize] = state.projectsAPI.pageSize;

    const handleChange = (value) => {
        setOffset((value - 1) * pageSize);
        setPage(value);
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    };

    return (
        <Pagination
            count={itemsPerPage}
            variant="outlined"
            color="primary"
            page={page}
            onChange={(e, value) => handleChange(value)}
        />
    );
}

export default Panigation;
