import classNames from 'classnames/bind';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React, { useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import styles from '../CreateProject/CreateProjectDialog.module.scss';
import { Editor } from 'react-draft-wysiwyg';
import { convertFromHTML, convertToRaw, EditorState } from 'draft-js';
import Qs from 'qs';
import axios from 'axios';
import { useSelector } from 'react-redux';
import api from '~/utils/main';
import Moment from 'moment';
import { toast } from 'react-toastify';
import draftToHtml from 'draftjs-to-html';
import EditForm from '~/pages/Home/components/EditorForm/EditorForm';
import EditDescription from '~/pages/Home/components/DetailTask/components/EditDescription';

const cx = classNames.bind(styles);

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 15,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const initialState = {
    name: '',
    shortName: '',
    description: '',
    userAssign: '',
};

const EditReport = ({ editorState, onChange }) => {
    const formReport = useRef();

    useEffect(() => {
        formReport.current.focusEditor();
    }, [formReport]);

    return (
        <Editor
            ref={formReport}
            editorState={editorState}
            onEditorStateChange={onChange}
            wrapperClassName="wrapper-class res-editor-cmt"
            editorClassName="editor-class"
            toolbarClassName="toolbar-class"
            placeholder="Enter your comment...."
        />
    );
};

const EditProject = ({ openDialogEditProject, setOpenDialogEditProject, idProject, getListProject }) => {
    const [dataCreate, setDataCreate] = useState(initialState);
    const { name, shortName, userAssign } = dataCreate;
    const [editContent, setEditContent] = useState('');
    const [valueDes, setValueDes] = useState('');

    const inputNameProject = useRef();
    const auth = useSelector((state) => state.auth);

    const handleCloseEditProjectDialog = () => {
        setOpenDialogEditProject(false);
        setValueDes('');
        setEditContent('');
    };

    const handleChangeNameProject = (value) => {
        setDataCreate({ ...dataCreate, name: value });
    };

    const handleChangeShortNameProject = (value) => {
        setDataCreate({ ...dataCreate, shortName: value });
    };

    const handleChangeDefaultAssign = (value) => {
        setDataCreate({ ...dataCreate, userAssign: Number(value) });
    };

    useEffect(() => {
        if (idProject !== '') {
            getProject();
            setValueDes('');
        }
    }, [openDialogEditProject]);

    const getProject = async () => {
        api.get(`projects/${idProject}`).then((res) => {
            setDataCreate(res.data.data);
            setValueDes(res.data.data.description);
            setEditContent(res.data.data.description);
        });
    };

    const handleEditProject = async (e) => {
        e.preventDefault();

        const res = await toast.promise(
            api.patch(`projects/${idProject}/edit`, {
                projectId: idProject,
                name,
                shortName,
                userAssign: userAssign ?? 0,
                description: editContent,
            }),
            {
                pending: 'edit project is pending',
                success: 'edit project successfully',
                error: 'edit project failed',
            },
        );
        if (res.status === 204) {
            setOpenDialogEditProject(false);
            getListProject();
        }
    };
    return (
        <div className={cx('header-create-task')}>
            <form>
                <BootstrapDialog
                    open={openDialogEditProject}
                    onClose={handleCloseEditProjectDialog}
                    // maxWidth="xl"
                    maxWidth="1700px"
                    minwidth="1500px"
                    fullWidth
                    aria-labelledby="customized-dialog-title"
                >
                    <BootstrapDialogTitle
                        id="customized-dialog-title"
                        onClose={handleCloseEditProjectDialog}
                        className={cx('custom-title-control')}
                    >
                        <div className={cx('dialog-create-project-title')}>
                            <h2>Update Project</h2>
                        </div>
                    </BootstrapDialogTitle>
                    <DialogContent dividers>
                        <div className={cx('dialog-create-project-content')}>
                            <div className={cx('row')}>
                                <div className={cx('col-12 col-md-6 mb-3')}>
                                    <div className={cx('form-group row')}>
                                        <label htmlFor="project-name" className="col-lg-4 col-xxl-2 col-form-label">
                                            Project Name<i className={cx('hn-color-red')}>*</i>
                                        </label>
                                        <div className="col-lg-8 col-xxl-10">
                                            <input
                                                ref={inputNameProject}
                                                type="text"
                                                className="form-control form-control-lg"
                                                id="project-name"
                                                value={dataCreate.name}
                                                onChange={(e) => handleChangeNameProject(e.target.value)}
                                                disabled={(auth.users.userType === 1 || auth.users.userType === 3)}
                                            ></input>
                                        </div>
                                    </div>
                                </div>
                                <div className={cx('col-12 col-md-6 mb-3')}>
                                    <div className={cx('form-group row')}>
                                        <label htmlFor="project-short-name" className="col-lg-4 col-xxl-2 col-form-label">
                                            Short Name<i className={cx('hn-color-red')}>*</i>
                                        </label>
                                        <div className="col-lg-8 col-xxl-10">
                                            <input
                                                type="text"
                                                className="form-control form-control-lg"
                                                id="project-short-name"
                                                value={dataCreate.shortName}
                                                onChange={(e) => handleChangeShortNameProject(e.target.value)}
                                                disabled={(auth.users.userType === 1 || auth.users.userType === 3)}
                                            ></input>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={cx('row')}>
                                <div className={cx('col-12 col-md-6 mb-3')}>
                                    <div className={cx('form-group row')}>
                                        <label htmlFor="project-default-assign-to" className="col-lg-4 col-xxl-2 col-form-label">
                                            Default Assign To
                                        </label>
                                        <div className="col-lg-8 col-xxl-10">
                                            <select
                                                className="form-select form-select-lg mb-3"
                                                id="project-default-assign-to"
                                                onChange={(e) => handleChangeDefaultAssign(e.target.value)}
                                                disabled={(auth.users.userType === 1 || auth.users.userType === 3)}
                                            >
                                                <option value={dataCreate.assignToDefault}>---</option>
                                                {dataCreate?.assignTos?.map((userInProject) => {
                                                    return (
                                                        <option value={userInProject.id} key={userInProject.id}>
                                                            {userInProject.userName}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className={cx('col-12 col-md-6 mb-3')}>
                                    <div className={cx('form-group row')}>
                                        <label htmlFor="project-create-by" className="col-lg-4 col-xxl-2 col-form-label">
                                            Created by:
                                        </label>
                                        <div className={cx('col-lg-8 col-xxl-10')}>
                                            {/* <span>
                                                {dataCreate.createBy}, {Moment(dataCreate.dtCreated).format('DD-MM-YYYY')}
                                            </span> */}
                                            <input
                                                type="text"
                                                className="form-control form-control-lg"
                                                id="create-by"
                                                value={
                                                    dataCreate?.createBy +
                                                    ' ' +
                                                    Moment(dataCreate.dtCreated).format('DD-MM-YYYY')
                                                }
                                                disabled
                                            ></input>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mb-3">
                                <div className="row">
                                    <div className="col-12">
                                        <label
                                            htmlFor="hn-create-description"
                                            className={cx('form-label', 'form-label-title')}
                                        >
                                            Description
                                        </label>

                                        {valueDes !== '' && (
                                            <EditDescription
                                                name={valueDes}
                                                onChange={() => { }}
                                                setEditContent={setEditContent}
                                                editContent={editContent}
                                            />
                                        )}
                                        {valueDes === '' && (
                                            <EditDescription
                                                name={valueDes}
                                                onChange={() => { }}
                                                setEditContent={setEditContent}
                                                editContent={editContent}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions className={cx('create-project-action')}>
                        <button onClick={handleCloseEditProjectDialog} className={cx('hn-btn-cancel')} size="medium">
                            Cancel
                        </button>
                        <button type="submit" onClick={handleEditProject} className={cx('hn-btn-submit')} size="medium">
                            Save
                        </button>
                    </DialogActions>
                </BootstrapDialog>
            </form>
        </div>
    );
};

export default EditProject;
