import api from '~/utils/main';
import { toast } from 'react-toastify';

export const getAllQuestionReview = async () => {
    try {
        const res = await api.get(`review-member/group-question`);
        return res;
    } catch (error) {
        console.log(error);
    }
};

export const postAnswerFromReview = async (body) => {
    try {
        const res = await toast.promise(api.post(`review-member/answer-review`, body), {
            pending: 'Add review results pending',
            success: 'Add review results successfully',
            error: 'Add review results failed',
        });
        return res;
    } catch (error) {
        console.log(error);
    }
};

export const getListAllReviewOfMember = async (params) => {
    try {
        const res = await api.get(`review-member/all`, { params });
        return res;
    } catch (error) {
        console.log(error);
    }
};

export const getInfoReview = async (id, params) => {
    try {
        const res = await api.get(`review-member/${id}`, { params });
        return res;
    } catch (error) {
        console.log(error);
    }
};


export const getInfoReviewOfMember = async (reviewId) => {
    try {
        const params = {
            reviewId,
        };
        const res = await api.get(`review-member/user/${reviewId}`, { params });
        return res;
    } catch (error) {
        console.log(error);
    }
};

export const getGroupQuestions = async () => {
    try {
        const res = await api.get("review-member/group-question");
        return res;
    } catch (error) {
        console.log(error);
    }
};

// export const editInfoReviewOfMember = async (reviewId, body) => {
//     try {
//         const res = await toast.promise(api.patch(`review-member/user/${Number(reviewId)}`, body), {
//             pending: 'Update review results pending',
//             success: 'Update review results successfully',
//             error: 'Update review results failed',
//         });
//         return res;
//     } catch (error) {
//         console.log(error);
//     }
// };

export const createGroupQs = async (body) => {
    try {
        const res = await toast.promise(api.post(`review-member/create-group-question`, body), {
            pending: 'Create group question review pending',
            success: 'Create group question review successfully',
            error: 'Create group question review failed',
        });
        return res;
    } catch (error) {
        console.log(error);
    }
};

export const createQuestion = async (body) => {
    try {
        const res = await toast.promise(api.post(`review-member/create-question`, body), {
            pending: 'Create question review pending',
            success: 'Create question review successfully',
            error: 'Create question review failed',
        });
        return res;
    } catch (error) {
        console.log(error);
    }
};


export const sendEmailReview = async (body) => {
    try {
        const res = await toast.promise(api.post(`review-member/send-mail`, body), {
            pending: 'Send email review pending',
            success: 'Send email review successfully',
            error: 'Send email review failed',
        });
        return res;
    } catch (error) {
        console.log(error);
    }
};