import React, { useEffect, useState, useContext } from 'react';
import { Button, IconButton, Pagination } from '@mui/material';
import classNames from 'classnames/bind';
import styles from './Statistical.module.scss';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import ReactLoading from 'react-loading';
import api from '~/utils/main';
import { toast } from 'react-toastify';
import moment from 'moment';
import * as userService from '~/services/userService';

const cx = classNames.bind(styles);

const Statistical = () => {
    const [listUser, setListUser] = useState([]);
    const [loadingData, setLoadingData] = useState(false);

    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(12);
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(0);

    const [searchName, setSearchName] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [isSearchDate, setIsSearchDate] = useState(false);
    // const [validStartDate, setValidStartDate] = useState(false);
    // const [validEndDate, setValidEndDate] = useState(false);

    useEffect(() => {
        getListUser();
    }, [page, searchName]);

    useEffect(() => {
        if (startDate !== '' && endDate !== '') {
            setIsSearchDate(true);
        }
    }, [startDate, endDate]);

    const getListUser = async () => {
        setLoadingData(true);
        const params = {
            Offset: offset,
            Limit: limit,
            ...(startDate ? { FromDate: startDate } : {}),
            ...(endDate ? { ToDate: endDate } : {}),
            ...(isSearchDate ? { IsSearchDate: isSearchDate } : {}),
            ...(searchName ? { SearchName: searchName } : {}),
        };
        const res = await userService.getListStatistical(params);
        if (res.status === 200) {
            setListUser(res.data.result.listStatistical);
            setTotalPage(Math.ceil(res.data.result.totalCount / 12));
            setLoadingData(false);
        }
    };

    const handleSearchStatistical = async (e) => {
        // if (startDate === '' || startDate == null || startDate === 'undefined') {
        //     setValidStartDate(true);
        // }

        // if (endDate === '' || startDate == null || endDate === 'undefined') {
        //     setValidEndDate(true);
        // }

        getListUser();
    };

    const handleChange = (value) => {
        setOffset((value - 1) * limit);
        setPage(value);
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    };

    const handleChangeStartDate = (e) => {
        const value = moment(new Date(e.target.value)).format('YYYY-MM-DD');
        setStartDate(value);
        // setValidStartDate(false);
    };

    const handleChangeEndDate = (e) => {
        const value = moment(new Date(e.target.value)).format('YYYY-MM-DD');
        setEndDate(value);
        // setValidEndDate(false);
    };

    const handleChangeSearchName = (e) => {
        const searchName = e.target.value;
        if (!searchName.startsWith(' ')) {
            setOffset(0);
            setSearchName(searchName);
        }

        if (searchName === '') {
            getListUser();
        }
    };

    const getSearchStatistical = async (e) => {
        if (e.key === 'Enter') {
            getListUser();
        }
    };

    return (
        <div className={cx('wrapper-list')}>
            <div className={cx('mt-3', 'mb-3')}>
                <div className={cx('row')}>
                    <div className={cx('col-xxl-3', 'res-title')}>
                        <span className={cx('title-underlined')}>Statistic</span>
                    </div>
                    <div className={cx('col-xxl-9', 'mt-2', 'mt-lg-0', 'res-search')}>
                        <div className={cx('row', 'mt-lg-2')}>
                            <div className={cx('col-lg-12 col-xl-4 col-xxl-5')}>
                                <div className={cx('form-group row')}>
                                    <label
                                        htmlFor="search-name"
                                        className="col-lg-3 col-xl-6 col-xxl-4 col-form-label text-xl-end"
                                    >
                                        User Name
                                    </label>
                                    <div className={cx('col-lg-9 col-xl-6 col-xxl-8')}>
                                        <input
                                            id="search-name"
                                            type="search"
                                            placeholder="Search user..."
                                            className={cx('form-control', 'form-control-lg')}
                                            value={searchName}
                                            onChange={handleChangeSearchName}
                                            onKeyDown={getSearchStatistical}
                                        ></input>
                                    </div>
                                </div>
                            </div>
                            <div className={cx('col-md-6 col-lg-6 col-xl-4 col-xxl-3', 'mt-lg-2', 'mt-xl-0')}>
                                <div className={cx('form-group row')}>
                                    <label
                                        htmlFor="start-date"
                                        className="col-lg-5 col-xxl-5 col-form-label text-xl-end"
                                    >
                                        Start Date
                                    </label>
                                    <div className={cx('col-lg-7 col-xxl-7')}>
                                        <input
                                            type="date"
                                            className="form-control form-control-lg"
                                            id="from-day"
                                            value={startDate}
                                            onChange={(e) => handleChangeStartDate(e)}
                                            onKeyDown={(e) => {
                                                e.preventDefault();
                                            }}
                                        ></input>
                                        {/* {validStartDate && (
                                            <small className={cx('txt-notice', 'text-flex', 'mt-2')}>
                                                No select the start date
                                            </small>
                                        )} */}
                                    </div>
                                </div>
                            </div>
                            <div className={cx('col-md-6 col-lg-6 col-xl-4 col-xxl-3', 'mt-lg-2', 'mt-xl-0')}>
                                <div className={cx('form-group row')}>
                                    <label htmlFor="end-date" className="col-lg-5 col-xxl-5 col-form-label text-xl-end">
                                        End Date
                                    </label>
                                    <div className={cx('col-lg-7 col-xxl-7')}>
                                        <input
                                            type="date"
                                            className="form-control form-control-lg"
                                            id="end-date"
                                            value={endDate}
                                            onChange={(e) => handleChangeEndDate(e)}
                                            min={moment(startDate).format('YYYY/MM/DD').replaceAll('/', '-')}
                                        ></input>
                                        {/* {validEndDate && (
                                            <small className={cx('txt-notice', 'text-flex', 'mt-2')}>
                                                No select the end date
                                            </small>
                                        )} */}
                                    </div>
                                </div>
                            </div>
                            <div className={cx('col-xl-6 col-xxl-1', 'mt-3', 'mt-lg-2', 'mt-xxl-0', 'text-start')}>
                                <button className={cx('btn-search-statistical')} onClick={handleSearchStatistical}>
                                    Search
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={cx('statistical-table-content')}>
                <div className={cx('statistical-table')}>
                    <div className={cx('statistical_table-thread')}>
                        <div className={cx('list-statistical-username')}>User Name</div>
                        <div className={cx('list-statistical-level')}>Level</div>
                        <div className={cx('list-statistical-task-quantity')}>Task Quantity</div>
                        <div className={cx('list-statistical-total-bug')}>Bug Quantity</div>
                        <div className={cx('list-statistical-total-score')}>Total Score</div>
                        <div className={cx('list-statistical-total-point')}>Total Bug / Total Task</div>
                        <div className={cx('list-statistical-average-score')}>Total Score/Total Task</div>
                    </div>
                    <div className={cx('statistical_table-tbody')}>
                        {loadingData === true ? (
                            <ReactLoading type="spin" color="#ccc" height={'50px'} width={'50px'} />
                        ) : (
                            <>
                                {listUser.length !== 0 ? (
                                    <>
                                        {listUser?.map((taskStatistical, index) => {
                                            return (
                                                <div className={cx('row', 'statis-body', 'glo-row')} key={index}>
                                                    <div className={cx('statis-name')}>
                                                        <span
                                                            dangerouslySetInnerHTML={{
                                                                __html: taskStatistical.userName,
                                                            }}
                                                        />
                                                    </div>
                                                    <div className={cx('statis-total')}>
                                                        <div className={cx('row', 'sty-border')}>
                                                            <div
                                                                className={cx(
                                                                    'list-statistical-item-level',
                                                                    'cs-font-weight',
                                                                )}
                                                            >
                                                                Total
                                                            </div>
                                                            <div
                                                                className={cx(
                                                                    'list-statistical-item-task-quantity',
                                                                    'cs-font-weight',
                                                                )}
                                                            >
                                                                {taskStatistical.totalTasks}
                                                            </div>
                                                            <div
                                                                className={cx(
                                                                    'list-statistical-item-total-bug',
                                                                    'cs-font-weight',
                                                                )}
                                                            >
                                                                {taskStatistical.totalBugs}
                                                            </div>
                                                            <div
                                                                className={cx(
                                                                    'list-statistical-item-total-score',
                                                                    'cs-font-weight',
                                                                )}
                                                            >
                                                                {taskStatistical.totalScore}
                                                            </div>
                                                            <div
                                                                className={cx(
                                                                    'list-statistical-item-total-point',
                                                                    'cs-font-weight',
                                                                )}
                                                            >
                                                                {/* {taskStatistical.totalPoint} */}
                                                            </div>
                                                            <div
                                                                className={cx(
                                                                    'list-statistical-item-average-score',
                                                                    'cs-font-weight',
                                                                )}
                                                            >
                                                                {/* {taskStatistical.averageScore !== "null" && (
                                                                    <span>{(Math.round((taskStatistical.averageScore + Number.EPSILON) * 100) / 100)}</span>
                                                                )} */}
                                                            </div>
                                                        </div>
                                                        {taskStatistical?.statisticalByLevel?.length !== 0 && (
                                                            <>
                                                                {taskStatistical.statisticalByLevel.map(
                                                                    (lstLevel, index) => {
                                                                        return (
                                                                            <div
                                                                                className={cx('row', 'sty-border')}
                                                                                key={index}
                                                                            >
                                                                                <div
                                                                                    className={cx(
                                                                                        'list-statistical-item-level',
                                                                                    )}
                                                                                >
                                                                                    {lstLevel.level}
                                                                                </div>
                                                                                <div
                                                                                    className={cx(
                                                                                        'list-statistical-item-task-quantity',
                                                                                        'total-detail-color',
                                                                                    )}
                                                                                >
                                                                                    {lstLevel.totalTasksByLevel}
                                                                                </div>
                                                                                <div
                                                                                    className={cx(
                                                                                        'list-statistical-item-total-bug',
                                                                                        'total-detail-color',
                                                                                    )}
                                                                                >
                                                                                    {lstLevel.totalBugByLevel}
                                                                                </div>
                                                                                <div
                                                                                    className={cx(
                                                                                        'list-statistical-item-total-score',
                                                                                        'total-detail-color',
                                                                                    )}
                                                                                >
                                                                                    {lstLevel.totalScoreByLevel}
                                                                                </div>
                                                                                <div
                                                                                    className={cx(
                                                                                        'list-statistical-item-total-point',
                                                                                        'total-detail-color',
                                                                                    )}
                                                                                >
                                                                                    {lstLevel.totalBugOnTasks}
                                                                                </div>
                                                                                <div
                                                                                    className={cx(
                                                                                        'list-statistical-item-average-score',
                                                                                        'total-detail-color',
                                                                                    )}
                                                                                >
                                                                                    {lstLevel.totalScoreOnTasks}
                                                                                </div>
                                                                            </div>
                                                                        );
                                                                    },
                                                                )}
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </>
                                ) : (
                                    <p className={cx('txt-notice')}>No data on productivity statistics</p>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
            <div className={cx('statistical-pagination')}>
                <Pagination
                    count={totalPage}
                    variant="outlined"
                    color="primary"
                    page={page}
                    onChange={(e, value) => handleChange(value)}
                />
            </div>
        </div>
    );
};

export default Statistical;
