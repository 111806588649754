import classNames from 'classnames/bind';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React, { useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import styles from '../FormQuestion/FormQuestion.module.scss';
import { useSelector } from 'react-redux';
import * as reviewMemberService from '~/services/reviewMemberService';
import api from '~/utils/main';

const cx = classNames.bind(styles);

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 15,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const DialogResultReview = ({ openDialogResultRv, setOpenDialogResultRv, reviewID, nameRv }) => {
    const [dtQuestionAnswerMember, setDtQuestionAnswerMember] = useState([]);
    const [dtQuestionAnswerLeader, setDtQuestionAnswerLeader] = useState([]);

    useEffect(() => {
        if (openDialogResultRv) {
            getDetailInfoReviewMember();
            getDetailInfoReviewLeader();
        }
    }, [openDialogResultRv]);

    const getDetailInfoReviewMember = async () => {
        const params = {
            ReviewId: reviewID,
            // IsReviewedByLeader: false
        };
        const res = await reviewMemberService.getInfoReview(reviewID, params);
        if (res.status === 200) {
            setDtQuestionAnswerMember(res.data.data);
        }
    };

    const getDetailInfoReviewLeader = async () => {
        const params = {
            ReviewId: reviewID,
            IsReviewedByLeader: true,
        };
        const res = await reviewMemberService.getInfoReview(reviewID, params);
        if (res.status === 200) {
            setDtQuestionAnswerLeader(res.data.data);
        }
    };

    const handleCloseDialogViewReview = () => {
        setOpenDialogResultRv(false);
        setDtQuestionAnswerMember([]);
        setDtQuestionAnswerLeader([]);
    };

    return (
        <div className={cx('header-create-task')}>
            <form>
                <BootstrapDialog
                    open={openDialogResultRv}
                    onClose={handleCloseDialogViewReview}
                    // maxWidth="xl"
                    maxWidth="1700px"
                    minwidth="1500px"
                    fullWidth
                    aria-labelledby="customized-dialog-title"
                >
                    <BootstrapDialogTitle
                        id="customized-dialog-title"
                        onClose={handleCloseDialogViewReview}
                        className={cx('custom-title-control')}
                    >
                        <div className={cx('dialog-create-project-title')}>
                            <h2>Information Review</h2>
                        </div>
                    </BootstrapDialogTitle>
                    <DialogContent dividers>
                        <div className={cx('rev-dialog-fill')}>
                            <div className={cx('row')}>
                                <div className={cx('col-12 col-md-6', 'rev-result-member')}>
                                    <div className={cx('rev-information-staff', 'p-4')}>
                                        <div className={cx('row', 'glo-row')}>
                                            <div className={cx('col-12 col-xl-6')}>
                                                <p className={cx('rev-txt-employee')}>
                                                    Employee name:{' '}
                                                    <span dangerouslySetInnerHTML={{ __html: nameRv }}></span>
                                                </p>
                                            </div>
                                            <div className={cx('col-12 col-xl-6')}>
                                                <p className={cx('rev-txt-employee')}>
                                                    Job Title: <span>Employee</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={cx('rev-list-question-answer')}>
                                        {dtQuestionAnswerMember.length > 0 ? (
                                            <>
                                                {dtQuestionAnswerMember.map((lstMember, idx) => {
                                                    return (
                                                        <>
                                                            <h2
                                                                style={{
                                                                    color: '#0052cc',
                                                                    textDecoration: 'underline',
                                                                }}
                                                            >
                                                                {lstMember.groupQuestionName}
                                                            </h2>
                                                            {lstMember.reviewDetails.map((rv) => (
                                                                <div className={cx('rev-question-answer')} key={idx}>
                                                                    <div className={cx('rev-question')}>
                                                                        <div style={{ display: 'flex' }}>
                                                                            <b>Câu {rv.orderNumber}: </b>
                                                                            {rv.question}
                                                                            {rv.fromLevel && rv.toLevel && (
                                                                                <span
                                                                                    style={{
                                                                                        marginLeft: '8px',
                                                                                        color: '#0052cc',
                                                                                    }}
                                                                                >{`(${rv.fromLevel} => ${rv.toLevel})`}</span>
                                                                            )}
                                                                            {rv.level && (
                                                                                <div
                                                                                    className={cx('level')}
                                                                                    style={{ marginLeft: '8px' }}
                                                                                >
                                                                                    {rv.level}
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                    <div className={cx('rev-answer')}>
                                                                        {/* <div className={cx('wrapper-level')}>
                                                                            {rv.level && (
                                                                                <div className={cx('level')}>
                                                                                    {rv.level}
                                                                                </div>
                                                                            )}
                                                                        </div> */}
                                                                        <textarea
                                                                            className={cx(
                                                                                'form-control',
                                                                                'clear-answer-value',
                                                                                'font-text-area',
                                                                            )}
                                                                            id={`answer${idx}`}
                                                                            rows="4"
                                                                            defaultValue={rv.answer}
                                                                            disabled
                                                                        ></textarea>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </>
                                                    );
                                                })}
                                            </>
                                        ) : (
                                            <p className={cx('text-danger', 'text-center')}>Not reviewed yet</p>
                                        )}
                                    </div>
                                </div>
                                <div className={cx('col-12 col-md-6', 'rev-result-leader')}>
                                    <div className={cx('rev-information-staff', 'p-4')}>
                                        <div className={cx('row', 'glo-row')}>
                                            <div className={cx('col-12 col-xl-6')}>
                                                <p className={cx('rev-txt-employee')}>
                                                    Position: <span>Leader</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={cx('rev-list-question-answer')}>
                                        {dtQuestionAnswerLeader.length > 0 ? (
                                            <>
                                                {dtQuestionAnswerLeader.map((lstLeader, idx) => {
                                                    return (
                                                        <>
                                                            <h2
                                                                style={{
                                                                    color: '#0052cc',
                                                                    textDecoration: 'underline',
                                                                }}
                                                            >
                                                                {lstLeader.groupQuestionName}
                                                            </h2>
                                                            {lstLeader.reviewDetails.map((rv) => (
                                                                <div className={cx('rev-question-answer')} key={idx}>
                                                                    <div className={cx('rev-question')}>
                                                                        <div style={{ display: 'flex' }}>
                                                                            <b>Câu {rv.orderNumber}: </b>
                                                                            {rv.question}
                                                                            {rv.fromLevel && rv.toLevel && (
                                                                                <span
                                                                                    style={{
                                                                                        marginLeft: '8px',
                                                                                        color: '#0052cc',
                                                                                    }}
                                                                                >{`(${rv.fromLevel} => ${rv.toLevel})`}</span>
                                                                            )}
                                                                            {rv.level && (
                                                                                <div
                                                                                    className={cx('level')}
                                                                                    style={{ marginLeft: '8px' }}
                                                                                >
                                                                                    {rv.level}
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                    <div className={cx('rev-answer')}>
                                                                        {/* <div className={cx('wrapper-level')}>
                                                                            {rv.level && (
                                                                                <div className={cx('level')}>
                                                                                    {rv.level}
                                                                                </div>
                                                                            )}
                                                                        </div> */}
                                                                        <textarea
                                                                            className={cx(
                                                                                'form-control',
                                                                                'clear-answer-value',
                                                                                'font-text-area',
                                                                            )}
                                                                            id={`answer${idx}`}
                                                                            rows="4"
                                                                            defaultValue={rv.answer}
                                                                            disabled
                                                                        ></textarea>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </>
                                                    );
                                                })}
                                            </>
                                        ) : (
                                            <p className={cx('text-danger', 'text-center')}>Not reviewed yet</p>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions className={cx('review-action')}>
                        <button onClick={handleCloseDialogViewReview} className={cx('btn-review-cancel')} size="medium">
                            Cancel
                        </button>
                    </DialogActions>
                </BootstrapDialog>
            </form>
        </div>
    );
};

export default DialogResultReview;
