import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import styles from '../Profile.module.scss';
import ReactLoading from 'react-loading';
import * as userService from '~/services/userService';
import DialogDayOff from './DialogDayOff';
import moment from 'moment';
import { convertDuration, convertTypeOff } from '../../../components/DayOffCommon';

const cx = classNames.bind(styles);

const DayOff = () => {
    const [listDayOff, setListDayOff] = useState(null);
    const [listDetail, setListDetail] = useState([]);
    const [loadingData, setLoadingData] = useState(false);
    const [openDialogDayOff, setOpenDialogDayOff] = useState(false);
    const [checkAddOrUpdate, setCheckAddOrUpdate] = useState('');
    const [callback, setCallback] = useState(false);

    useEffect(() => {
        getListDayOff();
    }, [callback]);

    const getListDayOff = async () => {
        setLoadingData(true);
        const res = await userService.getDayOffUser();
        if (res.status === 200) {
            setListDayOff(res.data.result);
            setLoadingData(false);
            setCallback(false);
        }
    };

    const handleAddDayOff = () => {
        setOpenDialogDayOff(true);
        setCheckAddOrUpdate('Add');
        setListDetail([]);
    };

    const handleOpenDialogUpdate = async (item) => {
        setOpenDialogDayOff(true);
        setCheckAddOrUpdate('Update');
        setListDetail(item);
    };

    return (
        <div className={cx('wrapper-day-off')}>
            <div className={cx('list-user-table-content', 'mt-3')}>
                <div className={cx('mb-4', 'profile-control')}>
                    <button type="button" className={cx('btn', 'btn-profile-update')} onClick={handleAddDayOff}>
                        Add
                    </button>
                </div>
                <div className={cx('list-user-table')}>
                    {listDayOff?.length === 0 && <div className={cx('glo-have-not-day')}>You don't have days off</div>}
                    {listDayOff?.length > 0 && (
                        <div className={cx('list-user_table')}>
                            <div className={cx('list-user_title-table')}>
                                <div className={cx('glo-table-col')}>Date Start</div>
                                <div className={cx('glo-table-col')}>Duration Start</div>
                                <div className={cx('glo-table-col')}>Date End</div>
                                <div className={cx('glo-table-col')}>Duration End</div>
                                <div className={cx('glo-table-col')}>Type Off</div>
                                <div className={cx('glo-table-col')}>Reason</div>
                            </div>

                            {loadingData === true ? (
                                <ReactLoading type="spin" color="#ccc" height={'50px'} width={'50px'} />
                            ) : (
                                <>
                                    {listDayOff.map((item, index) => {
                                        return (
                                            <div
                                                className={cx('list-user_item-body-tr', 'glo-cursor-pointer')}
                                                key={index}
                                                onClick={(e) => handleOpenDialogUpdate(item)}
                                            >
                                                <div className={cx('glo-table-col')}>
                                                    {item.dayOffUser
                                                        ? moment(new Date(item.dayOffUser)).format('YYYY/MM/DD')
                                                        : ''}
                                                </div>
                                                <div className={cx('glo-table-col')}>
                                                    {convertDuration(item.duration)}
                                                </div>
                                                <div className={cx('glo-table-col')}>
                                                    {item.dayOffEndUser
                                                        ? moment(new Date(item.dayOffEndUser)).format('YYYY/MM/DD')
                                                        : ''}
                                                </div>
                                                <div className={cx('glo-table-col')}>
                                                    {convertDuration(item.durationEnd)}
                                                </div>
                                                <div className={cx('glo-table-col')}>
                                                    {convertTypeOff(item.typeDayOff)}
                                                </div>
                                                <div className={cx('glo-table-col')}>
                                                    <p>{item.reason}</p>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </>
                            )}
                        </div>
                    )}
                </div>
            </div>
            <DialogDayOff
                openDialogDayOff={openDialogDayOff}
                setOpenDialogDayOff={setOpenDialogDayOff}
                listDetail={listDetail}
                checkAddOrUpdate={checkAddOrUpdate}
                setCallback={setCallback}
            />
        </div>
    );
};

export default DayOff;
