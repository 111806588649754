import classNames from 'classnames/bind';
import { AiOutlineCheck, AiOutlineEdit, AiOutlinePlus } from 'react-icons/ai';
import { GoCalendar } from 'react-icons/go';
import { PieChart } from 'react-minimal-pie-chart';
import Typed from 'react-typed';
import 'react-typed/dist/animatedCursor.css';
import CountUp from 'react-countup';

import styles from './Summary.module.scss';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import * as userService from '~/services/userService';
const cx = classNames.bind(styles);

const dataChartInit = [
    {
        statusName: 'In progress',
        statusId: 2,
        color: '#679bff',
    },
    {
        statusName: 'Closed',
        statusId: 3,
        color: '#2c363c',
    },
    {
        statusName: 'Resolved',
        statusId: 5,
        color: '#4cbf36',
    },
    {
        statusName: 'Pending',
        statusId: 10,
        color: '#dba124',
    },
    {
        statusName: 'New',
        statusId: 1,
        color: '#ff9d63',
    },
    {
        statusName: 'Testing',
        statusId: 7,
        color: '#00FF99',
    },
    { statusName: 'Fixbug', color: '#e2e61d', statusId: 8 },
    { statusName: 'Test done', color: '#e61d94', statusId: 9 },
];

var today = new Date();
var curHr = today.getHours();

var initital = {
    statusPercent: [
        {
            statusName: 'In progress',
            color: '#679bff',
            statusId: 2,
        },
        {
            statusName: 'Closed',
            color: '#2c363c',
            statusId: 3,
        },
        {
            statusName: 'Resolved',
            statusId: 5,
            color: '#4cbf36',
        },
        {
            statusName: 'Pending',
            color: '#dba124',
            statusId: 10,
        },
        {
            statusName: 'New',
            color: '#ff9d63',
            statusId: 1,
        },
        {
            statusName: 'Testing',
            color: '#00FF99',
            statusId: 7,
        },
        { statusName: 'Fixbug', color: '#e2e61d', statusId: 8 },
        { statusName: 'Test done', color: '#e61d94', statusId: 9 },
    ],
    summaryStatusThisWeek: [
        {
            statusName: 'New',
        },
        {
            statusName: 'In progress',
        },
        {
            statusName: 'Resolved',
        },
        {
            statusName: 'Overdue',
        },
    ],
    recentTasks: [
        {
            taskProgress: 0,
        },
        {
            taskProgress: 0,
        },
        {
            taskProgress: 0,
        },
        {
            taskProgress: 0,
        },
        {
            taskProgress: 0,
        },
    ],
};

function Summary() {
    const [hours, setHours] = useState(curHr);
    const [dataSummary, setDataSummary] = useState(initital);
    const [dataChart, setDataChart] = useState(dataChartInit);
    const auth = useSelector((state) => state.auth);
    const { userName } = auth.users;

    useEffect(() => {
        const getData = async () => {
            const res = await userService.getSummaryOfUser();

            res.data.result.statusPercent.map((ele) => {
                initital.statusPercent.map((e) => {
                    if (ele.statusId === e.statusId) {
                        ele.color = e.color;
                    }
                });
            });

            setDataChart(res.data.result.statusPercent);
            setDataSummary(res.data.result);
        };
        getData();
    }, []);

    function getHours(curHr) {
        if (curHr < 12) {
            return [`Good morning, ${userName} ☕️`];
        } else if (curHr < 18) {
            return [`Good afternoon, ${userName} ☀️`];
        } else {
            return [`Good evening, ${userName}`];
        }
    }

    const getStatus = (statusName) => {
        switch (statusName) {
            case 'New':
                return {
                    class: 'card-new',
                    icon: <AiOutlinePlus className={cx('glo-out')} style={{ color: '#FF9D63' }} />,
                };

            case 'In progress':
                return { class: 'card-inprogress', icon: <AiOutlineEdit className={cx('glo-out')} /> };

            case 'Resolved':
                return {
                    class: 'card-resolved',
                    icon: <AiOutlineCheck className={cx('glo-out')} style={{ color: '#3ec025' }} />,
                };

            case 'Overdue':
                return {
                    class: 'card-overdue',
                    icon: <GoCalendar className={cx('glo-out')} style={{ color: '#d45c56' }} />,
                };
            default:
                break;
        }
    };

    return (
        <div className={cx('wrapper')}>
            <div className={cx('summary-header')}>
                <div className={cx('summary-title-page')}>
                    <h1 className={cx('summary-title')}>
                        <Typed strings={getHours(hours)} typeSpeed={60} showCursor={false} />
                    </h1>
                    <p className={cx('summary-p')}>
                        Here's where you'll view a summary of your status, priorities, workload, and more.
                    </p>
                </div>
            </div>
            <div className={cx('summary-body')}>
                <div className={cx('summary-body__content')}>
                    <div className={cx('glo-project')}>
                        <div className={cx('row', 'glo-summary-row')}>
                            {dataSummary?.summaryStatusThisWeek?.map((data, index) => (
                                <div className={cx('col-3', 'glo-summary-col')}>
                                    <div className={cx('glo-summary-col__border', getStatus(data.statusName).class)}>
                                        <div className={cx('row')}>
                                            <div className={cx('glo-summary__icon')}>
                                                {getStatus(data.statusName).icon}
                                            </div>
                                            <div className={cx('glo-infomation-project')}>
                                                <p className={cx('count-status')}>
                                                    <CountUp end={data.number} start={0} duration={0.25} />{' '}
                                                    {data.statusName}
                                                </p>
                                                <p className={cx('count-status-text')}>in this week</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className={cx('summary-body__chart')}>
                        <div className={cx('row')}>
                            <div className={cx('col-6')}>
                                <div className={cx('glo-summary-col__border')}>
                                    <div>Task Status (%)</div>
                                    <div className={cx('glo-summary-chart')}>
                                        <div className={cx('row')}>
                                            <div className={cx('col-6 d-flex align-items-center')}>
                                                <div className={cx('wrap-chart')}>
                                                    {dataChart.some((e) => e.value) && (
                                                        <PieChart
                                                            title={({ dataEntry }) => dataEntry.statusName}
                                                            data={dataChart}
                                                            label={({ dataEntry }) => dataEntry.value}
                                                            labelStyle={(index) => ({
                                                                fill: dataChart[index].color,
                                                                fontSize: '7px',
                                                                fontFamily: 'sans-serif',
                                                            })}
                                                            onMouseOver={(e, segmentIndex) => console.log(segmentIndex)}
                                                            labelPosition={75}
                                                            lineWidth={15}
                                                            paddingAngle={5}
                                                            animate={true}
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                            <div className={cx('col-6', 'glo-note')}>
                                                {dataChart.map((data, index) => (
                                                    <div className={cx('row', 'glo-note-content')} key={index}>
                                                        <div className={cx('col-3')}></div>
                                                        <div className={cx('col-9')}>
                                                            <div className={cx('row', 'glo-note-content__row')}>
                                                                <div
                                                                    className={cx('glo-note__color')}
                                                                    style={{ background: data.color }}
                                                                ></div>
                                                                <div className={cx('glo-note__name')}>
                                                                    {data.statusName}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={cx('col-6')}>
                                <div className={cx('glo-summary-col__border')}>
                                    <div>Tasks Recent</div>
                                    <div className={cx('glo-summary-chart', 'glo-team')}>
                                        <div className={cx('row', 'glo-team__title')}>
                                            <div className={cx('col-6')}>Title</div>
                                            <div className={cx('col-6')}>Task progress</div>
                                        </div>
                                        {dataSummary?.recentTasks?.map((data, index) => (
                                            <div className={cx('row', 'glo-team__content')} key={index}>
                                                <div className={cx('col-6')}>
                                                    <div className={cx('row', 'glo-item-name')}>
                                                        <div className={cx('glo-item-name__name')}> {data.title}</div>
                                                    </div>
                                                </div>
                                                <div className={cx('col-6', 'glo-item-work')}>
                                                    <div className={cx('row')}>
                                                        <div className={cx('glo-item-work__chart')}>
                                                            <div className={cx('glo-item-work__chart__background')}>
                                                                <div
                                                                    className={cx(
                                                                        'glo-item-work__chart__background__color',
                                                                    )}
                                                                    style={{
                                                                        background: '#0052cc',
                                                                        width: Math.round(data.taskProgress) + '%',
                                                                        height: '7px',
                                                                    }}
                                                                ></div>
                                                            </div>
                                                        </div>
                                                        <div className={cx('glo-item-work__value')}>
                                                            {Math.round(data.taskProgress)}%
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Summary;
