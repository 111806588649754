import api from '~/utils/main';

export const getProject = async (params) => {
    try {
        const res = await api.get('projects/get-and-counting', { params });
        return res;
    } catch (error) {
        console.log(error);
    }
};

export const getProjectsReport = async (params) => {
    try {
        const res = await api.get(`task/projects-status`, { params });
        return res;
    } catch (error) {
        console.log(error);
    }
};
