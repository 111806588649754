import React, { Fragment, useContext, useEffect } from 'react';
import styles from './CreateRequirementCheckList.module.scss';
import classNames from 'classnames/bind';
import { useState } from 'react';
import { CgAdd } from 'react-icons/cg';
import Swal from 'sweetalert2';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { BsCheck2Square } from 'react-icons/bs';
import { MdCheckCircleOutline, MdOutlineCheckBoxOutlineBlank, MdOutlineStickyNote2 } from 'react-icons/md';
import routesConfig from '~/config/routes';
import api from '~/utils/main';
import { toast } from 'react-toastify';
import { TbAlignJustified } from 'react-icons/tb';
import { AiOutlineDown, AiOutlinePullRequest } from 'react-icons/ai';
import { Tooltip } from '@mui/material';
import { GlobalStateProject } from '~/ProjectState/projectState';
import * as _ from 'lodash';
import { RiDeleteBin2Line } from 'react-icons/ri';
import * as requirementService from '~/services/requirementService';

const cx = classNames.bind(styles);

const initialState = [{ id: 0, classification: '', requirement: '', listTasks: [], isCheck: false, memo: '' }];

const CreateRequirementCheckList = () => {
    const [requirementChk, setRequirementChk] = useState({});

    const [listCheckedItem, setListCheckedItem] = useState([]);

    const location = useLocation();

    const projectID = location.state?.projectID;
    const projectNameCre = location.state?.projectName;
    const checklistName = location.state?.checklistName;
    const checklistId = location.state?.checklistId;
    const offsetT = location.state?.offsetT;

    //Update
    const [listSelected, setListSelected] = useState([]);
    const [dataSelectTask, setDataSelectTask] = useState([]);
    const [dataSelectAllTask, setDataSelectAllTask] = useState([]);
    const [isSelected, setIsSelected] = useState(false);
    const [updateCheckListItem, setUpdateCheckListItem] = useState(initialState);
    const state = useContext(GlobalStateProject);
    const [isGetFullTasks, setIsGetFullTasks] = state.projectsAPI.isGetFullTasks;

    //OpenInput
    const [checkOpenTask, setCheckOpenTask] = useState([]);
    const [checkOpenSelectTask, setCheckOpenSelectTask] = useState([]);
    const [checkOpenClassification, setCheckOpenClassification] = useState([]);
    const [checkOpenRequirement, setCheckOpenRequirement] = useState([]);
    const [checkOpenMemo, setCheckOpenMemo] = useState([]);
    const [sendDataUpdate, setSendDataUpdate] = useState([]);
    const [sendDataDelete, setSendDataDelete] = useState([]);

    //addRow
    const [getTaskAddRow, setGetTaskAddRow] = useState([]);
    const [sendDataAdd, setSendDataAdd] = useState([]);
    const [checkOpenSelectTaskAdd, setCheckOpenSelectTaskAdd] = useState([]);
    const [checkNumberRowAdd, setCheckNumberRowAdd] = useState([]);

    const navigate = useNavigate();

    const handleAddRowRequirement = async () => {
        const dataSend = sendDataAdd.map((task) => {
            const { index, ...data } = task;

            const rs = data.listTasks.map((task) => {
                return task.taskId;
            });

            data.listTasks = rs;

            return data;
        });

        const body = {
            ChecklistId: +checklistId,
            ListChecklistItems: dataSend,
        };

        const res = await requirementService.addRequirement(body);

        if (res.status === 204) {
            getCheckLists();
            setSendDataAdd([]);
        }
    };

    const handleCreateTaskForRequire = (projectID, projectNameCre, checklistName, checklistId, requireList, idx) => {
        const itemRequireList = requireList.id[idx];
        navigate(routesConfig.createTask, {
            state: {
                projectID: projectID,
                projectName: projectNameCre,
                checklistName: checklistName,
                checklistId: checklistId,
                checklistItemId: itemRequireList,
                createTaskRequire: true,
            },
        });
    };

    useEffect(() => {
        getCheckLists();
        getAPIAllTaskInProject();
    }, [checklistId]);

    const getAPIAllTaskInProject = async () => {
        if (projectID != null) {
            const res = await requirementService.getAllTaskInProject(isGetFullTasks, projectID);
            if (res.status === 200) {
                var datas = [];
                for (let i = 0; i < res.data.data.items.length; i++) {
                    for (let j = 0; j < res.data.data.items[i]?.taskItems.length; j++) {
                        datas.push(res.data.data.items[i].taskItems[j]);
                    }
                }

                setDataSelectAllTask(datas);
                setGetTaskAddRow(datas);
            }
        }
    };

    const getCheckLists = async () => {
        const res = await requirementService.getRequirement(checklistId);
        if (res.data.status === 'success') {
            setRequirementChk(res.data.data);
        }
    };

    const handleDeleteRequirementChkList = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You want to delete checklist item ?',
            icon: 'warning',
            width: '400px',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            dangerMode: true,
        }).then(async (result) => {
            if (result.isConfirmed) {
                const res = await requirementService.deleteRequirement(sendDataDelete);

                if (res.status === 204) {
                    getCheckLists();
                    setSendDataDelete([]);
                    const element = document.querySelectorAll(cx('.glo-checkbox-delete'));
                    element.forEach((box) => {
                        box.checked = false;
                    });
                }
            }
        });
    };

    const handleSubmitRequire = async (e) => {
        var arrItemColume = [];

        //so sánh cùng colume không
        listCheckedItem.forEach((data) => {
            arrItemColume.push(data.nameEvent);
        });

        const checkColumn = arrItemColume.every((val, i, arr) => val === arr[0]);

        //sắp xếp lại vị trí object theo offTop
        listCheckedItem.sort((a, b) => {
            return a.offTop - b.offTop;
        });

        var itemCheckCellNear = false;

        for (let i = 0; i < listCheckedItem.length - 1; i++) {
            var sumHeightTop = listCheckedItem[i].offTop + listCheckedItem[i].offHeight;
            var offsetNext = listCheckedItem[i + 1].offTop;

            if (offsetNext === sumHeightTop) {
                itemCheckCellNear = true;
            } else {
                itemCheckCellNear = false;
            }
        }

        if (checkColumn && itemCheckCellNear) {
            var listItemID = [];

            listCheckedItem.forEach((data) => {
                listItemID.push(...data.idRequire);
            });

            const body = {
                checklistItemIds: listItemID,
            };

            Swal.fire({
                title: 'Are you sure?',
                text: `You want to merge cell ?`,
                icon: 'warning',
                width: '400px',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'OK',
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const res = await requirementService.mergerRequirement(body);

                    if (res.status === 201) {
                        setListCheckedItem([]);
                        getCheckLists();
                        const element = document.querySelectorAll(cx('.glo-cell'));
                        element.forEach((box) => {
                            box.classList.remove(cx('glo-cell-color'));
                        });

                        Swal.fire('Success!', `Merge cell successfully`, 'success');
                    }
                }
            });
        } else {
            toast.error('Can not Merge cell');
            setListCheckedItem([]);
        }
    };

    const handleBtnUpdate = async () => {
        const dataSend = sendDataUpdate.map((task) => {
            const rs = task.listTasks.map((data) => {
                return data.taskId;
            });

            task.listTasks = rs;

            return task;
        });

        const body = {
            ListChecklistItem: dataSend,
        };

        const res = await requirementService.updateRequirement(body);

        if (res.status === 204) {
            getCheckLists();
            setCheckOpenTask([]);
            setCheckOpenSelectTask([]);
            setCheckOpenClassification([]);
            setCheckOpenRequirement([]);
            setCheckOpenMemo([]);
            const element = document.querySelectorAll(cx('.glo-cell'));
            element.forEach((box) => {
                box.classList.remove(cx('glo-cell-color'));
            });
        }
    };

    //ADD VALUE UPDATE START
    const addValueUpdate = (id, indexItem, cloneArr) => {
        const requireList = cloneArr.listRequirements[0].checklistItems;

        const found = requireList.find((item) => {
            return item.id[indexItem] === id;
        });

        if (sendDataUpdate.length === 0) {
            const itemCheckList = {
                id: found.id[indexItem],
                classification: found.classification[0],
                requirement: found.requirement[indexItem],
                memo: found.memo[indexItem],
                listTasks: found.listTasks[indexItem],
                isCheck: found.isCheck[indexItem],
                mergeGroup: found.mergeGroup,
            };
            sendDataUpdate.push(itemCheckList);
        } else {
            const foundData = sendDataUpdate.find((item) => {
                return item.id === id;
            });

            if (foundData === undefined) {
                const itemCheckList = {
                    id: found.id[indexItem],
                    classification: found.classification[0],
                    requirement: found.requirement[indexItem],
                    memo: found.memo[indexItem],
                    listTasks: found.listTasks[indexItem],
                    isCheck: found.isCheck[indexItem],
                    mergeGroup: found.mergeGroup,
                };
                sendDataUpdate.push(itemCheckList);
            } else {
                sendDataUpdate.map((item) => {
                    if (item.id === id) {
                        item.id = found.id[indexItem];
                        item.classification = found.classification[0];
                        item.requirement = found.requirement[indexItem];
                        item.memo = found.memo[indexItem];
                        item.listTasks = found.listTasks[indexItem];
                        item.isCheck = found.isCheck[indexItem];
                        item.mergeGroup = found.mergeGroup;
                    }
                });
            }
        }
    };
    //ADD VALUE UPDATE END

    //OPENT INPUT STRART
    const toggleInputClassi = (id) => {
        setCheckOpenClassification([...checkOpenClassification, id]);
    };

    const toggleInputRequirement = (id) => {
        setCheckOpenRequirement([...checkOpenRequirement, id]);
    };

    const toggleInputTask = (id, dataTask) => {
        setCheckOpenTask([...checkOpenTask, id]);
        setListSelected((prev) => {
            const clone = [...prev];
            return [...clone, dataTask];
        });
    };

    const toggleInputMemo = (id) => {
        setCheckOpenMemo([...checkOpenMemo, id]);
    };
    //OPENT INPUT END

    //CHANGE VALUE START
    const handleChangeClassi = (e, id, indexParent, indexChecklist, idxClassi, idInput) => {
        const cloneArr = _.cloneDeep(requirementChk);

        cloneArr.listRequirements[indexParent].checklistItems[indexChecklist].classification[idxClassi] =
            e.target.value;

        setRequirementChk(cloneArr);

        addValueUpdate(id, idxClassi, cloneArr);
    };

    const handleChangeRequirement = (e, id, indexParent, idxRequireList, idxRequirement) => {
        const cloneArr = _.cloneDeep(requirementChk);

        cloneArr.listRequirements[indexParent].checklistItems[idxRequireList].requirement[idxRequirement] =
            e.target.value;

        setRequirementChk(cloneArr);

        addValueUpdate(id, idxRequirement, cloneArr);
    };

    const handleChangeCheckDone = (e, id, indexParent, idxRequireList, indexCheck) => {
        const cloneArr = _.cloneDeep(requirementChk);

        cloneArr.listRequirements[indexParent].checklistItems[idxRequireList].isCheck[indexCheck] = e.target.checked;

        setRequirementChk(cloneArr);

        addValueUpdate(id, indexCheck, cloneArr);
    };

    const handleChangeMemo = (e, id, indexParent, idxRequireList, indexMomo) => {
        const cloneArr = _.cloneDeep(requirementChk);

        cloneArr.listRequirements[indexParent].checklistItems[idxRequireList].memo[indexMomo] = e.target.value;

        setRequirementChk(cloneArr);

        addValueUpdate(id, indexMomo, cloneArr);
    };

    //Change value task start
    //open select task start
    const handleOpenSeletedTask = (id, idxrequireList, idxListTasks) => {
        if (checkOpenSelectTask.includes(id)) {
            setCheckOpenSelectTask(checkOpenSelectTask.filter((e) => e !== id));
        } else {
            setCheckOpenSelectTask([...checkOpenSelectTask, id]);
        }
        getAllTaskInProject(idxrequireList, idxListTasks);
    };
    const getAllTaskInProject = async (idxrequireList, idxListTasks) => {
        const cloneArr = _.cloneDeep(dataSelectAllTask);

        const idTask = Number(
            requirementChk.listRequirements[0].checklistItems[idxrequireList].listTasks[idxListTasks].map((o) => {
                return o.taskId;
            }),
        );

        const data = cloneArr.filter((i) => i.id !== idTask);

        setDataSelectTask(data);
    };
    //open select task end
    //add task start
    const handleSelect = (id, item, indexParent, idxRequireList, idxListTasks, idDivTask) => {
        const validDataChild = {};
        validDataChild.taskId = item.id;
        validDataChild.status = item.status;
        validDataChild.title = item.title;

        const cloneArr = _.cloneDeep(requirementChk);

        cloneArr.listRequirements[indexParent].checklistItems[idxRequireList].listTasks[idxListTasks].push(
            validDataChild,
        );

        setRequirementChk(cloneArr);

        addValueUpdate(id, idxListTasks, cloneArr);

        setUpdateCheckListItem({ ...updateCheckListItem, listTasks: listSelected });

        const data = dataSelectTask.filter((i) => i.id !== item.id);
        setDataSelectTask(data);
    };
    //add task end
    //delete task start
    const handleRemoveItem = (id, item, idxRequirements, idxChecklistItems, idxListTasks) => {
        const cloneArr = _.cloneDeep(requirementChk);

        const test = cloneArr.listRequirements[idxRequirements].checklistItems[idxChecklistItems].listTasks[
            idxListTasks
        ].filter((i) => i.taskId !== item.taskId);

        cloneArr.listRequirements[idxRequirements].checklistItems[idxChecklistItems].listTasks[idxListTasks] = test;

        setRequirementChk(cloneArr);

        const data = listSelected.filter((i) => i.id !== item.id);

        setListSelected(data);
        setDataSelectTask(dataSelectTask.concat(item));

        addValueUpdate(id, idxRequirements, idxChecklistItems);
    };

    //delete task end
    //Change value task end
    //CHANGE VALUE END

    //DELETE VALUE START
    const handleClickDelete = (e, id) => {
        if (e.target.checked === true) {
            setSendDataDelete([...sendDataDelete, id]);
        } else {
            setSendDataDelete(sendDataDelete.filter((i) => i !== id));
        }
    };
    //DELETE VALUE END

    //MERGE VALUE START
    const handleClickCell = (value, idRequire, nameEvent, e) => {
        const found = listCheckedItem.find(
            (e) => e.value === value && e.idRequire === idRequire && e.nameEvent === nameEvent,
        );
        const styleClassCss = `${value}${idRequire}class`.replaceAll(' ', '').replaceAll(',', '');

        var positionDiv = document.getElementById(styleClassCss);

        var offHeight = positionDiv.offsetHeight;
        var offTop = positionDiv.offsetTop;

        var element = document.getElementById(styleClassCss);

        if (listCheckedItem.includes(found)) {
            listCheckedItem.splice(listCheckedItem.indexOf(found), 1);
            // document.getElementById(styleClassCss).style.color = '#1c1c1c';
            element.classList.add(cx('glo-cell-no-color'));
        } else {
            listCheckedItem.push({
                value: value,
                idRequire: idRequire,
                nameEvent: nameEvent,
                offHeight: offHeight,
                offTop: offTop,
            });
            // document.getElementById(styleClassCss).style.color = '#0d6efd';
            element.classList.add(cx('glo-cell-color'));
        }
    };
    //MERGE VALUE END

    //ADD ROW START
    const addRow = () => {
        var numberRowAdd0 =
            checkNumberRowAdd[0] === undefined ? 0 : checkNumberRowAdd[checkNumberRowAdd.length - 1] + 1;
        const body = {
            index: numberRowAdd0,
            classification: '',
            requirement: '',
            isCheck: false,
            memo: '',
            listTasks: [],
            checklistId: '',
        };
        setSendDataAdd([...sendDataAdd, body]);
        setCheckNumberRowAdd([...checkNumberRowAdd, numberRowAdd0]);
    };

    const deleteRow = (index) => {
        setSendDataAdd(sendDataAdd.filter((e) => e.index !== index));
        setCheckNumberRowAdd(checkNumberRowAdd.filter((e) => e !== index));
    };

    const handleOpenSeletedTaskAdd = (index) => {
        if (checkOpenSelectTaskAdd.includes(index)) {
            setCheckOpenSelectTaskAdd(checkOpenSelectTaskAdd.filter((e) => e !== index));
        } else {
            setCheckOpenSelectTaskAdd([...checkOpenSelectTaskAdd, index]);
        }
    };

    const handleChangeClassAdd = (e, index) => {
        const cloneArr = _.cloneDeep(sendDataAdd);

        cloneArr.map((itemClone) => {
            if (itemClone.index === index) {
                itemClone.classification = e.target.value;
            }
        });

        setSendDataAdd(cloneArr);
    };

    const handleChangeRequirementAdd = (e, index) => {
        const cloneArr = _.cloneDeep(sendDataAdd);

        cloneArr.map((itemClone) => {
            if (itemClone.index === index) {
                itemClone.requirement = e.target.value;
            }
        });

        setSendDataAdd(cloneArr);
    };

    const handleChangeCheckAdd = (e, index) => {
        const cloneArr = _.cloneDeep(sendDataAdd);

        cloneArr.map((itemClone) => {
            if (itemClone.index === index) {
                itemClone.isCheck = e.target.checked;
            }
        });

        setSendDataAdd(cloneArr);
    };

    const handleChangeMemoAdd = (e, index) => {
        const cloneArr = _.cloneDeep(sendDataAdd);

        cloneArr.map((itemClone) => {
            if (itemClone.index === index) {
                itemClone.memo = e.target.value;
            }
        });

        setSendDataAdd(cloneArr);
    };

    const handleSelectAdd = (item, index) => {
        const cloneArr = _.cloneDeep(sendDataAdd);

        const validDataChild = {};
        validDataChild.taskId = item.id;
        validDataChild.status = item.status;
        validDataChild.title = item.title;

        cloneArr.map((itemClone) => {
            if (itemClone.index === index) {
                itemClone.listTasks.push(validDataChild);
            }
        });

        setSendDataAdd(cloneArr);

        const data = getTaskAddRow.filter((i) => i.id !== item.id);

        setGetTaskAddRow(data);
    };

    const removeSelectedItemAdd = (item, taskId, index) => {
        const cloneArr = _.cloneDeep(sendDataAdd);

        cloneArr.map((itemClone) => {
            if (itemClone.index === index) {
                itemClone.listTasks = itemClone.listTasks.filter((i) => i.taskId !== taskId);
            }
        });

        setSendDataAdd(cloneArr);

        setGetTaskAddRow(getTaskAddRow.concat(item));
    };

    //ADD ROW END

    //Back requirement list

    const handleBackRequire = () => {
        navigate(routesConfig.requirementChecklist);
    };

    return (
        <div className={cx('wrapper')}>
            <div className={cx('header-page-requirement')}>
                <div className={cx('row')}>
                    <div className="col-8 col-sm-9">
                        <p className={cx('head-title')}>Requirement Checklist</p>
                    </div>
                    <div className="col-4 col-sm-3 text-end">
                        <button
                            className={cx('btn-create-requirement', 'glo-margin-right')}
                            type="submit"
                            onClick={handleBackRequire}
                        >
                            Back
                        </button>
                        <button
                            className={cx('btn-create-requirement', 'glo-margin-right')}
                            type="submit"
                            onClick={handleSubmitRequire}
                        >
                            Merge
                        </button>
                        <button
                            type="text"
                            onClick={handleAddRowRequirement}
                            className={cx('btn-create-requirement', 'glo-margin-right')}
                            size="medium"
                        >
                            Add
                        </button>
                        <button
                            className={cx('btn-create-requirement', 'glo-margin-right')}
                            onClick={() => handleBtnUpdate()}
                        >
                            Update
                        </button>
                        <button
                            className={cx('btn-create-requirement')}
                            onClick={() => handleDeleteRequirementChkList()}
                        >
                            Delete
                        </button>
                    </div>
                </div>
            </div>

            <div className={cx('require-res-table-scroll')}>
                <div className={cx('require-res-table')}>
                    <div className={cx('table-requirement-checklist', 'mt-3')}>
                        <div className={cx('table-content-head')}>
                            <div className={cx('row', 'table-content-head-title')}>
                                <div className={cx('tbl-content-checkbox', 'text-center', 'font-weight-600')}></div>
                                <div className={cx('tbl-content-no', 'text-center', 'font-weight-600')}>No.</div>
                                <div className={cx('tbl-content-classification', 'text-center', 'font-weight-600')}>
                                    Classification <TbAlignJustified />
                                </div>
                                <div className={cx('tbl-content-requirement', 'text-center', 'font-weight-600')}>
                                    Requirement <AiOutlinePullRequest />
                                </div>
                                <div className={cx('tbl-content-task', 'text-center', 'font-weight-600')}>Task #</div>
                                <div className={cx('tbl-content-check', 'font-weight-600')}>
                                    Check <MdCheckCircleOutline />
                                </div>
                                <div className={cx('tbl-content-memo', 'text-center', 'font-weight-600')}>
                                    Memo <MdOutlineStickyNote2 />
                                </div>
                            </div>
                        </div>
                        <div className={cx('table-content-body')}>
                            <div>
                                <div className={cx('head-project', 'my-3')}>
                                    <h3 className={cx('rqm-project-name', 'glo-margin-right')}>{projectNameCre}</h3>
                                    <p>{checklistName}</p>
                                </div>
                                {requirementChk?.listRequirements?.map((requireList, idxRequirements) => {
                                    return (
                                        <Fragment key={idxRequirements}>
                                            {requireList?.checklistItems?.map((requireList, idxChecklistItems) => {
                                                return (
                                                    <div
                                                        className={cx('table-content-body-data', 'row', 'glo-row')}
                                                        id={`${requireList.id}` + 'row'}
                                                        key={idxChecklistItems}
                                                    >
                                                        <div className={cx('require-tbl-checkbox', 'border-left')}>
                                                            {requireList?.number?.map((dataID, indexNo) => {
                                                                return (
                                                                    <div
                                                                        className={cx(
                                                                            'item-padd',
                                                                            'border-top',
                                                                            'glo-content-center',
                                                                        )}
                                                                        key={indexNo}
                                                                        style={
                                                                            requireList?.listTasks[indexNo]?.length > 0
                                                                                ? {
                                                                                      height:
                                                                                          `${
                                                                                              requireList?.listTasks[
                                                                                                  indexNo
                                                                                              ]?.length *
                                                                                                  30 +
                                                                                              requireList?.listTasks[
                                                                                                  indexNo
                                                                                              ]?.length *
                                                                                                  4 +
                                                                                              22
                                                                                          }` + 'px',
                                                                                  }
                                                                                : { height: '50px' }
                                                                        }
                                                                    >
                                                                        <input
                                                                            type="checkbox"
                                                                            className={cx(
                                                                                'form-check-input',
                                                                                'glo-checkbox-delete',
                                                                            )}
                                                                            onChange={(e) =>
                                                                                handleClickDelete(
                                                                                    e,
                                                                                    requireList.id[indexNo],
                                                                                )
                                                                            }
                                                                        ></input>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                        <div
                                                            className={cx(
                                                                'require-tbl-id',
                                                                'border-right',
                                                                'border-left',
                                                            )}
                                                        >
                                                            {requireList?.number?.map((dataID, indexNo) => {
                                                                return (
                                                                    <div
                                                                        className={cx('item-padd', 'border-top')}
                                                                        key={indexNo}
                                                                        style={
                                                                            requireList?.listTasks[indexNo]?.length > 0
                                                                                ? {
                                                                                      height:
                                                                                          `${
                                                                                              requireList?.listTasks[
                                                                                                  indexNo
                                                                                              ]?.length *
                                                                                                  30 +
                                                                                              requireList?.listTasks[
                                                                                                  indexNo
                                                                                              ]?.length *
                                                                                                  4 +
                                                                                              22
                                                                                          }` + 'px',
                                                                                  }
                                                                                : { height: '50px' }
                                                                        }
                                                                    >
                                                                        <span
                                                                            className={cx(
                                                                                'text-ellip',
                                                                                'glo-padding-10',
                                                                            )}
                                                                        >
                                                                            {dataID}
                                                                        </span>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                        <div
                                                            className={cx('require-tbl-classification', 'border-right')}
                                                        >
                                                            {requireList?.classification?.map(
                                                                (dataClassi, idxClassi) => {
                                                                    return (
                                                                        <div
                                                                            key={idxClassi}
                                                                            onClick={(e) =>
                                                                                handleClickCell(
                                                                                    dataClassi,
                                                                                    requireList.id,
                                                                                    2,
                                                                                    e,
                                                                                )
                                                                            }
                                                                            id={`${dataClassi}${requireList.id}class`
                                                                                .replaceAll(' ', '')
                                                                                .replaceAll(',', '')}
                                                                            className={cx(
                                                                                'border-top',
                                                                                'item-padd',
                                                                                'height-100-percent',
                                                                                'glo-cursor-pointer',
                                                                                'glo-padding-10',
                                                                                'glo-cell',
                                                                            )}
                                                                            onDoubleClick={(e) =>
                                                                                toggleInputClassi(
                                                                                    requireList.id[idxClassi],
                                                                                )
                                                                            }
                                                                        >
                                                                            {!checkOpenClassification.includes(
                                                                                requireList.id[idxClassi],
                                                                            ) && (
                                                                                <p className={cx('glo-margin-0')}>
                                                                                    {dataClassi}
                                                                                </p>
                                                                            )}

                                                                            {checkOpenClassification.includes(
                                                                                requireList.id[idxClassi],
                                                                            ) && (
                                                                                <input
                                                                                    type="text"
                                                                                    value={dataClassi}
                                                                                    disabled={
                                                                                        requireList.mergeGroup !== null
                                                                                    }
                                                                                    onChange={(e) =>
                                                                                        handleChangeClassi(
                                                                                            e,
                                                                                            requireList.id[idxClassi],
                                                                                            idxRequirements,
                                                                                            idxChecklistItems,
                                                                                            idxClassi,
                                                                                            requireList.id[idxClassi],
                                                                                        )
                                                                                    }
                                                                                    className={cx(
                                                                                        'form-control',
                                                                                        'form-control-lg',
                                                                                    )}
                                                                                />
                                                                            )}
                                                                        </div>
                                                                    );
                                                                },
                                                            )}
                                                        </div>
                                                        <div className={cx('require-tbl-requirement', 'border-right')}>
                                                            {requireList?.requirement?.map(
                                                                (dataRequirement, idxRequirement) => {
                                                                    return (
                                                                        <div
                                                                            className={cx(
                                                                                'border-top',
                                                                                'item-padd',
                                                                                'glo-padding-10',
                                                                            )}
                                                                            key={idxRequirement}
                                                                            style={
                                                                                requireList?.listTasks[idxRequirement]
                                                                                    ?.length > 0
                                                                                    ? {
                                                                                          height:
                                                                                              `${
                                                                                                  requireList
                                                                                                      ?.listTasks[
                                                                                                      idxRequirement
                                                                                                  ]?.length *
                                                                                                      30 +
                                                                                                  requireList
                                                                                                      ?.listTasks[
                                                                                                      idxRequirement
                                                                                                  ]?.length *
                                                                                                      4 +
                                                                                                  22
                                                                                              }` + 'px',
                                                                                      }
                                                                                    : { height: '50px' }
                                                                            }
                                                                            onDoubleClick={(e) =>
                                                                                toggleInputRequirement(
                                                                                    requireList.id[idxRequirement],
                                                                                )
                                                                            }
                                                                        >
                                                                            {!checkOpenRequirement.includes(
                                                                                requireList.id[idxRequirement],
                                                                            ) && (
                                                                                <Tooltip
                                                                                    arrow
                                                                                    title={
                                                                                        <span
                                                                                            style={{
                                                                                                fontSize: '1.4rem',
                                                                                            }}
                                                                                            arrow
                                                                                        >
                                                                                            {dataRequirement}
                                                                                        </span>
                                                                                    }
                                                                                >
                                                                                    <p
                                                                                        className={cx(
                                                                                            'glo-margin-0',
                                                                                            'text-ellip',
                                                                                        )}
                                                                                    >
                                                                                        {dataRequirement}
                                                                                    </p>
                                                                                </Tooltip>
                                                                            )}
                                                                            {checkOpenRequirement.includes(
                                                                                requireList.id[idxRequirement],
                                                                            ) && (
                                                                                <input
                                                                                    type="text"
                                                                                    value={dataRequirement}
                                                                                    onChange={(e) =>
                                                                                        handleChangeRequirement(
                                                                                            e,
                                                                                            requireList.id[
                                                                                                idxRequirement
                                                                                            ],
                                                                                            idxRequirements,
                                                                                            idxChecklistItems,
                                                                                            idxRequirement,
                                                                                        )
                                                                                    }
                                                                                    className={cx(
                                                                                        'form-control',
                                                                                        'form-control-lg',
                                                                                    )}
                                                                                />
                                                                            )}
                                                                        </div>
                                                                    );
                                                                },
                                                            )}
                                                        </div>
                                                        <div className={cx('require-tbl-task', 'border-right')}>
                                                            {requireList?.listTasks?.map((dataTask, idxListTasks) => {
                                                                return (
                                                                    <Fragment key={idxListTasks}>
                                                                        <div
                                                                            className={cx(
                                                                                'border-top',
                                                                                'item-padd',
                                                                                'glo-cursor-pointer',
                                                                                'glo-padding-10',
                                                                            )}
                                                                            onDoubleClick={(e) =>
                                                                                toggleInputTask(
                                                                                    requireList.id[idxListTasks],
                                                                                    dataTask,
                                                                                    idxRequirements,
                                                                                    idxChecklistItems,
                                                                                    idxListTasks,
                                                                                )
                                                                            }
                                                                            style={
                                                                                requireList?.listTasks[idxListTasks]
                                                                                    ?.length > 0
                                                                                    ? {
                                                                                          height:
                                                                                              `${
                                                                                                  requireList
                                                                                                      ?.listTasks[
                                                                                                      idxListTasks
                                                                                                  ]?.length *
                                                                                                      30 +
                                                                                                  requireList
                                                                                                      ?.listTasks[
                                                                                                      idxListTasks
                                                                                                  ]?.length *
                                                                                                      4 +
                                                                                                  22
                                                                                              }` + 'px',
                                                                                      }
                                                                                    : { height: '50px' }
                                                                            }
                                                                        >
                                                                            {!checkOpenTask.includes(
                                                                                requireList.id[idxListTasks],
                                                                            ) && (
                                                                                <div className={cx('icons-add-task')}>
                                                                                    <CgAdd
                                                                                        className={cx(
                                                                                            'icons-add-task-req',
                                                                                        )}
                                                                                        onClick={() =>
                                                                                            handleCreateTaskForRequire(
                                                                                                projectID,
                                                                                                projectNameCre,
                                                                                                checklistName,
                                                                                                checklistId,
                                                                                                requireList,
                                                                                                idxListTasks,
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                    &nbsp;
                                                                                </div>
                                                                            )}

                                                                            <div className={cx('width-select-task')}>
                                                                                {!checkOpenTask.includes(
                                                                                    requireList.id[idxListTasks],
                                                                                ) && (
                                                                                    <div>
                                                                                        {dataTask.length > 0 && (
                                                                                            <div>
                                                                                                {dataTask?.map(
                                                                                                    (task, idxTask) => {
                                                                                                        return (
                                                                                                            <div
                                                                                                                style={{
                                                                                                                    height: '30px',
                                                                                                                }}
                                                                                                                className={cx()}
                                                                                                                key={
                                                                                                                    idxTask
                                                                                                                }
                                                                                                            >
                                                                                                                <span
                                                                                                                    className={cx(
                                                                                                                        'task-require-ckl',
                                                                                                                        'text-ellip',
                                                                                                                    )}
                                                                                                                >
                                                                                                                    <Link
                                                                                                                        to={`/task/${task.taskId}`}
                                                                                                                        target="_blank"
                                                                                                                        className={cx(
                                                                                                                            'item-task-link',
                                                                                                                        )}
                                                                                                                    >
                                                                                                                        {
                                                                                                                            task.taskId
                                                                                                                        }
                                                                                                                    </Link>
                                                                                                                    &nbsp;
                                                                                                                    {
                                                                                                                        task.status
                                                                                                                    }
                                                                                                                    <i>
                                                                                                                        ,
                                                                                                                    </i>
                                                                                                                    &nbsp;
                                                                                                                </span>
                                                                                                            </div>
                                                                                                        );
                                                                                                    },
                                                                                                )}
                                                                                            </div>
                                                                                        )}
                                                                                    </div>
                                                                                )}

                                                                                {checkOpenTask.includes(
                                                                                    requireList.id[idxListTasks],
                                                                                ) && (
                                                                                    <div
                                                                                        className={cx(
                                                                                            'width-select-task',
                                                                                        )}
                                                                                    >
                                                                                        <div
                                                                                            className={cx(
                                                                                                'option-custom',
                                                                                            )}
                                                                                        >
                                                                                            <div
                                                                                                className={cx(
                                                                                                    'select-input',
                                                                                                    'select-input--multiple',
                                                                                                    'selected-list',
                                                                                                )}
                                                                                            >
                                                                                                {dataTask?.map(
                                                                                                    (task, idxTask) => {
                                                                                                        return (
                                                                                                            <div
                                                                                                                key={
                                                                                                                    idxTask
                                                                                                                }
                                                                                                                className={cx(
                                                                                                                    'selected-item',
                                                                                                                )}
                                                                                                            >
                                                                                                                <div
                                                                                                                    className={cx(
                                                                                                                        'glo-title-task',
                                                                                                                    )}
                                                                                                                >
                                                                                                                    {
                                                                                                                        task.title
                                                                                                                    }
                                                                                                                </div>
                                                                                                                <span
                                                                                                                    onClick={() =>
                                                                                                                        handleRemoveItem(
                                                                                                                            requireList
                                                                                                                                .id[0],
                                                                                                                            task,
                                                                                                                            idxRequirements,
                                                                                                                            idxChecklistItems,
                                                                                                                            idxListTasks,
                                                                                                                        )
                                                                                                                    }
                                                                                                                >
                                                                                                                    x
                                                                                                                </span>
                                                                                                            </div>
                                                                                                        );
                                                                                                    },
                                                                                                )}

                                                                                                <AiOutlineDown
                                                                                                    className={cx(
                                                                                                        'glo-icon-select',
                                                                                                    )}
                                                                                                    onClick={() =>
                                                                                                        handleOpenSeletedTask(
                                                                                                            requireList
                                                                                                                .id[
                                                                                                                idxListTasks
                                                                                                            ],
                                                                                                            idxChecklistItems,
                                                                                                            idxListTasks,
                                                                                                        )
                                                                                                    }
                                                                                                />
                                                                                            </div>
                                                                                            {checkOpenSelectTask.includes(
                                                                                                requireList.id[
                                                                                                    idxListTasks
                                                                                                ],
                                                                                            ) && (
                                                                                                <div
                                                                                                    className={cx(
                                                                                                        'select-list',
                                                                                                    )}
                                                                                                >
                                                                                                    {dataSelectTask?.map(
                                                                                                        (
                                                                                                            item,
                                                                                                            index,
                                                                                                        ) => {
                                                                                                            return (
                                                                                                                <div
                                                                                                                    key={
                                                                                                                        index
                                                                                                                    }
                                                                                                                    onClick={() =>
                                                                                                                        handleSelect(
                                                                                                                            requireList
                                                                                                                                .id[
                                                                                                                                idxListTasks
                                                                                                                            ],
                                                                                                                            item,
                                                                                                                            idxRequirements,
                                                                                                                            idxChecklistItems,
                                                                                                                            idxListTasks,
                                                                                                                            requireList
                                                                                                                                .id[
                                                                                                                                idxListTasks
                                                                                                                            ],
                                                                                                                        )
                                                                                                                    }
                                                                                                                    className={cx(
                                                                                                                        'select-item',
                                                                                                                    )}
                                                                                                                >
                                                                                                                    <span
                                                                                                                        className={cx(
                                                                                                                            'select-title',
                                                                                                                        )}
                                                                                                                    >
                                                                                                                        {
                                                                                                                            item.title
                                                                                                                        }
                                                                                                                    </span>
                                                                                                                </div>
                                                                                                            );
                                                                                                        },
                                                                                                    )}
                                                                                                </div>
                                                                                            )}
                                                                                        </div>
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </Fragment>
                                                                );
                                                            })}
                                                        </div>
                                                        <div className={cx('require-tbl-check', 'border-right')}>
                                                            {requireList?.isCheck?.map((dataCheckDone, indexCheck) => {
                                                                return (
                                                                    <Fragment key={indexCheck}>
                                                                        <div
                                                                            className={cx(
                                                                                'border-top',
                                                                                'item-padd',
                                                                                'contentCenter',
                                                                            )}
                                                                            style={
                                                                                requireList?.listTasks[indexCheck]
                                                                                    ?.length > 0
                                                                                    ? {
                                                                                          height:
                                                                                              `${
                                                                                                  requireList
                                                                                                      ?.listTasks[
                                                                                                      indexCheck
                                                                                                  ]?.length *
                                                                                                      30 +
                                                                                                  requireList
                                                                                                      ?.listTasks[
                                                                                                      indexCheck
                                                                                                  ]?.length *
                                                                                                      4 +
                                                                                                  22
                                                                                              }` + 'px',
                                                                                      }
                                                                                    : { height: '50px' }
                                                                            }
                                                                        >
                                                                            <input
                                                                                type="checkbox"
                                                                                className={cx('form-check-input')}
                                                                                value={dataCheckDone}
                                                                                checked={dataCheckDone}
                                                                                onChange={(e) =>
                                                                                    handleChangeCheckDone(
                                                                                        e,
                                                                                        requireList.id[indexCheck],
                                                                                        idxRequirements,
                                                                                        idxChecklistItems,
                                                                                        indexCheck,
                                                                                    )
                                                                                }
                                                                            ></input>
                                                                        </div>
                                                                    </Fragment>
                                                                );
                                                            })}
                                                        </div>

                                                        <div
                                                            className={cx(
                                                                'require-tbl-memo',
                                                                'border-right',
                                                                'glo-cursor-pointer',
                                                                '',
                                                            )}
                                                        >
                                                            {requireList?.memo?.map((dataMemo, indexMomo) => {
                                                                return (
                                                                    <div
                                                                        className={cx(
                                                                            'border-top',
                                                                            'item-padd',
                                                                            'glo-padding-10',
                                                                        )}
                                                                        key={indexMomo}
                                                                        style={
                                                                            requireList?.listTasks[indexMomo]?.length >
                                                                            0
                                                                                ? {
                                                                                      height:
                                                                                          `${
                                                                                              requireList?.listTasks[
                                                                                                  indexMomo
                                                                                              ]?.length *
                                                                                                  30 +
                                                                                              requireList?.listTasks[
                                                                                                  indexMomo
                                                                                              ]?.length *
                                                                                                  4 +
                                                                                              22
                                                                                          }` + 'px',
                                                                                  }
                                                                                : { height: '50px' }
                                                                        }
                                                                        onDoubleClick={(e) =>
                                                                            toggleInputMemo(requireList.id[indexMomo])
                                                                        }
                                                                    >
                                                                        {!checkOpenMemo.includes(
                                                                            requireList.id[indexMomo],
                                                                        ) && (
                                                                            <Tooltip
                                                                                arrow
                                                                                title={
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: '1.4rem',
                                                                                        }}
                                                                                        arrow
                                                                                        id={
                                                                                            'InputMemo' +
                                                                                            `${requireList.id}`
                                                                                        }
                                                                                    >
                                                                                        {dataMemo}
                                                                                    </span>
                                                                                }
                                                                            >
                                                                                <p className={cx('glo-margin-0')}>
                                                                                    {dataMemo}
                                                                                </p>
                                                                            </Tooltip>
                                                                        )}
                                                                        {checkOpenMemo.includes(
                                                                            requireList.id[indexMomo],
                                                                        ) && (
                                                                            <input
                                                                                type="text"
                                                                                value={dataMemo}
                                                                                onChange={(e) =>
                                                                                    handleChangeMemo(
                                                                                        e,
                                                                                        requireList.id[indexMomo],
                                                                                        idxRequirements,
                                                                                        idxChecklistItems,
                                                                                        indexMomo,
                                                                                    )
                                                                                }
                                                                                className={cx(
                                                                                    'form-control',
                                                                                    'form-control-lg',
                                                                                )}
                                                                            />
                                                                        )}
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </Fragment>
                                    );
                                })}
                            </div>
                            <div className={cx('mt-5')}>
                                <div className={cx('mt-3')}>
                                    <div
                                        className={cx('table-content-head', {
                                            'border-bottom': sendDataAdd.length > 0 ? true : false,
                                        })}
                                    >
                                        {sendDataAdd?.map((item, index) => {
                                            return (
                                                <div
                                                    key={index}
                                                    className={cx(
                                                        'row',
                                                        'glo-row',
                                                        'border-top',
                                                        'border-left',
                                                        'table-content-add',
                                                    )}
                                                >
                                                    <div
                                                        className={cx(
                                                            'tbl-content-checkbox-add',
                                                            'text-center',
                                                            'font-weight-600',
                                                            'border-right',
                                                            'glo-padding-10',
                                                        )}
                                                    >
                                                        <div className={cx('glo-display-center')}>
                                                            <RiDeleteBin2Line onClick={() => deleteRow(item.index)} />
                                                        </div>
                                                    </div>
                                                    <div
                                                        className={cx(
                                                            'tbl-content-classification',
                                                            'text-center',
                                                            'font-weight-600',
                                                            'border-right',
                                                            'glo-padding-10',
                                                        )}
                                                    >
                                                        <div className={cx('glo-display-center')}>
                                                            <input
                                                                type="text"
                                                                value={item.classification}
                                                                onChange={(e) => handleChangeClassAdd(e, item.index)}
                                                                className={cx('form-control', 'form-control-lg')}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div
                                                        className={cx(
                                                            'tbl-content-requirement',
                                                            'text-center',
                                                            'font-weight-600',
                                                            'border-right',
                                                            'glo-padding-10',
                                                        )}
                                                    >
                                                        <div className={cx('glo-display-center')}>
                                                            <input
                                                                type="text"
                                                                value={item.requirement}
                                                                onChange={(e) =>
                                                                    handleChangeRequirementAdd(e, item.index)
                                                                }
                                                                className={cx('form-control', 'form-control-lg')}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div
                                                        className={cx(
                                                            'tbl-content-task',
                                                            'text-center',
                                                            'font-weight-600',
                                                            'border-right',
                                                            'glo-padding-10',
                                                        )}
                                                    >
                                                        <div className={cx('width-select-task')}>
                                                            <div className={cx('option-custom')}>
                                                                <div
                                                                    className={cx(
                                                                        'select-input',
                                                                        'select-input--multiple',
                                                                        'selected-list',
                                                                    )}
                                                                >
                                                                    {item.listTasks?.map((task, idxTask) => {
                                                                        return (
                                                                            <div
                                                                                key={idxTask}
                                                                                className={cx('selected-item')}
                                                                            >
                                                                                <div className={cx('glo-title-task')}>
                                                                                    {task.title}
                                                                                </div>
                                                                                <span
                                                                                    onClick={() =>
                                                                                        removeSelectedItemAdd(
                                                                                            task,
                                                                                            task.taskId,
                                                                                            item.index,
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    x
                                                                                </span>
                                                                            </div>
                                                                        );
                                                                    })}

                                                                    <AiOutlineDown
                                                                        className={cx('glo-icon-select')}
                                                                        onClick={() => handleOpenSeletedTaskAdd(index)}
                                                                    />
                                                                </div>
                                                                {checkOpenSelectTaskAdd.includes(index) && (
                                                                    <div className={cx('select-list')}>
                                                                        {getTaskAddRow?.map((data, indexTask) => {
                                                                            return (
                                                                                <div
                                                                                    key={indexTask}
                                                                                    onClick={() =>
                                                                                        handleSelectAdd(
                                                                                            data,
                                                                                            item.index,
                                                                                        )
                                                                                    }
                                                                                    className={cx('select-item')}
                                                                                >
                                                                                    <span
                                                                                        className={cx('select-title')}
                                                                                    >
                                                                                        {data.title}
                                                                                    </span>
                                                                                </div>
                                                                            );
                                                                        })}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className={cx(
                                                            'tbl-content-check',
                                                            'font-weight-600',
                                                            'border-right',
                                                            'glo-padding-10',
                                                        )}
                                                    >
                                                        <div className={cx('glo-center')}>
                                                            <div className={cx('glo-display-center')}>
                                                                <input
                                                                    type="checkbox"
                                                                    className={cx('form-check-input')}
                                                                    onChange={(e) =>
                                                                        handleChangeCheckAdd(e, item.index)
                                                                    }
                                                                ></input>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className={cx(
                                                            'tbl-content-memo',
                                                            'text-center',
                                                            'font-weight-600',
                                                            'border-right',
                                                            'glo-padding-10',
                                                        )}
                                                    >
                                                        <div className={cx('glo-display-center')}>
                                                            <input
                                                                type="text"
                                                                value={item.memo}
                                                                onChange={(e) => handleChangeMemoAdd(e, item.index)}
                                                                className={cx('form-control', 'form-control-lg')}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                                <button
                                    onClick={addRow}
                                    className={cx('btn-create-requirement', 'glo-margin-right', 'mt-3')}
                                >
                                    Add Row
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateRequirementCheckList;
