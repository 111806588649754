import { toast } from 'react-toastify';
import api from '~/utils/main';

export const getDataMilestones = async (projectId, isActive = 1, pageSize, page) => {
    const params = {
        projectIds: projectId,
        isActive,
        MilestonePage: page,
        ItemMilestonePageSize: 1000,
        MilestonePageSize: pageSize,
    };
    try {
        const res = await api.get(`milestones/project`, { params });
        return res;
    } catch (error) {
        console.log(error);
    }
};

export const moveMilestone = async (taskId, milestoneId, projectId) => {
    const body = {
        id: taskId,
        projectId: projectId,
        milestoneId: milestoneId,
    };
    try {
        const res = await toast.promise(api.patch(`task/move-to-milestone/${taskId}`, body), {
            pending: 'Move to milestone is pending',
            success: 'Move to milestone successfully',
            error: 'Move to milestone failed',
        });
        return res;
    } catch (error) {
        console.log(error);
    }
};

export const updateMilestone = async (milestoneId, title) => {
    const body = {
        title,
    };
    try {
        const res = await toast.promise(api.put(`milestones/${milestoneId}`, body), {
            pending: 'Update milestone is pending',
            success: 'Update milestone successfully',
            error: 'Update milestone failed',
        });
        return res;
    } catch (error) {
        console.log(error);
    }
};
