import { useCallback, useContext, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames/bind';
import { AiFillCloseCircle, AiOutlineSearch, AiOutlineSetting } from 'react-icons/ai';
import { IoIosHelpCircle, IoMdNotifications } from 'react-icons/io';

import routesConfig from '~/config/routes';
import * as listProjectService from '~/services/listProjectService';
import * as logoutService from '~/services/logoutService';
import styles from './Header.module.scss';
import logo from '~/assets/images/logo/logo-company.png';
import { Avatar, Button } from '@mui/material';
import Logout from '@mui/icons-material/Logout';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { dispatchProject } from '~/redux/actions/projectAction';
import MenuHeader from './components/MenuHeader';
import { Link, useNavigate } from 'react-router-dom';
import { GlobalStateProject } from '~/ProjectState/projectState';
import { dispatchLogout } from '~/redux/actions/authAction';

const cx = classNames.bind(styles);

const menuTeams = [
    { id: 0, name: 'All' },
    { id: 1, name: 'G1' },
    { id: 2, name: 'G2' },
];

function Header() {
    const [openDialogCreateTask, setOpenDialogCreateTask] = useState(false);
    const [listProject, setListProject] = useState([]);
    const token = useSelector((state) => state.token);
    const auth = useSelector((state) => state.auth);
    const state = useContext(GlobalStateProject);
    const [showProjects, setShowProjects] = useState([]);
    const [nbShowProjects, setNbShowProjects] = useState(7);
    // const [search, setSearch] = useState('');
    const [search, setSearch] = state.projectsAPI.search;
    const [offset, setOffset] = state.projectsAPI.offset;
    const [projectId, setProjectId] = state.projectsAPI.projectId;
    const [teamId, setTeamId] = state.projectsAPI.teamId;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { userEmail, userName } = auth.users;

    useEffect(() => {
        const getProject = () => {
            const result = listProject.filter((item) => projectId.includes(item.projectId));

            setShowProjects(result);
        };

        getProject();
    }, [projectId]);

    window.addEventListener('resize', (event) => {
        if (window.innerWidth > 1500) {
            setNbShowProjects(7);
        }
        if (window.innerWidth >= 1001 && window.innerWidth <= 1500) {
            setNbShowProjects(3);
        }
    });
    useEffect(() => {
        if (window.innerWidth > 1500) {
            setNbShowProjects(7);
        }
        if (window.innerWidth >= 1001 && window.innerWidth <= 1500) {
            setNbShowProjects(3);
        }
    }, []);

    useEffect(() => {
        const getProjectAll = async () => {
            const params = { teamId: teamId };
            const res = await listProjectService.getProject(params);

            if (res.data.status === 'success') {
                await dispatch(dispatchProject(res.data.data));
                setListProject(res.data.data);
            }
        };
        getProjectAll();
    }, [teamId]);

    const handleLogout = async () => {
        const res = await logoutService.logout();

        if (res.status === 201) {
            localStorage.removeItem('accessToken');
            localStorage.removeItem('user');
            dispatch(dispatchLogout());
            navigate(routesConfig.login);
        }
    };

    const handleSearchTask = (e) => {
        const searchValue = e.target.value;
        if (!searchValue.startsWith(' ')) {
            setOffset(0);
            setSearch(searchValue);
        }
    };

    const handleCloseText = () => {
        setSearch('');
    };

    const handleChangeTeamAll = (value) => {
        setTeamId(value);
    };

    const handleChangeTeamG1 = (value) => {
        setTeamId(value);
    };

    const handleChangeTeamG2 = (value) => {
        setTeamId(value);
    };

    const handleClickTeamAll = (value) => {
        setTeamId(value);
    };

    const handleClickTeamG1 = (value) => {
        setTeamId(value);
    };

    const handleClickTeamG2 = (value) => {
        setTeamId(value);
    };

    return (
        <div className={cx('wrapper', 'glo-width-header')}>
            <header className={cx('wrapper-menu', 'row')}>
                <nav className={cx('menu-horizontal-header')}>
                    <Link to={routesConfig.home} className={cx('menu-horizontal-header__thumnail')}>
                        <img src={logo} alt="logo-header" />
                        Glotech
                    </Link>
                    <div className={cx('menu-horizontal-wrap')}>
                        <div className={cx('menu-horizontal-list')}>
                            <MenuHeader listProject={listProject} />
                        </div>
                    </div>
                    {(auth.users.userType === 1 || auth.users.userType === 2) && (
                        <div className={cx('wrap-btn-team')}>
                            {menuTeams.map((ele) => (
                                <div
                                    className={cx('cursor-pointer')}
                                    onClick={() => handleClickTeamAll(ele.id)}
                                    key={ele.id}
                                >
                                    <input
                                        id={`radio-project-${ele.name}`}
                                        type="radio"
                                        className={cx('cursor-pointer')}
                                        checked={teamId === ele.id}
                                        value={ele.id}
                                        onChange={() => handleChangeTeamAll(ele.id)}
                                    ></input>
                                    <label htmlFor={`radio-project-${ele.name}`} className={cx('label-team')}>
                                        {ele.name}
                                    </label>
                                </div>
                            ))}
                        </div>
                    )}

                    <div className={cx('glo-btn-project-mobile')}>
                        {showProjects.slice(0, nbShowProjects)?.map((project) => (
                            <button key={project.projectId} className={cx('btn-project')}>
                                {project.projectName}
                            </button>
                        ))}
                        {showProjects.length > nbShowProjects && (
                            <button className={cx('btn-project', 'btn-project-number')}>
                                +{showProjects.length - nbShowProjects}
                            </button>
                        )}
                    </div>
                </nav>

                {/* menu user */}
                <div className={cx('menu-user')}>
                    {window.location.pathname === '/' ? (
                        <div
                            className={cx('menu-user__wrap-input')}
                            style={{ border: search.length > 0 ? '2px solid #0052cc' : '2px solid #dfe1e6' }}
                        >
                            <AiOutlineSearch className={cx('input-icon')} />
                            <div className={cx('input-text')}>
                                <input
                                    type="text"
                                    value={search}
                                    placeholder="Search....."
                                    onChange={handleSearchTask}
                                />
                            </div>
                            {search && (
                                <button className={cx('input-text__close')} onClick={handleCloseText}>
                                    <AiFillCloseCircle />
                                </button>
                            )}
                        </div>
                    ) : (
                        ''
                    )}
                    <div className={cx('menu-user__item')}>
                        <div className={cx('dropdown', 'hn-dropdown-profile')}>
                            <button
                                className={cx('dropdown-toggle')}
                                type="button"
                                id="dropdownMenuButton1"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                            >
                                <Avatar
                                    src="https://i1.wp.com/avatar-management--avatars.us-west-2.prod.public.atl-paas.net/initials/LD-3.png?ssl=1"
                                    sx={{ width: 24, height: 24 }}
                                    alt="Avatar"
                                />
                            </button>
                            <ul
                                className="dropdown-menu"
                                id={cx('hn-dropdown-menu__avatar')}
                                aria-labelledby="dropdownMenuButton1"
                            >
                                <li className={cx('hn-my-profile')}>
                                    <Avatar
                                        src="https://i1.wp.com/avatar-management--avatars.us-west-2.prod.public.atl-paas.net/initials/LD-3.png?ssl=1"
                                        sx={{ width: 24, height: 24 }}
                                        alt="Avatar"
                                    />
                                    <div className={cx('hn-info-profile')}>
                                        <p className={cx('hn-info-profile-username')}>
                                            <b dangerouslySetInnerHTML={{ __html: userName }} />
                                        </p>
                                        <label className={cx('hn-info-profile-email')}>{userEmail}</label>
                                    </div>
                                </li>
                                <li>
                                    <hr className="dropdown-divider" />
                                </li>
                                <li className={cx('hn-my-profile')}>
                                    <Link className="dropdown-item" to="/profile">
                                        <AccountCircle fontSize="large" />
                                        <span className={cx('header-text-span')}>Profile</span>
                                    </Link>
                                </li>
                                {(auth.users.userType === 1 || auth.users.userType === 2) && (
                                    <li className={cx('hn-my-profile')}>
                                        <Link className="dropdown-item" to="/user">
                                            <AccountCircle fontSize="large" />
                                            <span className={cx('header-text-span')}>User</span>
                                        </Link>
                                    </li>
                                )}

                                <li>
                                    <hr className="dropdown-divider" />
                                </li>
                                <li className={cx('hn-my-profile')}>
                                    <Button
                                        className={cx('btn-logout')}
                                        onClick={handleLogout}
                                        startIcon={<Logout fontSize="large" />}
                                    >
                                        Logout
                                    </Button>
                                </li>
                            </ul>
                        </div>
                    </div>
                    {/* <img
                        className={cx('avatar-user', 'menu-user__item')}
                        src="https://i1.wp.com/avatar-management--avatars.us-west-2.prod.public.atl-paas.net/initials/LD-3.png?ssl=1"
                        alt="avatar-user"
                    /> */}
                    {/* <div className={cx('menu-user__item', 'profile-padding')}>
                        <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                            <Tooltip title="Account settings">
                                <Avatar src="https://i1.wp.com/avatar-management--avatars.us-west-2.prod.public.atl-paas.net/initials/LD-3.png?ssl=1"
                                    onClick={handleClickMenuProfile}
                                    sx={{ width: 24, height: 24 }} alt="Avatar"
                                />
                            </Tooltip>
                        </Box>
                        <Menu
                            anchorEl={anchorEl}
                            id="account-menu"
                            open={openMenuProfile}
                            onClose={handleCloseopenMenuProfile}
                            onClick={handleCloseopenMenuProfile}
                            PaperProps={{
                                elevation: 0,
                                sx: {
                                    overflow: 'visible',
                                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                    mt: 1.5,
                                    '& .MuiAvatar-root': {
                                        width: 32,
                                        height: 32,
                                        ml: -0.5,
                                        mr: 1,
                                    },
                                    '&:before': {
                                        content: '""',
                                        display: 'block',
                                        position: 'absolute',
                                        top: 0,
                                        right: 0,
                                        width: 14,
                                        height: 10,
                                        bgcolor: 'background.paper',
                                        transform: 'translateY(-50%) rotate(45deg)',
                                        zIndex: 0,
                                    },
                                },
                            }}
                            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                        >
                            <MenuItem>
                                <Avatar /> My Profile
                            </MenuItem>
                            <Divider />
                            <MenuItem>
                                <ListItemIcon>
                                    <Settings fontSize="small" />
                                </ListItemIcon>
                                Notifications
                            </MenuItem>
                            <MenuItem>
                                <ListItemIcon>
                                    <Settings fontSize="small" />
                                </ListItemIcon>
                                Email Reports
                            </MenuItem>
                            <MenuItem>
                                <ListItemIcon>
                                    <Logout fontSize="small" />
                                </ListItemIcon>
                                Logout
                            </MenuItem>
                        </Menu>
                    </div> */}
                </div>
            </header>
        </div>
    );
}

export default Header;
