import classNames from 'classnames/bind';
import Tippy from '@tippyjs/react/headless';

import MenuItem from './MenuItem';
import styles from './Menu.module.scss';
import { Wrapper as PopperWrapper } from '~/components/Popper';

const cx = classNames.bind(styles);

function Menu(props) {
    const { children, items = [], handleChangeTypeTask, idTask } = props;

    const renderItem = () => {
        return items.map((item, index) => {
            return <MenuItem key={index} data={item} handleChangeTypeTask={handleChangeTypeTask} idTask={idTask} />;
        });
    };

    return (
        <Tippy
            interactive
            trigger="click"
            placement="bottom"
            delay={[0, 700]}
            offset={[12, 8]}
            render={(attrs) => (
                <div className={cx('menu-list')} tabIndex="-1" {...attrs}>
                    <PopperWrapper className={cx('menu-popper')}>
                        <div className={cx('menu')}>{renderItem()}</div>
                    </PopperWrapper>
                </div>
            )}
        >
            {children}
        </Tippy>
    );
}

export default Menu;
