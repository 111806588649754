
import classNames from 'classnames/bind';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import styles from '../../Milestone.module.scss';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import api from '~/utils/main';

const cx = classNames.bind(styles);

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 15,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const initialState = {
    userIds: [],
};

const listDataTask = [
    { id: 1, task: 258, title: 'Task 25 - Thay đổi kích cỡ chữ của giao diện APP', status: 'In Progress', dueDate: '01/01/2023' },
    { id: 2, task: 259, title: 'Task 26 - Task 25 - Thay đổi kích cỡ chữ của giao diện APP_Nguyen_Dinh_Binh', status: 'New', dueDate: '01/01/2023' },
    { id: 3, task: 260, title: '210706_A022_Research_and_implement_refresh', status: 'Pending', dueDate: '01/01/2023' },
]


const MilestoneAddTask = ({ openDialogAddTaskDialog, setOpenDialogAddTaskDialog }) => {
    const [isCheckAllUser, setIsCheckAllUser] = useState(false);
    const [isCheckTask, setIsCheckTask] = useState([]);
    const [listTaskMilestone, setListTaskMilestone] = useState(listDataTask);
    const [searchValue, setSearchValue] = React.useState('');

    let token = useSelector((state) => state.token);

    const handleCloseRemoveProjectDialog = () => {
        setOpenDialogAddTaskDialog(false);
    };

    const handleSearchFilter = (e) => {
        setSearchValue(e.target.value);
    };

    const filtered = listTaskMilestone?.filter((dt) => {
        if (
            dt.title.toLowerCase().includes(searchValue.toLowerCase()) ||
            dt.title.toLowerCase().includes(searchValue.toLowerCase())
        )
            return dt;
    });

    const handleSelectAllTask = (e) => {
        setIsCheckAllUser(!isCheckAllUser);
        setIsCheckTask(listTaskMilestone.map((item) => item.id));
        if (isCheckAllUser) {
            setIsCheckTask([]);
        }
    };

    const handleClickTask = (e) => {
        const { checked } = e.target;
        const userId = +e.target.dataset.userid;
        setIsCheckTask([...isCheckTask, userId]);
        if (!checked) {
            setIsCheckTask(isCheckTask.filter((item) => item !== userId));
        }
    };



    return (
        <div className={cx('header-create-task')}>
            <form>
                <BootstrapDialog
                    open={openDialogAddTaskDialog}
                    onClose={handleCloseRemoveProjectDialog}
                    maxWidth="lg"
                    fullWidth
                    aria-labelledby="customized-dialog-title"
                >
                    <BootstrapDialogTitle
                        id="customized-dialog-title"
                        onClose={handleCloseRemoveProjectDialog}
                        className={cx('custom-title-control')}
                    >
                        <div className={cx('dialog-add-project-title')}>
                            <h2>Add Task</h2>
                        </div>
                    </BootstrapDialogTitle>
                    <DialogContent dividers>
                        <div className={cx('dialog-add-task-milestone')}>
                            <div className={cx('milestone-add-search-title', 'mb-3')}>
                                <label htmlFor="search-title" className={cx('form-label', 'lbl-search')}>
                                    Search:
                                </label>
                                <input
                                    type="search"
                                    placeholder="Search user..."
                                    className={cx('form-control', 'form-control-lg', 'ip-search-title')}
                                    id="search-title"
                                    value={searchValue}
                                    onChange={handleSearchFilter}
                                />
                            </div>
                            <div className={cx('row', 'mb-3')}>
                                <table className={cx('milistone-task-add_table')}>
                                    <thead>
                                        <tr className={cx('milistone-task-add_title-table')}>
                                            {/* check all */}
                                            <th className={cx('milistone-task-add_check-task')}>
                                                <input
                                                    type="checkbox"
                                                    name="selectAllUser"
                                                    id="selectAllUser"
                                                    onChange={handleSelectAllTask}
                                                    checked={isCheckAllUser}
                                                    className={cx('milistion-input-check-all')}
                                                />
                                            </th>
                                            <th className={cx('milistone-task-add_title-task')}>Task#</th>
                                            <th className={cx('milistone-task-add_title-title')}>Title</th>
                                            <th className={cx('milistone-task-add_title-status')}>Status</th>
                                            <th className={cx('milistone-task-add_title-due-date')}>Due Date</th>
                                        </tr>
                                    </thead>
                                    <tbody className={cx('milistone-task-list-tb')}>
                                        {filtered?.map((listUP, index) => {
                                            return (
                                                <tr className={cx('milistone-task-add_item-body-tr')} key={index}>
                                                    <td className={cx('milistone-task-add_check-task')}>
                                                        <input
                                                            type="checkbox"
                                                            name={listUP.title}
                                                            data-userid={listUP.id}
                                                            onChange={handleClickTask}
                                                            value={isCheckTask.includes(listUP.id)}
                                                        />
                                                    </td>
                                                    <td className={cx('milistone-task-add_title-task')}>
                                                        {listUP.task}
                                                    </td>
                                                    <td className={cx('milistone-task-add_title-title')}>
                                                        {listUP.title}
                                                    </td>
                                                    <td className={cx('milistone-task-add_title-status')}>{listUP.status}</td>
                                                    <td className={cx('milistone-task-add_title-due-date')}>{listUP.dueDate}</td>
                                                </tr>
                                            );
                                        })}

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions className={cx('create-project-action')}>
                        <button onClick={handleCloseRemoveProjectDialog} className={cx('hn-btn-cancel')} size="medium">
                            Cancel
                        </button>
                        <button
                            type="submit"
                            // onClick={handleAddTaskMilestone}
                            className={cx('hn-btn-submit')}
                            size="medium"
                        >
                            Add
                        </button>
                    </DialogActions>
                </BootstrapDialog>
            </form>
        </div>
    );
};

export default MilestoneAddTask;
