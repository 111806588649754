/* eslint-disable no-unused-vars */
import classNames from 'classnames/bind';
import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import styles from '../CreateTaskChild/CreateTaskChild.module.scss';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { convertToRaw, EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { useContext, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { GlobalStateProject } from '~/ProjectState/projectState';
import { SiXdadevelopers } from 'react-icons/si';
import { MdOutlineNewReleases } from 'react-icons/md';
import { AiFillBug, AiOutlineDeploymentUnit, AiOutlinePlus } from 'react-icons/ai';
import { CgArrowsExchange, CgSearchLoading } from 'react-icons/cg';
import { GrDocumentText, GrVmMaintenance } from 'react-icons/gr';
import { TbFileCheck, TbTableExport } from 'react-icons/tb';
import { TiLightbulb } from 'react-icons/ti';
import { VscReport } from 'react-icons/vsc';
import draftToHtml from 'draftjs-to-html';
import { toast } from 'react-toastify';
import ReactLoading from 'react-loading';
import { HighIcon, LowIcon, MediumIcon } from '~/components/Icons';
import api from '~/utils/main';
import { memo } from 'react';
import * as holidayService from '~/services/getHolidayService';
import CountDays from '~/components/CountDays';

const cx = classNames.bind(styles);

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 15,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const initialState = {
    assignTask: [],
};

// const listPoint = [
//     { point: 1, title: '<= 1d' },
//     { point: 2, title: '1-2d' },
//     { point: 3, title: '3-4d' },
//     { point: 5, title: '5-7d' },
//     { point: 8, title: '8-12d' },
//     { point: 13, title: '13-18d' },
//     { point: 21, title: '>=19d' },
// ];
const MENU_REPORT_TASK = [
    {
        icon: <SiXdadevelopers />,
        title: 'Development',
        id: 2,
    },
    {
        icon: <MdOutlineNewReleases />,
        title: 'Release',
        id: 9,
    },
    {
        icon: <AiFillBug />,
        title: 'Bug',
        id: 1,
    },
    {
        icon: <CgArrowsExchange />,
        title: 'Change Request',
        id: 12,
    },
    {
        icon: <GrDocumentText />,
        title: 'Document',
        id: 14,
    },
    {
        icon: <TbTableExport />,
        title: 'Enhancement',
        id: 3,
    },
    {
        icon: <TiLightbulb />,
        title: 'Idea',
        id: 11,
    },
    {
        icon: <GrVmMaintenance />,
        title: 'Maintenance',
        id: 7,
    },
    {
        icon: <AiOutlinePlus />,
        title: 'Others',
        id: 8,
    },
    {
        icon: <TbFileCheck />,
        title: 'Quality Assurance',
        id: 5,
    },
    {
        icon: <VscReport />,
        title: 'Report',
        id: 13,
    },
    {
        icon: <CgSearchLoading />,
        title: 'Research Do',
        id: 4,
    },
    {
        icon: <AiOutlineDeploymentUnit />,
        title: 'Unit Testing',
        id: 6,
    },
    {
        icon: <CgArrowsExchange />,
        title: 'Update',
        id: 10,
    },
];

const CreateTaskChild = (props) => {
    const {
        openDialogCreateTaskChild,
        setOpenDialogCreateTaskChild,
        infoChildTask,
        setInfoChildTask,
        setCountReload,
        countReload,
    } = props;

    const handleCloseCreateTaskChild = () => {
        setOpenDialogCreateTaskChild(false);
        setInfoChildTask({});
        setDataCreate(initialState);
        setIsPointAndLevelNull(false);
        setUnSubmit(false);
    };

    const [dataCreate, setDataCreate] = useState(initialState);
    const token = useSelector((state) => state.token);
    const projects = useSelector((state) => state.project);
    const state = useContext(GlobalStateProject);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [valuePicker, setValuePicker] = useState(null);
    const [dataMileStone, setDataMileStone] = useState('');
    const [valueTitle, setValueTitle] = useState(false);

    const { assignTask } = dataCreate;
    const ref = useRef();
    const [customDuedate, setCustomDueDate] = useState(false);
    const [createTaskInfo, setCreateTaskInfo] = useState([]);

    const [ipAssignTaskIsOpen, setIpAssignTaskIsOpen] = useState(false);
    const [inputAssignTask, setInputAssignTask] = useState('none');
    const [loadingAssign, setLoadingAssign] = useState(false);
    const [dueDateChildTask, setDueDateChildTask] = useState('');
    const [callback, setCallback] = state.projectsAPI.callback;

    const [validDueDateTaskChild, setValidDueDateTaskChild] = useState(false);
    const [errorDueDate, setErrorDueDate] = useState(false);
    const [listHoliday, setListHoliday] = useState([]);
    const [unSubmit, setUnSubmit] = useState(false);
    const [isPointAndLevelNull, setIsPointAndLevelNull] = useState(false);

    useEffect(() => {
        const getHoliday = async () => {
            const res = await holidayService.getHoliday();
            const newArr = [];

            res.data.items.map((ele) => {
                if (
                    !newArr.includes(ele.start.date) &&
                    !newArr.includes(ele.end.date) &&
                    ele.summary !== 'Christmas Day' &&
                    ele.summary !== 'Christmas Eve'
                ) {
                    newArr.push(ele.start.date, ele.end.date);
                }
            });

            if (res.status === 200) {
                setListHoliday(newArr);
            }
        };
        getHoliday();
    }, []);

    useEffect(() => {
        const getInfoCreateTask = async () => {
            setLoadingAssign(true);
            const res = await api.get(`task/init-create`, {
                params: {
                    projectId: infoChildTask !== {} && infoChildTask.projectId,
                },
            });
            if (res.data.status === 'success') {
                setLoadingAssign(false);
                setCreateTaskInfo(res.data.data);
            }
        };
        if (openDialogCreateTaskChild === true && infoChildTask !== {}) {
            getInfoCreateTask();
        }
    }, [infoChildTask]);

    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            // If the menu is open and the clicked target is not within the menu,
            // then close the menu
            if (customDuedate && ref.current && !ref.current.contains(e.target)) {
                setCustomDueDate(false);
            }
        };

        document.addEventListener('mousedown', checkIfClickedOutside);

        return () => {
            // Cleanup the event listener
            document.removeEventListener('mousedown', checkIfClickedOutside);
        };
    }, [customDuedate]);

    // const getListPoint = (date) => {
    //     if (date <= 1) {
    //         return 1;
    //     } else if (date <= 2) {
    //         return 2;
    //     } else if (date <= 4) {
    //         return 3;
    //     } else if (date <= 7) {
    //         return 5;
    //     } else if (date <= 12) {
    //         return 8;
    //     } else if (date <= 18) {
    //         return 13;
    //     } else {
    //         return 21;
    //     }
    // };

    // const getPoint = (endDate) => {
    //     var start = moment(moment().format('YYYY-MM-DD'), 'YYYY-MM-DD');
    //     var end = moment(endDate, 'YYYY-MM-DD');

    //     // const rs = moment.duration(end.diff(start)).asDays();

    //     const rs = CountDays(start, end, listHoliday);

    //     const point = getListPoint(rs);

    //     return point;
    // };

    // const filterPoint = (point) => {
    //     return listPoint.find((ele) => ele.point === point);
    // };

    const handleCheck = (e, id, name) => {
        const { checked } = e.target;

        setIpAssignTaskIsOpen(!ipAssignTaskIsOpen);
        // document.getElementById(`inPTest${id}`).style.display = 'block';
        // document.getElementById(`dueDate${id}`).style.display = 'block';

        let title;
        let valueDueDate;
        let dueDateChildTaskInit;

        dueDateChildTaskInit = moment(infoChildTask.dueDate, 'DD-MM-YYYY').format('YYYY-MM-DD');

        // const point = getPoint(dueDateChildTaskInit);
        // const rsPoint = filterPoint(point);

        if (document.querySelector(`#inPTest${id}`) !== null) {
            title = document.querySelector(`#inPTest${id}`).value;
            valueDueDate = document.querySelector(`#dueDate${id}`).value;
        }
        setDataCreate({
            ...dataCreate,
            assignTask: [
                ...assignTask,
                { title, assignId: id, dueDate: dueDateChildTaskInit, name, level: 'N', typeId: 2, isStarted: false },
            ],
        });

        if (!checked) {
            const newAssignTask = [...assignTask];
            const rs = newAssignTask.filter((item) => item.assignId !== id);
            setDataCreate({ ...dataCreate, assignTask: rs });
        }
    };

    const checkDueDate = () => {
        return assignTask.some((ele) => ele.dueDate === 'Invalid date');
    };

    useEffect(() => {
        if (isPointAndLevelNull) {
            const checkPointAndLevelNotNull = assignTask.every((info) => info.point && info.level);

            checkPointAndLevelNotNull && setIsPointAndLevelNull(false);
        }
    }, [assignTask]);

    const handleCreateTaskChild = async (e) => {
        setUnSubmit(true);
        e.preventDefault();

        const assignTaskNoName = assignTask.map((task) => {
            const { name, ...assginTask } = task;

            return assginTask;
        });

        const body = {
            assignInfos: assignTaskNoName,
        };

        const isPointAndLevelNotNull = assignTask.every((info) => {
            return info.point && info.level;
        });

        if (!isPointAndLevelNotNull) {
            setIsPointAndLevelNull(true);
            setUnSubmit(false);
            return;
        }

        if (checkDueDate() === true) {
            setErrorDueDate(true);
            setUnSubmit(false);
            return;
        }

        const id = infoChildTask.id || infoChildTask.taskId;

        const res = await api.post(`task/${id}/create-additional-tasks`, body);
        if (res.status === 201) {
            setCountReload(countReload + 1);
            setUnSubmit(false);
            handleCloseCreateTaskChild();
            setCallback(!callback);
            setUnSubmit(false);
            toast.success(`Create task successfully! 🚀`, {
                position: 'bottom-left',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    };

    const handleChecked = (id) => {
        assignTask.map((assign) => {
            return assign.assignId === id;
        });
    };

    const handleChangeInputTitle = (e, id) => {
        setDataCreate((prevState) => {
            const newState = prevState.assignTask.map((assign) => {
                if (assign.assignId === id) {
                    return { ...assign, title: e.target.value };
                }
                return assign;
            });

            return { ...prevState, assignTask: newState };
        });
    };

    const handleChangeInputDueDate = (e, id) => {
        const value = moment(new Date(e.target.value)).format('YYYY-MM-DD');

        //const point = getPoint(value);

        // const rsPoint = filterPoint(point);

        setDataCreate((prevState) => {
            const newState = prevState.assignTask.map((assign) => {
                if (assign.assignId === id) {
                    return { ...assign, dueDate: e.target.value };
                }
                return assign;
            });

            return { ...prevState, assignTask: newState };
        });
    };

    const handleChangePointChildTask = (event, id) => {
        setDataCreate((prevState) => {
            const newState = prevState.assignTask.map((assign) => {
                if (assign.assignId === id) {
                    return { ...assign, point: +event.target.value };
                }
                return assign;
            });

            return { ...prevState, assignTask: newState };
        });
    };

    const handleChangeLevelChildTask = (event, id) => {
        setDataCreate((prevState) => {
            const newState = prevState.assignTask.map((assign) => {
                if (assign.assignId === id) {
                    return { ...assign, level: event.target.value };
                }
                return assign;
            });

            return { ...prevState, assignTask: newState };
        });
    };

    const handleChangeTypeIdChildTask = (event, id) => {
        setDataCreate((prevState) => {
            const newState = prevState.assignTask.map((assign) => {
                if (assign.assignId === id) {
                    return { ...assign, typeId: Number(event.target.value) };
                }
                return assign;
            });

            return { ...prevState, assignTask: newState };
        });
    };

    const getIcon = (priority, priorityName) => {
        if (priority === 'Medium' || priorityName === 'Medium') {
            return <MediumIcon />;
        } else if (priority === 'High' || priorityName === 'High') {
            return <HighIcon />;
        } else {
            return <LowIcon />;
        }

        // return <HighIcon /> <MediumIcon /> <LowIcon />
    };

    const handleChangeStarted = (e, assignId) => {
        setDataCreate((prev) => {
            const cloneAssignTask = [...dataCreate.assignTask];

            const index = cloneAssignTask.findIndex((ele) => ele.assignId === assignId);
            cloneAssignTask[index].isStarted = e.target.checked;

            return { ...prev, assignTask: cloneAssignTask };
        });
    };

    return (
        <div className={cx('header-create-task')}>
            <form>
                <BootstrapDialog
                    open={openDialogCreateTaskChild}
                    onClose={handleCloseCreateTaskChild}
                    maxWidth="1700px"
                    minwidth="1500px"
                    fullWidth
                    aria-labelledby="customized-dialog-title"
                >
                    <BootstrapDialogTitle
                        id="customized-dialog-title"
                        onClose={handleCloseCreateTaskChild}
                        className={cx('custom-title-control')}
                    >
                        <div className={cx('dialog-create-task-title')}>
                            <h2>Create Child Task</h2>
                        </div>
                    </BootstrapDialogTitle>
                    <DialogContent dividers className={cx('move-project-content')}>
                        <div className="mb-4">
                            <b>ProjectName:</b>{' '}
                            <span className={cx('sp-create-project-name')}>{infoChildTask.projectName}</span>
                        </div>
                        <div className="mb-4">
                            <b>Title:</b> <span className={cx('sp-create-title')}>{infoChildTask.title}</span>
                        </div>
                        <div className="mb-4">
                            <b>Assignee To:</b>{' '}
                            <span
                                className={cx('sp-create-assignee-to')}
                                dangerouslySetInnerHTML={{
                                    __html: infoChildTask.userAssign || infoChildTask.assignName,
                                }}
                            />
                        </div>
                        <div className="mb-4">
                            <b>Priority:</b>
                            <span className={cx('sp-create-priority')}>
                                {getIcon(infoChildTask.priority, infoChildTask.priorityName)}

                                {infoChildTask.priority ? infoChildTask.priority : infoChildTask.priorityName}
                            </span>
                        </div>

                        <div className="mb-4">
                            <b>Type: </b>
                            <span className={cx('sp-create-type')}>{infoChildTask.typeName}</span>
                        </div>

                        <div className="mb-4">
                            <label htmlFor="hn-create-assignee" className="form-label">
                                <b>Assignee Task</b>
                            </label>
                            {loadingAssign ? (
                                <ReactLoading type="spin" color="#ccc" height={'20px'} width={'20px'} />
                            ) : (
                                <div className="row mt-2">
                                    {createTaskInfo.length !== 0 &&
                                        createTaskInfo.userCreates.map((data, index) => (
                                            <div
                                                className={cx(
                                                    'col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3',
                                                    'hn-assign-task',
                                                    'mb-2',
                                                )}
                                                key={index}
                                            >
                                                <div className={cx('form-check', 'hn-assign-task_item')}>
                                                    <input
                                                        checked={handleChecked(data.id)}
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        value=""
                                                        id={'#assigneeTask' + data.id}
                                                        onChange={(e) => handleCheck(e, data.id, data.name)}
                                                    />
                                                    <label
                                                        className={cx('form-check-label', 'lbl-name_item-assign-task')}
                                                        htmlFor={'#assigneeTask' + data.id}
                                                    >
                                                        <span
                                                            dangerouslySetInnerHTML={{
                                                                __html: data.name,
                                                            }}
                                                        />
                                                    </label>
                                                </div>
                                                {/* <input
                                                    type="text"
                                                    className={cx('form-control', 'input-to-assign-task')}
                                                    id={'inPTest' + data.id}
                                                    style={{ display: inputAssignTask }}
                                                    placeholder="Enter title"
                                                    onChange={(e) => handleChangeInputTitle(e, data.id)}
                                                />
                                                <input
                                                    type="date"
                                                    className={cx('form-control', 'input-to-assign-task')}
                                                    id={'dueDate' + data.id}
                                                    style={{ display: inputAssignTask }}
                                                    placeholder="Enter dueDate"
                                                    onChange={(e) => handleChangeInputDueDate(e, data.id)}
                                                /> */}
                                            </div>
                                        ))}
                                </div>
                            )}
                        </div>
                        <div className="mb-4 mt-4">
                            {dataCreate.length !== 0 &&
                                dataCreate.assignTask.map((data, index) => (
                                    <div className={cx('row', 'res-assign-task')} key={index}>
                                        <div className={cx('col-12 col-xl-2')}>
                                            <p
                                                className={cx('mr-2')}
                                                dangerouslySetInnerHTML={{
                                                    __html: data.name,
                                                }}
                                            />
                                        </div>
                                        <div className={cx('col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2')}>
                                            <input
                                                type="text"
                                                className={cx('form-control', 'input-to-assign-task', 'mb-3')}
                                                // style={{ display: inputAssignTask }}
                                                placeholder="Enter title"
                                                // id={'inPTest' + data.assignId}
                                                onChange={(e) => handleChangeInputTitle(e, data.assignId)}
                                                style={{ width: '100%' }}
                                            />
                                        </div>
                                        <div className={cx('col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2')}>
                                            <input
                                                type="date"
                                                className={cx('form-control form-control-lg', 'mb-3')}
                                                value={moment(data.dueDate).format('YYYY-MM-DD')}
                                                min={moment(new Date()).format('YYYY/MM/DD').replaceAll('/', '-')}
                                                // max={moment(data.dueDate).format('YYYY/MM/DD').replaceAll('/', '-')}
                                                onChange={(e) => handleChangeInputDueDate(e, data.assignId)}
                                                onKeyDown={(e) => {
                                                    e.preventDefault();
                                                }}
                                            ></input>
                                        </div>
                                        {/* <div className={cx('col-2')}>
                                            <select
                                                className="form-select form-select-lg mb-3"
                                                aria-label=".form-select-lg example"
                                                id="input-point"
                                                onChange={(e) => handleChangePointChildTask(e, data.assignId)}
                                                value={data.point.point}
                                            >
                                                <option value={0} className={cx('option-select')}>
                                                    Select point
                                                </option>
                                                {listPoint.map((ele, index) => (
                                                    <option
                                                        value={ele.point}
                                                        key={index}
                                                        className={cx('option-select')}
                                                    >
                                                        {ele.point} {'(' + ele.title + ')'}
                                                    </option>
                                                ))}
                                            </select>
                                        </div> */}
                                        <div className={cx('col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2')}>
                                            <input
                                                className={cx('form-control form-control-lg', 'mb-3')}
                                                placeholder="Enter point"
                                                type="number"
                                                step="0.25"
                                                min="0"
                                                onChange={(e) => handleChangePointChildTask(e, data.assignId)}
                                            />
                                        </div>

                                        <div className={cx('col-12 col-sm-6 col-md-4 col-lg-2 col-xl-1')}>
                                            <select
                                                className={cx('form-select form-select-lg', 'mb-3')}
                                                aria-label=".form-select-lg example"
                                                id="input-level"
                                                onChange={(e) => handleChangeLevelChildTask(e, data.assignId)}
                                                defaultValue={data.level}
                                            >
                                                {/* <option value={0} className={cx('option-select')}>
                                                    Select level
                                                </option> */}
                                                {['E', 'N', 'S'].map((level, index) => (
                                                    <option value={level} key={index} className={cx('option-select')}>
                                                        {level}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className={cx('col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2')}>
                                            <select
                                                className={cx('form-select form-select-lg', 'mb-3')}
                                                id="hn-create-assignee"
                                                // value="Development"
                                                onChange={(e) => handleChangeTypeIdChildTask(e, data.assignId)}
                                            >
                                                {MENU_REPORT_TASK.map((option, index) => (
                                                    <option key={index} value={option.id}>
                                                        {option.title}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>

                                        <div className={cx('col-12 col-sm-6 col-md-4 col-lg-3 col-xl-1')}>
                                            <input
                                                type="checkbox"
                                                id={`radio-started-${data.assignId}`}
                                                checked={data.isStarted}
                                                // value={data.isStarted}
                                                onChange={(e) => handleChangeStarted(e, data.assignId)}
                                            />
                                            <label
                                                htmlFor={`radio-started-${data.assignId}`}
                                                className={cx('input-started')}
                                            >
                                                Started
                                            </label>
                                        </div>
                                    </div>
                                ))}
                            {errorDueDate === true && checkDueDate() === true && (
                                <span className={cx('error-message')}>The field due date is required</span>
                            )}
                            {isPointAndLevelNull && (
                                <span className={cx('error-message')}>The point and level must not be blank</span>
                            )}
                        </div>
                    </DialogContent>
                    <DialogActions className={cx('create-task-action')}>
                        <button onClick={handleCloseCreateTaskChild} className={cx('hn-btn-cancel')} size="medium">
                            Cancel
                        </button>
                        <button
                            type="submit"
                            onClick={handleCreateTaskChild}
                            className={cx('hn-btn-submit')}
                            size="medium"
                            disabled={unSubmit ? true : false}
                        >
                            Add
                        </button>
                    </DialogActions>
                </BootstrapDialog>
            </form>
        </div>
    );
};

export default memo(CreateTaskChild);
