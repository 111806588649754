import classNames from 'classnames/bind';
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useRef } from 'react';
import { AiFillEdit, AiOutlineDelete, AiOutlineDownCircle, AiOutlineFileAdd } from 'react-icons/ai'
import { BsCheck2Circle } from 'react-icons/bs';
import { MdAddTask } from 'react-icons/md';
import { Link } from 'react-router-dom';
import routesConfig from '~/config/routes';
import styles from '../../Milestone.module.scss';
import MilestoneAddTask from './MilestoneAddTask';
import MilestoneEditTask from './MilestoneEditTask';

const cx = classNames.bind(styles);

const MilestoneTask = () => {
    const [dropdownControlIsOpen, setDropdownControlIsOpen] = useState(false);
    const [dropdownControl, setDropdownControl] = useState('none');
    const [openDialogAddTaskDialog, setOpenDialogAddTaskDialog] = useState(false);
    const [openDialogEditTaskDialog, setOpenDialogEditTaskDialog] = useState(false);

    const ref = useRef();

    useEffect(() => {
        if (dropdownControlIsOpen) {
            showDropdownControl();
        } else {
            hiddenDropdownControl();
        }
    }, [dropdownControlIsOpen]);

    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            if (dropdownControlIsOpen && ref.current && !ref.current.contains(e.target)) {
                setDropdownControlIsOpen(false);
            }
        };
        document.addEventListener('mousedown', checkIfClickedOutside);
        return () => {
            document.removeEventListener('mousedown', checkIfClickedOutside);
        };
    }, [dropdownControlIsOpen]);

    const showDropdownControl = () => {
        setDropdownControl('block');
    };

    const hiddenDropdownControl = () => {
        setDropdownControl('none');
    };

    const handleAddTaskMilestone = () => {
        setOpenDialogAddTaskDialog(true);
        setDropdownControl('none');
    }

    const handleEditTaskMilestone = () => {
        setOpenDialogEditTaskDialog(true);
        setDropdownControl('none');
    }

    return (
        <div>
            <div className={cx('dropdown-filter')} ref={ref}>
                <AiOutlineDownCircle className={cx('dropdown-toggle', 'milestone-task-icon-2')}
                    onClick={() => { setDropdownControlIsOpen(!dropdownControlIsOpen); }} aria-expanded="false" />
                <ul className={cx('dropdown-menu', 'menu-task-milestone')} style={{ display: dropdownControl }}>
                    <li>
                        <div className={cx('milestone-task-item-li')}>
                            <Link to={routesConfig.createTask}>
                                <AiOutlineFileAdd />
                                <span className={cx('sp-item-li')}>Create New Task</span>
                            </Link>
                        </div>
                    </li>
                    <li>
                        <div className={cx('milestone-task-item-li')} onClick={(e) => handleAddTaskMilestone()}>
                            <MdAddTask />
                            <span className={cx('sp-item-li')}>Add Task</span>
                        </div>
                    </li>
                    <li>
                        <div className={cx('milestone-task-item-li')}>
                            <AiFillEdit />
                            <span className={cx('sp-item-li')} onClick={(e) => handleEditTaskMilestone()}>Edit</span>
                        </div>
                    </li>
                    <li>
                        <div className={cx('milestone-task-item-li')}>
                            <AiOutlineDelete />
                            <span className={cx('sp-item-li')}>Delete</span>
                        </div>

                    </li>
                    <li>
                        <div className={cx('milestone-task-item-li')}>
                            <BsCheck2Circle />
                            <span className={cx('sp-item-li')}>Complete</span>
                        </div>
                    </li>
                </ul>
            </div>
            <MilestoneAddTask
                openDialogAddTaskDialog={openDialogAddTaskDialog}
                setOpenDialogAddTaskDialog={setOpenDialogAddTaskDialog} />
            <MilestoneEditTask
                openDialogEditTaskDialog={openDialogEditTaskDialog}
                setOpenDialogEditTaskDialog={setOpenDialogEditTaskDialog} />
        </div>
    )
}

export default MilestoneTask