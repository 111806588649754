const initialData = {
    columns: {
        1: {
            id: 1,
            title: 'New',
            taskIds: [],
        },
        2: {
            id: 2,
            title: 'In progress',
            taskIds: [],
        },
        5: {
            id: 5,
            title: 'Resolved',
            taskIds: [],
        },
        7: {
            id: 7,
            title: 'Testing',
            taskIds: [],
        },
        8: {
            id: 8,
            title: 'Fix bug',
            taskIds: [],
        },
        9: {
            id: 9,
            title: 'Test Done',
            taskIds: [],
        },
        10: {
            id: 10,
            title: 'Pending',
            taskIds: [],
        },
        3: {
            id: 3,
            title: 'Closed',
            taskIds: [],
        },
    },
    columnOrder: [1, 2, 5, 7, 8, 9, 10, 3],
};

export default initialData;
