import classNames from 'classnames/bind';
import { React, Fragment, useContext, useState, useCallback, Suspense, lazy, useRef } from 'react';
import ReactLoading from 'react-loading';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';

//icon
import { FiArrowRightCircle, FiAtSign, FiCheck } from 'react-icons/fi';
import { TbAlignJustified, TbTableExport, TbFileCheck } from 'react-icons/tb';
import { MdDateRange, MdDescription, MdOutlineNewReleases, MdToc } from 'react-icons/md';
import { AiFillBug, AiOutlinePlus, AiOutlineDeploymentUnit, AiTwotoneStar } from 'react-icons/ai';
import { IoIosArrowDropup } from 'react-icons/io';
import { RiMessage3Fill } from 'react-icons/ri';
import { VscDebugAll, VscReport } from 'react-icons/vsc';
import { TiArrowSortedDown, TiArrowSortedUp, TiLightbulb } from 'react-icons/ti';
import { GrDocumentText, GrFormClose, GrVmMaintenance } from 'react-icons/gr';
import { CgAdd, CgArrowsExchange, CgSearchLoading } from 'react-icons/cg';
import { SiXdadevelopers } from 'react-icons/si';

import routesConfig from '~/config/routes';

import styles from './Home.module.scss';
import Button from '~/components/Button';
import Menu from '~/components/Popper/Menu';
import { useSelector } from 'react-redux';
import DetailTask from './components/DetailTask';
import { HighIcon, LowIcon, MediumIcon } from '~/components/Icons';
import Panigation from './components/Panigation';
import { GlobalStateProject } from '~/ProjectState/projectState';
import ControlTask from './components/ControlTask/ControlTask';
import KanbanBug from './components/KanbanBug/KanbanBug';
import CreateTaskChild from './components/CreateTaskChild/CreateTaskChild';
import { useNavigate } from 'react-router-dom';
import SwitchView from './components/SwitchView';
import Board from '../Board';
import api from '~/utils/main';
import * as updateTaskService from '~/services/updateTaskService';
import * as detailTaskService from '~/services/detailTaskService';
import * as typeTaskService from '~/services/typeTaskService';
import { useEffect } from 'react';
import { Tooltip } from '@mui/material';
import { BiCopy } from 'react-icons/bi';
import CopyToClipboard from 'react-copy-to-clipboard';
import InfiniteScroll from 'react-infinite-scroll-component';
import { BsLink45Deg } from 'react-icons/bs';

// import parseStatus from '../../common/helpers/parseStatus';

const MenuFilter = lazy(() => import('./components/MenuFilter'));
const ListFiltersAndGroup = lazy(() => import('./components/ListFiltersAndGroup'));
const CountStatus = lazy(() => import('./components/CountStatus'));
const Popper = lazy(() => import('./components/MenuControl/Popper'));

const cx = classNames.bind(styles);

const dataFilterStatus = [
    { id: 1, name: 'New' },
    { id: 2, name: 'In Progress', subId: 4 },
    { id: 5, name: 'Resolved' },
    { id: 7, name: 'Testing' },
    { id: 8, name: 'Fix Bug' },
    { id: 9, name: 'Test done' },
    { id: 10, name: 'Pending' },
    { id: 3, name: 'Closed' },
];

const dataFilterDueDate = [
    { id: 0, name: 'Anytime', title: 'DueDate Anytime' },
    { id: 1, name: 'This week', title: 'DueDate This week' },
    { id: 2, name: 'Last week', title: 'DueDate Last week' },
    { id: 3, name: 'Next week', title: 'DueDate Next week' },
    { id: 4, name: 'Today', title: 'DueDate Today' },
];

const dataFilterCloseDate = [
    { id: 0, name: 'Anytime', title: 'CloseDate Anytime' },
    { id: 1, name: 'This week', title: 'CloseDate This week' },
    { id: 2, name: 'Last week', title: 'CloseDate Last week' },
    { id: 3, name: 'Today', title: 'CloseDate Today' },
];

const dataFilterCreatedDate = [
    { id: 0, name: 'Anytime', title: 'CreatedDate Anytime' },
    { id: 1, name: 'This week', title: 'CreatedDate This week' },
    { id: 2, name: 'Last week', title: 'CreatedDate Last week' },
    { id: 3, name: 'Today', title: 'CreatedDate Today' },
];

const dataFilterPriority = [
    { id: 0, name: 'High' },
    { id: 1, name: 'Medium' },
    { id: 2, name: 'Low' },
];

const dataGroupsBy = [
    { id: 1, name: 'Due Date', title: 'dueDate' },
    { id: 2, name: 'Status', title: 'status' },
    { id: 3, name: 'Priority', title: 'priority' },
    { id: 4, name: 'Assigned To', title: 'assignTo' },
    { id: 5, name: 'Project', title: 'project' },
];

const MENU_REPORT_TASK = [
    {
        icon: <MdOutlineNewReleases />,
        title: 'Release',
        id: 9,
    },
    {
        icon: <AiFillBug />,
        title: 'Bug',
        id: 1,
    },
    {
        icon: <CgArrowsExchange />,
        title: 'Change Request',
        id: 12,
    },
    {
        icon: <SiXdadevelopers />,
        title: 'Development',
        id: 2,
    },
    {
        icon: <GrDocumentText />,
        title: 'Document',
        id: 14,
    },
    {
        icon: <TbTableExport />,
        title: 'Enhancement',
        id: 3,
    },
    {
        icon: <TiLightbulb />,
        title: 'Idea',
        id: 11,
    },
    {
        icon: <GrVmMaintenance />,
        title: 'Maintenance',
        id: 7,
    },
    {
        icon: <AiOutlinePlus />,
        title: 'Others',
        id: 8,
    },
    {
        icon: <TbFileCheck />,
        title: 'Quality Assurance',
        id: 5,
    },
    {
        icon: <VscReport />,
        title: 'Report',
        id: 13,
    },
    {
        icon: <CgSearchLoading />,
        title: 'Research Do',
        id: 4,
    },
    {
        icon: <AiOutlineDeploymentUnit />,
        title: 'Unit Testing',
        id: 6,
    },
    {
        icon: <CgArrowsExchange />,
        title: 'Update',
        id: 10,
    },
];

function Home() {
    const [dataTask, setDataTask] = useState([]);
    const [checkedSwitch, setCheckedSwitch] = useState('list');
    const [status, setStatus] = useState();
    const [taskId, setTaskId] = useState();
    const [assignTo, setAssignTo] = useState('');
    const [statusDetailTask, setStatusDetailTask] = useState('');
    const [isCheckAll, setIsCheckAll] = useState(false);
    const [firstMount, setFirstMount] = useState(false);
    const [priorityTask, setPriorityTask] = useState('');
    const [projectIdTask, setProjectIdTask] = useState('');
    const [idTaskTitleCopy, setIdTaskTitleCopy] = useState('');

    // const [page, setPage] = useState(1);
    const state = useContext(GlobalStateProject);
    const [typeSort, setTypeSort] = state.projectsAPI.typeSort;
    const [isTypeSort, setIsTypeSort] = state.projectsAPI.isTypeSort;
    const [loading, setLoading] = state.projectsAPI.loading;
    const [pageSize, setPageSize] = state.projectsAPI.pageSize;
    const [projectIdMilestone, setProjectIdMilestone] = useState('');
    const [copied, setCopied] = useState(false);
    const [valueCopy, setValueCopy] = useState('');
    //pageApi
    const [open, setOpen] = useState(false);
    const [valuePicker, setValuePicker] = useState(null);
    const [firstOpen, setFirstOpen] = useState(false);
    // const stateMeTask = useContext(GlobalStateTask);
    // const [totalPagesMeTask] = stateMeTask.meTaskAPI.totalPagesMeTask;
    const [callback, setCallback] = state.projectsAPI.callback;
    // const [tasks] = stateMeTask.meTaskAPI.tasks;
    const [projects, setProjects] = state.projectsAPI.projects;
    const [isMyTask, setIsMyTask] = state.projectsAPI.isMyTask;
    const [show, setShow] = state.projectsAPI.show;
    const [totalCount] = state.projectsAPI.totalCount;
    const [groupBy] = state.projectsAPI.groupBy;
    const [countItemProjects, setCountItemProjects] = state.projectsAPI.countItemProjects;
    const [typeGroup, setTypeGroup] = state.projectsAPI.typeGroup;
    const [projectId, setProjectId] = state.projectsAPI.projectId;
    const [isShowMenu, setIsShowMenu] = state.projectsAPI.isShowMenu;

    const token = useSelector((state) => state.token);
    const [hideChild, setHideChild] = useState(false);
    // const [idProjectCreateTaskChild, setIdProjectCreateTaskChild] = useState('');
    const [infoChildTask, setInfoChildTask] = useState({});
    const [usersAssign, setUsersAssign] = useState([]);
    const [openDialogCreateTaskChild, setOpenDialogCreateTaskChild] = useState(false);
    const [countReload, setCountReload] = useState(0);
    const [offsetDetailTask, setOffsetDetailTask] = useState(0);

    //KanbanBug
    const [openKanbanBug, setOpenKanbanBug] = useState(false);
    const [nameProject, setNameProject] = useState();
    const [idTask, setIdTask] = useState('');
    const [idProject, setIdProject] = useState('');
    const [createTaskInfo, setCreateTaskInfo] = useState([]);
    const [widthWindown, setWidthWindown] = useState(0);
    const [kanBanRefId, setKanBanRefId] = useState('');

    const [offset, setOffset] = state.projectsAPI.offset;
    const [hasMore, setHasMore] = state.projectsAPI.hasMore;
    const getProject = state.projectsAPI.getProject;
    const [totalCountComment, setTotalCountComment] = useState(null);
    const [isShowChildTask, setIsShowChildTask] = useState(true);
    const [kanbanInfo, setKanBanInfo] = useState('');
    const [isFilterOrGroup, setIsFilterOrGroup] = useState(false);

    const listFilterAndGroupRef = useRef();

    useEffect(() => {
        const countItemProjects = () => {
            let count = 0;

            projects.map((ele, idx) => {
                return (count += ele.taskItems.length);
            });
            setCountItemProjects(count);
        };
        countItemProjects();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projects]);

    const fetchData = () => {
        setTimeout(() => {
            setOffset(offset + 20);
        }, 500);
    };

    async function handleClickOpen(id, noToast = false) {
        setOpen(true);
        setTaskId(id);
        const res = await detailTaskService.getDataDetailTask(id, noToast, offsetDetailTask);
        if (res.data.status === 'success') {
            setDataTask(res.data.data);
            setAssignTo({ id: res.data.data.assignId, name: res.data.data.assignName });
            setStatusDetailTask({ id: res.data.data.statusId, title: res.data.data.status });
            setValuePicker(res.data.data.dueDate);
            setPriorityTask(res.data.data.priorityId);
            setProjectIdTask(res.data.data.projectId);
            res.data.data.taskRelations.length > 0 && setTotalCountComment(res.data.data.taskRelations[0].totalCount);
        }
    }

    const openDialogKanbanBug = (nameProject, idTask, projectId, refId, typeId, assignId) => {
        setOpenKanbanBug(true);
        setNameProject(nameProject);
        setIdTask(idTask);
        setIdProject(projectId);
        setKanBanRefId(refId);
        setKanBanInfo({
            typeId,
            assignId,
            refId,
        });
        // getTranckBug(idTask);
        // navigate(routesConfig['tracking-bug']);
    };

    const getIdProjectMilestion = (id) => {
        setProjectIdMilestone(id);
    };

    const getIconTypeTask = (typeId) => {
        const rs = MENU_REPORT_TASK.filter((item) => {
            if (item.id === typeId) {
                return item;
            }
        });
        return rs[0].icon;
    };

    const handleSortUpTask = () => {
        setIsTypeSort('caseNo');
        setTypeSort('ASC');

        if (typeSort === 'ASC' && isTypeSort === 'caseNo') {
            setIsTypeSort('');
            setTypeSort('');
        }
    };

    const handleSortDownTask = () => {
        setIsTypeSort('caseNo');
        setTypeSort('DESC');
        if (typeSort === 'DESC' && isTypeSort === 'caseNo') {
            setIsTypeSort('');
            setTypeSort('');
        }
    };

    const handleSortUpTitle = () => {
        setIsTypeSort('title');
        setTypeSort('ASC');
        if (typeSort === 'ASC' && isTypeSort === 'title') {
            setIsTypeSort('');
            setTypeSort('');
        }
    };

    const handleSortDownTitle = () => {
        setIsTypeSort('title');
        setTypeSort('DESC');
        if (typeSort === 'DESC' && isTypeSort === 'title') {
            setIsTypeSort('');
            setTypeSort('');
        }
    };

    const handleSortUpStatus = () => {
        setIsTypeSort('status');
        setTypeSort('ASC');
        if (typeSort === 'ASC' && isTypeSort === 'status') {
            setIsTypeSort('');
            setTypeSort('');
        }
    };
    const handleSortDownStatus = () => {
        setIsTypeSort('status');
        setTypeSort('DESC');
        if (typeSort === 'DESC' && isTypeSort === 'status') {
            setIsTypeSort('');
            setTypeSort('');
        }
    };
    const handleSortUpAssignedTo = () => {
        setIsTypeSort('assignTo');
        setTypeSort('ASC');
        if (typeSort === 'ASC' && isTypeSort === 'assignTo') {
            setIsTypeSort('');
            setTypeSort('');
        }
    };
    const handleSortDownAssignedTo = () => {
        setIsTypeSort('assignTo');
        setTypeSort('DESC');
        if (typeSort === 'DESC' && isTypeSort === 'assignTo') {
            setIsTypeSort('');
            setTypeSort('');
        }
    };
    const handleSortUpDueDate = () => {
        setIsTypeSort('overdue');
        setTypeSort('ASC');
        if (typeSort === 'ASC' && isTypeSort === 'overdue') {
            setIsTypeSort('');
            setTypeSort('');
        }
    };
    const handleSortDownDueDate = () => {
        setIsTypeSort('overdue');
        setTypeSort('DESC');
        if (typeSort === 'DESC' && isTypeSort === 'overdue') {
            setIsTypeSort('');
            setTypeSort('');
        }
    };
    // const handleSortUpCloseDate = () => {
    //     setIsTypeSort('closedate');
    //     setTypeSort('ASC');
    //     if (typeSort === 'ASC' && isTypeSort === 'closedate') {
    //         setIsTypeSort('');
    //         setTypeSort('');
    //     }
    // };
    // const handleSortDownCloseDate = () => {
    //     setIsTypeSort('closedate');
    //     setTypeSort('DESC');
    //     if (typeSort === 'DESC' && isTypeSort === 'closedate') {
    //         setIsTypeSort('');
    //         setTypeSort('');
    //     }
    // };
    const handleSortUpPriority = () => {
        setIsTypeSort('priority');
        setTypeSort('ASC');
        if (typeSort === 'ASC' && isTypeSort === 'priority') {
            setIsTypeSort('');
            setTypeSort('');
        }
    };
    const handleSortDownPriority = () => {
        setIsTypeSort('priority');
        setTypeSort('DESC');
        if (typeSort === 'DESC' && isTypeSort === 'priority') {
            setIsTypeSort('');
            setTypeSort('');
        }
    };

    const handleSortUpProject = () => {
        setIsTypeSort('project');
        setTypeSort('ASC');
        if (typeSort === 'ASC' && isTypeSort === 'project') {
            setIsTypeSort('');
            setTypeSort('');
        }
    };

    const handleSortDownProject = () => {
        setIsTypeSort('project');
        setTypeSort('DESC');
        if (typeSort === 'DESC' && isTypeSort === 'project') {
            setIsTypeSort('');
            setTypeSort('');
        }
    };

    const updateTask = async (idStatus, idTask) => {
        const res = await updateTaskService.updateTask(idStatus, idTask);

        if (res.status === 204) {
            setCallback(!callback);
        } else {
            // error
        }
    };

    const getTotalPerPagePagination = (totalCount, totalCountMeTask, totalOverDueTask) => {
        switch (show) {
            case 'all':
                return Math.ceil(totalCount / pageSize);
            case 'myTask':
                return Math.ceil(totalCountMeTask / pageSize);
            case 'overDue':
                return Math.ceil(totalOverDueTask / pageSize);
            default:
                break;
        }
    };

    const getTotalCount = (totalCount, totalCountMeTask, totalOverDueTask) => {
        switch (show) {
            case 'all':
                return totalCount;
            case 'myTask':
                return totalCountMeTask;
            case 'overDue':
                return totalOverDueTask;
            default:
                break;
        }
    };

    const checkNoMatch = (projects) => {
        if (projects.length === 0) {
            return (
                <table>
                    <tbody>
                        <tr className={cx('no-match-filter')}>
                            <td className={cx('glo-no-border')} colSpan={7}>
                                There are no results that match your current filter
                            </td>
                        </tr>
                    </tbody>
                </table>
            );
        }
    };

    const handleChangeTypeTask = async (typeId, Idtask) => {
        // const body = {
        //     typeId: typeId,
        // };
        // const res = await toast.promise(api.patch(`task/${Idtask}/change-type-task`, body), {
        //     pending: 'Change type is pending',
        //     success: 'Change type successfully',
        //     error: 'Change type failed',
        // });

        const res = await typeTaskService.updateTypeTask(typeId, Idtask);

        if (res.status === 200) {
            setCallback(!callback);
        }
    };

    const handleCreateTaskChild = (task) => {
        const typeName = MENU_REPORT_TASK.find((ele) => ele.id === task.typeId).title;
        setOpenDialogCreateTaskChild(true);
        setInfoChildTask({ ...task, typeName });
    };

    useEffect(() => {
        if (isShowChildTask === false) {
            let Ids = [];
            projects.map((ele) =>
                ele.taskItems.map((e) => {
                    if (e.taskAdditionalItems.length !== 0) {
                        Ids.push(e.id);
                    }
                }),
            );
            Ids.map((id) => {
                document.getElementById(`child${id}`).style.display = 'none';
                document.getElementById(`childUp${id}`).style.display = 'block';
                document.getElementById(`childDown${id}`).style.display = 'none';
            });
        }
    }, [projects]);

    const handleHideChildUp = (e, id) => {
        document.getElementById(`child${id}`).style.display = 'block';
        document.getElementById(`childUp${id}`).style.display = 'none';
        document.getElementById(`childDown${id}`).style.display = 'block';
    };

    const handleHideChildDown = (e, id) => {
        document.getElementById(`child${id}`).style.display = 'none';
        document.getElementById(`childUp${id}`).style.display = 'block';
        document.getElementById(`childDown${id}`).style.display = 'none';
    };

    const statusBug = (item) => {
        // const totalBug = item.total;
        // const totalBugCopy = totalBug.join(', ');

        switch (item.status) {
            case 'New':
                return (
                    <span className={cx('glo-number-bug-new')}>
                        {item.total}
                        <span style={{ color: 'black' }}></span>
                    </span>
                );
            case 'Fixing':
                return (
                    <span className={cx('glo-number-bug-fix')}>
                        {item.total}
                        <span style={{ color: 'black' }}></span>
                    </span>
                );
            case 'Fix Done':
                return (
                    <span className={cx('glo-number-bug-fix-done')}>
                        {item.total}
                        <span style={{ color: 'black' }}></span>
                    </span>
                );
            case 'Testing':
                return (
                    <span className={cx('glo-number-bug-testing')}>
                        {item.total}
                        <span style={{ color: 'black' }}></span>
                    </span>
                );
            case 'Test Fail':
                return (
                    <span className={cx('glo-number-bug-test-fail')}>
                        {item.total}
                        <span style={{ color: 'black' }}></span>
                    </span>
                );
            case 'Close':
                return <span className={cx('glo-number-bug-close')}>{item.total}</span>;
            default:
                break;
        }
    };
    window.addEventListener('resize', (event) => {
        if (window.innerWidth <= 1000) {
            setWidthWindown(window.innerWidth - 40 + 'px');
        } else {
            setWidthWindown('100%');
        }
    });

    useEffect(() => {
        if (window.innerWidth <= 1000) {
            setWidthWindown(window.innerWidth - 40 + 'px');
        } else {
            setWidthWindown('100%');
        }
    }, []);

    const onCopy = useCallback(() => {
        setCopied(true);
    }, []);

    useEffect(() => {
        let interval;
        let timeout;

        if (copied === true) {
            interval = setInterval(() => {
                setCopied(false);
            }, 3000);

            timeout = setTimeout(() => {
                setIdTaskTitleCopy('');
            }, 3000);
        }

        return () => {
            clearInterval(interval);
            clearTimeout(timeout);
        };
    }, [copied]);

    const handleCopy = (title, id) => {
        setIdTaskTitleCopy(id);
        setValueCopy(title);
    };

    const checkBorderBottom = (listChildTasks, listParentTasks, index, indexParent) => {
        if (listParentTasks.length - 1 === indexParent && listChildTasks.length - 1 === index) {
            return true;
        }
        // if (listChildTasks.length - 1 === index) {
        //     return true;
        // }

        return false;
    };

    const handleCopyPathFile = (pathFile) => {
        navigator.clipboard.writeText(pathFile);
        toast.success(`Copy path file successfully ✔`, {
            position: 'bottom-left',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    return (
        <>
            {checkedSwitch === 'list' ? (
                <div className={cx('wrapper')}>
                    <div className={cx('header-in-list-task')}>
                        <div className={cx('wrapper-menu-status')}>
                            <div className={cx('row', 'glo-row')}>
                                <div className={cx('list-task-switch-view')}>
                                    <SwitchView checkedSwitch={checkedSwitch} setCheckedSwitch={setCheckedSwitch} />
                                </div>
                                <div className={cx('list-task-count-status')}>
                                    <Suspense
                                        fallback={
                                            <ReactLoading type="spin" color="#0052cc" height={'20px'} width={'20px'} />
                                        }
                                    >
                                        <CountStatus />
                                    </Suspense>
                                </div>
                            </div>
                        </div>
                        <div className={cx('wrapper-menu-filter')}>
                            <Suspense
                                fallback={<ReactLoading type="spin" color="#0052cc" height={'20px'} width={'20px'} />}
                            >
                                <MenuFilter
                                    usersAssign={usersAssign}
                                    setUsersAssign={setUsersAssign}
                                    dataFilterStatus={dataFilterStatus}
                                    dataFilterPriority={dataFilterPriority}
                                    dataGroupsBy={dataGroupsBy}
                                    MENU_REPORT_TASK={MENU_REPORT_TASK}
                                    dataFilterDueDate={dataFilterDueDate}
                                    dataFilterCloseDate={dataFilterCloseDate}
                                    dataFilterCreatedDate={dataFilterCreatedDate}
                                    isShowChildTask={isShowChildTask}
                                    setIsShowChildTask={setIsShowChildTask}
                                />
                            </Suspense>
                        </div>
                        <div
                            className={cx('list-info-filter-groupBy')}
                            style={{ width: widthWindown }}
                            ref={listFilterAndGroupRef}
                        >
                            <Suspense
                                fallback={<ReactLoading type="spin" color="#0052cc" height={'20px'} width={'20px'} />}
                            >
                                <ListFiltersAndGroup
                                    dataFilterDueDate={dataFilterDueDate}
                                    usersAssign={usersAssign}
                                    MENU_REPORT_TASK={MENU_REPORT_TASK}
                                    dataFilterStatus={dataFilterStatus}
                                    dataFilterPriority={dataFilterPriority}
                                    dataGroupsBy={dataGroupsBy}
                                    dataFilterCloseDate={dataFilterCloseDate}
                                    dataFilterCreatedDate={dataFilterCreatedDate}
                                    setIsFilterOrGroup={setIsFilterOrGroup}
                                />
                            </Suspense>
                        </div>
                    </div>

                    {/* lldldl333333333333333333333333333333 */}
                    <div className={cx('glo-table-scroll')}>
                        {/* <b><span id="position">{scrollPosition}</span></b> */}
                        <div className={cx('glo-table')}>
                            <div>
                                <div className={cx('content-in-list-task')}>
                                    <div className={cx('row', 'glo-row', 'glo-header-table')}>
                                        <div className={cx('table-content-title', 'glo-type', 'glo-text-weight')}>
                                            {/* Type */}
                                            Project
                                            {isTypeSort !== 'project' && (
                                                <div className={cx('table-content-title__sort')}>
                                                    <TiArrowSortedUp
                                                        className={cx('table-content-title__sort-icon')}
                                                        onClick={handleSortUpProject}
                                                    />
                                                    <TiArrowSortedDown
                                                        className={cx('table-content-title__sort-icon')}
                                                        onClick={handleSortDownProject}
                                                    />
                                                </div>
                                            )}
                                            <div className={cx('table-content-title__sort')}>
                                                {isTypeSort === 'project' && typeSort === 'DESC' && (
                                                    <>
                                                        <TiArrowSortedUp
                                                            className={cx('table-content-title__sort-icon')}
                                                            onClick={handleSortUpProject}
                                                        />
                                                        <TiArrowSortedDown
                                                            className={cx('table-content-title__sort-icon')}
                                                            style={{ color: '#0052cc' }}
                                                            onClick={handleSortDownProject}
                                                        />
                                                    </>
                                                )}
                                                {isTypeSort === 'project' && typeSort === 'ASC' && (
                                                    <>
                                                        <TiArrowSortedUp
                                                            style={{ color: '#0052cc' }}
                                                            className={cx('table-content-title__sort-icon')}
                                                            onClick={handleSortUpProject}
                                                        />
                                                        <TiArrowSortedDown
                                                            className={cx('table-content-title__sort-icon')}
                                                            onClick={handleSortDownProject}
                                                        />
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                        <div
                                            className={cx(
                                                'table-content-title',
                                                'glo-father',
                                                'glo-text-weight',
                                                'hn-content-center',
                                            )}
                                        ></div>
                                        <div
                                            className={cx(
                                                'table-content-title',
                                                'glo-task',
                                                'glo-text-weight',
                                                'hn-content-center',
                                            )}
                                        >
                                            <span className={cx('text-center')}>Task#</span>
                                            {isTypeSort !== 'caseNo' && (
                                                <div className={cx('table-content-title__sort')}>
                                                    <TiArrowSortedUp
                                                        className={cx('table-content-title__sort-icon')}
                                                        onClick={handleSortUpTask}
                                                    />
                                                    <TiArrowSortedDown
                                                        className={cx('table-content-title__sort-icon')}
                                                        onClick={handleSortDownTask}
                                                    />
                                                </div>
                                            )}
                                            <div className={cx('table-content-title__sort')}>
                                                {isTypeSort === 'caseNo' && typeSort === 'DESC' && (
                                                    <>
                                                        <TiArrowSortedUp
                                                            className={cx('table-content-title__sort-icon')}
                                                            onClick={handleSortUpTask}
                                                        />
                                                        <TiArrowSortedDown
                                                            className={cx('table-content-title__sort-icon')}
                                                            style={{ color: '#0052cc' }}
                                                            onClick={handleSortDownTask}
                                                        />
                                                    </>
                                                )}
                                                {isTypeSort === 'caseNo' && typeSort === 'ASC' && (
                                                    <>
                                                        <TiArrowSortedUp
                                                            style={{ color: '#0052cc' }}
                                                            className={cx('table-content-title__sort-icon')}
                                                            onClick={handleSortUpTask}
                                                        />
                                                        <TiArrowSortedDown
                                                            className={cx('table-content-title__sort-icon')}
                                                            onClick={handleSortDownTask}
                                                        />
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                        <div
                                            className={cx(
                                                'table-content-title',
                                                'glo-title',
                                                'glo-text-weight',
                                                'hn-content-center',
                                            )}
                                        >
                                            <TbAlignJustified className={cx('table-content-title__icon')} />
                                            Title
                                            {isTypeSort !== 'title' && (
                                                <div className={cx('table-content-title__sort')}>
                                                    <TiArrowSortedUp
                                                        className={cx('table-content-title__sort-icon')}
                                                        onClick={handleSortUpTitle}
                                                    />
                                                    <TiArrowSortedDown
                                                        className={cx('table-content-title__sort-icon')}
                                                        onClick={handleSortDownTitle}
                                                    />
                                                </div>
                                            )}
                                            <div className={cx('table-content-title__sort')}>
                                                {isTypeSort === 'title' && typeSort === 'DESC' && (
                                                    <>
                                                        <TiArrowSortedUp
                                                            className={cx('table-content-title__sort-icon')}
                                                            onClick={handleSortUpTitle}
                                                        />
                                                        <TiArrowSortedDown
                                                            style={{ color: '#0052cc' }}
                                                            className={cx('table-content-title__sort-icon')}
                                                            onClick={handleSortDownTitle}
                                                        />
                                                    </>
                                                )}
                                                {isTypeSort === 'title' && typeSort === 'ASC' && (
                                                    <>
                                                        <TiArrowSortedUp
                                                            style={{ color: '#0052cc' }}
                                                            className={cx('table-content-title__sort-icon')}
                                                            onClick={handleSortUpTitle}
                                                        />
                                                        <TiArrowSortedDown
                                                            className={cx('table-content-title__sort-icon')}
                                                            onClick={handleSortDownTitle}
                                                        />
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                        <div
                                            className={cx(
                                                'table-content-title',
                                                'glo-due-date',
                                                'glo-text-weight',
                                                'hn-content-center',
                                            )}
                                        >
                                            Due Date
                                            {isTypeSort !== 'overdue' && (
                                                <div className={cx('table-content-title__sort')}>
                                                    <TiArrowSortedUp
                                                        className={cx('table-content-title__sort-icon')}
                                                        onClick={handleSortUpDueDate}
                                                    />
                                                    <TiArrowSortedDown
                                                        className={cx('table-content-title__sort-icon')}
                                                        onClick={handleSortDownDueDate}
                                                    />
                                                </div>
                                            )}
                                            <div className={cx('table-content-title__sort')}>
                                                {isTypeSort === 'overdue' && typeSort === 'DESC' && (
                                                    <>
                                                        <TiArrowSortedUp
                                                            className={cx('table-content-title__sort-icon')}
                                                            onClick={handleSortUpDueDate}
                                                        />
                                                        <TiArrowSortedDown
                                                            style={{ color: '#0052cc' }}
                                                            className={cx('table-content-title__sort-icon')}
                                                            onClick={handleSortDownDueDate}
                                                        />
                                                    </>
                                                )}
                                                {isTypeSort === 'overdue' && typeSort === 'ASC' && (
                                                    <>
                                                        <TiArrowSortedUp
                                                            style={{ color: '#0052cc' }}
                                                            className={cx('table-content-title__sort-icon')}
                                                            onClick={handleSortUpDueDate}
                                                        />
                                                        <TiArrowSortedDown
                                                            className={cx('table-content-title__sort-icon')}
                                                            onClick={handleSortDownDueDate}
                                                        />
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                        <div className={cx('table-content-title', 'glo-status', 'glo-text-weight')}>
                                            <div className={cx('glo-center-div')}>
                                                {/* <FiArrowRightCircle className={cx('table-content-title__icon')} /> */}
                                                Status
                                                <div>
                                                    {isTypeSort !== 'status' && (
                                                        <div className={cx('table-content-title__sort')}>
                                                            <TiArrowSortedUp
                                                                className={cx('table-content-title__sort-icon')}
                                                                onClick={handleSortUpStatus}
                                                            />
                                                            <TiArrowSortedDown
                                                                className={cx('table-content-title__sort-icon')}
                                                                onClick={handleSortDownStatus}
                                                            />
                                                        </div>
                                                    )}
                                                    <div className={cx('table-content-title__sort')}>
                                                        {isTypeSort === 'status' && typeSort === 'DESC' && (
                                                            <>
                                                                <TiArrowSortedUp
                                                                    className={cx('table-content-title__sort-icon')}
                                                                    onClick={handleSortUpStatus}
                                                                />
                                                                <TiArrowSortedDown
                                                                    style={{ color: '#0052cc' }}
                                                                    className={cx('table-content-title__sort-icon')}
                                                                    onClick={handleSortDownStatus}
                                                                />
                                                            </>
                                                        )}
                                                        {isTypeSort === 'status' && typeSort === 'ASC' && (
                                                            <>
                                                                <TiArrowSortedUp
                                                                    style={{ color: '#0052cc' }}
                                                                    className={cx('table-content-title__sort-icon')}
                                                                    onClick={handleSortUpStatus}
                                                                />
                                                                <TiArrowSortedDown
                                                                    className={cx('table-content-title__sort-icon')}
                                                                    onClick={handleSortDownStatus}
                                                                />
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className={cx(
                                                'table-content-title',
                                                'glo-assigned',
                                                'glo-text-weight',
                                                'hn-content-center',
                                            )}
                                        >
                                            {/* <FiAtSign className={cx('table-content-title__icon')} /> */}
                                            Assigned to
                                            {isTypeSort !== 'assignTo' && (
                                                <div className={cx('table-content-title__sort')}>
                                                    <TiArrowSortedUp
                                                        className={cx('table-content-title__sort-icon')}
                                                        onClick={handleSortUpAssignedTo}
                                                    />
                                                    <TiArrowSortedDown
                                                        className={cx('table-content-title__sort-icon')}
                                                        onClick={handleSortDownAssignedTo}
                                                    />
                                                </div>
                                            )}
                                            <div className={cx('table-content-title__sort')}>
                                                {isTypeSort === 'assignTo' && typeSort === 'DESC' && (
                                                    <>
                                                        <TiArrowSortedUp
                                                            className={cx('table-content-title__sort-icon')}
                                                            onClick={handleSortUpAssignedTo}
                                                        />
                                                        <TiArrowSortedDown
                                                            style={{ color: '#0052cc' }}
                                                            className={cx('table-content-title__sort-icon')}
                                                            onClick={handleSortDownAssignedTo}
                                                        />
                                                    </>
                                                )}
                                                {isTypeSort === 'assignTo' && typeSort === 'ASC' && (
                                                    <>
                                                        <TiArrowSortedUp
                                                            style={{ color: '#0052cc' }}
                                                            className={cx('table-content-title__sort-icon')}
                                                            onClick={handleSortUpAssignedTo}
                                                        />
                                                        <TiArrowSortedDown
                                                            className={cx('table-content-title__sort-icon')}
                                                            onClick={handleSortDownAssignedTo}
                                                        />
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                        <div
                                            className={cx(
                                                'table-content-title',
                                                'glo-assigned-bug',
                                                'glo-text-weight',
                                                'hn-content-center',
                                            )}
                                        >
                                            {/* <FiAtSign className={cx('table-content-title__icon')} /> */}
                                            Assigned bug
                                        </div>

                                        <div
                                            className={cx(
                                                'table-content-title',
                                                'glo-priority',
                                                'glo-text-weight',
                                                'hn-content-center',
                                            )}
                                        >
                                            Last message
                                            {/* <MdDescription /> */}
                                            {/* <IoIosArrowDropup className={cx('table-content-title__icon')} />
                                            Priority
                                            {isTypeSort !== 'priority' && (
                                                <div className={cx('table-content-title__sort')}>
                                                    <TiArrowSortedUp
                                                        className={cx('table-content-title__sort-icon')}
                                                        onClick={handleSortUpPriority}
                                                    />
                                                    <TiArrowSortedDown
                                                        className={cx('table-content-title__sort-icon')}
                                                        onClick={handleSortDownPriority}
                                                    />
                                                </div>
                                            )}
                                            <div className={cx('table-content-title__sort')}>
                                                {isTypeSort === 'priority' && typeSort === 'DESC' && (
                                                    <>
                                                        <TiArrowSortedUp
                                                            title="sort up"
                                                            className={cx('table-content-title__sort-icon')}
                                                            onClick={handleSortUpPriority}
                                                        />
                                                        <TiArrowSortedDown
                                                            title="sort down"
                                                            style={{ color: '#0052cc' }}
                                                            className={cx('table-content-title__sort-icon')}
                                                            onClick={handleSortDownPriority}
                                                        />
                                                    </>
                                                )}
                                                {isTypeSort === 'priority' && typeSort === 'ASC' && (
                                                    <>
                                                        <TiArrowSortedUp
                                                            title="sort up"
                                                            style={{ color: '#0052cc' }}
                                                            className={cx('table-content-title__sort-icon')}
                                                            onClick={handleSortUpPriority}
                                                        />
                                                        <TiArrowSortedDown
                                                            title="sort down"
                                                            className={cx('table-content-title__sort-icon')}
                                                            onClick={handleSortDownPriority}
                                                        />
                                                    </>
                                                )}
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={cx('content-in-list-task')}>
                                <div
                                    className={cx('glo-body-table')}
                                    id="taskScrollContent"
                                    style={
                                        isFilterOrGroup
                                            ? { height: 'calc(100vh - 237px)' }
                                            : { height: 'calc(100vh - 206px)' }
                                    }
                                >
                                    <div className={cx('table-content')}>
                                        <div className={cx('row', 'glo-row', 'glo-text-weight')}>
                                            {loading === true ? (
                                                <ReactLoading
                                                    type="spin"
                                                    color="#0052cc"
                                                    height={'50px'}
                                                    width={'50px'}
                                                />
                                            ) : (
                                                <InfiniteScroll
                                                    dataLength={projects.length}
                                                    next={fetchData}
                                                    hasMore={countItemProjects >= totalCount ? false : hasMore}
                                                    loader={<h4>Loading...</h4>}
                                                    height={
                                                        isFilterOrGroup ? 'calc(100vh - 237px)' : 'calc(100vh - 206px)'
                                                    }
                                                    style={{ overflowX: 'hidden' }}
                                                    endMessage={
                                                        offset >= 20 && (
                                                            <p style={{ textAlign: 'center' }}>
                                                                <b>Yay! You have seen it all</b>
                                                            </p>
                                                        )
                                                    }
                                                >
                                                    {checkNoMatch(projects) ||
                                                        projects.map((taskData, index) => (
                                                            <Fragment key={index}>
                                                                <div className={cx('glo-padd-none')}>
                                                                    <div
                                                                        className={cx(
                                                                            'table-title-project',
                                                                            'glo-text-weight',
                                                                        )}
                                                                    >
                                                                        <div
                                                                            className={cx('title-project')}
                                                                            colSpan="2"
                                                                        >
                                                                            {projectId.length === 1
                                                                                ? ''
                                                                                : taskData.projectName}{' '}
                                                                            <span className={cx('title-project-date')}>
                                                                                {taskData.projectDate}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    {/* <div>
                                                                        <div
                                                                            className={cx(
                                                                                'title-project',
                                                                                'title-project-date',
                                                                                'glo-text-weight',
                                                                            )}
                                                                            colSpan="2"
                                                                        >
                                                                            {taskData.projectDate}
                                                                        </div>
                                                                    </div> */}
                                                                </div>

                                                                {taskData.taskItems.map((task, indexParent) => (
                                                                    <Fragment key={task.id}>
                                                                        <div
                                                                            className={cx(
                                                                                'row',
                                                                                'glo-row',
                                                                                'glo-border-top',
                                                                                {
                                                                                    'glo-border-bottom':
                                                                                        task.taskAdditionalItems
                                                                                            .length > 0 ||
                                                                                        taskData.taskItems.length ===
                                                                                            1 ||
                                                                                        taskData.taskItems.length -
                                                                                            1 ===
                                                                                            indexParent,
                                                                                },
                                                                                // 'glo-border-bottom',
                                                                                'list-project__item',
                                                                                'glo-margin-bottom',
                                                                                'glo-text-weight',
                                                                                {
                                                                                    'list-project__item-high':
                                                                                        task.priority === 'High'
                                                                                            ? true
                                                                                            : false,
                                                                                    'list-project__item-medium':
                                                                                        task.priority === 'Medium'
                                                                                            ? true
                                                                                            : false,
                                                                                    'list-project__item-low':
                                                                                        task.priority === 'Low'
                                                                                            ? true
                                                                                            : false,
                                                                                },
                                                                                {
                                                                                    'glo-background-status-closed':
                                                                                        task.status === 'Closed'
                                                                                            ? true
                                                                                            : false,
                                                                                },
                                                                            )}
                                                                        >
                                                                            <div
                                                                                className={cx(
                                                                                    'table-content-title',
                                                                                    'glo-type-body',
                                                                                    'list-project__item-select',
                                                                                    'glo-border-right',
                                                                                    'glo-text-color',
                                                                                    'glo-text-weight',
                                                                                )}
                                                                            >
                                                                                <div
                                                                                    className={cx(
                                                                                        'list-project__item-select-wrapper',
                                                                                    )}
                                                                                >
                                                                                    <div
                                                                                        className={cx(
                                                                                            'glo-margin-right',
                                                                                        )}
                                                                                    >
                                                                                        {task.taskAdditionalItems &&
                                                                                        task.taskAdditionalItems
                                                                                            .length !== 0 ? (
                                                                                            <div>
                                                                                                <TiArrowSortedUp
                                                                                                    className={cx(
                                                                                                        'glo-icon-child-up',
                                                                                                    )}
                                                                                                    id={
                                                                                                        'childUp' +
                                                                                                        task.id
                                                                                                    }
                                                                                                    onClick={(e) =>
                                                                                                        handleHideChildUp(
                                                                                                            e,
                                                                                                            task.id,
                                                                                                        )
                                                                                                    }
                                                                                                />
                                                                                                <TiArrowSortedDown
                                                                                                    id={
                                                                                                        'childDown' +
                                                                                                        task.id
                                                                                                    }
                                                                                                    onClick={(e) =>
                                                                                                        handleHideChildDown(
                                                                                                            e,
                                                                                                            task.id,
                                                                                                        )
                                                                                                    }
                                                                                                />
                                                                                            </div>
                                                                                        ) : (
                                                                                            ''
                                                                                        )}
                                                                                    </div>
                                                                                    {/* <div className={cx('glo-margin-right')}>
                                                                                <ControlTask
                                                                                    idTask={task.id}
                                                                                    titleTask={task.title}
                                                                                    projectId={task.projectId}
                                                                                    status={task.statusUpdates}
                                                                                    caseNo={task.caseNo}
                                                                                    setStatus={setStatus}
                                                                                    updateTask={updateTask}
                                                                                    firstMount={firstMount}
                                                                                    setFirstMount={setFirstMount}
                                                                                    userId={task.userId}
                                                                                />
                                                                            </div> */}
                                                                                    <div
                                                                                        className={cx(
                                                                                            'glo-margin-right',
                                                                                        )}
                                                                                    >
                                                                                        <Suspense
                                                                                            fallback={
                                                                                                <ReactLoading
                                                                                                    type="spin"
                                                                                                    color="#0052cc"
                                                                                                    height={'20px'}
                                                                                                    width={'20px'}
                                                                                                />
                                                                                            }
                                                                                        >
                                                                                            <Popper
                                                                                                idTask={task.id}
                                                                                                titleTask={task.title}
                                                                                                projectId={
                                                                                                    task.projectId
                                                                                                }
                                                                                                status={
                                                                                                    task.statusUpdates
                                                                                                }
                                                                                                caseNo={task.caseNo}
                                                                                                setStatus={setStatus}
                                                                                                updateTask={updateTask}
                                                                                                firstMount={firstMount}
                                                                                                setFirstMount={
                                                                                                    setFirstMount
                                                                                                }
                                                                                                userId={task.userId}
                                                                                            >
                                                                                                <button
                                                                                                    className={cx(
                                                                                                        'icon-more',
                                                                                                    )}
                                                                                                >
                                                                                                    <MdToc
                                                                                                        style={{
                                                                                                            fontSize:
                                                                                                                '1.6rem',
                                                                                                        }}
                                                                                                    />
                                                                                                </button>
                                                                                            </Popper>
                                                                                        </Suspense>
                                                                                    </div>

                                                                                    <div
                                                                                        className={cx(
                                                                                            'glo-margin-right',
                                                                                        )}
                                                                                    >
                                                                                        <Menu
                                                                                            items={MENU_REPORT_TASK}
                                                                                            handleChangeTypeTask={
                                                                                                handleChangeTypeTask
                                                                                            }
                                                                                            idTask={task.id}
                                                                                        >
                                                                                            <button
                                                                                                className={cx(
                                                                                                    'icon-more',
                                                                                                )}
                                                                                            >
                                                                                                {getIconTypeTask(
                                                                                                    task.typeId,
                                                                                                )}
                                                                                            </button>
                                                                                        </Menu>
                                                                                    </div>
                                                                                    {(task.refId === 0 ||
                                                                                        task.typeId === 9) && (
                                                                                        <div
                                                                                            className={cx(
                                                                                                'glo-margin-right',
                                                                                            )}
                                                                                        >
                                                                                            <CgAdd
                                                                                                onClick={() =>
                                                                                                    handleCreateTaskChild(
                                                                                                        task,
                                                                                                    )
                                                                                                }
                                                                                            />
                                                                                        </div>
                                                                                    )}

                                                                                    <div
                                                                                        style={{ cursor: 'pointer' }}
                                                                                        onClick={() =>
                                                                                            handleCopyPathFile(
                                                                                                task.pathFile,
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        {task.pathFile ? (
                                                                                            <BsLink45Deg
                                                                                                style={{
                                                                                                    fontSize: '18px',
                                                                                                }}
                                                                                            />
                                                                                        ) : (
                                                                                            ''
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div
                                                                                className={cx(
                                                                                    'table-content-title',
                                                                                    'glo-father',
                                                                                    'glo-border-right',
                                                                                    'glo-text-weight',
                                                                                )}
                                                                            >
                                                                                <div className={cx('text-center')}>
                                                                                    {task.refId === 0 ||
                                                                                    task.typeId === 9 ? (
                                                                                        <AiTwotoneStar
                                                                                            style={{ color: '#ecd500' }}
                                                                                        />
                                                                                    ) : (
                                                                                        ''
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                            <div
                                                                                className={cx(
                                                                                    'table-content-title',
                                                                                    'glo-task',
                                                                                    'glo-border-right',
                                                                                    'glo-text-color',
                                                                                    'glo-text-weight',
                                                                                )}
                                                                            >
                                                                                {task.caseNo}
                                                                            </div>
                                                                            <div
                                                                                className={cx(
                                                                                    'table-content-title',
                                                                                    'glo-title',
                                                                                    'glo-border-right',
                                                                                    'glo-text-color',
                                                                                    'glo-text-weight',
                                                                                )}
                                                                            >
                                                                                <div
                                                                                    className={cx(
                                                                                        'list-project__item-title',
                                                                                    )}
                                                                                >
                                                                                    <div
                                                                                        className={cx(
                                                                                            'list-project__item-title-wrapper',
                                                                                        )}
                                                                                    >
                                                                                        <div
                                                                                            className={cx(
                                                                                                'd-flex',
                                                                                                'wrapper-title-icon',
                                                                                            )}
                                                                                        >
                                                                                            <div
                                                                                                className={cx('d-flex')}
                                                                                            >
                                                                                                <Tooltip
                                                                                                    title={
                                                                                                        <span
                                                                                                            style={{
                                                                                                                fontSize:
                                                                                                                    '1.4rem',
                                                                                                            }}
                                                                                                        >
                                                                                                            {
                                                                                                                task.description
                                                                                                            }
                                                                                                        </span>
                                                                                                    }
                                                                                                    placement="bottom"
                                                                                                >
                                                                                                    <div
                                                                                                        onClick={() =>
                                                                                                            handleClickOpen(
                                                                                                                task.id,
                                                                                                            )
                                                                                                        }
                                                                                                        className={cx(
                                                                                                            'glo-margin-none',
                                                                                                            'glo-text-overflow-title',
                                                                                                            'glo-hover-title',
                                                                                                        )}
                                                                                                    >
                                                                                                        {task.title}
                                                                                                    </div>
                                                                                                </Tooltip>
                                                                                                {task.point !== 0 &&
                                                                                                    task.point !==
                                                                                                        null && (
                                                                                                        <div
                                                                                                            className={cx(
                                                                                                                'point-task',
                                                                                                            )}
                                                                                                        >
                                                                                                            {task.point}
                                                                                                        </div>
                                                                                                    )}
                                                                                                {task.level && (
                                                                                                    <div
                                                                                                        className={cx(
                                                                                                            'level-task',
                                                                                                        )}
                                                                                                    >
                                                                                                        {task.level}
                                                                                                    </div>
                                                                                                )}
                                                                                            </div>
                                                                                            <CopyToClipboard
                                                                                                onCopy={onCopy}
                                                                                                text={task.title}
                                                                                                options={{
                                                                                                    format: 'text/plain',
                                                                                                }}
                                                                                            >
                                                                                                {copied === true &&
                                                                                                idTaskTitleCopy ===
                                                                                                    task.id ? (
                                                                                                    <Tooltip
                                                                                                        open={true}
                                                                                                        title={
                                                                                                            <span
                                                                                                                style={{
                                                                                                                    fontSize:
                                                                                                                        '1.4rem',
                                                                                                                }}
                                                                                                            >
                                                                                                                Copied
                                                                                                            </span>
                                                                                                        }
                                                                                                        placement="bottom"
                                                                                                    >
                                                                                                        <span>
                                                                                                            <FiCheck
                                                                                                                style={{
                                                                                                                    color: '#2da72d',
                                                                                                                }}
                                                                                                            />
                                                                                                        </span>
                                                                                                    </Tooltip>
                                                                                                ) : (
                                                                                                    <BiCopy
                                                                                                        onClick={() =>
                                                                                                            handleCopy(
                                                                                                                task.title,
                                                                                                                task.id,
                                                                                                            )
                                                                                                        }
                                                                                                    />
                                                                                                )}
                                                                                            </CopyToClipboard>
                                                                                        </div>
                                                                                        <div
                                                                                            className={cx(
                                                                                                'row',
                                                                                                'glo-row',
                                                                                            )}
                                                                                        >
                                                                                            <div
                                                                                                className={cx(
                                                                                                    'glo-icon-title',
                                                                                                )}
                                                                                            >
                                                                                                <RiMessage3Fill
                                                                                                    className={cx(
                                                                                                        'list-project__item-title-icon',
                                                                                                    )}
                                                                                                    title="Comment"
                                                                                                />
                                                                                                <span
                                                                                                    className={cx(
                                                                                                        'list-project__item-title-text',
                                                                                                    )}
                                                                                                >
                                                                                                    ({task.caseCount})
                                                                                                </span>
                                                                                            </div>
                                                                                            <div
                                                                                                className={cx(
                                                                                                    'glo-icon-completed',
                                                                                                )}
                                                                                            >
                                                                                                <div
                                                                                                    className={cx(
                                                                                                        'glo-item-work__chart__background',
                                                                                                    )}
                                                                                                    title="Completed"
                                                                                                >
                                                                                                    <div
                                                                                                        className={cx(
                                                                                                            'glo-icon-completed-color',
                                                                                                        )}
                                                                                                        style={{
                                                                                                            background:
                                                                                                                '#0052cc',
                                                                                                            width:
                                                                                                                `${Math.round(
                                                                                                                    task.completedTask,
                                                                                                                )}` +
                                                                                                                '%',
                                                                                                            height: '7px',
                                                                                                        }}
                                                                                                    ></div>
                                                                                                </div>
                                                                                                <div
                                                                                                    className={cx(
                                                                                                        'glo-icon-completed-value',
                                                                                                    )}
                                                                                                    title="Completed"
                                                                                                >
                                                                                                    <span
                                                                                                        className={cx(
                                                                                                            'fontSize-14',
                                                                                                        )}
                                                                                                    >
                                                                                                        {Math.round(
                                                                                                            task.completedTask,
                                                                                                        )}
                                                                                                        %
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div
                                                                                                className={cx(
                                                                                                    'glo-icon-bug',
                                                                                                    'glo-hover-title',
                                                                                                    'row glo-row-bug',
                                                                                                    'glo-center-bug',
                                                                                                )}
                                                                                                onClick={() =>
                                                                                                    openDialogKanbanBug(
                                                                                                        task.projectName,
                                                                                                        task.id,
                                                                                                        task.projectId,
                                                                                                        task.refId,
                                                                                                        task.typeId,
                                                                                                        task.assignTo,
                                                                                                    )
                                                                                                }
                                                                                            >
                                                                                                <VscDebugAll
                                                                                                    className={cx(
                                                                                                        'glo-button-bug',
                                                                                                    )}
                                                                                                    title="Bug"
                                                                                                />
                                                                                                <p
                                                                                                    className={cx(
                                                                                                        'glo-number-bug',
                                                                                                        'fontSize-14',
                                                                                                    )}
                                                                                                >
                                                                                                    {task.countBug !==
                                                                                                        null && (
                                                                                                        <>
                                                                                                            (
                                                                                                            {task
                                                                                                                .countBug
                                                                                                                .total ? (
                                                                                                                <span>
                                                                                                                    {
                                                                                                                        task
                                                                                                                            .countBug
                                                                                                                            .total
                                                                                                                    }
                                                                                                                    {isMyTask &&
                                                                                                                        ' - '}
                                                                                                                    {!isMyTask &&
                                                                                                                        ': ' +
                                                                                                                            task
                                                                                                                                .countBug
                                                                                                                                ?.totalNoAssign +
                                                                                                                            ' - '}{' '}
                                                                                                                </span>
                                                                                                            ) : (
                                                                                                                0
                                                                                                            )}
                                                                                                            <span
                                                                                                                className={cx(
                                                                                                                    'glo-number-bug-font-size',
                                                                                                                )}
                                                                                                            >
                                                                                                                {task.countBug.totalBugs?.map(
                                                                                                                    (
                                                                                                                        item,
                                                                                                                        indexParent,
                                                                                                                    ) => {
                                                                                                                        return (
                                                                                                                            <span
                                                                                                                                key={
                                                                                                                                    indexParent
                                                                                                                                }
                                                                                                                                className={cx(
                                                                                                                                    'comma-assign-bug',
                                                                                                                                )}
                                                                                                                            >
                                                                                                                                <span>
                                                                                                                                    {statusBug(
                                                                                                                                        item,
                                                                                                                                    )}
                                                                                                                                    <i>
                                                                                                                                        ,
                                                                                                                                        &nbsp;
                                                                                                                                    </i>
                                                                                                                                </span>
                                                                                                                            </span>
                                                                                                                        );
                                                                                                                    },
                                                                                                                )}
                                                                                                            </span>
                                                                                                            )
                                                                                                        </>
                                                                                                    )}
                                                                                                </p>
                                                                                            </div>
                                                                                            <div
                                                                                                className={cx(
                                                                                                    'title-task-parent',
                                                                                                )}
                                                                                            >
                                                                                                {isMyTask && (
                                                                                                    <Tooltip
                                                                                                        title={
                                                                                                            <span
                                                                                                                style={{
                                                                                                                    fontSize:
                                                                                                                        '1.4rem',
                                                                                                                }}
                                                                                                            >
                                                                                                                {
                                                                                                                    task.parentTitle
                                                                                                                }
                                                                                                            </span>
                                                                                                        }
                                                                                                        placement="bottom"
                                                                                                    >
                                                                                                        <span
                                                                                                            className={cx(
                                                                                                                'fontSize-14',
                                                                                                            )}
                                                                                                        >
                                                                                                            {
                                                                                                                task.parentTitle
                                                                                                            }
                                                                                                        </span>
                                                                                                    </Tooltip>
                                                                                                )}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div
                                                                                className={cx(
                                                                                    'table-content-title',
                                                                                    'glo-due-date',
                                                                                    'glo-border-right',
                                                                                    'glo-text-color',
                                                                                    'glo-text-weight',
                                                                                    'hn-content-center-due-date',
                                                                                )}
                                                                            >
                                                                                {task.dueDate && (
                                                                                    <div
                                                                                        className={cx(
                                                                                            'table-content__due-date',
                                                                                        )}
                                                                                        style={{
                                                                                            background: `${task.backgroundDueDate}`,
                                                                                            color: `${task.colorDueDate}`,
                                                                                        }}
                                                                                    >
                                                                                        {task.dueDate}{' '}
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                            <div
                                                                                className={cx(
                                                                                    'table-content-title',
                                                                                    'glo-status',
                                                                                    'glo-border-right',
                                                                                    'glo-center-status',
                                                                                    'glo-text-weight',
                                                                                    'txt-close-due-date',
                                                                                )}
                                                                            >
                                                                                <div
                                                                                    className={cx('glo-center-status')}
                                                                                >
                                                                                    <Button
                                                                                        className={cx(
                                                                                            `list-project__item-status__${task.status
                                                                                                .split(' ')
                                                                                                .join('')}`,
                                                                                        )}
                                                                                        cursorText
                                                                                    >
                                                                                        {task.status}
                                                                                    </Button>
                                                                                    {task.closeDate && (
                                                                                        <span
                                                                                            style={{ fontSize: '12px' }}
                                                                                        >
                                                                                            {task.closeDate}
                                                                                        </span>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                            <div
                                                                                className={cx(
                                                                                    'table-content-title',
                                                                                    'glo-assigned',
                                                                                    'glo-border-right',
                                                                                    'glo-text-color',
                                                                                    'glo-text-weight',
                                                                                )}
                                                                            >
                                                                                <div
                                                                                    className={cx(
                                                                                        'table-content-title__thumbnail',
                                                                                        'glo-width-100',
                                                                                    )}
                                                                                >
                                                                                    <span
                                                                                        className={cx(
                                                                                            'glo-text-overflow',
                                                                                        )}
                                                                                        dangerouslySetInnerHTML={{
                                                                                            __html: task.userAssign,
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div
                                                                                className={cx(
                                                                                    'table-content-title',
                                                                                    'glo-assigned-bug',
                                                                                    'glo-border-right',
                                                                                    'glo-text-color',
                                                                                    'glo-text-weight',
                                                                                    'text-pl-asigneed-bug',
                                                                                    'txt-asigneed-bug',
                                                                                )}
                                                                            >
                                                                                {task.listAssignToOfBug !== null && (
                                                                                    <>
                                                                                        {task.listAssignToOfBug.length >
                                                                                            0 && (
                                                                                            <p
                                                                                                className={cx(
                                                                                                    'text-assign-bug-ellip',
                                                                                                )}
                                                                                            >
                                                                                                {task.listAssignToOfBug.map(
                                                                                                    (assignBug) => {
                                                                                                        const listName =
                                                                                                            task.listAssignToOfBug.map(
                                                                                                                (x) =>
                                                                                                                    x.name,
                                                                                                            );
                                                                                                        const listNameCP =
                                                                                                            listName.join(
                                                                                                                ', ',
                                                                                                            );
                                                                                                        return (
                                                                                                            <span
                                                                                                                className={cx(
                                                                                                                    'tooltip-text-assign-bug',
                                                                                                                )}
                                                                                                                key={
                                                                                                                    assignBug.id
                                                                                                                }
                                                                                                            >
                                                                                                                <Tooltip
                                                                                                                    title={
                                                                                                                        <span
                                                                                                                            style={{
                                                                                                                                fontSize:
                                                                                                                                    '1.4rem',
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            {
                                                                                                                                listNameCP
                                                                                                                            }
                                                                                                                        </span>
                                                                                                                    }
                                                                                                                >
                                                                                                                    <span>
                                                                                                                        {
                                                                                                                            assignBug.shortName
                                                                                                                        }
                                                                                                                        <i>
                                                                                                                            ,
                                                                                                                            &nbsp;
                                                                                                                        </i>
                                                                                                                    </span>
                                                                                                                </Tooltip>
                                                                                                            </span>
                                                                                                        );
                                                                                                    },
                                                                                                )}
                                                                                            </p>
                                                                                        )}
                                                                                    </>
                                                                                )}
                                                                            </div>

                                                                            {/* <div
                                                        className={cx(
                                                            'table-content-title',
                                                            'glo-close-date',
                                                            'glo-border-right',
                                                            'glo-text-color',
                                                            'glo-text-weight',
                                                            'hn-content-center-due-date',
                                                        )}
                                                    >
                                                        <div className={cx('table-content__due-date')}>
                                                            {task.closeDate}
                                                        </div>
                                                    </div> */}

                                                                            <div
                                                                                className={cx(
                                                                                    'table-content-title',
                                                                                    'glo-priority',
                                                                                )}
                                                                            >
                                                                                <div
                                                                                    className={cx(
                                                                                        'list-project__item-priority',
                                                                                        'glo-text-weight',
                                                                                    )}
                                                                                >
                                                                                    {/* {(task.priority === 'High' && (
                                                                                        <HighIcon />
                                                                                    )) ||
                                                                                        (task.priority === 'Medium' && (
                                                                                            <MediumIcon />
                                                                                        )) ||
                                                                                        (task.priority === 'Low' && (
                                                                                            <LowIcon />
                                                                                        ))}
                                                                                    <span>{task.priority}</span> */}

                                                                                    <Tooltip
                                                                                        title={
                                                                                            <div
                                                                                                className={cx(
                                                                                                    'fontSize-14',
                                                                                                    'mr-description-task',
                                                                                                )}
                                                                                                dangerouslySetInnerHTML={{
                                                                                                    __html: task.description,
                                                                                                }}
                                                                                            ></div>
                                                                                        }
                                                                                        placement="bottom"
                                                                                    >
                                                                                        <div
                                                                                            className={cx(
                                                                                                'fontSize-14',
                                                                                                'hn-overflow-description',
                                                                                            )}
                                                                                            style={
                                                                                                isShowMenu
                                                                                                    ? { width: '232px' }
                                                                                                    : {}
                                                                                            }
                                                                                            dangerouslySetInnerHTML={{
                                                                                                __html: task?.description
                                                                                                    ?.replaceAll(
                                                                                                        '<p>',
                                                                                                        '<span>',
                                                                                                    )
                                                                                                    .replaceAll(
                                                                                                        '</p>',
                                                                                                        '</span>',
                                                                                                    )
                                                                                                    .replaceAll(
                                                                                                        '<br>',
                                                                                                        '',
                                                                                                    )
                                                                                                    .replaceAll(
                                                                                                        '</br>',
                                                                                                        '',
                                                                                                    )
                                                                                                    .replaceAll(
                                                                                                        '<br />',
                                                                                                        '',
                                                                                                    )
                                                                                                    .replaceAll(
                                                                                                        '<br/>',
                                                                                                        '',
                                                                                                    )
                                                                                                    .replaceAll(
                                                                                                        '<ol>',
                                                                                                        '',
                                                                                                    )
                                                                                                    .replaceAll(
                                                                                                        '</ol>',
                                                                                                        '',
                                                                                                    )
                                                                                                    .replaceAll(
                                                                                                        '<li>',
                                                                                                        '',
                                                                                                    )
                                                                                                    .replaceAll(
                                                                                                        '</li>',
                                                                                                        '',
                                                                                                    )
                                                                                                    .replaceAll(
                                                                                                        '<ul>',
                                                                                                        '',
                                                                                                    )
                                                                                                    .replaceAll(
                                                                                                        '</ul>',
                                                                                                        '',
                                                                                                    )
                                                                                                    .replaceAll(
                                                                                                        '<p style="text-align:left;">',
                                                                                                        '',
                                                                                                    )
                                                                                                    .replaceAll(
                                                                                                        '<p style="text-align:start;">',
                                                                                                        '',
                                                                                                    )
                                                                                                    .replaceAll(
                                                                                                        '<p style="text-align:end;">',
                                                                                                        '',
                                                                                                    )
                                                                                                    .replaceAll(
                                                                                                        '<li style="text-align:left;">',
                                                                                                        '',
                                                                                                    )
                                                                                                    .replaceAll(
                                                                                                        '<li style="text-align:start;">',
                                                                                                        '',
                                                                                                    )
                                                                                                    .replaceAll(
                                                                                                        '<li style="text-align:end;">',
                                                                                                        '',
                                                                                                    )
                                                                                                    .replaceAll(
                                                                                                        '<p style="margin-left:0px;">',
                                                                                                        '',
                                                                                                    )
                                                                                                    .replaceAll(
                                                                                                        '<p style="margin-right:0px;"">',
                                                                                                        '',
                                                                                                    )
                                                                                                    .replaceAll(
                                                                                                        '<li style="margin-left:0px;">',
                                                                                                        '',
                                                                                                    )
                                                                                                    .replaceAll(
                                                                                                        '<li style="margin-right:0px;"">',
                                                                                                        '',
                                                                                                    ),
                                                                                            }}
                                                                                        ></div>
                                                                                    </Tooltip>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div
                                                                            id={'child' + task.id}
                                                                            className={cx(
                                                                                'glo-padd-none',
                                                                                // 'glo-margin-bottom-child',
                                                                            )}
                                                                        >
                                                                            {task.taskAdditionalItems &&
                                                                            task.taskAdditionalItems.length !== 0 ? (
                                                                                <div className={cx('glo-padd-none')}>
                                                                                    {task.taskAdditionalItems.map(
                                                                                        (taskChild, index) => (
                                                                                            <Fragment
                                                                                                key={taskChild.id}
                                                                                            >
                                                                                                <div
                                                                                                    className={cx(
                                                                                                        'row',
                                                                                                        'glo-row',
                                                                                                        {
                                                                                                            'glo-border-top':
                                                                                                                index !==
                                                                                                                0,
                                                                                                        },
                                                                                                        {
                                                                                                            'glo-border-bottom':
                                                                                                                checkBorderBottom(
                                                                                                                    task.taskAdditionalItems,
                                                                                                                    taskData.taskItems,
                                                                                                                    index,
                                                                                                                    indexParent,
                                                                                                                ),
                                                                                                        },
                                                                                                        // 'glo-border-bottom',
                                                                                                        'list-project__item',
                                                                                                        'glo-margin-bottom',
                                                                                                        'glo-text-weight',
                                                                                                        'glo-margin-left',
                                                                                                        'glo-background-child',
                                                                                                        {
                                                                                                            'list-project__item-high':
                                                                                                                taskChild.priority ===
                                                                                                                'High'
                                                                                                                    ? true
                                                                                                                    : false,
                                                                                                            'list-project__item-medium':
                                                                                                                taskChild.priority ===
                                                                                                                'Medium'
                                                                                                                    ? true
                                                                                                                    : false,
                                                                                                            'list-project__item-low':
                                                                                                                taskChild.priority ===
                                                                                                                'Low'
                                                                                                                    ? true
                                                                                                                    : false,
                                                                                                        },
                                                                                                    )}
                                                                                                >
                                                                                                    <div
                                                                                                        className={cx(
                                                                                                            'table-content-title',
                                                                                                            'glo-type-child',
                                                                                                            'list-project__item-select',
                                                                                                            'glo-border-right',
                                                                                                            'glo-text-color',
                                                                                                            'glo-text-weight',
                                                                                                        )}
                                                                                                    >
                                                                                                        <div
                                                                                                            className={cx(
                                                                                                                'list-project__item-select-wrapper',
                                                                                                            )}
                                                                                                        >
                                                                                                            {/* <div
                                                                                                        className={cx(
                                                                                                            'glo-margin-right',
                                                                                                            'child-icon-padding',
                                                                                                        )}
                                                                                                    >
                                                                                                        <ControlTask
                                                                                                            idTask={
                                                                                                                taskChild.id
                                                                                                            }
                                                                                                            titleTask={
                                                                                                                taskChild.title
                                                                                                            }
                                                                                                            projectId={
                                                                                                                taskChild.projectId
                                                                                                            }
                                                                                                            status={
                                                                                                                taskChild.statusUpdates
                                                                                                            }
                                                                                                            caseNo={
                                                                                                                taskChild.caseNo
                                                                                                            }
                                                                                                            setStatus={
                                                                                                                setStatus
                                                                                                            }
                                                                                                            updateTask={
                                                                                                                updateTask
                                                                                                            }
                                                                                                            firstMount={
                                                                                                                firstMount
                                                                                                            }
                                                                                                            setFirstMount={
                                                                                                                setFirstMount
                                                                                                            }
                                                                                                            userId={
                                                                                                                taskChild.userId
                                                                                                            }
                                                                                                        />
                                                                                                    </div> */}
                                                                                                            <div
                                                                                                                className={cx(
                                                                                                                    'glo-margin-right',
                                                                                                                )}
                                                                                                            >
                                                                                                                <Popper
                                                                                                                    idTask={
                                                                                                                        taskChild.id
                                                                                                                    }
                                                                                                                    titleTask={
                                                                                                                        taskChild.title
                                                                                                                    }
                                                                                                                    projectId={
                                                                                                                        taskChild.projectId
                                                                                                                    }
                                                                                                                    status={
                                                                                                                        taskChild.statusUpdates
                                                                                                                    }
                                                                                                                    caseNo={
                                                                                                                        taskChild.caseNo
                                                                                                                    }
                                                                                                                    setStatus={
                                                                                                                        setStatus
                                                                                                                    }
                                                                                                                    updateTask={
                                                                                                                        updateTask
                                                                                                                    }
                                                                                                                    firstMount={
                                                                                                                        firstMount
                                                                                                                    }
                                                                                                                    setFirstMount={
                                                                                                                        setFirstMount
                                                                                                                    }
                                                                                                                    userId={
                                                                                                                        taskChild.userId
                                                                                                                    }
                                                                                                                >
                                                                                                                    <button
                                                                                                                        className={cx(
                                                                                                                            'icon-more',
                                                                                                                        )}
                                                                                                                    >
                                                                                                                        <MdToc
                                                                                                                            style={{
                                                                                                                                fontSize:
                                                                                                                                    '1.6rem',
                                                                                                                            }}
                                                                                                                        />
                                                                                                                    </button>
                                                                                                                </Popper>
                                                                                                            </div>

                                                                                                            <div
                                                                                                                className={cx(
                                                                                                                    'glo-margin-right',
                                                                                                                )}
                                                                                                            >
                                                                                                                <Menu
                                                                                                                    items={
                                                                                                                        MENU_REPORT_TASK
                                                                                                                    }
                                                                                                                    handleChangeTypeTask={
                                                                                                                        handleChangeTypeTask
                                                                                                                    }
                                                                                                                    idTask={
                                                                                                                        taskChild.id
                                                                                                                    }
                                                                                                                >
                                                                                                                    <button
                                                                                                                        className={cx(
                                                                                                                            'icon-more',
                                                                                                                        )}
                                                                                                                    >
                                                                                                                        {/* {task.typeId} */}
                                                                                                                        {getIconTypeTask(
                                                                                                                            taskChild.typeId,
                                                                                                                        )}
                                                                                                                    </button>
                                                                                                                </Menu>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className={cx(
                                                                                                            'table-content-title',
                                                                                                            'glo-father',
                                                                                                            'glo-border-right',
                                                                                                            'glo-text-weight',
                                                                                                        )}
                                                                                                    >
                                                                                                        <div
                                                                                                            className={cx(
                                                                                                                'text-center',
                                                                                                            )}
                                                                                                        >
                                                                                                            {taskChild.refId ===
                                                                                                                1 ||
                                                                                                            taskChild.typeId ===
                                                                                                                9 ? (
                                                                                                                <AiTwotoneStar
                                                                                                                    style={{
                                                                                                                        color: '#ecd500',
                                                                                                                    }}
                                                                                                                />
                                                                                                            ) : (
                                                                                                                ''
                                                                                                            )}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className={cx(
                                                                                                            'table-content-title',
                                                                                                            'glo-task',
                                                                                                            'glo-border-right',
                                                                                                            'glo-text-color',
                                                                                                            'glo-text-weight',
                                                                                                        )}
                                                                                                    >
                                                                                                        {
                                                                                                            taskChild.caseNo
                                                                                                        }
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className={cx(
                                                                                                            'table-content-title',
                                                                                                            'glo-title',
                                                                                                            'glo-border-right',
                                                                                                            'glo-text-color',
                                                                                                            'glo-text-weight',
                                                                                                        )}
                                                                                                    >
                                                                                                        <div
                                                                                                            className={cx(
                                                                                                                'list-project__item-title',
                                                                                                            )}
                                                                                                        >
                                                                                                            <div
                                                                                                                className={cx(
                                                                                                                    'list-project__item-title-wrapper',
                                                                                                                    'glo-width-100',
                                                                                                                )}
                                                                                                            >
                                                                                                                <div
                                                                                                                    className={cx(
                                                                                                                        'd-flex',
                                                                                                                        'wrapper-title-icon',
                                                                                                                    )}
                                                                                                                >
                                                                                                                    <div
                                                                                                                        className={cx(
                                                                                                                            'd-flex',
                                                                                                                        )}
                                                                                                                    >
                                                                                                                        <Tooltip
                                                                                                                            title={
                                                                                                                                <span
                                                                                                                                    style={{
                                                                                                                                        fontSize:
                                                                                                                                            '1.4rem',
                                                                                                                                    }}
                                                                                                                                >
                                                                                                                                    {
                                                                                                                                        taskChild.description
                                                                                                                                    }
                                                                                                                                </span>
                                                                                                                            }
                                                                                                                            placement="bottom"
                                                                                                                        >
                                                                                                                            <div
                                                                                                                                onClick={() =>
                                                                                                                                    handleClickOpen(
                                                                                                                                        taskChild.id,
                                                                                                                                    )
                                                                                                                                }
                                                                                                                                className={cx(
                                                                                                                                    'glo-margin-none',
                                                                                                                                    'glo-text-overflow-title',
                                                                                                                                    'glo-hover-title',
                                                                                                                                )}
                                                                                                                            >
                                                                                                                                {
                                                                                                                                    taskChild.title
                                                                                                                                }
                                                                                                                            </div>
                                                                                                                        </Tooltip>
                                                                                                                        {taskChild.point !==
                                                                                                                            0 &&
                                                                                                                            taskChild.point !==
                                                                                                                                null && (
                                                                                                                                <div
                                                                                                                                    className={cx(
                                                                                                                                        'point-task',
                                                                                                                                    )}
                                                                                                                                >
                                                                                                                                    {
                                                                                                                                        taskChild.point
                                                                                                                                    }
                                                                                                                                </div>
                                                                                                                            )}
                                                                                                                        {taskChild.level && (
                                                                                                                            <div
                                                                                                                                className={cx(
                                                                                                                                    'level-task',
                                                                                                                                )}
                                                                                                                            >
                                                                                                                                {
                                                                                                                                    taskChild.level
                                                                                                                                }
                                                                                                                            </div>
                                                                                                                        )}
                                                                                                                    </div>
                                                                                                                    <CopyToClipboard
                                                                                                                        onCopy={
                                                                                                                            onCopy
                                                                                                                        }
                                                                                                                        text={
                                                                                                                            taskChild.title
                                                                                                                        }
                                                                                                                        options={{
                                                                                                                            format: 'text/plain',
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        {copied ===
                                                                                                                            true &&
                                                                                                                        idTaskTitleCopy ===
                                                                                                                            taskChild.id ? (
                                                                                                                            <Tooltip
                                                                                                                                open={
                                                                                                                                    true
                                                                                                                                }
                                                                                                                                title={
                                                                                                                                    <span
                                                                                                                                        style={{
                                                                                                                                            fontSize:
                                                                                                                                                '1.6rem',
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        Copied
                                                                                                                                    </span>
                                                                                                                                }
                                                                                                                                placement="bottom"
                                                                                                                            >
                                                                                                                                <span>
                                                                                                                                    <FiCheck
                                                                                                                                        style={{
                                                                                                                                            color: '#2da72d',
                                                                                                                                        }}
                                                                                                                                    />
                                                                                                                                </span>
                                                                                                                            </Tooltip>
                                                                                                                        ) : (
                                                                                                                            <BiCopy
                                                                                                                                onClick={() =>
                                                                                                                                    handleCopy(
                                                                                                                                        taskChild.title,
                                                                                                                                        taskChild.id,
                                                                                                                                    )
                                                                                                                                }
                                                                                                                            />
                                                                                                                        )}
                                                                                                                    </CopyToClipboard>
                                                                                                                </div>
                                                                                                                <div
                                                                                                                    className={cx(
                                                                                                                        'row',
                                                                                                                        'glo-row',
                                                                                                                    )}
                                                                                                                >
                                                                                                                    <div
                                                                                                                        className={cx(
                                                                                                                            'glo-icon-title',
                                                                                                                        )}
                                                                                                                    >
                                                                                                                        <RiMessage3Fill
                                                                                                                            title="Comment"
                                                                                                                            className={cx(
                                                                                                                                'list-project__item-title-icon',
                                                                                                                            )}
                                                                                                                        />

                                                                                                                        <span
                                                                                                                            className={cx(
                                                                                                                                'list-project__item-title-text',
                                                                                                                            )}
                                                                                                                        >
                                                                                                                            (
                                                                                                                            {
                                                                                                                                taskChild.caseCount
                                                                                                                            }

                                                                                                                            )
                                                                                                                        </span>
                                                                                                                    </div>

                                                                                                                    <div
                                                                                                                        className={cx(
                                                                                                                            'glo-icon-completed',
                                                                                                                        )}
                                                                                                                    >
                                                                                                                        <div
                                                                                                                            className={cx(
                                                                                                                                'glo-item-work__chart__background',
                                                                                                                            )}
                                                                                                                            title="Completed"
                                                                                                                        >
                                                                                                                            <div
                                                                                                                                className={cx(
                                                                                                                                    'glo-icon-completed-color',
                                                                                                                                )}
                                                                                                                                style={{
                                                                                                                                    background:
                                                                                                                                        '#0052cc',
                                                                                                                                    width:
                                                                                                                                        `${Math.round(
                                                                                                                                            taskChild.completedTask,
                                                                                                                                        )}` +
                                                                                                                                        '%',
                                                                                                                                    height: '7px',
                                                                                                                                }}
                                                                                                                            ></div>
                                                                                                                        </div>
                                                                                                                        <div
                                                                                                                            className={cx(
                                                                                                                                'glo-icon-completed-value',
                                                                                                                            )}
                                                                                                                            title="Completed"
                                                                                                                        >
                                                                                                                            <span
                                                                                                                                className={cx(
                                                                                                                                    'fontSize-14',
                                                                                                                                )}
                                                                                                                            >
                                                                                                                                {Math.round(
                                                                                                                                    taskChild.completedTask,
                                                                                                                                )}

                                                                                                                                %
                                                                                                                            </span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div
                                                                                                                        className={cx(
                                                                                                                            'glo-icon-bug',
                                                                                                                            'glo-hover-title',
                                                                                                                            'row glo-row-bug',
                                                                                                                            'glo-center-bug',
                                                                                                                        )}
                                                                                                                        onClick={() =>
                                                                                                                            openDialogKanbanBug(
                                                                                                                                taskChild.projectName,
                                                                                                                                taskChild.id,
                                                                                                                                taskChild.projectId,
                                                                                                                                taskChild.refId,
                                                                                                                                taskChild.typeId,
                                                                                                                                taskChild.assignTo,
                                                                                                                            )
                                                                                                                        }
                                                                                                                    >
                                                                                                                        <VscDebugAll
                                                                                                                            className={cx(
                                                                                                                                'glo-button-bug',
                                                                                                                            )}
                                                                                                                            title="Bug"
                                                                                                                        />
                                                                                                                        <p
                                                                                                                            className={cx(
                                                                                                                                'glo-number-bug',
                                                                                                                                'fontSize-14',
                                                                                                                            )}
                                                                                                                        >
                                                                                                                            {taskChild.countBug !==
                                                                                                                                null && (
                                                                                                                                <>
                                                                                                                                    (
                                                                                                                                    {taskChild
                                                                                                                                        .countBug
                                                                                                                                        .total ? (
                                                                                                                                        <span>
                                                                                                                                            {taskChild
                                                                                                                                                .countBug
                                                                                                                                                .total +
                                                                                                                                                ' - '}
                                                                                                                                        </span>
                                                                                                                                    ) : (
                                                                                                                                        0
                                                                                                                                    )}
                                                                                                                                    {taskChild.countBug?.totalBugs.map(
                                                                                                                                        (
                                                                                                                                            item,
                                                                                                                                            index,
                                                                                                                                        ) => {
                                                                                                                                            return (
                                                                                                                                                <span
                                                                                                                                                    key={
                                                                                                                                                        index
                                                                                                                                                    }
                                                                                                                                                    className={cx(
                                                                                                                                                        'comma-assign-bug',
                                                                                                                                                    )}
                                                                                                                                                >
                                                                                                                                                    <span>
                                                                                                                                                        {statusBug(
                                                                                                                                                            item,
                                                                                                                                                        )}
                                                                                                                                                        <i>
                                                                                                                                                            ,
                                                                                                                                                            &nbsp;
                                                                                                                                                        </i>
                                                                                                                                                    </span>
                                                                                                                                                </span>
                                                                                                                                            );
                                                                                                                                        },
                                                                                                                                    )}

                                                                                                                                    )
                                                                                                                                </>
                                                                                                                            )}
                                                                                                                        </p>
                                                                                                                    </div>

                                                                                                                    <div
                                                                                                                        className={cx(
                                                                                                                            'title-task-parent',
                                                                                                                        )}
                                                                                                                    >
                                                                                                                        {isMyTask && (
                                                                                                                            <Tooltip
                                                                                                                                title={
                                                                                                                                    <span
                                                                                                                                        style={{
                                                                                                                                            fontSize:
                                                                                                                                                '1.4rem',
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        {
                                                                                                                                            task.title
                                                                                                                                        }
                                                                                                                                    </span>
                                                                                                                                }
                                                                                                                                placement="bottom"
                                                                                                                            >
                                                                                                                                <span
                                                                                                                                    className={cx(
                                                                                                                                        'fontSize-14',
                                                                                                                                    )}
                                                                                                                                >
                                                                                                                                    {
                                                                                                                                        task.title
                                                                                                                                    }
                                                                                                                                </span>
                                                                                                                            </Tooltip>
                                                                                                                        )}
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className={cx(
                                                                                                            'table-content-title',
                                                                                                            'glo-due-date',
                                                                                                            'glo-border-right',
                                                                                                            'glo-text-color',
                                                                                                            'glo-text-weight',
                                                                                                            'hn-content-center-due-date',
                                                                                                        )}
                                                                                                    >
                                                                                                        {taskChild.dueDate && (
                                                                                                            <div
                                                                                                                className={cx(
                                                                                                                    'table-content__due-date',
                                                                                                                )}
                                                                                                                style={{
                                                                                                                    background: `${taskChild.backgroundDueDate}`,
                                                                                                                    color: `${taskChild.colorDueDate}`,
                                                                                                                }}
                                                                                                            >
                                                                                                                {
                                                                                                                    taskChild.dueDate
                                                                                                                }{' '}
                                                                                                            </div>
                                                                                                        )}
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className={cx(
                                                                                                            'table-content-title',
                                                                                                            'glo-status',
                                                                                                            'glo-border-right',
                                                                                                            'glo-center-status',
                                                                                                            'glo-text-weight',
                                                                                                            'txt-close-due-date',
                                                                                                        )}
                                                                                                    >
                                                                                                        <div
                                                                                                            className={cx(
                                                                                                                'glo-center-status',
                                                                                                            )}
                                                                                                        >
                                                                                                            <Button
                                                                                                                className={cx(
                                                                                                                    `list-project__item-status__${taskChild.status
                                                                                                                        .split(
                                                                                                                            ' ',
                                                                                                                        )
                                                                                                                        .join(
                                                                                                                            '',
                                                                                                                        )}`,
                                                                                                                )}
                                                                                                            >
                                                                                                                {
                                                                                                                    taskChild.status
                                                                                                                }
                                                                                                            </Button>
                                                                                                            {taskChild.closeDate && (
                                                                                                                <span
                                                                                                                    style={{
                                                                                                                        fontSize:
                                                                                                                            '12px',
                                                                                                                    }}
                                                                                                                >
                                                                                                                    {
                                                                                                                        taskChild.closeDate
                                                                                                                    }
                                                                                                                </span>
                                                                                                            )}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className={cx(
                                                                                                            'table-content-title',
                                                                                                            'glo-assigned',
                                                                                                            'glo-border-right',
                                                                                                            'glo-text-color',
                                                                                                            'glo-text-weight',
                                                                                                        )}
                                                                                                    >
                                                                                                        <div
                                                                                                            className={cx(
                                                                                                                'table-content-title__thumbnail',
                                                                                                                'glo-width-100',
                                                                                                            )}
                                                                                                        >
                                                                                                            {/* <img
                                                                                                        src="https://anonyviet.com/wp-content/uploads/2021/08/Anh-vit-cute-1.png"
                                                                                                        alt={
                                                                                                            taskChild.userAssign
                                                                                                        }
                                                                                                    /> */}
                                                                                                            <span
                                                                                                                className={cx(
                                                                                                                    'glo-text-overflow',
                                                                                                                )}
                                                                                                                dangerouslySetInnerHTML={{
                                                                                                                    __html: taskChild.userAssign,
                                                                                                                }}
                                                                                                            />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className={cx(
                                                                                                            'table-content-title',
                                                                                                            'glo-assigned-bug',
                                                                                                            'glo-border-right',
                                                                                                            'glo-text-color',
                                                                                                            'glo-text-weight',
                                                                                                            'text-pl-asigneed-bug',
                                                                                                        )}
                                                                                                    >
                                                                                                        {taskChild
                                                                                                            .listAssignToOfBug
                                                                                                            .length >
                                                                                                            0 && (
                                                                                                            <p
                                                                                                                className={cx(
                                                                                                                    'text-assign-bug-ellip',
                                                                                                                )}
                                                                                                            >
                                                                                                                {taskChild.listAssignToOfBug.map(
                                                                                                                    (
                                                                                                                        assignBug,
                                                                                                                    ) => {
                                                                                                                        const listName =
                                                                                                                            taskChild.listAssignToOfBug.map(
                                                                                                                                (
                                                                                                                                    x,
                                                                                                                                ) =>
                                                                                                                                    x.name,
                                                                                                                            );
                                                                                                                        const listNameCP =
                                                                                                                            listName.join(
                                                                                                                                ', ',
                                                                                                                            );
                                                                                                                        return (
                                                                                                                            <span
                                                                                                                                className={cx(
                                                                                                                                    'tooltip-text-assign-bug',
                                                                                                                                )}
                                                                                                                                key={
                                                                                                                                    assignBug.id
                                                                                                                                }
                                                                                                                            >
                                                                                                                                <Tooltip
                                                                                                                                    title={
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                fontSize:
                                                                                                                                                    '1.4rem',
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            {
                                                                                                                                                listNameCP
                                                                                                                                            }
                                                                                                                                        </span>
                                                                                                                                    }
                                                                                                                                >
                                                                                                                                    <span>
                                                                                                                                        {
                                                                                                                                            assignBug.shortName
                                                                                                                                        }
                                                                                                                                        <i>
                                                                                                                                            ,
                                                                                                                                            &nbsp;
                                                                                                                                        </i>
                                                                                                                                    </span>
                                                                                                                                </Tooltip>
                                                                                                                            </span>
                                                                                                                        );
                                                                                                                    },
                                                                                                                )}
                                                                                                            </p>
                                                                                                        )}
                                                                                                    </div>

                                                                                                    {/* <div
                                                                            className={cx(
                                                                                'table-content-title',
                                                                                'glo-close-date',
                                                                                'glo-border-right',
                                                                                'glo-text-color',
                                                                                'glo-text-weight',
                                                                                'hn-content-center-due-date',
                                                                            )}
                                                                        >
                                                                            <div
                                                                                className={cx(
                                                                                    'table-content__due-date',
                                                                                )}
                                                                            >
                                                                                01/01/2023
                                                                            </div>
                                                                        </div> */}
                                                                                                    <div
                                                                                                        className={cx(
                                                                                                            'table-content-title',
                                                                                                            'glo-priority',
                                                                                                        )}
                                                                                                    >
                                                                                                        <div
                                                                                                            className={cx(
                                                                                                                'list-project__item-priority',
                                                                                                                'glo-text-weight',
                                                                                                            )}
                                                                                                        >
                                                                                                            {/* {(taskChild.priority ===
                                                                                                                'High' && (
                                                                                                                    <HighIcon />
                                                                                                                )) ||
                                                                                                                (taskChild.priority ===
                                                                                                                    'Medium' && (
                                                                                                                        <MediumIcon />
                                                                                                                    )) ||
                                                                                                                (taskChild.priority ===
                                                                                                                    'Low' && (
                                                                                                                        <LowIcon />
                                                                                                                    ))}
                                                                                                            <span>
                                                                                                                {
                                                                                                                    taskChild.priority
                                                                                                                }
                                                                                                            </span> */}
                                                                                                            <Tooltip
                                                                                                                title={
                                                                                                                    <div
                                                                                                                        className={cx(
                                                                                                                            'fontSize-14',
                                                                                                                            'mr-description-task',
                                                                                                                        )}
                                                                                                                        dangerouslySetInnerHTML={{
                                                                                                                            __html: taskChild.description,
                                                                                                                        }}
                                                                                                                    ></div>
                                                                                                                }
                                                                                                                placement="bottom"
                                                                                                            >
                                                                                                                <div
                                                                                                                    className={cx(
                                                                                                                        'fontSize-14',
                                                                                                                        'hn-overflow-description',
                                                                                                                    )}
                                                                                                                    style={
                                                                                                                        isShowMenu
                                                                                                                            ? {
                                                                                                                                  width: '232px',
                                                                                                                              }
                                                                                                                            : {}
                                                                                                                    }
                                                                                                                    dangerouslySetInnerHTML={{
                                                                                                                        __html: taskChild.description
                                                                                                                            ?.replaceAll(
                                                                                                                                '<p>',
                                                                                                                                '<span>',
                                                                                                                            )
                                                                                                                            .replaceAll(
                                                                                                                                '</p>',
                                                                                                                                '</span>',
                                                                                                                            )
                                                                                                                            .replaceAll(
                                                                                                                                '<br>',
                                                                                                                                '',
                                                                                                                            )
                                                                                                                            .replaceAll(
                                                                                                                                '</br>',
                                                                                                                                '',
                                                                                                                            )
                                                                                                                            .replaceAll(
                                                                                                                                '<br />',
                                                                                                                                '',
                                                                                                                            )
                                                                                                                            .replaceAll(
                                                                                                                                '<br/>',
                                                                                                                                '',
                                                                                                                            )
                                                                                                                            .replaceAll(
                                                                                                                                '<ol>',
                                                                                                                                '',
                                                                                                                            )
                                                                                                                            .replaceAll(
                                                                                                                                '</ol>',
                                                                                                                                '',
                                                                                                                            )
                                                                                                                            .replaceAll(
                                                                                                                                '<li>',
                                                                                                                                '',
                                                                                                                            )
                                                                                                                            .replaceAll(
                                                                                                                                '</li>',
                                                                                                                                '',
                                                                                                                            )
                                                                                                                            .replaceAll(
                                                                                                                                '<ul>',
                                                                                                                                '',
                                                                                                                            )
                                                                                                                            .replaceAll(
                                                                                                                                '</ul>',
                                                                                                                                '',
                                                                                                                            )
                                                                                                                            .replaceAll(
                                                                                                                                '<p style="text-align:left;">',
                                                                                                                                '',
                                                                                                                            )
                                                                                                                            .replaceAll(
                                                                                                                                '<p style="text-align:start;">',
                                                                                                                                '',
                                                                                                                            )
                                                                                                                            .replaceAll(
                                                                                                                                '<p style="text-align:end;">',
                                                                                                                                '',
                                                                                                                            )
                                                                                                                            .replaceAll(
                                                                                                                                '<li style="text-align:left;">',
                                                                                                                                '',
                                                                                                                            )
                                                                                                                            .replaceAll(
                                                                                                                                '<li style="text-align:start;">',
                                                                                                                                '',
                                                                                                                            )
                                                                                                                            .replaceAll(
                                                                                                                                '<li style="text-align:end;">',
                                                                                                                                '',
                                                                                                                            )
                                                                                                                            .replaceAll(
                                                                                                                                '<p style="margin-left:0px;">',
                                                                                                                                '',
                                                                                                                            )
                                                                                                                            .replaceAll(
                                                                                                                                '<p style="margin-right:0px;"">',
                                                                                                                                '',
                                                                                                                            )
                                                                                                                            .replaceAll(
                                                                                                                                '<li style="margin-left:0px;">',
                                                                                                                                '',
                                                                                                                            )
                                                                                                                            .replaceAll(
                                                                                                                                '<li style="margin-right:0px;"">',
                                                                                                                                '',
                                                                                                                            ),
                                                                                                                    }}
                                                                                                                ></div>
                                                                                                            </Tooltip>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </Fragment>
                                                                                        ),
                                                                                    )}
                                                                                </div>
                                                                            ) : (
                                                                                ''
                                                                            )}
                                                                        </div>
                                                                    </Fragment>
                                                                ))}
                                                            </Fragment>
                                                        ))}
                                                </InfiniteScroll>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className={cx('glo-panigation-group')}>
                        <div className={cx('glo-panigation', 'hn-panigation-row')}>
                            <div className={cx('d-flex justify-content-end', 'results-panigation', 'hn-pagnition-mr')}>
                                Results: {page * pageSize - pageSize + 1} -
                                {getTotalCount(totalCount, totalCountMeTask, totalOverDueTask) < pageSize * page
                                    ? getTotalCount(totalCount, totalCountMeTask, totalOverDueTask)
                                    : page * pageSize}{' '}
                                of {getTotalCount(totalCount, totalCountMeTask, totalOverDueTask)}
                            </div>
                            <div className={cx('d-flex justify-content-end align-items-center', 'list-panigation')}>
                                <Panigation
                                    itemsPerPage={getTotalPerPagePagination(
                                        totalCount,
                                        totalCountMeTask,
                                        totalOverDueTask,
                                    )}
                                    setPage={setPage}
                                />
                            </div>
                        </div>
                    </div> */}

                    <KanbanBug
                        open={openKanbanBug}
                        setOpen={setOpenKanbanBug}
                        nameProject={nameProject}
                        idTask={idTask}
                        createTaskInfo={createTaskInfo}
                        idProject={idProject}
                        dataTask={dataTask}
                        projects={projects}
                        kanBanRefId={kanBanRefId}
                        kanBanInfo={kanbanInfo}
                        setKanBanInfo={setKanBanInfo}
                        // getTranckBug={getTranckBug}
                    />

                    <CreateTaskChild
                        openDialogCreateTaskChild={openDialogCreateTaskChild}
                        setOpenDialogCreateTaskChild={setOpenDialogCreateTaskChild}
                        infoChildTask={infoChildTask}
                        setInfoChildTask={setInfoChildTask}
                        setCountReload={setCountReload}
                        countReload={countReload}
                    />
                </div>
            ) : (
                <div className={cx('wrapper-kanban-view')}>
                    <div className={cx('switch-view-padding')}>
                        <SwitchView checkedSwitch={checkedSwitch} setCheckedSwitch={setCheckedSwitch} />
                    </div>
                    <Board handleClickOpen={handleClickOpen} />
                </div>
            )}
            <DetailTask
                open={open}
                handleClickOpen={handleClickOpen}
                projectIdTask={projectIdTask}
                dataTask={dataTask}
                setOpen={setOpen}
                taskId={taskId}
                setDataTask={setDataTask}
                assignTo={assignTo}
                setAssignTo={setAssignTo}
                statusDetailTask={statusDetailTask}
                setStatusDetailTask={setStatusDetailTask}
                setValuePicker={setValuePicker}
                valuePicker={valuePicker}
                priorityTask={priorityTask}
                setPriorityTask={setPriorityTask}
                offsetDetailTask={offsetDetailTask}
                setOffsetDetailTask={setOffsetDetailTask}
                totalCountComment={totalCountComment}
            />
        </>
    );
}

export default Home;
