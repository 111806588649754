import classNames from 'classnames/bind';
import Dialog from '@mui/material/Dialog';
import { memo, useRef, useState } from 'react';

import styles from '../FormCreatePathFile/FormCreatePathFile.module.scss';
import { IoMdClose } from 'react-icons/io';
import * as detailTaskService from '~/services/detailTaskService';
import api from '~/utils/main';
const cx = classNames.bind(styles);

function FormUpdatePathFile(props) {
    const {
        taskId,
        openUpdatePathFile,
        setOpenUpdatePathFile,
        setCallbackCreatePathFile,
        callbackCreatePathFile,
        pathFile,
        bugId,
        getDetailTask,
    } = props;

    const [valueInputPathFile, setValueInputPathFile] = useState('');

    const inputRef = useRef();

    const handleClose = () => {
        setOpenUpdatePathFile(false);
        setValueInputPathFile('');
    };

    const handleChangePathFile = (e) => {
        setValueInputPathFile(e.target.value);
    };

    const handleUpdatePathFile = async () => {
        let res;

        if (bugId) {
            const body = {
                pathFile: valueInputPathFile || pathFile,
                bugCardId: bugId,
            };

            res = await api.patch(`checking-bug/app`, body);
        } else {
            res = await detailTaskService.updatePathFile(taskId, valueInputPathFile);
        }

        if (bugId && res.status === 204) {
            getDetailTask(bugId);
        }

        if (res.status === 204) {
            handleClose();
            setCallbackCreatePathFile(!callbackCreatePathFile);
        }
    };

    return (
        <Dialog open={openUpdatePathFile} onClose={handleClose} maxWidth="md">
            <div className={cx('d-flex', 'wrapper-btn-close')}>
                <h2>Update File</h2>
                <button className={cx('btn-icon-item')} onClick={handleClose}>
                    <IoMdClose className={cx('icon-item')} />
                </button>
            </div>
            <div className={cx('wrapper')}>
                <label>Path File:</label>
                <input
                    ref={inputRef}
                    className={cx('input-path-file')}
                    placeholder="Enter path file...."
                    defaultValue={valueInputPathFile || pathFile}
                    onChange={(e) => handleChangePathFile(e)}
                    autoFocus
                />
            </div>

            <div className={cx('d-flex', 'wrapper-btn-create')}>
                <button
                    className={cx('btn-icon-create-item')}
                    disabled={valueInputPathFile === ''}
                    onClick={handleUpdatePathFile}
                >
                    <span>Update</span>
                </button>
            </div>
        </Dialog>
    );
}

export default memo(FormUpdatePathFile);
