import classNames from 'classnames/bind';

import styles from './Status.module.scss';

const cx = classNames.bind(styles);

// const MENU_STATUS = [
//     { id: 1, title: 'NEW' },
//     { id: 2, title: 'OPEN' },
//     { id: 3, title: 'CLOSE' },
//     { id: 4, title: 'START' },
//     { id: 5, title: 'RESOLVE' },
// ];

function Status({ children, setStatusDetailTask, menuStatus }) {
    const handleClick = (name, id) => {
        setStatusDetailTask({ id, title: name });
    };

    return (
        <>
            <button
                className={cx('dropdown-toggle')}
                // key={index}
                id="menu-status"
                data-bs-toggle="dropdown"
                aria-expanded="false"
            >
                <div className={cx('wrapper')}>{children}</div>
            </button>
            <ul className="dropdown-menu" id={cx('dropdown-menu')} aria-labelledby="menu-status">
                {menuStatus?.map((status, index) => (
                    <li
                        key={index}
                        className={cx('status-item', `status-item__${status.name.split(' ').join('')}`)}
                        onClick={() => handleClick(status.name, status.id)}
                    >
                        {status.name}
                    </li>
                ))}
            </ul>
        </>
    );
}

export default Status;
