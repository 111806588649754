import { Droppable } from 'react-beautiful-dnd';
import styled from 'styled-components';
import Button from '~/components/Button';
import Task from './ListItem';
import styles from './Board.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

const DroppableStyles = styled.div`
    padding: 10px;
    border-radius: 6px;
    background: #f4f5f7;
    width: 360px;
    height: 480px
    overflow: hidden;
    margin-bottom: 24px;
`;

const ColumnHeader = styled.div`
    padding: 12px;
    background: #f4f3f3;
`;

const Content = styled.div`
    height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
`;

const TaskList = styled.div`
    padding: 8px;
    transition: background-color 0.2s ease;
    background: #f4f3f3;
    flex-grow: 1;
    min-height: 400px;
    max-height: 600px;
    overflow-y: auto;
    overflow-x: hidden;
`;

const Container = styled.div`
    margin: 8px;
    border: 1px solid lightgrey;
    border-radius: 2px;
    width: 360px;
    min-height: 600px;
    box-shadow: 0 2px 6px 0 hsl(0deg 0% 0% / 20%);
    display: flex;
    flex-direction: column;
`;

function Column({ column, tasks, handleClickOpen }) {
    return (
        <Container>
            <ColumnHeader>
                <Button className={cx(`list-project__item-status__${column.title.split(' ').join('')}`)} radius8px>
                    {column.title} {column.taskIds.length}
                </Button>
            </ColumnHeader>
            <Droppable droppableId={column.id.toString()} type="TASK">
                {(provided, snapshot) => (
                    <TaskList
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={{
                            background: snapshot.isDraggingOver,
                        }}
                    >
                        {tasks.map((task, index) => (
                            <Task key={task.id} task={task} index={index} handleClickOpen={handleClickOpen} />
                        ))}
                        {provided.placeholder}
                    </TaskList>
                )}
            </Droppable>
        </Container>
    );
}

export default Column;
