import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import styles from '../Profile.module.scss';
import imageProfile from '../../../assets/images/user/pexels-pixabay-261777.jpg';
import { IconButton } from '@mui/material';
import { MdCameraAlt } from 'react-icons/md';
import * as userService from '~/services/userService';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import api from '~/utils/main';

const cx = classNames.bind(styles);

function CustomHtmlElement(props) {
    return <div dangerouslySetInnerHTML={{ __html: props.customHtml }} />;
}

// const initialState = {
//     userName: '',
//     shortName: '',
//     email: '',
// };

const EditProfile = () => {
    const [fileImageProfileUser, setFileImageProfileUser] = useState(imageProfile);
    // const [infoUser, setInfoUser] = useState(initialState);

    const [userName, setUserName] = useState('');
    const [shortName, setShortName] = useState('');
    const [email, setEmail] = useState('');

    const [validName, setValidName] = useState(false);
    const [validShortName, setValidShortName] = useState(false);
    // const [validEmail, setValidEmail] = useState(null);

    const auth = useSelector((state) => state.auth);
    const { users } = auth;

    // function isValidEmail(email) {
    //     return /\S+@\S+\.\S+/.test(email);
    // }

    const handleChangeImageProfileUser = (e) => {
        setFileImageProfileUser(URL.createObjectURL(e.target.files[0]));
    };

    const handleChangeUserName = (e) => {
        setValidName(false);
        // setInfoUser({ ...infoUser, userName: value });
        setUserName(e.target.value)
    };

    const handleChangeShortName = (e) => {
        setValidShortName(false);
        // setInfoUser({ ...infoUser, shortName: value });
        setShortName(e.target.value)
    };

    // const handleChangeEmail = (e) => {
    //     if (!isValidEmail(e.target.value)) {
    //         setValidEmail('Email is invalid');
    //     } else {
    //         setValidEmail(null);
    //     }
    //     // setInfoUser({ ...infoUser, email: value });
    //     setEmail(e.target.value);
    // };

    useEffect(() => {
        getInfoUser();
    }, []);

    const getInfoUser = async () => {
        const res = await userService.getInfo(users.id);

        if (res.data.success === true) {
            // setInfoUser(res.data.result);
            setUserName(res.data.result.userName);
            setShortName(res.data.result.shortName);
            setEmail(res.data.result.email);
        }
    };

    const handleUpdateProfile = async (e) => {
        e.preventDefault();

        if (userName === '') {
            setValidName(true)
        }

        if (shortName === '') {
            setValidShortName(true)
        }

        const body = {
            id: users.id,
            userName: userName,
            shortName: shortName,
        }

        if (userName !== '' && shortName !== '') {
            const res = await toast.promise(api.put(`admin/user/`, body), {
                pending: 'Update user is pending',
                success: 'Update user successfully',
                error: 'Update user failed',
            });
        }


    };

    return (
        <form className={cx('form-change-top')}>
            {/* <p className={cx('mt-5', 'mb-5', 'profile-title')}>USER INFORMATION</p> */}
            <div className={cx('row', 'change-password-content')}>
                <div className={cx('mb-4', 'profile-image-user')}>
                    <img src={fileImageProfileUser} alt="" />
                    <div className={cx('profile-icon-file')}>
                        <IconButton color="primary" aria-label="upload picture" component="label">
                            <input hidden accept="image/*" type="file" onChange={handleChangeImageProfileUser} />
                            <MdCameraAlt className={cx('profile-icon-file-upload')} />
                        </IconButton>
                    </div>
                </div>
                <div className="mb-4">
                    <label htmlFor="name" className="form-label">
                        Name
                    </label>
                    {/* <CustomHtmlElement
                        customHtml={`<input value='${userName}' class='form-control form-control-lg' type='text' id='name' onChange={${(e)} => ${handleChangeUserName(e)} />`}
                    /> */}
                    <input
                        type="text"
                        className="form-control form-control-lg"
                        id="name"
                        value={userName}
                        onChange={(e) => handleChangeUserName(e)}
                    />
                </div>
                {validName ? <span className={cx('sp-valid')}>The name must not be blank </span> : null}
                <div className="mb-4">
                    <label htmlFor="shortname" className="form-label">
                        Short Name
                    </label>
                    <input
                        type="text"
                        className="form-control form-control-lg"
                        id="shortname"
                        value={shortName}
                        onChange={(e) => handleChangeShortName(e)}
                    />
                </div>
                {validShortName ? <span className={cx('sp-valid')}>The short name must not be blank </span> : null}
                <div className="mb-4">
                    <label htmlFor="email" className="form-label">
                        Email
                    </label>
                    <input
                        type="email"
                        className="form-control form-control-lg"
                        id="email"
                        value={email}
                        disabled
                    // onChange={(e) => handleChangeEmail(e)}
                    />
                </div>
                {/* {validEmail && <span className={cx('sp-valid')}>{validEmail}</span>} */}
                <div className={cx('mb-4', 'profile-control')}>
                    <button type="submit" className={cx('btn', 'btn-profile-update')} onClick={handleUpdateProfile}>
                        Update
                    </button>
                    {/* <button type="submit" className={cx('btn', 'btn-profile-cancel')}>
                        Cancel
                    </button> */}
                </div>
            </div>
        </form>
    );
};

export default EditProfile;
