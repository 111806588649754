import React, { useState } from 'react';
import classNames from 'classnames/bind';
import styles from './ControlTask.module.scss';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { BsArrowsMove } from 'react-icons/bs';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { GlobalStateProject } from '~/ProjectState/projectState';
import { useContext } from 'react';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import api from '~/utils/main';

const cx = classNames.bind(styles);

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 15,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

function ControlTaskMoveProjectDialog(props) {
    const { openDialogControlTask, setOpenDialogControlTask, idTask, titleTask, projectId } = props;
    const projects = useSelector((state) => state.project);
    const token = useSelector((state) => state.token);
    const [projectIdCurrent, setProjectIdCurrent] = useState('');
    const state = useContext(GlobalStateProject);
    // const [projectId] = state.projectsAPI.projectId;
    const [callback, setCallback] = state.projectsAPI.callback;

    const handleClose = () => {
        setOpenDialogControlTask(false);
    };

    const handleReportMoveProject = async () => {
        const body = {
            id: idTask,
            projectId: projectIdCurrent,
        };
        const res = await toast.promise(api.patch(`task/move-to-project/${idTask}`, body), {
            pending: 'Move to project is pending',
            success: 'Move to project successfully',
            error: 'Report task failed',
        });

        if (res.status === 204) {
            // Swal.fire('Success!', 'Move to project successfully', 'success');
            setCallback(!callback);
            setOpenDialogControlTask(false);
        }
    };

    const handleChangeProject = (value) => {
        setProjectIdCurrent(value);
    };

    return (
        <div className={cx('control-task-dialog')}>
            <form>
                <BootstrapDialog
                    open={openDialogControlTask}
                    onClose={handleClose}
                    aria-labelledby="customized-dialog-title"
                    maxWidth="sm"
                    fullWidth
                >
                    <BootstrapDialogTitle
                        id="customized-dialog-title"
                        onClose={handleClose}
                        className={cx('custom-title-control')}
                    >
                        <BsArrowsMove className={cx('custom-title-control-icon')} /> {titleTask}
                    </BootstrapDialogTitle>
                    <DialogContent className={cx('move-project-content')}>
                        <div className={cx('hn-custom-project')}>
                            <label htmlFor="selectMoveProject" className={cx('lbl-move-project')}>
                                Project
                            </label>
                            <select
                                className={cx('form-select', 'form-select-lg', 'mt-3', 'select-option-move-task')}
                                aria-label=".form-select-lg"
                                id="selectMoveProject"
                                // defaultValue={projectId}
                                onChange={(e) => handleChangeProject(e.target.value)}
                            >
                                {projects.map((project, index) => (
                                    <option
                                        value={project.projectId}
                                        key={index}
                                        selected={project.projectId === projectId}
                                    >
                                        {project.projectName}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </DialogContent>
                    <DialogActions className={cx('control-dialog-action')}>
                        <button onClick={handleClose} className={cx('hn-btn-cancel')} size="medium">
                            Cancel
                        </button>
                        <button
                            type="submit"
                            onClick={handleReportMoveProject}
                            className={cx('hn-btn-submit')}
                            size="medium"
                        >
                            Submit
                        </button>
                    </DialogActions>
                </BootstrapDialog>
            </form>
        </div>
    );
}

export default ControlTaskMoveProjectDialog;
