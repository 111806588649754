import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import imageProfile from '../../../../assets/images/user/pexels-pixabay-261777.jpg';
import { MdCameraAlt } from 'react-icons/md';
import { useSelector } from 'react-redux';
import * as userService from '~/services/userService';
import styles from '../../Admin.module.scss';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import api from '~/utils/main';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

const cx = classNames.bind(styles);

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 15,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

function CustomHtmlElement(props) {
    return <div dangerouslySetInnerHTML={{ __html: props.customHtml }} />;
}

const EditUser = ({ openDialogEditUser,
    setOpenDialogEditUser,
    idUser,
    getListUser,
    projects
}) => {
    const [fileImageProfileUser, setFileImageProfileUser] = useState(imageProfile);
    const [userName, setUserName] = useState('');
    const [shortName, setShortName] = useState('');
    const [email, setEmail] = useState('');
    const [userProjects, setUserProjects] = useState([]);

    const [validName, setValidName] = useState(false);
    const [validShortName, setValidShortName] = useState(false);
    const [userProjectIds, setuserProjectIds] = useState([]);

    // const [validEmail, setValidEmail] = useState(null);

    const auth = useSelector((state) => state.auth);
    const { users } = auth;

    // function isValidEmail(email) {
    //     return /\S+@\S+\.\S+/.test(email);
    // }

    const handleChangeImageProfileUser = (e) => {
        setFileImageProfileUser(URL.createObjectURL(e.target.files[0]));
    };

    const handleChangeUserName = (e) => {
        setValidName(false);
        setUserName(e.target.value);
    };

    const handleChangeShortName = (e) => {
        setValidShortName(false);
        setShortName(e.target.value);
    };

    useEffect(() => {
        idUser !== '' && getInfoUser();
    }, [idUser]);

    const getInfoUser = async () => {
        const res = await userService.getInfo(idUser);
        // console.log(res);
        if (res.data.success === true) {
            // setInfoUser(res.data.result);
            setUserName(res.data.result.userName);
            setShortName(res.data.result.shortName);
            setEmail(res.data.result.email);
            setUserProjects(res.data.result?.userProjects);
            setuserProjectIds(res.data.result?.userProjects?.map((x) => x.id));
        }
    };

    const handleUpdateProfile = async (e) => {
        e.preventDefault();

        if (userName === '') {
            setValidName(true);
        }

        if (shortName === '') {
            setValidShortName(true);
        }

        const body = {
            id: idUser,
            userName: userName,
            shortName: shortName,
            projectids: userProjectIds
        };

        if (userName !== '' && shortName !== '') {
            const res = await toast.promise(api.put(`admin/user/admin`, body), {
                pending: 'Update user is pending',
                success: 'Update user successfully',
                error: 'Update user failed',
            });

            if (res.data.success) {
                setOpenDialogEditUser(false);
                getListUser();
            }
        }
    };

    const handleCloseCreate = () => {
        setOpenDialogEditUser(false);
    };

    useEffect(() => {
        const el = document.getElementById('nameUser');
        if (el) {
            el.addEventListener('change', (e) => setUserName(e.target.value));
        }
    }, [userName]);

    const checkUserInProject = (projectId) => {
        // debugger;
        var check = userProjectIds.includes(projectId)
        if (check) {
            return true
        }
        else return false;
    }

    const handleChange = (e, id) => {
        // e.preventDefault();
        if (userProjectIds.includes(id)) {
            setuserProjectIds(userProjectIds.filter((projectId) => projectId !== id));
        } else {
            setuserProjectIds([...userProjectIds, id]);
        }
    }

    return (
        <div className={cx('header-create-task')}>
            <form>
                <BootstrapDialog
                    open={openDialogEditUser}
                    onClose={handleCloseCreate}
                    maxWidth="sm"
                    fullWidth
                    aria-labelledby="customized-dialog-title"
                >
                    <BootstrapDialogTitle
                        id="customized-dialog-title"
                        onClose={handleCloseCreate}
                        className={cx('custom-title-control')}
                    >
                        <div className={cx('dialog-create-project-title')}>
                            <h2>Update User</h2>
                        </div>
                    </BootstrapDialogTitle>
                    <DialogContent dividers>
                        <div className={cx('wrapper-content')}>
                            <form>
                                <div className={cx('row', 'change-password-content')}>
                                    <div className={cx('mb-4', 'profile-image-user')}>
                                        <img src={fileImageProfileUser} alt="" />
                                        <div className={cx('profile-icon-file')}>
                                            <IconButton color="primary" aria-label="upload picture" component="label">
                                                <input
                                                    hidden
                                                    accept="image/*"
                                                    type="file"
                                                    onChange={handleChangeImageProfileUser}
                                                />
                                                <MdCameraAlt className={cx('profile-icon-file-upload')} />
                                            </IconButton>
                                        </div>
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="nameUser" className="form-label">
                                            Name
                                        </label>
                                        <CustomHtmlElement
                                            customHtml={`<input type='text' class='form-control form-control-lg' id="nameUser" value='${userName}'></input>`}
                                        />
                                        {/* <input
                                            type="text"
                                            className="form-control form-control-lg"
                                            id="name"
                                            value={userName}
                                            onChange={(e) => handleChangeUserName(e)}
                                        /> */}
                                    </div>
                                    {validName ? (
                                        <span className={cx('sp-valid')}>The name must not be blank </span>
                                    ) : null}
                                    <div className="mb-4">
                                        <label htmlFor="shortname" className="form-label">
                                            Short Name
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control form-control-lg"
                                            id="shortname"
                                            value={shortName}
                                            onChange={(e) => handleChangeShortName(e)}
                                        />
                                    </div>
                                    {validShortName ? (
                                        <span className={cx('sp-valid')}>The name must not be blank </span>
                                    ) : null}
                                    <div className="mb-4">
                                        <label htmlFor="email" className="form-label">
                                            Email
                                        </label>
                                        <input
                                            type="email"
                                            className="form-control form-control-lg"
                                            id="email"
                                            value={email}
                                            disabled
                                        />
                                    </div>
                                    {/* {validEmail && <span className={cx('sp-valid')}>{validEmail}</span>} */}
                                    <div className="mb-4">
                                        <label htmlFor="email" className="form-label">
                                            Project
                                        </label>
                                        {(projects.length > 0) ?
                                            projects.map((project, index) => {
                                                return (
                                                    <div>
                                                        <input
                                                            className={cx('glo-cursor-pointer')}
                                                            type="checkbox"
                                                            checked={checkUserInProject(project.id)}
                                                            onChange={(e) => handleChange(e, project.id)}
                                                        />
                                                        <label
                                                            className={cx('lable-format')}
                                                            onClick={(e) => handleChange(e, project.id)}

                                                        >{project?.projectName}</label>
                                                    </div>
                                                )
                                            }
                                            ) : <></>}
                                    </div>

                                </div>
                            </form>
                        </div>
                    </DialogContent>
                    <DialogActions className={cx('create-project-action')}>
                        <div className={cx('mb-4', 'profile-control')}>
                            <button
                                type="text"
                                className={cx('hn-btn-cancel')}
                                onClick={handleCloseCreate}
                                size="medium"
                            >
                                Cancel
                            </button>
                            <button
                                type="submit"
                                className={cx('hn-btn-submit')}
                                onClick={handleUpdateProfile}
                                size="medium"
                            >
                                Update
                            </button>
                        </div>
                    </DialogActions>
                </BootstrapDialog>
            </form>
        </div>
    );
};

export default EditUser;
