import { Draggable } from 'react-beautiful-dnd';
import React, { useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import classNames from 'classnames/bind';
import api from '~/utils/main';

import { HiOutlineClock } from 'react-icons/hi';
import Button from '~/components/Button';
import styles from './KanbanBug.module.scss';
import DetailBugDialog from './DetailBugDialog';
import moment from 'moment';
import Swal from 'sweetalert2';
import DetailTask from '../DetailTask';
const cx = classNames.bind(styles);

const Avatar = styled.img`
    height: 30px;
    width: 30px;
    border: 3px solid white;
    border-radius: 50%;
`;

const CardHeader = styled.div`
    font-weight: 500;
`;

const Author = styled.div`
    display: flex;
    align-items: center;
`;
const CardFooter = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const Container = styled.div`
    padding: 10px;
    border-radius: 6px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    background: white;
    margin: 0 0 8px 0;
    display: grid;
    grid-gap: 20px;
    flex-direction: column;
`;

const convertStatus = (status) => {
    switch (status) {
        case 1:
            status = 'New';
            break;
        case 2:
            status = 'Fixing';
            break;
        case 3:
            status = 'Fix Done';
            break;
        case 4:
            status = 'Testing';
            break;
        case 5:
            status = 'Test Fail';
            break;
        case 6:
            status = 'Close';
            break;
        default:
            status = 0;
            break;
    }
    return status;
};

function Task({ task, index, convertDataKanBan, users, setUsers, listTaskChild, kanBanRefId, setOpen }) {
    const token = useSelector((state) => state.token);
    const [openDetail, setOpenDetail] = useState(false);
    const [detailStatus, setDetailStatus] = useState();
    const [detailId, setDetailId] = useState();
    const [detailTitle, setDetailTitle] = useState();
    const [detailDescription, setDetailDescription] = useState();
    const [detailIdUser, setDetailIdUser] = useState();
    const [detailPicker, setDetailPicker] = useState(null);
    const [detailCompleted, setDetailCompleted] = useState();
    const [detailFile, setDetailFile] = useState();
    const [assingBug, setAssingBug] = useState([]);
    const [idTaskDetail, setIdTaskDetail] = useState();
    const [pathFile, setPathFile] = useState('');
    const [easycaseId, setEasyCaseId] = useState('');
    const [statusId, setStatusId] = useState('');
    const [refIdBug, setRefIdBug] = useState('');

    const handleButtonDelete = async (cardId) => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'Delete bug',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            dangerMode: true,
        }).then((result) => {
            if (result.isConfirmed) {
                toast.promise(
                    api.delete(`${process.env.REACT_APP_BASE_URL}checking-bug/${cardId}`).then(() => {
                        convertDataKanBan();
                    }),
                    {
                        pending: 'delete to bug is pending',
                        success: 'delete to bug successfully',
                        error: 'Report task failed',
                    },
                );
            }
        });
    };

    const getDetailTask = async (idTaskDetail) => {
        const res = await api.get(`checking-bug/${idTaskDetail}`);
        if (res.data.status === 'success') {
            setIdTaskDetail(idTaskDetail);
            setDetailTitle(res.data.data.title);
            setDetailDescription(res.data.data.description);
            setDetailStatus(res.data.data.status);
            setDetailIdUser(res.data.data.assignTo);
            setDetailPicker(res.data.data.dueDate);
            setDetailCompleted(res.data.data.completed);
            setDetailFile(res.data.data.files);
            setAssingBug(res.data.data.referencedBy);
            setPathFile(res.data.data.pathFile);
            setEasyCaseId(res.data.data.easycaseId);
            setStatusId(res.data.data.status);
            setRefIdBug(res.data.data.refId);
        }
    };

    const handleDetailBug = (id) => {
        setOpenDetail(true);
        setDetailId(id);
        getDetailTask(id);
    };

    return (
        <Draggable draggableId={`${task.id}`} index={index}>
            {(provided, snapshot) => {
                return (
                    <>
                        <Container
                            ref={provided.innerRef}
                            // snapshot={snapshot}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{
                                boxShadow: '0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)',
                                display: 'grid',
                                grid: '20px',
                                ...provided.draggableProps.style,
                            }}
                        >
                            <div onClick={() => handleDetailBug(task.id)}>
                                <CardHeader>
                                    <div className={cx('row')}>
                                        <div className={cx('wrapper-title')}>
                                            <div
                                                title={task.title}
                                                className={cx('title', 'custom-title-ellipsis', 'glo-text-ellipsis')}
                                            >
                                                {task.title}
                                            </div>
                                        </div>
                                    </div>
                                </CardHeader>
                                <p
                                    className={cx('description-bug-item', 'glo-text-ellipsis')}
                                    dangerouslySetInnerHTML={{ __html: task.description }}
                                />
                                <CardFooter className={cx('glo-list-bug-margin-top')}>
                                    <Button className={cx('table-content-title__date')} fs1rem>
                                        {task.dueDate ? moment(task.dueDate).format('DD/MM/YYYY') : 'None'}
                                    </Button>
                                    <div className={cx('glo-kanbanBug-completed')}>
                                        <div
                                            className={cx('glo-kanbanBug-completed-background')}
                                            title={task.completed}
                                        >
                                            <div
                                                className={cx('glo-kanbanBug-completed-background-color')}
                                                style={{
                                                    background: '#0052cc',
                                                    width: task.completed + '%',
                                                    height: '5px',
                                                }}
                                            ></div>
                                        </div>
                                    </div>
                                    {/* <Author>
                                        <Avatar
                                            src={`https://anonyviet.com/wp-content/uploads/2021/08/Anh-vit-cute-1.png`}
                                        />
                                    </Author> */}
                                </CardFooter>
                            </div>
                            <div>
                                {task.refChildBugcards?.length > 0 && (
                                    <div>
                                        {task.refChildBugcards.map((assign) => {
                                            return (
                                                <div
                                                    className={cx(
                                                        'row',
                                                        'glo-row',
                                                        'glo-hover',
                                                        'glo-border-task-assign',
                                                    )}
                                                    key={assign.id}
                                                    onClick={() => handleDetailBug(assign.id)}
                                                    title={assign.title}
                                                >
                                                    <div className={cx('col-5', 'title-bug-item-ellip')}>
                                                        <small>{assign.title}</small>
                                                    </div>
                                                    <div className={cx('col-4', 'title-bug-item-ellip')}>
                                                        <small>{assign.assignName}</small>
                                                    </div>
                                                    <div className={cx('col-3', 'status-bug-item')}>
                                                        <small
                                                            className={cx(
                                                                `color_${convertStatus(assign.status)
                                                                    .split(' ')
                                                                    .join('')}`,
                                                            )}
                                                        >
                                                            {convertStatus(assign.status)}
                                                        </small>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                )}
                            </div>

                            <div className={cx('row', 'glo-row', 'text-end')}>
                                <div>
                                    <Button
                                        className={cx('button-delete', 'glo-button-delete-bug-kanban')}
                                        fs1rem
                                        onClick={() => handleButtonDelete(task.id)}
                                    >
                                        Delete
                                    </Button>
                                </div>
                            </div>
                        </Container>
                        <DetailBugDialog
                            setOpen={setOpen}
                            openDetail={openDetail}
                            setOpenDetail={setOpenDetail}
                            idTaskDetail={idTaskDetail}
                            setIdTaskDetail={setIdTaskDetail}
                            users={users}
                            setUsers={setUsers}
                            detailTitle={detailTitle}
                            detailDescription={detailDescription}
                            setDetailStatus={setDetailStatus}
                            detailStatus={detailStatus}
                            detailIdUser={detailIdUser}
                            setDetailIdUser={setDetailIdUser}
                            detailPicker={detailPicker}
                            setDetailPicker={setDetailPicker}
                            detailCompleted={detailCompleted}
                            setDetailCompleted={setDetailCompleted}
                            detailId={detailId}
                            setDetailId={setDetailId}
                            detailFile={detailFile}
                            setDetailFile={setDetailFile}
                            convertDataKanBan={convertDataKanBan}
                            listTaskChild={listTaskChild}
                            setAssingBug={setAssingBug}
                            assingBug={assingBug}
                            getDetailTask={getDetailTask}
                            kanBanRefId={kanBanRefId}
                            pathFile={pathFile}
                            easycaseId={easycaseId}
                            statusId={statusId}
                            refIdBug={refIdBug}
                        />
                    </>
                );
            }}
        </Draggable>
    );
}

export default Task;
