import { Draggable } from 'react-beautiful-dnd';
import styled from 'styled-components';
import styles from './Board.module.scss';
import classNames from 'classnames/bind';
import { AiTwotoneStar } from 'react-icons/ai';
import { HiOutlineClock } from 'react-icons/hi';
import Button from '~/components/Button';
import { HighIcon, LowIcon, MediumIcon } from '~/components/Icons';
import { BiMessageDots } from 'react-icons/bi';

const cx = classNames.bind(styles);

const Container = styled.div`
    padding: 10px;
    border-radius: 6px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    background: white;
    margin: 0 0 8px 0;
    display: grid;
    grid-gap: 20px;
    flex-direction: column;
    &:hover {
        background-color: rgba(9, 30, 66, 0.04);
    }
`;

const CardHeader = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
`;

const CardFooter = styled.div`
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: space-between;
`;

const Author = styled.div`
    display: flex;
    align-items: center;
`;

const Avatar = styled.img`
    height: 30px;
    width: 30px;
    border: 3px solid white;
    border-radius: 50%;
`;

function Task({ index, task, handleClickOpen }) {
    return (
        <Draggable
            draggableId={task.id.toString()}
            index={index}
            // isDragDisabled={task.id === 'task-1'}
        >
            {(provided, snapshot) => (
                <Container
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                    style={{
                        boxShadow: '0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)',
                        display: 'grid',
                        grid: '20px',
                        ...provided.draggableProps.style,
                    }}
                    onClick={() => handleClickOpen(task.id)}
                >
                    <CardHeader>
                        <div className={cx('wrapper-title')}>
                            <div style={{ display: 'flex', color: '#ecd500' }}>
                                {task.refId === 0 && <AiTwotoneStar />}
                            </div>{' '}
                            <div className={cx('title')}>{task.title}</div>
                        </div>
                        {/* <div className={cx('est-hours')}>
                            <HiOutlineClock style={{ color: '#0052cc' }} />{' '}
                            {<span className={cx('est-hours__text')}>{task.estimatedHours}h</span>}
                        </div> */}
                        <div>
                            <BiMessageDots style={{ marginRight: '6px', color: '#a840a8' }} />
                            <span>{task.caseCount}</span>
                        </div>
                    </CardHeader>
                    <CardFooter>
                        {task.dueDate ? (
                            <Button className={cx('table-content-title__date')} fs1rem>
                                {task.dueDate}
                            </Button>
                        ) : (
                            <div className={cx('no-due-date')}>No Due Date</div>
                        )}

                        <Author>
                            <div>
                                {(task.priority === 'High' && <HighIcon />) ||
                                    (task.priority === 'Medium' && <MediumIcon />) ||
                                    (task.priority === 'Low' && <LowIcon />)}
                            </div>
                            {/* <Avatar src={`https://anonyviet.com/wp-content/uploads/2021/08/Anh-vit-cute-1.png`} /> */}
                            {/* <span className={cx(`text-assignTo`)} >{task.userAssign}</span> */}

                            <span
                                className={cx(`text-assignTo`)}
                                dangerouslySetInnerHTML={{ __html: task.userAssign }}
                            />
                        </Author>
                    </CardFooter>
                    <div className={cx('d-flex', 'glo-item-work__chart')}>
                        <div className={cx('glo-item-work__chart__background')}>
                            <div
                                className={cx('glo-item-work__chart__background__color')}
                                style={{
                                    background: '#0052cc',
                                    width: task.completedTask + '%',
                                    height: '4px',
                                }}
                            ></div>
                        </div>
                    </div>
                </Container>
            )}
        </Draggable>
    );
}

export default Task;
