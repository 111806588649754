import axios from 'axios';
import Qs from 'qs';
import jwt_decode from "jwt-decode";

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
    failedQueue.forEach(prom => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    })

    failedQueue = [];
}
function getLocalAccessToken() {
    const accessToken = window.localStorage.getItem('accessToken');
    return accessToken;
}
const instance = axios.create({
    baseURL: window.location.hostname.startsWith('local') ? process.env.REACT_APP_BASE_URL : `${window.location.origin}/api/`,
    paramsSerializer: (params) => Qs.stringify(params, { arrayFormat: 'repeat' }),
    headers: {
        'Content-Type': 'application/json',
    },
});
instance.interceptors.request.use(
    (config) => {
        const token = getLocalAccessToken();
        if (config.url !== "/login" && token && !checkTokenExpired(token)) {
            config.headers['Authorization'] = 'Bearer ' + token;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    },
);
instance.interceptors.response.use(
    (res) => {
        return res;
    },
    async (error) => {
        const originalRequest = error.config;

        if (error.response.status === 401 && !originalRequest._retry) {

            if (isRefreshing) {
                return new Promise(function (resolve, reject) {
                    failedQueue.push({ resolve, reject })
                }).then(token => {
                    originalRequest.headers['Authorization'] = 'Bearer ' + token;
                    return instance.request(originalRequest);
                }).catch(err => {
                    return Promise.reject(err);
                })
            }

            originalRequest._retry = true;
            isRefreshing = true;

            return new Promise((resolve, reject) => {
                refreshToken()
                    .then((res) => {

                        if (res.status === 201) {
                            localStorage.removeItem('accessToken');
                            localStorage.removeItem('user');
                            window.location.href = '/login';
                        }
                        else {
                            const accessToken = res.data.data;
                            window.localStorage.setItem('accessToken', accessToken);
                            instance.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken;
                            originalRequest.headers['Authorization'] = 'Bearer ' + accessToken;
                            processQueue(null, accessToken);
                            resolve(instance(originalRequest));
                        }
                    })
                    .catch((err) => {
                        processQueue(err, null);
                        reject(err);
                    })
                    .then(() => { isRefreshing = false })
            })
        }

        return Promise.reject(error);
    },
);
function refreshToken() {
    return instance.post('/admin/refresh-token', null, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
    });
}

function checkTokenExpired(token) {
    const expiry = jwt_decode(token).exp < (Date.now() / 1000);
    return expiry;
}


export default instance;
