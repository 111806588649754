import { Draggable } from 'react-beautiful-dnd';
import styled from 'styled-components';
import styles from './Milestone.module.scss';
import classNames from 'classnames/bind';
import { AiTwotoneStar } from 'react-icons/ai';
import { HiOutlineClock } from 'react-icons/hi';
import Button from '~/components/Button';
import { HighIcon, LowIcon, MediumIcon } from '~/components/Icons';
import { BiMessageDots } from 'react-icons/bi';
import { IoIosArrowDropdown } from 'react-icons/io';

import ControlTask from '~/pages/Home/components/ControlTask/ControlTask';
import * as detailTaskService from '~/services/detailTaskService';
import { useState } from 'react';
import DetailTask from '../Home/components/DetailTask';
const cx = classNames.bind(styles);

const Container = styled.div`
    padding: 10px;
    border-radius: 6px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    background: white;
    margin: 0 0 8px 0;
    display: grid;
    grid-gap: 20px;
    flex-direction: column;
    &:hover {
        background-color: rgba(9, 30, 66, 0.04);
    }
`;

const CardHeader = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
`;

const CardFooter = styled.div`
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: space-between;
`;

const Author = styled.div`
    display: flex;
    align-items: center;
`;

const Avatar = styled.img`
    height: 30px;
    width: 30px;
    border: 3px solid white;
    border-radius: 50%;
`;

function Task({ index, task }) {
    const [firstMount, setFirstMount] = useState(false);
    const [open, setOpen] = useState(false);
    const [valuePicker, setValuePicker] = useState(null);
    const [taskId, setTaskId] = useState();
    const [dataTask, setDataTask] = useState([]);
    const [assignTo, setAssignTo] = useState('');
    const [statusDetailTask, setStatusDetailTask] = useState('');
    const [priorityTask, setPriorityTask] = useState('');
    const [projectIdTask, setProjectIdTask] = useState('');

    async function handleClickOpen(id) {
        setOpen(true);
        setTaskId(id);
        const res = await detailTaskService.getDataDetailTask(id);
        if (res.data.status === 'success') {
            setDataTask(res.data.data);
            setAssignTo({ id: res.data.data.assignId, name: res.data.data.assignName });
            setStatusDetailTask({ id: res.data.data.statusId, title: res.data.data.status });
            setValuePicker(res.data.data.dueDate);
            setPriorityTask(res.data.data.priorityId);
            setProjectIdTask(res.data.data.projectId);
        }
    }

    return (
        <>
            <Draggable
                draggableId={task.id.toString()}
                index={index}
                key={task.id}
                // isDragDisabled={task.id === 'task-1'}
            >
                {(provided, snapshot) => (
                    <Container
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                        style={{
                            boxShadow: '0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)',
                            display: 'grid',
                            grid: '20px',
                            ...provided.draggableProps.style,
                        }}
                        onClick={() => handleClickOpen(task.easycases?.id)}
                    >
                        <CardHeader>
                            <div className={cx('wrapper-title')}>
                                <span className={cx('caseNo')}>#{task.easycases?.caseNo}</span>
                                <div className={cx('title')}>
                                    {task.easycases?.refId === 0 && <AiTwotoneStar style={{ color: '#ecd500' }} />}
                                    {task.easycases?.title}
                                </div>
                            </div>

                            <div>
                                <BiMessageDots style={{ marginRight: '6px', color: '#a840a8' }} />
                                <span>{task.easycases?.caseCount}</span>
                            </div>
                        </CardHeader>
                        <CardFooter>
                            <Button
                                className={cx(
                                    `list-project__item-status__${task.easycases?.status.split(' ').join('')}`,
                                )}
                                minWidth72px
                                fs1rem
                            >
                                {task.easycases?.status}
                            </Button>

                            <Author>
                                <div className={cx(`icon-dropdown`)}>
                                    {/* <IoIosArrowDropdown /> */}
                                    <ControlTask
                                        idTask={task.id}
                                        titleTask={task.title}
                                        projectId={task.projectId}
                                        status={task.statusUpdates}
                                        caseNo={task.caseNo}
                                        // setStatus={setStatus}
                                        // updateTask={updateTask}
                                        firstMount={firstMount}
                                        setFirstMount={setFirstMount}
                                        userId={task.userId}
                                    />
                                </div>
                                <div>
                                    {(task.priority === 'High' && <HighIcon />) ||
                                        (task.priority === 'Medium' && <MediumIcon />) ||
                                        (task.priority === 'Low' && <LowIcon />)}
                                </div>
                                <Avatar src={`https://anonyviet.com/wp-content/uploads/2021/08/Anh-vit-cute-1.png`} />
                                <span
                                    className={cx(`text-assignTo`)}
                                    dangerouslySetInnerHTML={{ __html: task.easycases?.userAssign.name }}
                                />
                            </Author>
                        </CardFooter>
                    </Container>
                )}
            </Draggable>
            <DetailTask
                open={open}
                handleClickOpen={handleClickOpen}
                projectIdTask={projectIdTask}
                dataTask={dataTask}
                setOpen={setOpen}
                taskId={taskId}
                setDataTask={setDataTask}
                assignTo={assignTo}
                setAssignTo={setAssignTo}
                statusDetailTask={statusDetailTask}
                setStatusDetailTask={setStatusDetailTask}
                setValuePicker={setValuePicker}
                valuePicker={valuePicker}
                priorityTask={priorityTask}
                setPriorityTask={setPriorityTask}
            />
        </>
    );
}

export default Task;
