import { toast } from 'react-toastify';
import api from '~/utils/main';

export const updateTypeTask = async (typeId, Idtask) => {
    const body = {
        typeId: typeId,
    };

    try {
        const res = await toast.promise(api.patch(`task/${Idtask}/change-type-task`, body), {
            pending: 'Change type is pending',
            success: 'Change type successfully',
            error: 'Change type failed',
        });
        return res;
    } catch (error) {
        console.log(error);
    }
};
