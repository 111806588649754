const initialData = {
    columns: {
        1: {
            id: 1,
            title: 'New',
            taskIds: [],
        },
        2: {
            id: 2,
            title: 'Fixing',
            taskIds: [],
        },
        3: {
            id: 3,
            title: 'Fix Done',
            taskIds: [],
        },
        4: {
            id: 4,
            title: 'Testing',
            taskIds: [],
        },
        5: {
            id: 5,
            title: 'Test Fail',
            taskIds: [],
        },
        6: {
            id: 6,
            title: 'Close',
            taskIds: [],
        },
    },
    columnOrder: [1, 2, 3, 4, 5, 6],
};

export default initialData;
