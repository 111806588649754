import React, { useState } from 'react';
import classNames from 'classnames/bind';
import styles from './Profile.module.scss';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import EditProfile from './component/EditProfile';
import ChangePassword from './component/ChangePassword';
import DayOff from './component/DayOff';

const cx = classNames.bind(styles);

function Profile() {
    const [valueTabProfile, setValueTabProfile] = useState('3');
    const handleChangeTabProfile = (event, newValue) => {
        setValueTabProfile(newValue);
    };

    return (
        <div className={cx('wrapper')}>
            <Box sx={{ width: '100%' }}>
                <TabContext value={valueTabProfile}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleChangeTabProfile} aria-label="lab API tabs example">
                            <Tab label="Edit Profile" value="1" className={cx('profile-tab-text')} />
                            <Tab label="Change Password" value="2" className={cx('profile-tab-text')} />
                            <Tab label="Day Off" value="3" className={cx('profile-tab-text')} />
                        </TabList>
                    </Box>
                    <TabPanel value="1">
                        <EditProfile />
                    </TabPanel>
                    <TabPanel value="2">
                        <ChangePassword />
                    </TabPanel>
                    <TabPanel value="3">
                        <DayOff />
                    </TabPanel>
                </TabContext>
            </Box>
        </div>
    );
}

export default Profile;
