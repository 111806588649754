export const authAction = {
    LOGIN: 'LOGIN',
    LOGOUT: 'LOGOUT',
};

export const dispatchLogin = (user) => {
    return {
        type: authAction.LOGIN,
        payload: user,
    };
};

export const dispatchLogout = () => {
    return {
        type: authAction.LOGOUT,
    };
};
