import classNames from 'classnames/bind';
import { FaGripLines } from 'react-icons/fa';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';

import styles from './Icon.module.scss';

const cx = classNames.bind(styles);

export const MediumIcon = () => {
    return <FaGripLines className={cx('list-project__item-priority__icon-medium')} />;
};

export const LowIcon = () => {
    return <IoIosArrowDown className={cx('list-project__item-priority__icon-low')} />;
};

export const HighIcon = () => {
    return <IoIosArrowUp className={cx('list-project__item-priority__icon-high')} />;
};

export const RightIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" role="presentation">
        <path
            d="M10.294 9.698a.988.988 0 010-1.407 1.01 1.01 0 011.419 0l2.965 2.94a1.09 1.09 0 010 1.548l-2.955 2.93a1.01 1.01 0 01-1.42 0 .988.988 0 010-1.407l2.318-2.297-2.327-2.307z"
            fill="currentColor"
            fillRule="evenodd"
        ></path>
    </svg>
);
