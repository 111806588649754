import routesConfig from '~/config/routes';

// Pages
import Home from '~/pages/Home';
import Login from '~/pages/Login';
import Summary from '~/pages/Summary';
// import Board from '~/pages/Board';
import Profile from '~/pages/Profile';
import CreateTask from '~/pages/CreateTask';
import Milestone from '~/pages/Milestone';
import KanbanBug from '~/pages/Home/components/KanbanBug/KanbanBug';
import NotFound from '~/pages/NotFound';
// import Test from '~/pages/Board/components/Filters/Test';
import Project from '~/pages/Project';
import DetailTask from '~/pages/DetailTask';
import RequirementChecklist from '~/pages/RequirementChecklist';
import CreateRequirementCheckList from '~/pages/RequirementChecklist/component/CreateRequirementCheckList';
import Admin from '~/pages/Admin/Admin';
import DetailBugPage from '~/pages/DetailBug/DetailBugPage';
import ReviewMember from '~/pages/ReviewMember';
import FormQuestion from '~/pages/ReviewMember/component/FormQuestion/FormQuestion';
import WeekendReport from '~/pages/WeekendReport';
//  public routes
const publicRoutes = [
    { path: routesConfig.login, component: Login, layout: null },
    { path: routesConfig.notFound, component: NotFound, layout: null },
];

// private routes
const privateRoutes = [
    { path: routesConfig.home, component: Home },
    { path: routesConfig.createTask, component: CreateTask },
    { path: routesConfig.summary, component: Summary },
    // { path: routesConfig.board, component: Board },
    { path: routesConfig.profile, component: Profile },
    { path: routesConfig.user, component: Admin },
    { path: routesConfig.milestone, component: Milestone },
    { path: routesConfig.project, component: Project },
    { path: routesConfig['tracking-bug'], component: KanbanBug },
    { path: routesConfig.detailTask, component: DetailTask },
    { path: routesConfig.detailBug, component: DetailBugPage },
    { path: routesConfig.requirementChecklist, component: RequirementChecklist },
    { path: routesConfig.createRequirementChecklist, component: CreateRequirementCheckList },
    { path: routesConfig.reviewForm, component: ReviewMember },
    { path: routesConfig.questionReview, component: FormQuestion },
    { path: routesConfig.weekendReport, component: WeekendReport },
];

export { publicRoutes, privateRoutes };
