import React, { useEffect, useState, useContext } from 'react';
import { Button, IconButton, Pagination } from '@mui/material';
import classNames from 'classnames/bind';
import styles from '../../Admin.module.scss';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import ReactLoading from 'react-loading';
import api from '~/utils/main';
import { toast } from 'react-toastify';
import CreateUser from './CreateUser';
import EditUser from './EditUser';

const cx = classNames.bind(styles);

const ListUser = () => {
    const [listUser, setListUser] = useState([]);
    const token = useSelector((state) => state.token);
    const [loadingData, setLoadingData] = useState(false);

    const [offset, setOffset] = useState(0);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(12);
    const [totalPage, setTotalPage] = useState(0);

    const [openDialogCreateUser, setOpenDialogCreateUser] = useState(false);
    const [openDialogEditUser, setOpenDialogEditUser] = useState(false);

    const [searchValue, setSearchValue] = useState('');
    const [idUser, setIdUser] = useState('');
    const [totalProject, setTotalProject] = useState([]);

    useEffect(() => {
        getAllProject();
    }, []);

    useEffect(() => {
        getListUser();
    }, [page]);

    const getListUser = async () => {
        setLoadingData(true);
        const listGL = await api.get(`admin/user/${page}/12`).then((response) => {
            setListUser(response.data.result.items);
            setTotalPage(response.data.result.totalPages);
            setLoadingData(false);
        });
    };

    const getAllProject = async () => {
        setLoadingData(true);
        await api.get(`projects/all`).then((response) => {
            console.log(response);
            setTotalProject(response.data.data);
        });
    };

    const handleClickDeleteUser = async (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'User will have Admin Privileges',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            dangerMode: true,
        }).then((result) => {
            if (result.isConfirmed) {
                toast.promise(
                    api.delete(`admin/user/${id}`).then(() => {
                        getListUser();
                    }),
                    {
                        pending: 'Delete user is pending',
                        success: 'Delete user successfully',
                        error: 'Delete user failed',
                    },
                );
            }
        });
    };

    const handleChange = (value) => {
        setOffset((value - 1) * pageSize);
        setPage(value);
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    };

    const handleClickUpdateUser = (idUser) => {
        setIdUser(idUser);
        setOpenDialogEditUser(true);
    };

    const handleCreateUser = () => {
        setOpenDialogCreateUser(true);
    };

    const handleSearchFilterUser = (e) => {
        const searchUser = e.target.value;
        if (!searchValue.startsWith(' ')) {
            // setPage(1);
            setOffset(0);
            setSearchValue(searchUser);
        }

        if (searchUser === '') {
            getListUser();
        }
    };

    useEffect(() => {
        if (searchValue) {
            getSearchUser();
        }
    }, [page, searchValue]);

    const getSearchUser = async (e) => {
        const res = await api.get(`admin/user/1/12?searchTerm=${searchValue}`).then((result) => {
            setListUser(result.data.result.items);
        });

    };

    const getKeyEnterSearchUser = async (e) => {
        if (e.key === 'Enter') {
            getSearchUser();
        }
    };




    return (
        <div className={cx('wrapper-list')}>
            <div className={cx('mt-3', 'mb-3', 'list-user-content')}>
                <div className={cx('list-user-title-top')}>
                    <div>
                        <p className={cx('list-user-title')}>List user</p>
                    </div>
                    <div className={cx('list-user-button-add')}>
                        <button className={cx('btn', 'list-project_item-button-add')} onClick={handleCreateUser}>
                            Create User
                        </button>
                    </div>
                </div>

                <div className={cx('list-user-search')}>
                    <label htmlFor="search-user" className={cx('lbl-search')}>
                        Search:
                    </label>
                    <input
                        id="search-user"
                        type="search"
                        placeholder="Search user..."
                        className={cx('form-control', 'form-control-lg', 'ip-search-user')}
                        value={searchValue}
                        onChange={handleSearchFilterUser}
                        onKeyDown={getKeyEnterSearchUser}
                    />
                </div>
            </div>
            <div className={cx('list-user-table-content')}>
                <div className={cx('list-user-table')}>
                    <div className={cx('list-user_table')}>
                        <div className={cx('list-user_title-table')}>
                            {/* <th className={cx('list-user_title-table-id')}>No</th> */}
                            <div className={cx('list-user_title-table-username')}>User Name</div>
                            <div className={cx('list-user_title-table-shortname')}>Short Name</div>
                            <div className={cx('list-user_title-table-email')}>Email</div>
                            <div className={cx('list-user_title-table-action')}>Action</div>
                        </div>

                        {loadingData === true ? (
                            <ReactLoading type="spin" color="#ccc" height={'50px'} width={'50px'} />
                        ) : (
                            <>
                                {listUser.map((lu, index) => {
                                    return (
                                        <div className={cx('list-user_item-body-tr')} key={index}>
                                            {/* <td className={cx('list-user_title-item-body-id')}>{index + 1}</td> */}
                                            <div className={cx('list-user_title-item-body-username')}>
                                                <span
                                                    dangerouslySetInnerHTML={{
                                                        __html: lu.userName,
                                                    }}
                                                />
                                            </div>
                                            <div className={cx('list-user_title-item-body-shortname')}>
                                                {lu.shortName}
                                            </div>
                                            <div className={cx('list-user_title-item-body-email')}>{lu.email}</div>
                                            <div className={cx('list-user_title-item-body-action')}>
                                                <button
                                                    className={cx('btn-edit-user')}
                                                    onClick={(e) => handleClickUpdateUser(lu.id)}
                                                >
                                                    Update
                                                </button>
                                                <button
                                                    className={cx('btn-delete-user')}
                                                    onClick={(e) => handleClickDeleteUser(lu.id)}
                                                >
                                                    Delete
                                                </button>
                                            </div>
                                        </div>
                                    );
                                })}
                            </>
                        )}
                    </div>
                </div>
                <div className={cx('list-user-pagination')}>
                    <Pagination
                        count={totalPage}
                        variant="outlined"
                        color="primary"
                        page={page}
                        onChange={(e, value) => handleChange(value)}
                    />
                </div>
            </div>

            <CreateUser openDialogCreateUser={openDialogCreateUser} setOpenDialogCreateUser={setOpenDialogCreateUser} />
            <EditUser
                openDialogEditUser={openDialogEditUser}
                setOpenDialogEditUser={setOpenDialogEditUser}
                idUser={idUser}
                getListUser={getListUser}
                projects={totalProject}

            />
        </div>
    );
};

export default ListUser;
