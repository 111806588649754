import { projectAction } from '.././actions';

const initialState = [];

const projectReducer = (state = initialState, action) => {
    switch (action.type) {
        case projectAction.SET_PROJECTS:
            return action.payload;
        default:
            return state;
    }
};

export default projectReducer;
