import { Dialog, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';

export const listDuration = [
    { value: 1, name: 'Sáng' },
    { value: 2, name: 'Chiều' },
    { value: 3, name: 'Cả ngày' },
];

export const typeOff = [
    { value: 1, name: 'Nghỉ phép' },
    { value: 2, name: 'Nghỉ không lương' },
    { value: 3, name: 'Nghỉ theo chế độ' },
];
export const convertDuration = (duration) => {
    var textDuration;
    switch (duration) {
        case 1:
            textDuration = 'Sáng';
            break;
        case 2:
            textDuration = 'Chiều';
            break;
        case 3:
            textDuration = 'Cả ngày';
            break;
        default:
    }
    return textDuration;
};

export const convertTypeOff = (type) => {
    var textType;
    switch (type) {
        case 1:
            textType = 'Nghỉ phép';
            break;
        case 2:
            textType = 'Nghỉ không lương';
            break;
        case 3:
            textType = 'Nghỉ theo chế độ';
            break;
        default:
    }
    return textType;
};

export const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 15,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};
