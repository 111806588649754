import React, { useEffect, useState, useContext } from 'react';
import classNames from 'classnames/bind';
import styles from '../../Admin.module.scss';
import api from '~/utils/main';
import { IoMdCheckmark } from 'react-icons/io';
import axios from 'axios';

const cx = classNames.bind(styles);

const ExportReport = () => {
    const [month, setMonth] = useState('');
    const [year, setYear] = useState(new Date().getFullYear());
    const [isSuccess, setIsSuccess] = useState(false);

    const handleChangeMonth = (e) => {
        if (isSuccess) {
            setIsSuccess(false);
        }
        setMonth(e.target.value);
    };

    const handleChangeYear = (e) => {
        if (isSuccess) {
            setIsSuccess(false);
        }
        setYear(e.target.value);
    };

    const handleExport = async () => {
        const monthFormat = +month < 10 && month.length === 1 ? `${0}${month}` : month;

        const headers = { responseType: 'blob' };
        const res = await api.post(`reports/export/${year}${monthFormat}`, {}, headers);
        const a = document.createElement('a');
        const objectUrl = URL.createObjectURL(
            new Blob([res.data], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
            }),
        );
        a.href = objectUrl;
        a.download = `Report_${year}${monthFormat}.xlsx`;
        a.click();
        URL.revokeObjectURL(objectUrl);
    };

    return (
        <>
            <input
                type="number"
                className={cx('input-datetime')}
                value={month}
                onChange={handleChangeMonth}
                placeholder="Month"
                autoFocus
            />

            <input
                type="number"
                className={cx('input-datetime')}
                value={year}
                onChange={handleChangeYear}
                placeholder="Year"
            />

            <button className={cx('btn-export')} disabled={!month} onClick={handleExport}>
                Export
            </button>

            <span className={cx('msg-export')}>
                {isSuccess && (
                    <span>
                        Export report successfull <IoMdCheckmark />
                    </span>
                )}
            </span>
        </>
    );
};

export default ExportReport;
