import React, { useEffect, useState } from 'react';
import styles from '../../Admin.module.scss';
import imageProfile from '../../../../assets/images/user/pexels-pixabay-261777.jpg';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { MdCameraAlt } from 'react-icons/md';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import api from '~/utils/main';
import classNames from 'classnames/bind';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

const cx = classNames.bind(styles);

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 15,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const initialState = {
    name: '',
    shortName: '',
    userAssignIds: [],
};

const CreateUser = ({ openDialogCreateUser, setOpenDialogCreateUser }) => {
    const token = useSelector((state) => state.token);
    const [fileImageProfileUser, setFileImageProfileUser] = useState(imageProfile);

    const [userName, setUsername] = useState('');
    const [shortName, setShortname] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [data, setData] = useState(null);

    const [valid, setValidity] = useState(true);
    const [compare, setCompare] = useState(true);
    const [validCurrentPw, setValidCurrentPw] = useState(false);
    const [validEmail, setValidEmail] = useState(null);

    function isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
    }

    useEffect(() => {
        const regExp = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
        if (password) {
            setCompare(true);
            if (password.match(regExp)) {
                setValidity(true);
            } else {
                setValidity(false);
            }
        }
    }, [password]);

    function comparePassword() {
        if (password !== confirmPassword) {
            setCompare(false);
        } else {
            setCompare(true);
        }
    }

    const handleChangeImageProfileUser = (e) => {
        setFileImageProfileUser(URL.createObjectURL(e.target.files[0]));
    };

    const handleChangeEmail = (e) => {
        if (!isValidEmail(e.target.value)) {
            setValidEmail('Email is invalid');
        } else {
            setValidEmail(null);
        }
        // setInfoUser({ ...infoUser, email: value });
        setEmail(e.target.value);
    };

    const handleCloseCreate = async (e) => {
        e.preventDefault();
        setOpenDialogCreateUser(false);
        setUsername('');
        setShortname('');
        setEmail('');
        setPassword('');
        setConfirmPassword('');
        setValidCurrentPw(true);
        setValidity(true);
        setCompare(true);
        setValidEmail(null);
    };

    const handleSubmitCreateUser = async (e) => {
        e.preventDefault();
        comparePassword();
        const data = {
            userName,
            shortName,
            email,
            password,
        };

        if (password === confirmPassword) {
            const res = await toast
                .promise(api.post(`admin/user`, data), {
                    pending: 'Create user is pending',
                    success: 'Create user successfully',
                    error: 'Create user failed',
                })
                .then((res) => {
                    if (res.data.success) {
                        setUsername('');
                        setShortname('');
                        setEmail('');
                        setPassword('');
                        setConfirmPassword('');
                    }
                })
                .catch((err) => {
                    setValidCurrentPw(true);
                });
        }
    };

    return (
        <div className={cx('header-create-task')}>
            <form>
                <BootstrapDialog
                    open={openDialogCreateUser}
                    onClose={handleCloseCreate}
                    maxWidth="sm"
                    fullWidth
                    aria-labelledby="customized-dialog-title"
                >
                    <BootstrapDialogTitle
                        id="customized-dialog-title"
                        onClose={handleCloseCreate}
                        className={cx('custom-title-control')}
                    >
                        <div className={cx('dialog-create-project-title')}>
                            <h2>Create User</h2>
                        </div>
                    </BootstrapDialogTitle>
                    <DialogContent dividers>
                        <div className={cx('wrapper-content')}>
                            <form onSubmit={handleSubmitCreateUser}>
                                <div className={cx('row')}>
                                    {/* <p className={cx('mt-5', 'mb-5', 'profile-title-create')}>CREATE USER</p> */}
                                    {/* <div className={cx('mb-4', 'profile-image-user')}>
                            <img src={fileImageProfileUser} alt="" />
                            <div className={cx('profile-icon-file')}>
                                <IconButton color="primary" aria-label="upload picture" component="label">
                                    <input hidden accept="image/*" type="file" onChange={handleChangeImageProfileUser} />
                                    <MdCameraAlt className={cx('profile-icon-file-upload')} />
                                </IconButton>
                            </div>
                        </div> */}
                                    <div className="mb-4">
                                        <label htmlFor="name" className="form-label">
                                            User Name
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control form-control-lg"
                                            id="name"
                                            value={userName}
                                            onChange={(event) => {
                                                setUsername(event.target.value);
                                            }}
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="shortname" className="form-label">
                                            Short Name
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control form-control-lg"
                                            id="shortname"
                                            value={shortName}
                                            onChange={(event) => {
                                                setShortname(event.target.value);
                                            }}
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="email" className="form-label">
                                            Email
                                        </label>
                                        <input
                                            type="email"
                                            className="form-control form-control-lg"
                                            id="email"
                                            value={email}
                                            onChange={(e) => handleChangeEmail(e)}
                                        />
                                    </div>
                                    {validEmail && (
                                        <div className={cx('txt-valid-password')}>
                                            <ul>
                                                <li>{validEmail}</li>
                                            </ul>
                                        </div>
                                    )}
                                    <div className="mb-4">
                                        <label htmlFor="password" className="form-label">
                                            New Password
                                        </label>
                                        <input
                                            type="password"
                                            className="form-control form-control-lg"
                                            id="password"
                                            value={password}
                                            onChange={(event) => {
                                                setPassword(event.target.value);
                                            }}
                                        />
                                    </div>
                                    {!valid ? (
                                        <div className={cx('txt-valid-password')}>
                                            <ul>
                                                <li>Password should be minimum 8 characters in length</li>
                                                <li>
                                                    Password a mix of uppercase, lowercase, digits and special
                                                    characters
                                                </li>
                                            </ul>
                                        </div>
                                    ) : null}
                                    <div className="mb-4">
                                        <label htmlFor="password" className="form-label">
                                            Password Confirmation
                                        </label>
                                        <input
                                            type="password"
                                            className="form-control form-control-lg"
                                            id="confirmstionPassword"
                                            value={confirmPassword}
                                            onChange={(event) => {
                                                setConfirmPassword(event.target.value);
                                            }}
                                        />
                                    </div>
                                    {!compare ? (
                                        <div className={cx('txt-valid-password')}>
                                            <ul>
                                                <li>Passwords do not match</li>
                                            </ul>
                                        </div>
                                    ) : null}
                                </div>
                            </form>
                        </div>
                    </DialogContent>
                    <DialogActions className={cx('create-project-action')}>
                        <div className={cx('mb-4', 'profile-control')}>
                            <button
                                type="text"
                                className={cx('hn-btn-cancel')}
                                onClick={handleCloseCreate}
                                size="medium"
                            >
                                Cancel
                            </button>
                            <button
                                type="submit"
                                className={cx('hn-btn-submit')}
                                onClick={handleSubmitCreateUser}
                                size="medium"
                            >
                                Create
                            </button>
                        </div>
                    </DialogActions>
                </BootstrapDialog>
            </form>
        </div>
    );
};

export default CreateUser;
