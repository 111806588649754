import { useState, useEffect } from 'react';
import useDebounce from '~/hooks/useDebounce';
import api from '~/utils/main';

function ProjectsAPI() {
    const [projectId, setProjectId] = useState([]);
    const [projects, setProjects] = useState([]);
    const [callback, setCallback] = useState(false);
    const [dueDateType, setDueDateType] = useState(0);
    const [closeDateType, setCloseDateType] = useState(0);
    const [createDateType, setCreateDateType] = useState(0);
    const [countItemProjects, setCountItemProjects] = useState(0);
    const [stopScroll, setStopScroll] = useState(false);

    const [typeSort, setTypeSort] = useState('');
    const [isTypeSort, setIsTypeSort] = useState('');
    const [isFilterTime, setIsFilterTime] = useState(false);
    const [groupBy, setGroupBy] = useState('');
    const [search, setSearch] = useState('');
    const [fromDueDate, setFromDueDate] = useState('');
    const [toDueDate, setToDueDate] = useState('');
    const [fromCloseDate, setFromCloseDate] = useState('');
    const [toCloseDate, setToCloseDate] = useState('');
    const [fromDateCreate, setFromDateCreate] = useState('');
    const [toDateCreate, setToDateCreate] = useState('');
    const [customeSearchDate, setCustomeSearchDate] = useState(0);
    const [hasMore, setHasMore] = useState(true);

    const [toTime, setToTime] = useState('');
    const [show, setShow] = useState('myTask');
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [offset, setOffset] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [isFilterTypes, setIsFilterTypes] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isMyTask, setIsMyTask] = useState(true);
    const [isOverDue, setIsOverDue] = useState(false);
    const [createdByIds, setCreatedByIds] = useState([]);
    const [assignToIds, setAssignToIds] = useState([]);
    // const [typesIds, setTypesIds] = useState([]);
    const [priorityIds, setPriorityIds] = useState([]);
    const [statusIds, setStatusIds] = useState([1, 2, 5, 7, 8, 9, 4]);
    const [typeIds, setTypeIds] = useState([]);
    const [firstTime, setFirstTime] = useState(false);
    const [firstTimeOverDue, setFirstTimeOverDue] = useState(false);
    // const [typeTask, setTypeTask] = useState('');
    const [typeGroup, setTypeGroup] = useState('');
    const [summaryStatus, setSummaryStatus] = useState([]);
    const [callbackKanban, setCallbackKanban] = useState(false);
    const [teamId, setTeamId] = useState(0);
    const [isShowMenu, setIsShowMenu] = useState(false);

    const debouncedSearch = useDebounce(search, 800);
    const debouncedProjectIds = useDebounce(projectId, 800);
    const debouncedStatus = useDebounce(statusIds, 800);
    const [isGetFullTasks, setIsGetFullTasks] = useState(false);
    const [offsetRqm, setOffsetRqm] = useState(0);

    const getProject = async () => {
        // setLoading(true);
        const res = await api.get(`task/filters`, {
            params: {
                ...(offset ? { Offset: offset } : {}),
                ...(isOverDue ? { IsOverDue: isOverDue } : {}),
                ...(debouncedSearch ? { SearchTitle: debouncedSearch } : {}),
                ...(typeSort ? { SortType: typeSort } : {}),
                ...(isTypeSort === 'title' ? { IsSortTitle: isTypeSort === 'title' ? true : false } : {}),
                ...(isTypeSort === 'caseNo' ? { IsSortCaseNo: isTypeSort === 'caseNo' ? true : false } : {}),
                ...(isTypeSort === 'status' ? { IsSortStatus: isTypeSort === 'status' ? true : false } : {}),
                ...(isTypeSort === 'assignTo' ? { IsSortAssignTo: isTypeSort === 'assignTo' ? true : false } : {}),
                ...(isTypeSort === 'overdue' ? { IsSortOverDue: isTypeSort === 'overdue' ? true : false } : {}),
                ...(isTypeSort === 'priority' ? { IsSortPriority: isTypeSort === 'priority' ? true : false } : {}),
                ...(isTypeSort === 'project' ? { IsSortProject: isTypeSort === 'project' ? true : false } : {}),
                ...(typeGroup === 'dueDate' ? { IsGroupDueDate: typeGroup === 'dueDate' ? true : false } : {}),
                ...(typeGroup === 'status' ? { IsGroupStatus: typeGroup === 'status' ? true : false } : {}),
                ...(typeGroup === 'priority' ? { IsGroupPriority: typeGroup === 'priority' ? true : false } : {}),
                ...(typeGroup === 'assignTo' ? { IsGroupAssignTo: typeGroup === 'assignTo' ? true : false } : {}),
                ...(typeGroup === 'project' ? { IsGroupProject: typeGroup === 'project' ? true : false } : {}),
                ProjectIds: debouncedProjectIds,
                StatusIds: debouncedStatus,
                PriorityIds: priorityIds,
                AssignIds: assignToIds,
                TypeTaskIds: typeIds,
                ...(teamId ? { TeamId: teamId } : {}),
                ...(isMyTask ? { IsMyTask: isMyTask } : {}),
                ...(dueDateType ? { DueDateType: dueDateType } : {}),
                ...(closeDateType ? { CloseDateType: closeDateType } : {}),
                ...(createDateType ? { CreateDateType: createDateType } : {}),
                ...(fromDueDate ? { FromDueDate: fromDueDate } : {}),
                ...(toDueDate ? { ToDueDate: toDueDate } : {}),
                ...(fromCloseDate ? { FromCloseDate: fromCloseDate } : {}),
                ...(toCloseDate ? { ToCloseDate: toCloseDate } : {}),
                ...(fromDateCreate ? { FromDateCreate: fromDateCreate } : {}),
                ...(toDateCreate ? { ToDateCreate: toDateCreate } : {}),
                ...(isGetFullTasks ? { IsGetFullTasks: isGetFullTasks } : {}),
            },
        });

        return res.data.data.items;
    };

    useEffect(() => {
        if (offset !== 0) {
            // setProjects(projects.concat(['1', '2', '3']))
            async function fetchData() {
                const res = await getProject();
                if (res.length === 0) {
                    setStopScroll(true);
                } else {
                    if (projects.some((ele) => ele.projectName === res[0].projectName) && typeGroup === 'project') {
                        const parseRes = [...res];
                        parseRes[0].projectName = '';
                        parseRes[0].projectDate = '';
                        setProjects(projects.concat(parseRes));
                    } else {
                        setProjects(projects.concat(res));
                    }
                }
            }

            fetchData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [offset]);

    useEffect(() => {
        const getSummaryStatus = async () => {
            setLoading(true);
            const res = await api.get(`task/filters`, {
                params: {
                    ProjectIds: debouncedProjectIds,
                    ...(isMyTask ? { IsMyTask: isMyTask } : {}),
                    ...(isOverDue ? { IsOverDue: isOverDue } : {}),
                    StatusIds: debouncedStatus,
                },
            });
            setSummaryStatus(res.data.data.summaryStatus);
        };

        getSummaryStatus();
    }, [debouncedProjectIds, isMyTask, isOverDue, callback, teamId]);

    useEffect(() => {
        async function fetchData() {
            const res = await getProject();

            if (!isMyTask && !isOverDue) {
                if (res.length !== 0) {
                    setTotalCount(res[0].totalCount);
                } else {
                    setTotalCount(res.length);
                }
                setProjects(res);
                setLoading(false);
            }

            if (isOverDue) {
                if (res.length !== 0) {
                    setTotalCount(res[0].totalCount);
                } else {
                    setTotalCount(res.length);
                }
                setLoading(false);
                setProjects(res);
            }

            if (isMyTask) {
                if (res.length !== 0) {
                    setTotalCount(res[0].totalCount);
                } else {
                    setTotalCount(res.length);
                }
                setProjects(res);
                setLoading(false);
            }
        }
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        closeDateType,
        dueDateType,
        createDateType,
        debouncedSearch,
        debouncedProjectIds,
        callback,
        isFilterTime,
        typeSort,
        isOverDue,
        typeGroup,
        groupBy,
        toTime,
        isTypeSort,
        isFilterTypes,
        debouncedStatus,
        createdByIds,
        assignToIds,
        typeIds,
        priorityIds,
        isMyTask,
        customeSearchDate,
        isGetFullTasks,
        teamId,
    ]);

    return {
        projectId: [projectId, setProjectId],
        callback: [callback, setCallback],
        projects: [projects, setProjects],
        isFilterTime: [isFilterTime, setIsFilterTime],
        typeSort: [typeSort, setTypeSort],
        groupBy: [groupBy, setGroupBy],
        search: [search, setSearch],
        fromDueDate: [fromDueDate, setFromDueDate],
        toDueDate: [toDueDate, setToDueDate],
        fromCloseDate: [fromCloseDate, setFromCloseDate],
        toCloseDate: [toCloseDate, setToCloseDate],
        toTime: [toTime, setToTime],
        totalPages: [totalPages, setTotalPages],
        totalCount: [totalCount, setTotalCount],
        pageSize: [pageSize, setPageSize],
        isFilterTypes: [isFilterTypes, setIsFilterTypes],
        statusIds: [statusIds, setStatusIds],
        createdByIds: [createdByIds, setCreatedByIds],
        assignToIds: [assignToIds, setAssignToIds],
        typeIds: [typeIds, setTypeIds],
        priorityIds: [priorityIds, setPriorityIds],
        loading: [loading, setLoading],
        isMyTask: [isMyTask, setIsMyTask],
        firstTime: [firstTime, setFirstTime],
        firstTimeOverDue: [firstTimeOverDue, setFirstTimeOverDue],
        isTypeSort: [isTypeSort, setIsTypeSort],
        offset: [offset, setOffset],
        isOverDue: [isOverDue, setIsOverDue],
        show: [show, setShow],
        typeGroup: [typeGroup, setTypeGroup],
        summaryStatus: [summaryStatus, setSummaryStatus],
        callbackKanban: [callbackKanban, setCallbackKanban],
        dueDateType: [dueDateType, setDueDateType],
        closeDateType: [closeDateType, setCloseDateType],
        createDateType: [createDateType, setCreateDateType],
        fromDateCreate: [fromDateCreate, setFromDateCreate],
        toDateCreate: [toDateCreate, setToDateCreate],
        customeSearchDate: [customeSearchDate, setCustomeSearchDate],
        countItemProjects: [countItemProjects, setCountItemProjects],
        hasMore: [hasMore, setHasMore],
        stopScroll: [stopScroll, setStopScroll],
        getProject: getProject,
        isGetFullTasks: [isGetFullTasks, setIsGetFullTasks],
        offsetRqm: [offsetRqm, setOffsetRqm],
        teamId: [teamId, setTeamId],
        isShowMenu: [isShowMenu, setIsShowMenu],
    };
}

export default ProjectsAPI;
