import classNames from 'classnames/bind';
import { TbLayoutNavbar } from 'react-icons/tb';
import {
    AiOutlineUnorderedList,
    AiOutlineCalendar,
    AiOutlineFileAdd,
    AiFillFolderOpen,
    AiOutlineMenu,
} from 'react-icons/ai';
import { HiOutlineViewBoards } from 'react-icons/hi';
import { MdOutlineChecklist, MdOutlineRateReview, MdTimeline } from 'react-icons/md';
import { TbSettings } from 'react-icons/tb';

import styles from './Sidebar.module.scss';
import { useNavigate } from 'react-router-dom';
import { GlobalStateProject } from '~/ProjectState/projectState';
import { useContext, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { BsCardChecklist, BsFillArrowRightCircleFill, BsFillSignpostSplitFill } from 'react-icons/bs';
import { IoMdAdd, IoMdClose } from 'react-icons/io';
import { Tooltip } from '@mui/material';
import { GoChecklist } from 'react-icons/go';
import { CgChevronRightO } from 'react-icons/cg';
import { RightIcon } from '~/components/Icons';
const cx = classNames.bind(styles);

const listMenu = [
    {
        icon: <TbLayoutNavbar />,
        name: 'Summary',
        isActive: false,
        path: '/summary',
    },
    {
        icon: <AiOutlineUnorderedList />,
        name: 'Tasks',
        isActive: true,
        path: '/',
    },
    {
        icon: <AiOutlineFileAdd />,
        name: 'Create Task',
        isActive: true,
        path: '/create-task',
    },
    {
        icon: <BsFillSignpostSplitFill />,
        name: 'Milestone',
        isActive: true,
        path: '/milestone',
    },
    {
        icon: <AiFillFolderOpen />,
        name: 'Project',
        isActive: true,
        path: '/project',
    },
    {
        icon: <MdOutlineChecklist />,
        name: 'Requirement Checklist',
        isActive: true,
        path: '/requirement-checklist',
    },
    {
        icon: <MdOutlineRateReview />,
        // icon: <GoChecklist />,
        // icon: <BsCardChecklist />,
        name: 'Weekend Report',
        isActive: true,
        path: '/weekend-report',
    },
];

const listMenuNoName = listMenu.map(({ name, ...rest }) => {
    return {
        ...rest,
        nameHover: name,
    };
});

function Sidebar(props) {
    const state = useContext(GlobalStateProject);
    const [projectId, setProjectId] = state.projectsAPI.projectId;
    const [isShowMenu, setIsShowMenu] = state.projectsAPI.isShowMenu;
    const projects = useSelector((state) => state.project);
    const navigate = useNavigate();
    const [checkMobile, setCheckMobile] = useState(false);
    const [checkIconMenu, setCheckIconMenu] = useState(false);
    const [isVisible, setIsVisible] = useState(false);

    const { setMarginLeft } = props;

    const ref = useRef();

    const handleRidirect = (path) => {
        navigate(path);
    };
    const getNameProject = (id) => {
        const rs = projects.filter((project) => project.projectId === id);
        return rs[0].projectName;
    };

    const openMenu = () => {
        setCheckMobile(!checkMobile);
    };

    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            if (checkMobile && ref.current && !ref.current.contains(e.target)) {
                setCheckMobile(false);
            }
        };

        document.addEventListener('mousedown', checkIfClickedOutside);

        return () => {
            document.removeEventListener('mousedown', checkIfClickedOutside);
        };
    }, [checkMobile]);

    const handleShowMenu = () => {
        setIsShowMenu(!isShowMenu);
        if (!isShowMenu) {
            setTimeout(() => {
                setIsVisible(true);
            }, 510);
        } else {
            setIsVisible(false);
        }
    };
    return (
        <div
            className={cx('wrapper-sidebar', 'glo-position-relative')}
            ref={ref}
            style={isShowMenu ? { width: '24rem' } : {}}
        >
            <button className={cx(!isShowMenu ? 'btn-icon-arrow' : 'btn-icon-left-arrow')} onClick={handleShowMenu}>
                <div className={cx('icon-arrow')} style={isShowMenu ? { transform: 'rotate(180deg)' } : {}}>
                    <RightIcon />
                </div>
            </button>

            <div className={cx('glo-btnMenu-mobile')}>
                <div className={cx('menu-custom-witdth-click')} onClick={openMenu}>
                    {checkMobile ? <IoMdClose /> : <AiOutlineMenu />}
                </div>
            </div>
            <div className={cx(checkMobile === true ? 'glo-menu-mobile-block' : 'glo-menu-mobile')}>
                <div>
                    <div className={cx('sidebar-menu')}>
                        <div className={cx('sidebar-menu-list')}>
                            {isShowMenu &&
                                listMenu.map((item, index) => (
                                    <div
                                        className={cx(
                                            'sidebar-menu-list__item',
                                            window.location.pathname === item.path ? 'item-active' : '',
                                        )}
                                        key={index}
                                        onClick={() => handleRidirect(item.path)}
                                    >
                                        <span
                                            className={cx(
                                                'sidebar-menu-list__item-icon',
                                                window.location.pathname === item.path ? 'icon-active' : '',
                                            )}
                                        >
                                            {item.icon}
                                        </span>
                                        {isVisible && (
                                            <span className={cx('sidebar-menu-list__item-text')}>{item?.name}</span>
                                        )}
                                    </div>
                                ))}

                            {!isShowMenu &&
                                listMenuNoName.map((item, index) => (
                                    <Tooltip
                                        title={<span style={{ fontSize: '1.6rem' }}>{item.nameHover}</span>}
                                        placement="right"
                                        key={index}
                                    >
                                        <div
                                            className={cx(
                                                'sidebar-menu-list__item',
                                                window.location.pathname === item.path ? 'item-active' : '',
                                            )}
                                            onClick={() => handleRidirect(item.path)}
                                        >
                                            <span
                                                className={cx(
                                                    'sidebar-menu-list__item-icon',
                                                    window.location.pathname === item.path ? 'icon-active' : '',
                                                )}
                                            >
                                                {item.icon}
                                            </span>
                                            {isVisible && (
                                                <span className={cx('sidebar-menu-list__item-text')}>{item?.name}</span>
                                            )}
                                        </div>
                                    </Tooltip>
                                ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Sidebar;
