import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { publicRoutes, privateRoutes } from '~/routes';
import DefaultLayout from '~/layouts';
import { Fragment, Suspense } from 'react';
import { useSelector } from 'react-redux';
import routesConfig from '~/config/routes';
import { ProjectState } from './ProjectState/projectState';
import { ToastContainer } from 'react-toastify';
import { RequirementCheckListScroll } from './RequirementCheckListScroll/RequirementCheckListScroll';
// import 'bootstrap/dist/css/bootstrap.min.css';
import ReactLoading from 'react-loading';

function RequireAuth({ children, redirectTo }) {
    const auth = useSelector((state) => state.auth);
    const { isAuthenticated } = auth;
    return isAuthenticated ? children : <Navigate to={redirectTo} />;
}

function RequireLoginAuth({ children, redirectTo }) {
    const auth = useSelector((state) => state.auth);
    const { isAuthenticated } = auth;
    return isAuthenticated ? <Navigate to={redirectTo} /> : children;
}

function App() {
    return (
        <Router>
            <div className="App">
                <Routes>
                    {publicRoutes.map((route, index) => {
                        let Layout = DefaultLayout;
                        let Page = route.component;

                        if (route.layout === null) {
                            Layout = Fragment;
                        }

                        return (
                            <Route
                                key={index}
                                path={route.path}
                                element={
                                    <RequireLoginAuth redirectTo={routesConfig.home}>
                                        <Suspense
                                            fallback={
                                                <ReactLoading
                                                    type="spin"
                                                    color="#0052cc"
                                                    height={'20px'}
                                                    width={'20px'}
                                                />
                                            }
                                        >
                                            <Layout>
                                                <Page />
                                            </Layout>
                                        </Suspense>
                                    </RequireLoginAuth>
                                }
                            />
                        );
                    })}

                    {privateRoutes.map((route, index) => {
                        let Layout = DefaultLayout;
                        let Page = route.component;

                        if (route.layout === null) {
                            Layout = Fragment;
                        }

                        return (
                            <Route
                                key={index}
                                path={route.path}
                                element={
                                    <ProjectState>
                                        <RequirementCheckListScroll>
                                            <RequireAuth redirectTo={routesConfig.login}>
                                                <Suspense
                                                    fallback={
                                                        <ReactLoading
                                                            type="spin"
                                                            color="#0052cc"
                                                            height={'20px'}
                                                            width={'20px'}
                                                        />
                                                    }
                                                >
                                                    <Layout>
                                                        <Page />
                                                    </Layout>
                                                </Suspense>
                                            </RequireAuth>
                                        </RequirementCheckListScroll>
                                    </ProjectState>
                                }
                            />
                        );
                    })}
                </Routes>
                <ToastContainer
                    position="bottom-left"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </div>
        </Router>
    );
}

export default App;
