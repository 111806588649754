import classNames from 'classnames/bind';

import styles from './MenuUsers.module.scss';

const cx = classNames.bind(styles);

function MenuUsers({ children, users, setAssignTo }) {
    const handleClick = (id, name) => {
        setAssignTo({ id, name });
    };

    return (
        <>
            <button
                className={cx('dropdown-toggle')}
                // key={index}
                id="menu-users"
                data-bs-toggle="dropdown"
                aria-expanded="false"
            >
                <div className={cx('wrapper')}>{children}</div>
            </button>
            <ul className="dropdown-menu" id={cx('dropdown-menu')} aria-labelledby="menu-users">
                {users &&
                    users.map((user, index) => (
                        <li key={index} className={cx('user-item')} onClick={() => handleClick(user.id, user.name)}>
                            <span dangerouslySetInnerHTML={{ __html: user.name }} />
                        </li>
                    ))}
            </ul>
        </>
    );
}

export default MenuUsers;
