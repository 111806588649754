import api from '~/utils/main';
import { toast } from 'react-toastify';

export const getRequirement = async (checklistId) => {
    try {
        const res = await api.get(`check-list/?checklistId=${checklistId}`);
        return res;
    } catch (error) {
        console.log(error);
    }
};

export const getAllTaskInProject = async (isGetFullTasks, projectID) => {
    try {
        const res = await api.get(`task/filters?IsGetFullTasks=${isGetFullTasks}&ProjectIds=${projectID}`);
        return res;
    } catch (error) {
        console.log(error);
    }
};

export const addRequirement = async (body) => {
    try {
        const res = await toast.promise(api.post(`check-list/create-checklist-item`, body), {
            pending: 'Add checklist items pending',
            success: 'Add checklist items successfully',
            error: 'Add checklist items failed',
        });
        return res;
    } catch (error) {
        console.log(error);
    }
};

export const updateRequirement = async (body) => {
    try {
        const res = await toast.promise(api.patch(`check-list/update-checklist-item`, body), {
            pending: 'Update checklist item pending',
            success: 'Update checklist item successfully',
            error: 'Update checklist item failed',
        });
        return res;
    } catch (error) {
        console.log(error);
    }
};

export const deleteRequirement = async (sendDataDelete) => {
    try {
        const res = await toast.promise(
            api.delete(`check-list/item`, {
                params: {
                    checklistItemId: sendDataDelete,
                },
            }),
            {
                pending: 'Delete checklist item is pending',
                success: 'Delete checklist item successfully',
                error: 'Delete checklist item failed',
            },
        );
        return res;
    } catch (error) {
        console.log(error);
    }
};

export const mergerRequirement = async (body) => {
    try {
        const res = await api.post(`check-list/merge-checklist-item`, body);
        return res;
    } catch (error) {
        console.log(error);
    }
};

export const deleteCheckListRequirement = async (checklistId) => {
    try {
        const res = await toast.promise(api.delete(`/check-list/${checklistId}`), {
            pending: 'Delete requirement checklist is pending',
            success: 'Delete requirement checklist successfully',
            error: 'Delete requirement checklist item failed',
        });
        return res;
    } catch (error) {
        console.log(error);
    }
};
