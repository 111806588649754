import React, { memo, useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import styles from './ControlTask.module.scss';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { BsArrowsMove } from 'react-icons/bs';
import axios from 'axios';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { toast } from 'react-toastify';
import api from '~/utils/main';

const cx = classNames.bind(styles);

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 15,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const dataMilestone = [
    {
        milestone: 'Milestone 1',
        startDate: '29/07/2022',
        endDate: '01/10/2022',
    },
    {
        milestone: 'Milestone 2',
        startDate: '29/07/2022',
        endDate: '01/08/2022',
    },
];

function ControlTaskMoveMilestone(props) {
    const {
        projectId,
        openDialogMoveMilestone,
        setOpenDialogMoveMilestone,
        setFirstMount,
        dataMileStone,
        setDataMileStone,
        idTask,
    } = props;
    const token = useSelector((state) => state.token);
    const [milestoneId, setMilestoneId] = useState('');
    const handleClose = () => {
        setOpenDialogMoveMilestone(false);
        setFirstMount(false);
        setDataMileStone([]);
    };

    const handleReportMoveMilestone = async () => {
        const body = {
            id: idTask,
            projectId: projectId,
            milestoneId: milestoneId,
        };

        const res = await toast.promise(api.patch(`task/move-to-milestone/${idTask}`, body), {
            pending: 'Move to milestone is pending',
            success: 'Move to milestone successfully',
            error: 'Move to milestone failed',
        });

        if (res.status === 204) {
            setOpenDialogMoveMilestone(false);
        }
    };

    const handleChangeMileStone = (e) => {
        setMilestoneId(e.target.value);
    };

    // useEffect(() => {
    //     const getMilestone = async () => {
    //         const res = await axios.get(`${process.env.REACT_APP_BASE_URL}milestones/project/${projectId}`, {
    //             headers: {
    //                 Authorization: `Bearer ${token}`,
    //             },
    //         });
    //         console.log(res);
    //         if (res.data.status === 'success') {
    //             console.log(res.data.data);
    //         }
    //         setDataMileStone()
    //     };
    //     getMilestone();
    // }, []);

    return (
        <div className={cx('control-task-dialog')}>
            <form>
                <BootstrapDialog
                    open={openDialogMoveMilestone}
                    onClose={handleClose}
                    aria-labelledby="customized-dialog-title"
                    maxWidth="sm"
                    fullWidth
                >
                    <BootstrapDialogTitle
                        id="customized-dialog-title"
                        onClose={handleClose}
                        className={cx('custom-title-control')}
                    >
                        <BsArrowsMove className={cx('custom-title-control-icon')} /> Move task to milestone
                    </BootstrapDialogTitle>
                    <DialogContent className={cx('move-project-content')}>
                        <table className={cx('table')}>
                            <thead>
                                <tr className={cx('milestone-table-tr')}>
                                    <th scope="col">Milestone</th>
                                    <th scope="col">Start Date</th>
                                    <th scope="col">End Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                {dataMileStone === '' ? (
                                    <>
                                        <td className={cx('hn-no-milestone')}>No Milestone(s) available.</td>
                                    </>
                                ) : (
                                    <>
                                        {dataMileStone.map((data) => (
                                            <tr key={data.id}>
                                                <td scope="row">
                                                    <input
                                                        type="radio"
                                                        id={data.id}
                                                        name=""
                                                        value={data.id}
                                                        checked={milestoneId == data.id}
                                                        className={cx('milestone-ipRadio')}
                                                        onChange={(e) => handleChangeMileStone(e)}
                                                    ></input>
                                                    <label htmlFor={data.id}>{data.title}</label>
                                                </td>
                                                <td>
                                                    {moment(data.startDate).format('YYYY/MM/DD').replaceAll('/', '-')}
                                                </td>
                                                <td>
                                                    {moment(data.endDate).format('YYYY/MM/DD').replaceAll('/', '-')}
                                                </td>
                                            </tr>
                                        ))}
                                    </>
                                )}
                            </tbody>
                        </table>
                    </DialogContent>
                    <DialogActions className={cx('control-dialog-action')}>
                        <button onClick={handleClose} className={cx('hn-btn-cancel')} size="medium">
                            Cancel
                        </button>
                        <button
                            type="submit"
                            onClick={handleReportMoveMilestone}
                            className={cx('hn-btn-submit')}
                            size="medium"
                        >
                            Submit
                        </button>
                    </DialogActions>
                </BootstrapDialog>
            </form>
        </div>
    );
}

export default memo(ControlTaskMoveMilestone);
