import { combineReducers } from 'redux';
import auth from './authReducer';
import token from './tokenReducer';
import project from './projectReducer';

export default combineReducers({
    auth,
    token,
    project,
});
