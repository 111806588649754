export const projectAction = {
    SET_PROJECTS: 'SET_PROJECTS',
};

export const dispatchProject = (proj) => {
    return {
        type: projectAction.SET_PROJECTS,
        payload: proj,
    };
};
