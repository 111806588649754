/* eslint-disable jsx-a11y/alt-text */
import classNames from 'classnames/bind';
import Dialog from '@mui/material/Dialog';

import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { IoIosArrowDown, IoMdClose } from 'react-icons/io';
import ReactLoading from 'react-loading';

import styles from './DetailTask.module.scss';
import { lazy, memo, React, Suspense, useContext, useEffect, useRef, useState } from 'react';
import Button from '~/components/Button';
import { MdDeleteOutline, MdInsertDriveFile, MdKeyboardArrowUp, MdOutlineAddCircleOutline } from 'react-icons/md';
import { HighIcon, LowIcon, MediumIcon } from '~/components/Icons';
import './FormEdit.scss';
import { BsFillBookmarkFill, BsLink45Deg } from 'react-icons/bs';
import moment from 'moment';
import Status from '~/components/MenuStatus';
import MenuUsers from '~/components/MenuUsers';
import { toast } from 'react-toastify';
import { GlobalStateProject } from '~/ProjectState/projectState';
import { AiOutlineSortAscending, AiOutlineSortDescending, AiTwotoneStar } from 'react-icons/ai';
import api from '~/utils/main';
// import Comment from './components/comments';
import Swal from 'sweetalert2';
import * as detailTaskService from '~/services/detailTaskService';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useCallback } from 'react';
import EditDescription from './components/EditDescription';
import { BiEditAlt } from 'react-icons/bi';
import CreateTaskChild from '../CreateTaskChild/CreateTaskChild';
import { callToWindowApp } from '~/common/common';
import { useSelector } from 'react-redux';
import { VscEdit } from 'react-icons/vsc';
import { Tooltip } from '@mui/material';
import FormCreatePathFile from '~/components/FormCreatePathFile';
import FormUpdatePathFile from '~/components/FormUpdatePathFile';
import { TiArrowSortedDown, TiArrowSortedUp } from 'react-icons/ti';
import { RiSortAsc, RiSortDesc } from 'react-icons/ri';
import InfiniteScroll from 'react-infinite-scroll-component';
import { FaRegFolderOpen } from 'react-icons/fa';

const cx = classNames.bind(styles);

const Comment = lazy(() => import('./components/comments'));

const EditReport = ({ editorState, onChange }) => {
    const formReport = useRef();

    useEffect(() => {
        formReport.current.focusEditor();
    }, [formReport]);

    return (
        <Editor
            ref={formReport}
            editorState={editorState}
            onEditorStateChange={onChange}
            wrapperClassName="wrapper-class res-editor-cmt"
            editorClassName="editor-class"
            toolbarClassName="toolbar-class"
            placeholder="Enter your comment...."
        />
    );
};

function DetailTask(props) {
    const {
        projectIdTask,
        open,
        dataTask,
        setOpen,
        taskId,
        setDataTask,
        assignTo,
        setAssignTo,
        statusDetailTask,
        setStatusDetailTask,
        setValuePicker,
        valuePicker,
        priorityTask,
        setPriorityTask,
        handleClickOpen,
        offsetDetailTask,
        setOffsetDetailTask,
        totalCountComment,
    } = props;
    const state = useContext(GlobalStateProject);
    const auth = useSelector((state) => state.auth);
    // const [isChecked, setIsChecked] = useState([]);
    // const [isCheckAll, setIsCheckAll] = useState(false);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [hours, setHours] = useState('');
    const [listTaskChild, setListTaskChild] = useState([]);
    const [menuStatus, setMenuStatus] = useState([]);
    const [users, setUsers] = useState('');
    const [callback, setCallback] = state.projectsAPI.callback;
    const [callbackKanban, setCallbackKanban] = state.projectsAPI.callbackKanban;
    const [valueCopy, setValueCopy] = useState('');
    // eslint-disable-next-line no-unused-vars
    const [copied, setCopied] = useState(false);
    const [editDescription, setEditDescription] = useState('');
    const [isShowDescription, setIsShowDescription] = useState(false);
    const [infoChildTask, setInfoChildTask] = useState({});
    const [openDialogCreateTaskChild, setOpenDialogCreateTaskChild] = useState(false);
    const [countReload, setCountReload] = useState(0);
    const [openCreatePathFile, setOpenCreatePathFile] = useState(false);
    const [openUpdatePathFile, setOpenUpdatePathFile] = useState(false);
    const [callbackCreatePathFile, setCallbackCreatePathFile] = useState(false);
    const [isSortType, setIsSortType] = useState('');
    const [sortType, setSortType] = useState('');
    const [eleParentTask, setEleParentTask] = useState(null);
    const [isShowInputPoint, setIsShowInputPoint] = useState(false);
    const [loadingInput, setLoadingInput] = useState(false);
    const [countItemComment, setCountItemComment] = useState(0);

    const copyLink = window.location.hostname.startsWith('localhost')
        ? `http://${window.location.hostname}:${window.location.port}/task/${taskId}`
        : `http://${window.location.hostname}/task/${taskId}`;

    const getChildTask = async () => {
        const res = await detailTaskService.getDataTaskChild(taskId, projectIdTask, false);

        if (res.data.status === 'success') {
            const parentTask = res.data.data.filter((e) => e.refId === 0)[0];
            setListTaskChild(res.data.data.filter((e) => e.refId !== 0));
            setEleParentTask(parentTask);
        }
    };

    useEffect(() => {
        const fetchDataComment = async () => {
            const res = await detailTaskService.getDataDetailTask(taskId, true, offsetDetailTask);
            if (res.data.status === 'success') {
                setDataTask({
                    ...dataTask,
                    taskRelations: [...dataTask.taskRelations, ...res.data.data.taskRelations],
                });
            }
        };
        offsetDetailTask > 0 && fetchDataComment();
    }, [offsetDetailTask]);

    useEffect(() => {
        if (sortType !== '') {
            const handleSort = async () => {
                const res = await detailTaskService.getDataTaskChild(
                    taskId,
                    projectIdTask,
                    false,
                    sortType,
                    isSortType,
                );
                if (res.data.status === 'success') {
                    const filterEleNotParent = res.data.data.filter((item) => item.refId !== 0);
                    setListTaskChild(filterEleNotParent);
                }
            };
            handleSort();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sortType, isSortType]);

    useEffect(() => {
        if (countReload !== 0) {
            getChildTask();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [countReload]);

    useEffect(() => {
        if (open === true) handleClickOpen(taskId, true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [callbackCreatePathFile]);

    useEffect(() => {
        if (!open || projectIdTask === '') {
            return;
        }
        // if (open && firstOpen) {
        // if (firstOpen) {
        const getDataDetailTask = async () => {
            const res = await detailTaskService.getInitInfoTask(taskId);

            if (res.data.status === 'success') {
                setUsers(res.data.data.userUpdates);
                setMenuStatus(res.data.data.statusUpdates);
            }
        };
        getDataDetailTask();
        if (listTaskChild.length === 0) {
            getChildTask();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [taskId, projectIdTask, open]);

    // useEffect(() => {
    //     // if have task child -> list tasks
    //     console.log('projectIdTask', projectIdTask);
    //     console.log('firstOpen', firstOpen);
    //     if (projectIdTask !== '' && firstOpen) {
    //         const getChildTask = async () => {
    //             const res = await axios.get(`${process.env.REACT_APP_BASE_URL}task/${taskId}/references`, {
    //                 params: {
    //                     id: taskId,
    //                     projectId: projectIdTask,
    //                 },
    //                 headers: {
    //                     Authorization: `Bearer ${token}`,
    //                 },
    //             });
    //             if (res.data.status === 'success') {
    //                 setListTaskChild(res.data.data);
    //             }
    //         };
    //         getChildTask();
    //     }
    // }, [taskId, projectIdTask]);

    const handleClose = () => {
        setOpen(false);
        setEditorState(EditorState.createEmpty());
        setDataTask([]);
        setHours('');
        setListTaskChild([]);
        setIsShowDescription(false);
        setIsSortType('');
        setSortType('');
        setIsShowInputPoint(false);
        setOffsetDetailTask(0);
    };

    const handleDeleteTask = async () => {
        Swal.fire({
            title: 'Are you sure?',
            text: `You want to delete the task ${dataTask.title} ?`,
            icon: 'warning',
            width: '360px',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'OK',
        }).then(async (result) => {
            if (result.isConfirmed) {
                setOpen(false);
                const res = await api.delete(`task/${taskId}`);
                res.status === 204 && setCallback(!callback);
                Swal.fire('Success!', `Delete task ${dataTask.title} successfully`, 'success');
            }
        });
    };
    // const handleChange = (value) => {
    //     setValue(value);
    // };

    // const handleChangeCheckbox = (e, id) => {
    //     const { checked } = e.target;
    //     setIsChecked([...isChecked, id]);
    //     if (!checked) {
    //         setIsChecked(isChecked.filter((item) => item !== id));
    //     }
    // };

    // const handleChangeCheckAll = () => {
    //     setIsCheckAll(!isCheckAll);
    //     setIsChecked(users.map((li) => li.id));
    //     if (isCheckAll) {
    //         setIsChecked([]);
    //     }
    // };

    // eslint-disable-next-line no-lone-blocks
    {
        /* high = 0, medium = 1, low = 2 */
    }
    const handleReport = async () => {
        const data = {
            message: draftToHtml(convertToRaw(editorState.getCurrentContent())),
            statusId: statusDetailTask.id,
            assignTo: assignTo.id,
            priorityId: +priorityTask,
            hours: hours ? +hours : dataTask.hours,
            completedTask: document.getElementById('idCompleted').value || 0,
            dueDate: valuePicker,
            typeId: dataTask.typeId,
            ...(dataTask.level !== 0 ? { level: dataTask.level } : {}),
            ...(dataTask.point !== null ? { point: dataTask.point } : {}),
        };

        const res = await toast.promise(api.post(`task/report/${taskId}`, data), {
            pending: 'Report task is pending',
            success: 'Report task successfully',
            error: 'Report task failed',
        });

        if (res.data.status === 'success') {
            setDataTask({
                ...dataTask,
                completedTask: Math.round(res.data.data.completedTask),
                hours: res.data.data.hours,
                priorityName: res.data.data.priorityName,
                assignEmail: res.data.data.assignEmail,
                assignName: res.data.data.assignName,
                status: res.data.data.statusName,
                dueDate: res.data.data.dueDate,
                point: res.data.data.point,
                level: res.data.data.level,
                taskRelations: [res.data.data.taskRelation, ...dataTask.taskRelations],
            });
            listTaskChild.length > 0 && getChildTask();
            setMenuStatus(res.data.data.statusUpdates);
            setEditorState(EditorState.createEmpty());
            setCallback(!callback);
            setCallbackKanban(!callbackKanban);
        }
    };

    // const onEditorStateChange = (editorState) => {
    //     return setEditorState(editorState);
    // };

    // const handleEditReport = (id) => {
    //     document.getElementsByClassName(`comment-item-btn__${id}`)[0].style.display = 'none';
    //     document.getElementsByClassName(`content-editor__${id}`)[0].style.display = 'none';
    //     document.getElementsByClassName(`comment-item__save${id}`)[0].style.display = 'block';
    //     document.getElementsByClassName(`wrapper-form__edit${id}`)[0].style.display = 'block';
    //     // document.getElementsByClassName(`form-edit${id}`)[0].focus();
    // };

    // const handleUpdateComment = async (id, message) => {
    //     if (editContent !== '' && message !== editContent) {
    //         const res = await toast.promise(
    //             api.patch(`${process.env.REACT_APP_BASE_URL}task/${id}/message`, {
    //                 message: editContent,
    //             }),
    //             {
    //                 pending: 'Edit comment is pending',
    //                 success: 'Edit comment successfully',
    //                 error: 'Edit comment failed',
    //             },
    //         );
    //         if (res.status === 204) {
    //             setDataTask((prevState) => {
    //                 const newState = prevState.taskRelations.map((comment) => {
    //                     if (comment.id === id) {
    //                         return { ...comment, message: editContent };
    //                     }

    //                     return comment;
    //                 });
    //                 return { ...prevState, taskRelations: newState };
    //             });
    //             document.getElementsByClassName(`comment-item__save${id}`)[0].style.display = 'none';
    //             document.getElementsByClassName(`wrapper-form__edit${id}`)[0].style.display = 'none';
    //             document.getElementsByClassName(`comment-item-btn__${id}`)[0].style.display = 'block';
    //             document.getElementsByClassName(`content-editor__${id}`)[0].style.display = 'block';
    //         }
    //     }
    // };

    // const handleCancelComment = (id, message) => {
    //     const content = document.getElementsByClassName(`content-editor__${id}`)[0].innerHTML;
    //     console.log(content);
    //     // setEditContent(content);
    //     document.getElementsByClassName(`comment-item-btn__${id}`)[0].style.display = 'block';
    //     document.getElementsByClassName(`content-editor__${id}`)[0].style.display = 'block';
    //     document.getElementsByClassName(`comment-item__save${id}`)[0].style.display = 'none';
    //     document.getElementsByClassName(`wrapper-form__edit${id}`)[0].style.display = 'none';
    // };

    const handleChangeDate = (e) => {
        const value = moment(new Date(e.target.value)).format('YYYY-MM-DD');
        setValuePicker(value);
    };

    const handleChangePriority = (value) => {
        setPriorityTask(value);
    };

    const handleChangeDetailTask = (id) => {
        handleClickOpen(id);
    };

    const handleInputCompleted = (e) => {
        if (e.target.value >= 100) {
            return (e.target.value = 100);
        }
        if (e.target.value < 0) {
            return (e.target.value = 0);
        }

        document.getElementById('idCompleted').value = e.target.value;
    };

    const handleCopy = useCallback(() => {
        Swal.fire('Success!', `Copy link task successfully`, 'success');
    }, []);

    function getDirectoryPath(filePath) {
        const lastSlashIndex = filePath.lastIndexOf('\\');
        return filePath.substring(0, lastSlashIndex);
    }

    const handleCopyFolder = (pathFile) => {
        const pathFolder = getDirectoryPath(pathFile);

        var data = {
            strFileName: pathFolder,
        };

        callToWindowApp('OpenFileOrFolder', data);
    };

    const handleCopyPathFile = (pathFile) => {
        var data = {
            strFileName: pathFile,
        };

        callToWindowApp('OpenFileOrFolder', data);
    };

    const handleCopyPathFileName = (pathFile) => {
        navigator.clipboard.writeText(pathFile);
        Swal.fire('Success!', `Copy path file successfully`, 'success');
    };

    // useEffect(() => {
    //     const getLink = () => {
    //         if (window.location.hostname.startsWith('localhost')) {
    //             const value = `http://${window.location.hostname}:${window.location.port}/task/${taskId}`;
    //             setValueCopy(value);
    //         } else {
    //             const value = `http://${window.location.hostname}/task/${taskId}`;
    //             setValueCopy(value);
    //         }
    //     };
    //     getLink();
    // }, [taskId]);

    const onCopy = useCallback(() => {
        setCopied(true);
    }, []);

    const handleUpdateDescription = async (id) => {
        const res = await toast.promise(
            api.patch(`task/${id}/message`, {
                message: editDescription,
            }),
            {
                pending: 'Edit description is pending',
                success: 'Edit description successfully',
                error: 'Edit description failed',
            },
        );
        if (res.status === 204) {
            setDataTask((prevState) => {
                const newState = { ...prevState, message: editDescription };

                return newState;
            });
            setDataTask((prevState) => {
                const newState = prevState.taskRelations.map((comment) => {
                    if (comment.id === id) {
                        return { ...comment, message: editDescription };
                    }

                    return comment;
                });
                return { ...prevState, taskRelations: newState };
            });
            setIsShowDescription(false);
        }
    };

    useEffect(() => {
        if (dataTask.length !== 0) {
            document.getElementById('idCompleted').value = Math.round(dataTask.completedTask);
        }
    }, [dataTask]);

    const handleEditDescription = () => {
        if (isShowDescription === true) {
            setIsShowDescription(false);
        } else {
            setIsShowDescription(true);
            setEditDescription(dataTask.message);
        }
    };

    const handleCreateChildTask = () => {
        setOpenDialogCreateTaskChild(true);
        const formatDateDataTask = { ...dataTask, dueDate: moment(dataTask.dueDate).format('DD/MM/YYYY') };
        setInfoChildTask(formatDateDataTask);
    };

    const handleCreatePathFile = () => {
        setOpenCreatePathFile(true);
    };

    const handleSortUpAssignee = () => {
        setIsSortType('asc');
        setSortType('assign');
        if (sortType === 'assign' && isSortType === 'asc') {
            setIsSortType('');
            setSortType('');
        }
    };

    const handleSortDownAssignee = () => {
        setIsSortType('desc');
        setSortType('assign');
        if (sortType === 'assign' && isSortType === 'desc') {
            setIsSortType('');
            setSortType('');
        }
    };

    const handleSortUpStatus = () => {
        setIsSortType('asc');
        setSortType('status');
        if (sortType === 'status' && isSortType === 'asc') {
            setIsSortType('');
            setSortType('');
        }
    };

    const handleSortDownStatus = () => {
        setIsSortType('desc');
        setSortType('status');
        if (sortType === 'status' && isSortType === 'desc') {
            setIsSortType('');
            setSortType('');
        }
    };

    const handleClickEditPoint = (e) => {
        setIsShowInputPoint(true);
    };

    const fetchData = () => {
        setTimeout(() => {
            setOffsetDetailTask(offsetDetailTask + 20);
        }, 500);
    };

    useEffect(() => {
        const countItemComment = () => {
            setCountItemComment(dataTask?.taskRelations?.length);
        };
        countItemComment();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataTask]);

    const handleInputLevel = (e) => {
        setDataTask({ ...dataTask, level: e.target.value });
    };

    const handleInputPoint = (e) => {
        setDataTask({ ...dataTask, point: +e.target.value });
    };

    return (
        <>
            <Dialog open={open} onClose={handleClose} maxWidth="1700px" minwidth="1500px" className="res-dialog">
                {dataTask.length !== 0 && (
                    <div className={cx('glo-dialog')}>
                        <div className={cx('row', 'glo-wrapper')}>
                            <div className={cx('col-12 col-sm-6', 'wrapper-form')}>
                                <div className={cx('wrapper-form-project', 'glo-text')}>
                                    <BsFillBookmarkFill className={cx('wrapper-form__icon')} />
                                    <span className={cx('wrapper-form__name')}>{dataTask.projectName}</span>
                                </div>
                            </div>
                            <div className={cx('col-12 col-sm-6')}>
                                <div className={cx('d-flex justify-content-end align-items-center', 'wrapper-icon')}>
                                    {!dataTask.pathFile ? (
                                        <button className={cx('btn-icon-item')} onClick={handleCreatePathFile}>
                                            <MdOutlineAddCircleOutline style={{ fontSize: '16px' }} />
                                            <span style={{ fontSize: '16px', marginLeft: '6px' }}>Add File</span>
                                        </button>
                                    ) : (
                                        <>
                                            <button
                                                className={cx('btn-icon-item')}
                                                onClick={() => handleCopyFolder(dataTask.pathFile)}
                                            >
                                                <FaRegFolderOpen style={{ fontSize: '16px' }} />
                                                <span style={{ fontSize: '16px', marginLeft: '6px' }}>Open Folder</span>
                                            </button>
                                            <button
                                                className={cx('btn-icon-item')}
                                                onClick={() => handleCopyPathFile(dataTask.pathFile)}
                                            >
                                                <MdInsertDriveFile style={{ fontSize: '16px' }} />
                                                <span style={{ fontSize: '16px', marginLeft: '6px' }}>Open File</span>
                                            </button>
                                            <Tooltip
                                                title={<span style={{ fontSize: '1.6rem' }}>Update File</span>}
                                                placement="bottom"
                                            >
                                                <button
                                                    className={cx('btn-icon-item')}
                                                    onClick={() => setOpenUpdatePathFile(true)}
                                                >
                                                    <VscEdit style={{ fontSize: '16px' }} />
                                                </button>
                                            </Tooltip>

                                            {/* <button
                                                className={cx('btn-icon-item')}
                                                onClick={() => handleCopyPathFileName(dataTask.pathFile)}
                                            >
                                                <BsLink45Deg style={{ fontSize: '20px' }} />
                                                <span style={{ fontSize: '16px', marginLeft: '6px' }}>
                                                    Copy path file
                                                </span>
                                            </button> */}

                                            <CopyToClipboard
                                                onCopy={onCopy}
                                                text={dataTask.pathFile}
                                                options={{ format: 'text/plain' }}
                                            >
                                                <button className={cx('btn-icon-item')} onClick={handleCopy}>
                                                    <BsLink45Deg style={{ fontSize: '20px' }} />
                                                    <span style={{ fontSize: '16px', marginLeft: '6px' }}>
                                                        Copy path file
                                                    </span>
                                                </button>
                                            </CopyToClipboard>
                                        </>
                                    )}

                                    <CopyToClipboard onCopy={onCopy} text={copyLink} options={{ format: 'text/plain' }}>
                                        <button className={cx('btn-icon-item')} onClick={handleCopy}>
                                            <BsLink45Deg style={{ fontSize: '20px' }} />
                                            <span style={{ fontSize: '16px', marginLeft: '6px' }}>Copy link</span>
                                        </button>
                                    </CopyToClipboard>
                                    {(auth.users.id === dataTask.userCreateId ||
                                        auth.users.userType === 1 ||
                                        auth.users.userType === 2) && (
                                        <button className={cx('btn-icon-item')}>
                                            <MdDeleteOutline className={cx('icon-item')} onClick={handleDeleteTask} />
                                        </button>
                                    )}

                                    <button className={cx('btn-icon-item')} onClick={handleClose}>
                                        <IoMdClose className={cx('icon-item')} />
                                    </button>
                                </div>
                            </div>
                        </div>

                        <InfiniteScroll
                            dataLength={dataTask.taskRelations.length}
                            next={fetchData}
                            hasMore={countItemComment >= totalCountComment ? false : true}
                            loader={<h4>Loading...</h4>}
                            height={800}
                            endMessage={
                                offsetDetailTask > 0 && (
                                    <p style={{ textAlign: 'center' }}>
                                        <b>Yay! You have seen it all</b>
                                    </p>
                                )
                            }
                        >
                            <div className={cx('row', 'wrapper')}>
                                <div className={cx('col-12 col-xl-8', 'wrapper-form')}>
                                    <div className={cx('wrapper-form__title')}>{dataTask.title}</div>
                                    <div className={cx('content-description')}>
                                        <span className={cx('content-description__item')}>Description</span>
                                        <BiEditAlt style={{ fontSize: '2rem' }} onClick={handleEditDescription} />
                                        {isShowDescription === false && (
                                            <p dangerouslySetInnerHTML={{ __html: dataTask.message }} />
                                        )}

                                        {isShowDescription === true ? (
                                            <EditDescription
                                                name={dataTask.message}
                                                onChange={() => {}}
                                                setEditContent={setEditDescription}
                                                editContent={editDescription}
                                                // id={info.id}
                                            />
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                    {isShowDescription === true ? (
                                        <div className={cx('row')}>
                                            <div className={cx('col-8')}></div>
                                            <div className={cx('col-4', 'text-end', 'mt-2')}>
                                                <Button
                                                    className="btn-form__save"
                                                    onClick={() => handleUpdateDescription(taskId)}
                                                    radius8px
                                                >
                                                    Save
                                                </Button>
                                                <Button
                                                    className="btn-form__cancel"
                                                    onClick={() => setIsShowDescription(false)}
                                                    radius8px
                                                >
                                                    Cancel
                                                </Button>
                                            </div>
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                    <div className={cx('row d-flex align-items-center mb-2', 'comment-status')}>
                                        <div className={cx('col-12 col-md-6 col-lg-6 mb-2', 'res-padding-detail')}>
                                            <div className={cx('row')}>
                                                <div
                                                    className={cx(
                                                        'col-12 col-sm-3 col-md-4 col-lg-4',
                                                        'rest-text-detail',
                                                    )}
                                                >
                                                    Status:
                                                </div>
                                                <div
                                                    className={cx(
                                                        'col-12 col-sm-6 col-lg-6 d-flex',
                                                        'comment-status__select',
                                                        `comment-status__select-${statusDetailTask.title
                                                            .split(' ')
                                                            .join('')}`,
                                                    )}
                                                >
                                                    <Status
                                                        menuStatus={menuStatus}
                                                        setStatusDetailTask={setStatusDetailTask}
                                                    >
                                                        <div className={cx('comment-status__select-btn')}>
                                                            <span
                                                                className={cx(
                                                                    `status__${statusDetailTask.title
                                                                        .split(' ')
                                                                        .join('')}`,
                                                                )}
                                                            >
                                                                {statusDetailTask.title}
                                                            </span>
                                                            <span
                                                                className={cx(
                                                                    `status__${statusDetailTask.title
                                                                        .split(' ')
                                                                        .join('')}`,
                                                                )}
                                                            >
                                                                <IoIosArrowDown />
                                                            </span>
                                                        </div>
                                                    </Status>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={cx('col-12 col-md-6 col-lg-6 mb-2')}>
                                            <div className={cx('row')}>
                                                <div className={cx('col-12 col-sm-3', 'rest-text-detail')}>
                                                    Priority:
                                                </div>
                                                <div
                                                    className={cx(
                                                        'col-12 col-sm-9 d-flex',
                                                        'list-priority',
                                                        'res-priority',
                                                    )}
                                                    onChange={(e) => handleChangePriority(e.target.value)}
                                                >
                                                    <div className={cx('list-priority__item')}>
                                                        <input
                                                            id="Low"
                                                            type="radio"
                                                            name="Low"
                                                            checked={priorityTask === '2'}
                                                            value="2"
                                                        />
                                                        <label htmlFor="Low">Low</label>
                                                    </div>
                                                    <div className={cx('list-priority__item')}>
                                                        <input
                                                            id="Medium"
                                                            type="radio"
                                                            name="Medium"
                                                            checked={priorityTask === '1'}
                                                            value="1"
                                                        />
                                                        <label htmlFor="Medium">Medium</label>
                                                    </div>
                                                    <div className={cx('list-priority__item')}>
                                                        <input
                                                            id="High"
                                                            type="radio"
                                                            name="High"
                                                            checked={priorityTask === '0'}
                                                            value="0"
                                                        />
                                                        <label htmlFor="High">High</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={cx('row d-flex align-items-center mb-2', 'comment-assign')}>
                                        <div className={cx('col-12 col-md-6 col-lg-6 mb-2', 'res-padding-detail')}>
                                            <div className={cx('row')}>
                                                <div
                                                    className={cx(
                                                        'col-12 col-sm-3 col-md-4 col-lg-4',
                                                        'rest-text-detail',
                                                    )}
                                                >
                                                    Assign to:
                                                </div>
                                                <div
                                                    className={cx(
                                                        'col-12 col-sm-6 col-lg-6 d-flex',
                                                        'comment-assign__select',
                                                    )}
                                                >
                                                    <MenuUsers users={users} setAssignTo={setAssignTo}>
                                                        <div className={cx('assign')}>
                                                            <span
                                                                className={cx('assign-default')}
                                                                dangerouslySetInnerHTML={{ __html: assignTo.name }}
                                                            />
                                                            <span className={cx('assign-default__icon')}>
                                                                <IoIosArrowDown />
                                                            </span>
                                                        </div>
                                                    </MenuUsers>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={cx('col-12 col-md-6 col-lg-6 mb-2', 'res-padding-detail')}>
                                            <div className={cx('row')}>
                                                <div className={cx('col-12 col-md-6 col-lg-6 mb-2')}>
                                                    <div className={cx('row')}>
                                                        <div
                                                            className={cx(
                                                                'col-4 col-sm-3 col-md-7 col-xxl-6',
                                                                'rest-text-detail',
                                                            )}
                                                        >
                                                            Completed:
                                                        </div>
                                                        <div
                                                            className={cx(
                                                                'col-6 col-sm-3 col-md-5 col-xxl-5 d-flex align-items-center',
                                                                'res-padding-zero',
                                                            )}
                                                        >
                                                            <input
                                                                type="text"
                                                                id="idCompleted"
                                                                className={cx(
                                                                    'input-completed',
                                                                    dataTask.refId === 0 && listTaskChild.length > 1
                                                                        ? 'not-allow-input'
                                                                        : '',
                                                                )}
                                                                onChange={(e) => handleInputCompleted(e)}
                                                                disabled={
                                                                    dataTask.refId === 0 && listTaskChild.length > 1
                                                                }
                                                            />
                                                            <label>%</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={cx('col-12 col-md-6 col-lg-6 mb-2')}>
                                                    <div className={cx('row')}>
                                                        <div
                                                            className={cx(
                                                                'col-4 col-sm-3 col-md-7 col-xxl-4',
                                                                'res-text-hour',
                                                                'rest-text-detail',
                                                            )}
                                                        >
                                                            Hour(h):
                                                        </div>
                                                        <div
                                                            className={cx(
                                                                'col-6 col-sm-3 col-md-5 col-xxl-5 d-flex align-items-center',
                                                                'res-padding-zero',
                                                            )}
                                                        >
                                                            <input
                                                                type="number"
                                                                value={hours ? hours : dataTask.hours}
                                                                className={cx('input-hours')}
                                                                onChange={(e) => setHours(e.target.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={cx('row d-flex align-items-center mb-2', 'comment-assign')}>
                                        <div className={cx('col-12 col-md-6 col-lg-6 mb-2', 'res-padding-detail')}>
                                            <div className={cx('row')}>
                                                <div
                                                    className={cx(
                                                        'col-12 col-sm-3 col-md-4 col-lg-4',
                                                        'rest-text-detail',
                                                    )}
                                                >
                                                    Due Date:
                                                </div>
                                                <div
                                                    className={cx(
                                                        'col-12 col-sm-6 col-lg-6 d-flex',
                                                        'comment-assign__select',
                                                    )}
                                                >
                                                    <input
                                                        type="date"
                                                        className="form-control form-control-lg"
                                                        id="duedate"
                                                        value={moment(valuePicker)
                                                            .format('YYYY/MM/DD')
                                                            .replaceAll('/', '-')}
                                                        min={moment(new Date())
                                                            .format('YYYY/MM/DD')
                                                            .replaceAll('/', '-')}
                                                        onChange={(e) => handleChangeDate(e)}
                                                    ></input>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={cx('col-12 col-md-6 col-lg-6 mb-2', 'res-padding-detail')}>
                                            <div className={cx('row')}>
                                                <div className={cx('col-12 col-md-6 col-lg-6 mb-2')}>
                                                    <div className={cx('row')}>
                                                        <div
                                                            className={cx(
                                                                'col-4 col-sm-3 col-md-7 col-xxl-6',
                                                                'rest-text-detail',
                                                            )}
                                                        >
                                                            Point:
                                                        </div>
                                                        <div
                                                            className={cx(
                                                                'col-6 col-sm-3 col-md-5 col-xxl-5 d-flex align-items-center',
                                                                'res-padding-zero',
                                                            )}
                                                        >
                                                            <input
                                                                type="number"
                                                                className={cx(
                                                                    'input-completed',
                                                                    auth.users.userType === 3 ? 'not-allow-input' : '',
                                                                )}
                                                                step="0.25"
                                                                value={dataTask.point}
                                                                disabled={auth.users.userType === 3}
                                                                onChange={(e) => handleInputPoint(e)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                {dataTask.refId !== 0 && (
                                                    <div className={cx('col-12 col-md-6 col-lg-6 mb-2')}>
                                                        <div className={cx('row')}>
                                                            <div
                                                                className={cx(
                                                                    'col-4 col-sm-3 col-md-7 col-xxl-4',
                                                                    'res-text-hour',
                                                                    'rest-text-detail',
                                                                )}
                                                            >
                                                                Level:
                                                            </div>
                                                            <div
                                                                className={cx(
                                                                    'col-6 col-sm-3 col-md-5 col-xxl-5 d-flex align-items-center',
                                                                    'res-padding-zero',
                                                                )}
                                                            >
                                                                <select
                                                                    //className="form-select form-select-lg mb-3"
                                                                    className={cx(
                                                                        'input-hours',
                                                                        auth.users.userType === 3
                                                                            ? 'not-allow-input'
                                                                            : '',
                                                                    )}
                                                                    aria-label=".form-select-lg example"
                                                                    id="input-level"
                                                                    onChange={(e) => handleInputLevel(e)}
                                                                    defaultValue={dataTask.level}
                                                                    disabled={auth.users.userType === 3}
                                                                >
                                                                    {dataTask.level === null && (
                                                                        <option
                                                                            value={0}
                                                                            className={cx('option-select')}
                                                                        >
                                                                            Select level
                                                                        </option>
                                                                    )}
                                                                    {['E', 'N', 'S'].map((level, index) => (
                                                                        <option
                                                                            value={level}
                                                                            key={index}
                                                                            className={cx('option-select')}
                                                                        >
                                                                            {level}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <h3 className={cx('form-comment__title', 'rest-text-detail')}>Comments</h3>
                                    <div className={cx('row', 'form-comment')}>
                                        <div className={cx('col-12', 'res-comment-editor')}>
                                            <EditReport
                                                editorState={editorState}
                                                onChange={(editorState) => setEditorState(editorState)}
                                            />
                                            {/* <Editor
                                            editorState={editorState}
                                            onEditorStateChange={onEditorStateChange}
                                            wrapperClassName="wrapper-class"
                                            editorClassName="editor-class"
                                            toolbarClassName="toolbar-class"
                                            placeholder="Enter your comment...."
                                        /> */}
                                        </div>
                                    </div>

                                    {/* <div className={cx('row', 'comment-notify')}>
                                    <div className={cx('col-2')}>Notify via Email:</div>
                                    <div className={cx('col-10', 'list-name__check')}>
                                        <div className={cx('notify-all')}>
                                            <input
                                                id="input-all-notify"
                                                type="checkbox"
                                                onChange={(e) => handleChangeCheckAll(e)}
                                            />
                                            <label htmlFor="input-all-notify" className={cx('checkbox-name')}>
                                                All
                                            </label>
                                        </div>
    
                                        <div className={cx('row')}>
                                            {users !== '' &&
                                                users.map((item, index) => (
                                                    <div className={cx('col-4', 'item-checkbox__name')} key={index}>
                                                        <input
                                                            type="checkbox"
                                                            id={`item_${item.id}`}
                                                            name={item.name}
                                                            checked={isChecked.includes(item.id)}
                                                            onChange={(e) => handleChangeCheckbox(e, item.id)}
                                                        />
                                                        <label
                                                            htmlFor={`item_${item.id}`}
                                                            className={cx('checkbox-name')}
                                                            dangerouslySetInnerHTML={{ __html: item.name }}
                                                        />
                                                    </div>
                                                ))}
                                        </div>
                                    </div>
                                </div> */}
                                    <div className={cx('d-flex justify-content-end mt-3')}>
                                        <Button
                                            className="btn-form__save"
                                            onClick={() => handleReport(dataTask.projectId)}
                                            radius8px
                                        >
                                            Post
                                        </Button>
                                        <Button className="btn-form__cancel" onClick={() => setOpen(false)} radius8px>
                                            Cancel
                                        </Button>
                                    </div>
                                    {/* <List
                                    width={971}
                                    height={600}
                                    rowHeight={140}
                                    rowCount={dataTask.taskRelations.length}
                                    
                                    rowRenderer={({ key, index, style }) => {
                                        const comment = dataTask.taskRelations[index];
                                        return (
                                            <div key={key}>
                                                
                                                <Comment
                                                    key={comment.id}
                                                    info={comment}
                                                    dataTask={dataTask}
                                                    setDataTask={setDataTask}
                                                />
                                            </div>
                                        );
                                    }}
                                />  */}
                                    <Suspense
                                        fallback={
                                            <ReactLoading type="spin" color="#0052cc" height={'20px'} width={'20px'} />
                                        }
                                    >
                                        {dataTask.taskRelations.slice(0, -1).map((info) => (
                                            <Comment
                                                key={info.id}
                                                info={info}
                                                dataTask={dataTask}
                                                setDataTask={setDataTask}
                                            />
                                        ))}
                                    </Suspense>
                                </div>

                                {/* right task */}
                                <div className={cx('col-12 col-xl-4')}>
                                    <div className={cx('row d-flex align-items-center', 'wrapper-status-task')}>
                                        <div className={cx('col-12 col-sm-4')}>
                                            <div className={cx('row align-items-center')}>
                                                <span
                                                    className={cx(
                                                        'col-2 col-sm-3',
                                                        'status-task__item',
                                                        'res-text-status',
                                                    )}
                                                >
                                                    Status
                                                </span>
                                                <div className={cx('col-6 col-sm-4', 'res-btn-status')}>
                                                    <Button
                                                        className={cx(
                                                            `list-project__item-status__${dataTask.status
                                                                .split(' ')
                                                                .join('')}`,
                                                        )}
                                                    >
                                                        {dataTask.status}
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className={cx(
                                                'col-12 col-sm-8',
                                                'd-flex',
                                                'status-task',
                                                'res-create-task-child',
                                            )}
                                        >
                                            {dataTask.refId === 0 && (
                                                <div className={cx('wrapper-btn-add-create-child')}>
                                                    <button
                                                        className={cx('btn-add-create-child')}
                                                        onClick={handleCreateChildTask}
                                                    >
                                                        <MdOutlineAddCircleOutline
                                                            className={cx('btn-add-create-child__icon')}
                                                        />
                                                        <span>Create Child Task</span>
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className={cx('row d-flex', 'title-task')}>
                                        <span className={cx('col-10', 'body-details__title')}>Details</span>
                                        <span className={cx('col-2', 'title-task__icon')}>
                                            <MdKeyboardArrowUp />
                                        </span>
                                    </div>

                                    <div className={cx('row', 'wrapper-body-details')}>
                                        <div className={cx('row d-flex', 'body-details__item')}>
                                            <span className={cx('col-4', 'body-details__title')}>Assignee</span>
                                            <span
                                                className={cx('col-8')}
                                                dangerouslySetInnerHTML={{ __html: dataTask.assignName }}
                                            />
                                        </div>
                                        <div className={cx('row d-flex', 'body-details__item')}>
                                            <span className={cx('col-4', 'body-details__title')}>Email</span>
                                            <span className={cx('col-8')}>{dataTask.assignEmail}</span>
                                        </div>
                                        <div className={cx('row d-flex', 'body-details__item')}>
                                            <span className={cx('col-4', 'body-details__title')}>Type</span>
                                            <span className={cx('col-8')}>{dataTask.typeName}</span>
                                        </div>
                                        <div className={cx('row d-flex', 'body-details__item')}>
                                            <span className={cx('col-4', 'body-details__title')}>Priority</span>
                                            <span className={cx('col-8 d-flex align-items-center')}>
                                                {(dataTask.priorityName === 'High' && <HighIcon />) ||
                                                    (dataTask.priorityName === 'Medium' && <MediumIcon />) ||
                                                    (dataTask.priorityName === 'Low' && <LowIcon />)}
                                                <span className={cx('details__priority')}>{dataTask.priorityName}</span>
                                            </span>
                                        </div>
                                        <div className={cx('row d-flex', 'body-details__item')}>
                                            <span className={cx('col-4', 'body-details__title')}>Due Date</span>
                                            <span className={cx('col-8')}>
                                                {dataTask.dueDate
                                                    ? moment(dataTask.dueDate).format('DD/MM/YYYY')
                                                    : 'None'}
                                            </span>
                                        </div>
                                        <div className={cx('row d-flex', 'body-details__item')}>
                                            <span className={cx('col-4', 'body-details__title')}>Est.Hour(h)</span>
                                            <span className={cx('col-8')}>
                                                {dataTask.estimatedHours ? `${dataTask.estimatedHours} h` : 'None'}
                                            </span>
                                        </div>
                                        <div className={cx('row d-flex', 'body-details__item')}>
                                            <span className={cx('col-4', 'body-details__title')}>Milestone</span>
                                            <span className={cx('col-8')}>
                                                {dataTask.milestone ? dataTask.milestone : 'None'}
                                            </span>
                                        </div>
                                        <div className={cx('row d-flex', 'body-details__item')}>
                                            <span className={cx('col-4', 'body-details__title')}>Hour(h)</span>
                                            <span className={cx('col-8')}>
                                                {dataTask.hours ? `${dataTask.hours} h` : 'None'}
                                            </span>
                                        </div>
                                        <div className={cx('row d-flex', 'body-details__item')}>
                                            <span className={cx('col-4', 'body-details__title')}>User Created</span>
                                            <span
                                                className={cx('col-8')}
                                                // {dataTask.userCreated ? `${dataTask.userCreated}` : 'None'}
                                                dangerouslySetInnerHTML={{ __html: dataTask.userCreated }}
                                            />
                                        </div>
                                        <div className={cx('row d-flex', 'body-details__item')}>
                                            <span className={cx('col-4', 'body-details__title')}>
                                                Date created task
                                            </span>
                                            <span className={cx('col-8')}>
                                                {dataTask.dtCreated
                                                    ? `${moment(dataTask.dtCreated).format('LL')}`
                                                    : 'None'}
                                            </span>
                                        </div>
                                        {/* <div className={cx('row d-flex', 'body-details__item')}>
                                            <span className={cx('col-4', 'body-details__title')}>Point</span>
                                            <span className={cx('col-8')}>
                                                {loadingInput ? (
                                                    <ReactLoading
                                                        type="spin"
                                                        color="#0052cc"
                                                        height={'20px'}
                                                        width={'20px'}
                                                    />
                                                ) : (
                                                    <>
                                                        {isShowInputPoint ? (
                                                            <EditPoint
                                                                dataTask={dataTask}
                                                                setDataTask={setDataTask}
                                                                setIsShowInputPoint={setIsShowInputPoint}
                                                                setLoadingInput={setLoadingInput}
                                                            />
                                                        ) : (
                                                            <>
                                                                {dataTask.point ? dataTask.point : 'None'}
                                                                {auth.users.userType !== 3 &&
                                                                    (dataTask.refId === 0
                                                                        ? dataTask.point <= 21
                                                                        : true) && (
                                                                        <FaEdit
                                                                            className={cx('icon-edit-point')}
                                                                            onClick={handleClickEditPoint}
                                                                        />
                                                                    )}
                                                            </>
                                                        )}
                                                    </>
                                                )}
                                            </span>
                                        </div> */}
                                        <div className={cx('row d-flex', 'body-details__item')}>
                                            <span className={cx('col-4', 'body-details__title')}>Completed</span>
                                            <span className={cx('col-8')}>
                                                <div className={cx('d-flex', 'glo-item-work__chart')}>
                                                    <div className={cx('glo-item-work__chart__background')}>
                                                        <div
                                                            className={cx('glo-item-work__chart__background__color')}
                                                            style={{
                                                                background: '#0052cc',
                                                                width: Math.round(dataTask.completedTask) + '%',
                                                                height: '7px',
                                                            }}
                                                        ></div>
                                                    </div>
                                                    <div className={cx('glo-item-work__value')}>
                                                        {Math.round(dataTask.completedTask)}%
                                                    </div>
                                                </div>
                                            </span>
                                        </div>
                                    </div>
                                    {/* <div className={cx('row', 'body-details__time')}>
                                    Created: &nbsp; &nbsp;{moment(dataTask.actualDtCreated).format('lll')}
                                </div> */}

                                    <div className={cx('row', 'mt-4', 'body-detail_task_children')}>
                                        <table className={cx('detail_task_children-res', 'glo-margin-0')}>
                                            <thead>
                                                <tr className={cx('title-name-column', 'glo-detail-child-tr')}>
                                                    <th className={cx('glo-detail-child-title')}>Title</th>
                                                    <th className={cx('d-flex', 'glo-detail-child-assign')}>
                                                        {sortType === 'assign' && isSortType === 'asc' && (
                                                            <RiSortAsc className={cx('notify-icon-sort')} />
                                                        )}
                                                        {sortType === 'assign' && isSortType === 'desc' && (
                                                            <RiSortDesc className={cx('notify-icon-sort')} />
                                                        )}
                                                        Assignee
                                                        <div className={cx('wrapper-icon-sort')}>
                                                            <TiArrowSortedUp
                                                                className={cx('table-content-title__sort-icon')}
                                                                onClick={handleSortUpAssignee}
                                                            />
                                                            <TiArrowSortedDown
                                                                className={cx('table-content-title__sort-icon')}
                                                                onClick={handleSortDownAssignee}
                                                            />
                                                        </div>
                                                    </th>
                                                    <th className={cx('d-flex', 'glo-detail-child-status')}>
                                                        {sortType === 'status' && isSortType === 'asc' && (
                                                            <RiSortAsc className={cx('notify-icon-sort')} />
                                                        )}
                                                        {sortType === 'status' && isSortType === 'desc' && (
                                                            <RiSortDesc className={cx('notify-icon-sort')} />
                                                        )}
                                                        Status
                                                        <div className={cx('wrapper-icon-sort')}>
                                                            <TiArrowSortedUp
                                                                className={cx('table-content-title__sort-icon')}
                                                                onClick={handleSortUpStatus}
                                                            />
                                                            <TiArrowSortedDown
                                                                className={cx('table-content-title__sort-icon')}
                                                                onClick={handleSortDownStatus}
                                                            />
                                                        </div>
                                                    </th>
                                                    <th className={cx('glo-detail-child-complete')}>Completed</th>
                                                </tr>
                                            </thead>
                                            <tbody className={cx('glo-body-scroll-detail')}>
                                                {eleParentTask && (
                                                    <tr
                                                        className={cx('body-detail_task-tr', 'glo-detail-child-tr')}
                                                        style={{
                                                            color: eleParentTask.id === taskId ? '#0052cc' : '',
                                                        }}
                                                        onClick={() => handleChangeDetailTask(eleParentTask.id)}
                                                    >
                                                        <td className={cx('glo-padding', 'glo-detail-child-title')}>
                                                            <div className={cx('glo-row', 'row')}>
                                                                <div className={cx('glo-star')}>
                                                                    <AiTwotoneStar style={{ color: '#ecd500' }} />
                                                                </div>

                                                                <div
                                                                    className={cx(
                                                                        'glo-text-overflow',
                                                                        'glo-title-task',
                                                                    )}
                                                                >
                                                                    <div className={cx('glo-text-overflow')}>
                                                                        {eleParentTask.title}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className={cx('glo-padding', 'glo-detail-child-assign')}>
                                                            <div
                                                                className={cx('glo-text-overflow')}
                                                                dangerouslySetInnerHTML={{
                                                                    __html: eleParentTask.assignName,
                                                                }}
                                                            ></div>
                                                        </td>

                                                        <td className={cx('glo-padding', 'glo-detail-child-status')}>
                                                            {eleParentTask.status}
                                                        </td>
                                                        <td
                                                            className={cx(
                                                                'glo-padding',
                                                                'glo-completed',
                                                                'glo-detail-child-complete',
                                                            )}
                                                        >
                                                            {Math.round(eleParentTask.completedTask)} %
                                                        </td>
                                                    </tr>
                                                )}

                                                {listTaskChild.length !== 0 &&
                                                    listTaskChild.map((task) => (
                                                        <tr
                                                            className={cx('body-detail_task-tr', 'glo-detail-child-tr')}
                                                            key={task.id}
                                                            style={{
                                                                color: task.id === taskId ? '#0052cc' : '',
                                                            }}
                                                            onClick={() => handleChangeDetailTask(task.id)}
                                                        >
                                                            <td className={cx('glo-padding', 'glo-detail-child-title')}>
                                                                <div className={cx('glo-row', 'row')}>
                                                                    {/* <div className={cx('glo-star')}>
                                                                    {task.refId === 0 ? (
                                                                        <AiTwotoneStar style={{ color: '#ecd500' }} />
                                                                    ) : (
                                                                        ''
                                                                    )}{' '}
                                                                </div> */}

                                                                    <div
                                                                        className={cx(
                                                                            'glo-text-overflow',
                                                                            'glo-title-task',
                                                                        )}
                                                                    >
                                                                        <div className={cx('glo-text-overflow')}>
                                                                            {task.title}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td
                                                                className={cx('glo-padding', 'glo-detail-child-assign')}
                                                            >
                                                                <div
                                                                    className={cx('glo-text-overflow')}
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: task.assignName,
                                                                    }}
                                                                ></div>
                                                            </td>

                                                            <td
                                                                className={cx('glo-padding', 'glo-detail-child-status')}
                                                            >
                                                                {task.status}
                                                            </td>
                                                            <td
                                                                className={cx(
                                                                    'glo-padding',
                                                                    'glo-completed',
                                                                    'glo-detail-child-complete',
                                                                )}
                                                            >
                                                                {task.completedTask} %
                                                            </td>
                                                        </tr>
                                                    ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </InfiniteScroll>
                    </div>
                )}
            </Dialog>

            <CreateTaskChild
                openDialogCreateTaskChild={openDialogCreateTaskChild}
                setOpenDialogCreateTaskChild={setOpenDialogCreateTaskChild}
                infoChildTask={infoChildTask}
                setInfoChildTask={setInfoChildTask}
                setCountReload={setCountReload}
                countReload={countReload}
            />

            <FormCreatePathFile
                taskId={taskId}
                openCreatePathFile={openCreatePathFile}
                setOpenCreatePathFile={setOpenCreatePathFile}
                setCallbackCreatePathFile={setCallbackCreatePathFile}
                callbackCreatePathFile={callbackCreatePathFile}
            />

            <FormUpdatePathFile
                taskId={taskId}
                openUpdatePathFile={openUpdatePathFile}
                setOpenUpdatePathFile={setOpenUpdatePathFile}
                setCallbackCreatePathFile={setCallbackCreatePathFile}
                callbackCreatePathFile={callbackCreatePathFile}
                pathFile={dataTask.pathFile}
            />
        </>
    );
}

export default memo(DetailTask);
