import api from '~/utils/main';

export const getInfoCreateTask = async (projectId, projects) => {
    try {
        const params = {
            projectId,
        };

        const res = await api.get(`task/init-create`, {
            params,
        });

        return res;
    } catch (error) {
        return error.response;
    }
};
