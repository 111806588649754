import classNames from 'classnames/bind';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React, { useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import styles from '../FormQuestion/FormQuestion.module.scss';
import { useSelector } from 'react-redux';
import api from '~/utils/main';
import * as reviewMemberService from '~/services/reviewMemberService';
import moment from 'moment';

const cx = classNames.bind(styles);

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 15,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

// const DataUser = [
//     { id: 1, userName: 'Hang Ngo', email: 'hangnt@glotech.vn' },
//     { id: 2, userName: 'Chau Thi Tu', email: 'a@glotech.vn' },
//     { id: 3, userName: 'Duong Ai Uyen', email: 'a@glotech.vn' },
//     { id: 4, userName: 'Nguyen Dinh Binh', email: 'a@glotech.vn' },
//     { id: 5, userName: 'Tuan Nguyen', email: 'a@glotech.vn' },
//     { id: 6, userName: 'An Ly', email: 'a@glotech.vn' },
//     { id: 7, userName: 'Le Van Duy', email: 'a@glotech.vn' },
//     { id: 8, userName: 'Dao Thanh Phat', email: 'a@glotech.vn' },
//     { id: 9, userName: 'Uyen Phuong', email: 'a@glotech.vn' },
//     { id: 10, userName: 'Nguyen Thi Hoa', email: 'a@glotech.vn' },
// ]

const DialogNotifyReview = ({ openDialogNotifyRv, setOpenDialogNotifyRv }) => {
    const [isCheckAllUser, setIsCheckAllUser] = useState(false);
    // const [isCheckUser, setIsCheckUser] = useState([]);
    const [isCheckUser, setIsCheckUser] = useState([]);
    const [listUser, setListUser] = useState([]);
    const [searchValue, setSearchValue] = React.useState('');

    const [validDate, setValidDate] = useState(false);
    const [validCheckUser, setValidCheckUser] = useState(false);

    const [monthRv, setMonthRv] = useState('');
    const [yearRv, setYearRv] = useState(new Date().getFullYear());
    const [dueDateRv, setDueDateRv] = useState('');
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(0);

    useEffect(() => {
        getListUser();
    }, [page]);

    const getListUser = async () => {
        const res = await api.get(`admin/user/${page}/20`).then((response) => {
            setListUser(response.data.result.items);
            setTotalPage(response.data.result.totalPages);
        });
    };

    const handleSearchFilter = (e) => {
        setSearchValue(e.target.value);
    };

    useEffect(() => {
        getSearchUser();
    }, [page, searchValue]);

    const getSearchUser = async (e) => {
        const res = await api.get(`admin/user/1/20?searchTerm=${searchValue}`).then((result) => {
            setListUser(result.data.result.items);
        });
    };

    const getKeyEnterSearchUser = async (e) => {
        if (e.key === 'Enter') {
            getSearchUser();
        }
    };

    const handleSelectAllUser = (e) => {
        // const checkBox = isCheckAllUser;
        // setIsCheckAllUser(!checkBox);

        // setIsCheckUser(listUser.map((item) => item.id));
        // if (isCheckAllUser) {
        //     setIsCheckUser([]);
        // }

        const checkBox = isCheckAllUser;
        setIsCheckAllUser(!checkBox);

        setIsCheckUser(listUser.map((item) => ({ userId: item.id })));

        if (isCheckAllUser) {
            setIsCheckUser([]);
        }
    };

    const handleClickUser = (e) => {
        // const { checked } = e.target;
        // const userId = +e.target.dataset.userid;
        // setIsCheckUser([...isCheckUser, userId]);
        // if (!checked) {
        //     setIsCheckUser(isCheckUser.filter((item) => item !== userId));
        // }

        const { checked } = e.target;
        const userId = +e.target.dataset.userid;
        setIsCheckUser([...isCheckUser, { userId: userId }]);
        if (!checked) {
            const newUser = [...isCheckUser];
            const re = newUser.filter((item) => item.userId !== userId);
            setIsCheckUser(re);
        }
    };

    const CheckValidDateReview = () => {
        if (monthRv !== '' && yearRv !== '' && dueDateRv !== '') {
            setValidDate(false);
        }

        if (monthRv === '' || yearRv === '' || dueDateRv === '') {
            setValidDate(true);
        }
    };

    const handleNotifyReview = async (e) => {
        e.preventDefault();

        CheckValidDateReview();

        const body = {
            month: Number(monthRv),
            year: Number(yearRv),
            dueDate: dueDateRv,
            infoUserSendMails: isCheckUser,
        };

        const checkHasUser = isCheckUser.length;

        if (checkHasUser > 0) {
            setValidCheckUser(false);
            const response = await reviewMemberService.sendEmailReview(body);

            if (response.status === 204) {
                setIsCheckUser([]);
                setIsCheckAllUser(false);
            }
        } else {
            setValidCheckUser(true);
        }
    };

    const handleCloseDialogViewReview = () => {
        setOpenDialogNotifyRv(false);
        setIsCheckUser([]);
        setIsCheckAllUser(false);
    };

    const handleChangeMonth = (e) => {
        setMonthRv(e.target.value);
    };

    const handleChangeYear = (e) => {
        setYearRv(e.target.value);
    };

    const handleChangeDueDate = (e) => {
        setDueDateRv(e.target.value);
    };

    return (
        <div className={cx('header-create-task')}>
            <form>
                <BootstrapDialog
                    open={openDialogNotifyRv}
                    onClose={handleCloseDialogViewReview}
                    maxWidth="1700px"
                    minwidth="1500px"
                    fullWidth
                    aria-labelledby="customized-dialog-title"
                >
                    <BootstrapDialogTitle
                        id="customized-dialog-title"
                        onClose={handleCloseDialogViewReview}
                        className={cx('custom-title-control')}
                    >
                        <div className={cx('dialog-create-project-title')}>
                            <h2>Notifications Review</h2>
                        </div>
                    </BootstrapDialogTitle>
                    <DialogContent dividers>
                        <div className={cx('dialog-notify-content')}>
                            <div className={cx('row', 'mb-3')}>
                                <div className={cx('col-12 col-xl-9')}>
                                    <div className={cx('row')}>
                                        <div className={cx('col-sm-2')}>
                                            <div className={cx('row')}>
                                                <label
                                                    htmlFor="monthReview"
                                                    className={cx('col-sm-5', 'col-form-label')}
                                                >
                                                    Month
                                                </label>
                                                <div className="col-sm-7">
                                                    <input
                                                        type="number"
                                                        className={cx('form-control', 'form-control-lg')}
                                                        id="monthReview"
                                                        min="1"
                                                        max="12"
                                                        value={monthRv}
                                                        onChange={handleChangeMonth}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className={cx('col-sm-2')}>
                                            <div className={cx('row')}>
                                                <label
                                                    htmlFor="yearReview"
                                                    className={cx('col-sm-5', 'col-form-label', 'text-end')}
                                                >
                                                    Year
                                                </label>
                                                <div className="col-sm-7">
                                                    <input
                                                        type="number"
                                                        className={cx('form-control', 'form-control-lg')}
                                                        id="yearReview"
                                                        min="2017"
                                                        value={yearRv}
                                                        onChange={handleChangeYear}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className={cx('col-sm-4')}>
                                            <div className={cx('row')}>
                                                <label
                                                    htmlFor="yearReview"
                                                    className={cx('col-sm-4', 'col-form-label', 'text-end')}
                                                >
                                                    Due Date
                                                </label>
                                                <div className="col-sm-8">
                                                    <input
                                                        type="date"
                                                        className={cx('form-control', 'form-control-lg')}
                                                        id="yearReview"
                                                        value={dueDateRv}
                                                        onChange={handleChangeDueDate}
                                                        min={moment(new Date())
                                                            .format('YYYY/MM/DD')
                                                            .replaceAll('/', '-')}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {validDate && (
                                        <small className={cx('text-danger')}>
                                            Month, year, and review due date cannot be left blank
                                        </small>
                                    )}
                                </div>

                                <div className={cx('col-12 col-xl-3')}>
                                    <div className={cx('row')}>
                                        <label
                                            htmlFor="search-user"
                                            className={cx(
                                                'col-12 col-lg-4 col-xl-3 col-xxl-2',
                                                'form-label',
                                                'lbl-search',
                                            )}
                                        >
                                            Search:
                                        </label>
                                        <div className={cx('col-12 col-lg-8 col-xl-9 col-xxl-10')}>
                                            <input
                                                type="search"
                                                placeholder="Search user..."
                                                className={cx('form-control', 'form-control-lg')}
                                                id="search-user"
                                                value={searchValue}
                                                onChange={handleSearchFilter}
                                                onKeyDown={getKeyEnterSearchUser}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {validCheckUser && (
                                <div className={cx('mb-3')}>
                                    <small className={cx('text-danger')}>
                                        You have not selected the user to send the review email
                                    </small>
                                </div>
                            )}
                            <div className={cx('notice-table-review')}>
                                <div className={cx('table-review-head')}>
                                    <div className={cx('tbl-head-check-user')}>
                                        <input
                                            type="checkbox"
                                            name="selectAllUser"
                                            id="selectAllUser"
                                            onChange={handleSelectAllUser}
                                            checked={isCheckAllUser}
                                        />
                                    </div>
                                    <div className={cx('tbl-head-username')}>User Name</div>
                                    <div className={cx('tbl-head-email')}>Email</div>
                                </div>
                                <div className={cx('table-review-body-scroll')}>
                                    {listUser.map((lstUser, idx) => {
                                        return (
                                            <div className={cx('table-review-body')} key={idx}>
                                                <div className={cx('tbl-item-check-user')}>
                                                    <input
                                                        type="checkbox"
                                                        name={lstUser.name}
                                                        data-userid={lstUser.id}
                                                        onChange={handleClickUser}
                                                        // checked={isCheckUser.includes(lstUser.id)}
                                                        checked={isCheckUser.some((ele) => ele.userId === lstUser.id)}
                                                    />
                                                </div>
                                                <div className={cx('tbl-item-username')}>{lstUser.userName}</div>
                                                <div className={cx('tbl-item-email')}>{lstUser.email}</div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions className={cx('review-action')}>
                        <button onClick={handleCloseDialogViewReview} className={cx('btn-review-cancel')} size="medium">
                            Cancel
                        </button>
                        <button onClick={handleNotifyReview} className={cx('hn-btn-submit')} size="medium">
                            Notify
                        </button>
                    </DialogActions>
                </BootstrapDialog>
            </form>
        </div>
    );
};

export default DialogNotifyReview;
