import classNames from 'classnames/bind';
import { useContext, useState } from 'react';
import { BiFilterAlt } from 'react-icons/bi';
import { FaSort } from 'react-icons/fa';
import styles from '~/pages/Home/components/MenuFilter/MenuFilter.module.scss';
import { Button } from '@mui/material';
import ReactLoading from 'react-loading';

import api from '~/utils/main';
import { GlobalStateProject } from '~/ProjectState/projectState';
import { useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
const cx = classNames.bind(styles);

const dataFilterStatus = [
    { id: 1, name: 'New', isChecked: false },
    { id: [2, 4], name: 'In Progress', isChecked: false },
    { id: 3, name: 'Closed', isChecked: false },
    { id: 5, name: 'Resolved', isChecked: false },
    { id: 7, name: 'Testing', isChecked: false },
    { id: 8, name: 'Fix Bug', isChecked: false },
    { id: 9, name: 'Test done', isChecked: false },
    { id: 10, name: 'Pending', isChecked: false },
];
const dataFilterPriority = [
    { id: 0, name: 'High' },
    { id: 1, name: 'Medium' },
    { id: 2, name: 'Low' },
];

function Filters(props) {
    const { listStatus, setListStatus, setListPriority, setAssignToList, listPriority, assignToList } = props;

    const state = useContext(GlobalStateProject);
    const [filterSearchIsOpen, setFilterSearchIsOpen] = useState(false);
    const [dropdownFilter, setDropdownFilter] = useState('none');
    const [isActiveStatusClass, setIsActiveStatusClass] = useState(false);
    const [filterSearchIsOpenStatus, setFilterSearchIsOpenStatus] = useState(false);
    const [displayStatusItem, setDisplayStatusItem] = useState('none');
    const [isActivePriorityClass, setIsActivePriorityClass] = useState(false);
    const [statusIds, setStatusIds] = state.projectsAPI.statusIds;
    const [priorityIds, setPriorityIds] = state.projectsAPI.priorityIds;
    // const [assignToIds, setAssignToIds] = state.projectsAPI.assignToIds;
    const [projectId, setProjectId] = state.projectsAPI.projectId;
    const [filterSearchIsOpenPriority, setFilterSearchIsOpenPriority] = useState(false);
    const [displayPriorityItem, setDisplayPriorityItem] = useState('none');
    const [isActiveAssignToClass, setIsActiveAssignToClass] = useState(false);
    const [filterSearchIsOpenAssignTo, setFilterSearchIsOpenAssignTo] = useState(false);
    const [displayAssignToItem, setDisplayAssignToItem] = useState('none');
    const [loadingUser, setLoadingUser] = useState(false);
    const [usersAssign, setUsersAssign] = useState([]);

    const token = useSelector((state) => state.token);

    useEffect(() => {
        if (filterSearchIsOpen) {
            showDropdown();
        } else {
            hiddenDropdown();
            // setUsersCreateBy([]);
            // setUsersAssign([]);
        }
    }, [filterSearchIsOpen]);

    const showDropdown = () => {
        setDropdownFilter('block');
    };

    const hiddenDropdown = () => {
        setDropdownFilter('none');
    };

    const showDislayPriority = () => {
        setDisplayPriorityItem('block');
        setDisplayAssignToItem('none');
        setDisplayStatusItem('none');

        setFilterSearchIsOpenStatus(false);
        setFilterSearchIsOpenAssignTo(false);
    };

    const hideDislayPriority = () => {
        setDisplayPriorityItem('none');
    };

    const showDislayAssignTo = () => {
        if (usersAssign.length === 0) {
            handleDataUserAssign();
        }
        setDisplayAssignToItem('block');
        setDisplayStatusItem('none');
        setDisplayPriorityItem('none');
        setFilterSearchIsOpenStatus(false);
        setFilterSearchIsOpenPriority(false);
    };

    const hideDislayAssignTo = () => {
        setIsActiveAssignToClass(false);
        setDisplayAssignToItem('none');
    };

    useEffect(() => {
        if (filterSearchIsOpenPriority) {
            showDislayPriority();
        } else {
            hideDislayPriority();
        }
    }, [filterSearchIsOpenPriority]);

    useEffect(() => {
        if (filterSearchIsOpenStatus) {
            showDislayStatus();
        } else {
            hideDislayStatus();
        }
    }, [filterSearchIsOpenStatus]);

    useEffect(() => {
        if (filterSearchIsOpenAssignTo) {
            showDislayAssignTo();
        } else {
            hideDislayAssignTo();
        }
    }, [filterSearchIsOpenAssignTo]);

    const showDislayStatus = () => {
        setDisplayStatusItem('block');
        setDisplayPriorityItem('none');
        setDisplayAssignToItem('none');

        setFilterSearchIsOpenPriority(false);
        setFilterSearchIsOpenAssignTo(false);
    };

    const hideDislayStatus = () => {
        setIsActiveStatusClass(false);
        setDisplayStatusItem('none');
    };

    function handleChangeCkeckedStatus(e, id) {
        const { checked } = e.target;
        // setPage(1);
        // setOffset(0);
        setListStatus([...listStatus, id]);
        if (!checked) {
            setListStatus(listStatus.filter((item) => item !== id));
        }
    }
    const handleChangePriority = (e, id) => {
        const { checked } = e.target;
        setListPriority([...listPriority, id]);
        if (!checked) {
            setListPriority(listPriority.filter((item) => item !== id));
        }
    };

    const handleFilterAssignTo = (e, id) => {
        const { checked } = e.target;
        setAssignToList([...assignToList, id]);
        if (!checked) {
            setAssignToList(assignToList.filter((item) => item !== id));
        }
    };

    const handleDataUserAssign = async () => {
        setLoadingUser(true);
        const id = projectId === '' ? 0 : projectId;
        const res = await api.get(`projects/${id}/users`);

        if (res.data.status === 'success') {
            setLoadingUser(false);
            setUsersAssign(res.data.data);
        }
    };

    return (
        <div className={cx('dropdown-filter', 'dropdown-filter-relative')}>
            <button
                className={cx('btn', 'btn-secondary', 'dropdown-toggle', 'btn-dropdown-filter')}
                onClick={() => {
                    setFilterSearchIsOpen(!filterSearchIsOpen);
                }}
                type="button"
                aria-expanded="false"
            >
                <BiFilterAlt /> Filters <FaSort />
            </button>
            <ul className={cx('dropdown-menu', 'main-menu')} style={{ display: dropdownFilter }}>
                <li>
                    <Button
                        className={
                            isActiveStatusClass
                                ? cx('dropdown-item', 'btn-custom-fil', 'btn-before-status')
                                : cx('dropdown-item', 'btn-custom-fil')
                        }
                        onClick={() => {
                            setFilterSearchIsOpenStatus(!filterSearchIsOpenStatus);
                        }}
                    >
                        Status
                    </Button>
                    <div className={cx('sub-menu-wp')} id="hn-test" style={{ display: displayStatusItem }}>
                        <ul className={cx('sub-menu')}>
                            {dataFilterStatus.map((data, index) => (
                                <li key={index}>
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            value={data.id}
                                            id={`custom-status-${index}`}
                                            checked={listStatus.includes(data.id)}
                                            onChange={(e) => handleChangeCkeckedStatus(e, data.id)}
                                        />
                                        <label className="form-check-label" htmlFor={`custom-status-${index}`}>
                                            {data.name}
                                        </label>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </li>
                <li>
                    <Button
                        className={
                            isActivePriorityClass
                                ? cx('dropdown-item', 'btn-custom-fil', 'btn-before-priority')
                                : cx('dropdown-item', 'btn-custom-fil')
                        }
                        onClick={() => {
                            setFilterSearchIsOpenPriority(!filterSearchIsOpenPriority);
                        }}
                    >
                        Priority
                    </Button>
                    <div className={cx('sub-menu-wp')} id="hn-test" style={{ display: displayPriorityItem }}>
                        <ul className={cx('sub-menu')}>
                            {dataFilterPriority.map((data, index) => (
                                <li key={index}>
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            value={data.id}
                                            id={`custom-priority-${index}`}
                                            checked={listPriority.includes(data.id)}
                                            onChange={(e) => handleChangePriority(e, data.id)}
                                        />
                                        <label className="form-check-label" htmlFor={`custom-priority-${index}`}>
                                            {data.name}
                                        </label>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </li>
                <li>
                    <Button
                        className={
                            isActiveAssignToClass
                                ? cx('dropdown-item', 'btn-custom-fil', 'btn-before-assign-to')
                                : cx('dropdown-item', 'btn-custom-fil')
                        }
                        onClick={() => {
                            setFilterSearchIsOpenAssignTo(!filterSearchIsOpenAssignTo);
                        }}
                    >
                        Assign to
                    </Button>
                    <div className={cx('sub-menu-wp')} id="hn-test" style={{ display: displayAssignToItem }}>
                        <ul className={cx('sub-menu')}>
                            {loadingUser === true && (
                                <ReactLoading type="spin" color="#ccc" height={'20px'} width={'20px'} />
                            )}
                            {usersAssign &&
                                usersAssign.map((data, index) => (
                                    <li key={index}>
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                value={data.id}
                                                id={`custom-assignto-${index}`}
                                                checked={assignToList.includes(data.id)}
                                                onChange={(e) => handleFilterAssignTo(e, data.id)}
                                            // defaultChecked={data.id === }
                                            />
                                            <label className="form-check-label" htmlFor={`custom-assignto-${index}`}>
                                                <span dangerouslySetInnerHTML={{ __html: data.name }} />
                                            </label>
                                        </div>
                                    </li>
                                ))}
                        </ul>
                    </div>
                </li>
            </ul>
        </div>
    );
}

export default Filters;
