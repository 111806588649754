import React, { useState } from 'react';
import classNames from 'classnames/bind';
import styles from '../../Admin.module.scss';
import { DialogActions, DialogContent } from '@mui/material';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
    BootstrapDialog,
    convertDuration,
    BootstrapDialogTitle,
    typeOff,
    listDuration,
    convertTypeOff,
} from '~/components/DayOffCommon';
import { useRef } from 'react';
import * as userService from '~/services/userService';
import { useSelector } from 'react-redux';
import { AiOutlineDelete } from 'react-icons/ai';
import { FaRegEdit } from 'react-icons/fa';
import Swal from 'sweetalert2';
import api from '~/utils/main';

const cx = classNames.bind(styles);

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const DetailDayOff = (props) => {
    const {
        openDialogDetail,
        setOpenDialogDetail,
        listDetail,
        setCheckCallBacks,
        setListDetail,
        userId,
        setUserId,
        getListDetailDayOff,
        getListDayOff,
    } = props;
    const [checkBntAdd, setCheckBntAdd] = useState(false);
    const auth = useSelector((state) => state.auth);
    // Add Start
    const inputDueDate = useRef();
    const [checkValidDate, setCheckValidDate] = useState(false);
    const [checkValidDateEnd, setCheckValidDateEnd] = useState(false);
    const [checkValidDuration, setCheckValidDuration] = useState(false);
    const [checkValidDurationEnd, setCheckValidDurationEnd] = useState(false);
    const [checkBox, setCheckBox] = useState(false);
    const [validDate, setValidDate] = useState('');
    const [validDateEnd, setValidDateEnd] = useState('');
    const [validDuration, setValidDuration] = useState('');
    const [validDurationEnd, setValidDurationEnd] = useState('');
    const [validTypeOff, setValidTypeOff] = useState(typeOff[0].value);
    const [infoDayoffEdit, setInfoDayoffEdit] = useState(null);
    const [isEditDayoff, setIsEditDayoff] = useState(false);

    // Add End

    const handleCloseDialog = () => {
        setOpenDialogDetail(false);
        // setListDetail([]);
        setCheckBntAdd(false);
        setInfoDayoffEdit(null);
        setIsEditDayoff(false);
        setUserId(null);
    };

    const handleBlack = () => {
        setCheckBntAdd(false);
    };

    const handleEditDayoff = (dayoff) => {
        setIsEditDayoff(true);
        setCheckBntAdd(!checkBntAdd);
        setInfoDayoffEdit(dayoff);
    };

    //Add Start
    const handleAddDayOff = () => {
        setCheckBntAdd(!checkBntAdd);
    };
    const handleChangeDate = (e) => {
        setCheckValidDate(false);
        const value = moment(new Date(e.target.value)).format('YYYY-MM-DD');
        setValidDate(value);

        if (isEditDayoff) {
            const value = moment(new Date(e.target.value)).format('YYYY-MM-DD');
            setInfoDayoffEdit({ ...infoDayoffEdit, dayOffUser: value });
        }
    };

    const handleChangeDateEnd = (e) => {
        setCheckValidDateEnd(false);
        const value = moment(new Date(e.target.value)).format('YYYY-MM-DD');
        setValidDateEnd(value);

        if (isEditDayoff) {
            const value = moment(new Date(e.target.value)).format('YYYY-MM-DD');
            setInfoDayoffEdit({ ...infoDayoffEdit, dayOffEndUser: value });
        }
    };

    const handleTypeOff = (value) => {
        setValidTypeOff(value);
        if (isEditDayoff) {
            setInfoDayoffEdit({ ...infoDayoffEdit, typeDayOff: +value });
        }
    };

    const handleDuration = (value) => {
        setCheckValidDuration(false);
        setValidDuration(value);

        if (isEditDayoff) {
            setInfoDayoffEdit({ ...infoDayoffEdit, duration: +value });
        }
    };

    const handleDurationEnd = (value) => {
        setCheckValidDurationEnd(false);
        setValidDurationEnd(value);

        if (isEditDayoff) {
            setInfoDayoffEdit({ ...infoDayoffEdit, durationEnd: +value });
        }
    };

    const handleClear = () => {
        setValidDate('');
        setValidDuration('');
        setCheckBox(false);
        setValidDateEnd('');
        setValidDurationEnd('');
        setValidTypeOff(typeOff[0].value);
    };

    const handleChangeCheckBox = (e) => {
        const { checked } = e.target;
        setCheckBox(checked);
    };

    const handleSend = async () => {
        var checkvalue = true;
        if (validDate === '') {
            setCheckValidDate(true);
            checkvalue = false;
        }
        if (validDuration === '') {
            setCheckValidDuration(true);
            checkvalue = false;
        }

        if (checkBox) {
            if (validDurationEnd === '') {
                setCheckValidDurationEnd(true);
                checkvalue = false;
            }
            if (validDateEnd === '') {
                setCheckValidDateEnd(true);
                checkvalue = false;
            }
        }

        if (isEditDayoff) {
            const data = {
                id: infoDayoffEdit.id,
                DayOffUser: infoDayoffEdit.dayOffUser,
                Duration: infoDayoffEdit.duration,
                Reason: infoDayoffEdit.reason,
                TypeDayOff: infoDayoffEdit.typeDayOff,
                ...(infoDayoffEdit.dayOffEndUser ? { DayOffEndUser: infoDayoffEdit.dayOffEndUser } : {}),
                ...(infoDayoffEdit.durationEnd ? { DurationEnd: infoDayoffEdit.durationEnd } : {}),
            };
            const res = await userService.updateDayOffUser(infoDayoffEdit.id, data);

            if (res.status === 200) {
                setCheckCallBacks(true);
                handleClear();
            }
        } else {
            if (checkvalue) {
                const data = {
                    DayOffUser: validDate,
                    Duration: +validDuration,
                    TypeDayOff: +validTypeOff,
                    UserId: userId,
                    Reason: 'Added by Admin',
                    ...(validDateEnd ? { DayOffEndUser: validDateEnd } : {}),
                    ...(validDurationEnd ? { DurationEnd: +validDurationEnd } : {}),
                };
                const res = await userService.postDayOff(data);
                if (res.status === 200) {
                    setCheckCallBacks(true);
                    handleClear();
                    setCheckBntAdd(false);
                }
            }
        }
    };

    // Add End

    const handleDeleteDayoff = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: `You want to delete this day off?`,
            icon: 'warning',
            width: '360px',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'OK',
        }).then(async (result) => {
            if (result.isConfirmed) {
                // setOpen(false);
                const res = await api.delete(`admin/user/dayoff-admin/${id}`);
                if (res.status === 200) {
                    getListDetailDayOff();
                    getListDayOff();
                }
                Swal.fire('Success!', `Delete day off successfully`, 'success');
            }
        });
    };

    return (
        <div className={cx('header-create-task')}>
            <form>
                <BootstrapDialog
                    open={openDialogDetail}
                    onClose={handleCloseDialog}
                    maxWidth="1700px"
                    minWidth="1500px"
                    fullWidth
                    aria-labelledby="customized-dialog-title"
                >
                    <BootstrapDialogTitle
                        id="customized-dialog-title"
                        onClose={handleCloseDialog}
                        className={cx('custom-title-control')}
                    >
                        <div className={cx('dialog-create-project-title')}>
                            <h2>Detail Day Off</h2>
                        </div>
                    </BootstrapDialogTitle>
                    <DialogContent dividers>
                        <div className={cx('wrapper-content')}>
                            <div className={cx('mb-4', 'profile-control')}>
                                {checkBntAdd === false && (auth.users.userType === 2 || auth.users.userType === 1) && (
                                    <button
                                        type="button"
                                        className={cx('btn', 'btn-profile-update')}
                                        onClick={handleAddDayOff}
                                    >
                                        Add
                                    </button>
                                )}
                            </div>
                            {checkBntAdd === false ? (
                                <form>
                                    {listDetail.length > 0 && (
                                        <div className={cx('list-user-table')}>
                                            <div className={cx('list-user_table')}>
                                                <div className={cx('list-user_title-table')}>
                                                    <div className={cx('glo-table-col')}>Date Start</div>
                                                    <div className={cx('glo-table-col')}>Duration Start</div>
                                                    <div className={cx('glo-table-col')}>Date End</div>
                                                    <div className={cx('glo-table-col')}>Duration End</div>
                                                    <div className={cx('glo-table-col')}>Type Off</div>
                                                    <div className={cx('glo-table-col')}>Reason</div>
                                                    <div className={cx('glo-table-col')}>Action</div>
                                                </div>

                                                {listDetail.map((item, index) => {
                                                    return (
                                                        <div className={cx('list-user_item-body-tr')} key={index}>
                                                            <div className={cx('glo-table-col')}>
                                                                {item.dayOffUser
                                                                    ? moment(new Date(item.dayOffUser)).format(
                                                                          'YYYY/MM/DD',
                                                                      )
                                                                    : ''}
                                                            </div>
                                                            <div className={cx('glo-table-col')}>
                                                                {convertDuration(item.duration)}
                                                            </div>
                                                            <div className={cx('glo-table-col')}>
                                                                {item.dayOffEndUser
                                                                    ? moment(new Date(item.dayOffEndUser)).format(
                                                                          'YYYY/MM/DD',
                                                                      )
                                                                    : ''}
                                                            </div>
                                                            <div className={cx('glo-table-col')}>
                                                                {convertDuration(item.durationEnd)}
                                                            </div>
                                                            <div className={cx('glo-table-col')}>
                                                                {convertTypeOff(item.typeDayOff)}
                                                            </div>
                                                            <div className={cx('glo-table-col')}>{item.reason}</div>
                                                            <div className={cx('glo-table-col')}>
                                                                <span
                                                                    className={cx(
                                                                        'glo-table-col-icon',
                                                                        'glo-table-col-icon_edit',
                                                                    )}
                                                                    onClick={() => handleEditDayoff(item)}
                                                                >
                                                                    <FaRegEdit />
                                                                </span>
                                                                <span
                                                                    className={cx(
                                                                        'glo-table-col-icon',
                                                                        'glo-table-col-icon_delete',
                                                                    )}
                                                                    onClick={() => handleDeleteDayoff(item.id)}
                                                                >
                                                                    <AiOutlineDelete />
                                                                </span>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    )}
                                </form>
                            ) : (
                                <div>
                                    <div className={cx('row')}>
                                        <div className={cx('col-6')}>
                                            <div className="mb-4">
                                                <label className="form-label">Date Start</label>
                                                <input
                                                    ref={inputDueDate}
                                                    type="date"
                                                    className="form-control form-control-lg"
                                                    id="milestone-create-duedate"
                                                    // min={moment(new Date()).format('YYYY/MM/DD').replaceAll('/', '-')}
                                                    onChange={(e) => handleChangeDate(e)}
                                                    onKeyDown={(e) => {
                                                        e.preventDefault();
                                                    }}
                                                    value={
                                                        infoDayoffEdit
                                                            ? moment(infoDayoffEdit.dayOffUser)
                                                                  .format('YYYY/MM/DD')
                                                                  .replaceAll('/', '-')
                                                            : moment(validDate)
                                                                  .format('YYYY/MM/DD')
                                                                  .replaceAll('/', '-')
                                                    }
                                                ></input>
                                            </div>
                                            {checkValidDate ? (
                                                <div className={cx('txt-valid-password')}>
                                                    <ul>
                                                        <li>Date Start is not void </li>
                                                    </ul>
                                                </div>
                                            ) : null}
                                        </div>
                                        <div className={cx('col-6')}>
                                            <div className="mb-4">
                                                <label className="form-label">Duration Start</label>
                                                <select
                                                    className="form-select form-select-lg "
                                                    aria-label=".form-select-lg example"
                                                    id="hn-create-assignee"
                                                    onChange={(e) => handleDuration(e.target.value)}
                                                    value={infoDayoffEdit ? infoDayoffEdit?.duration : validDuration}
                                                >
                                                    <option value="">---</option>
                                                    {listDuration.map((options) => {
                                                        return (
                                                            <option value={options.value} key={options.value}>
                                                                {options.name}
                                                            </option>
                                                        );
                                                    })}
                                                </select>
                                            </div>
                                            {checkValidDuration ? (
                                                <div className={cx('txt-valid-password')}>
                                                    <ul>
                                                        <li>Duration Start off is not void </li>
                                                    </ul>
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>

                                    <div className="mb-4">
                                        <input
                                            className={cx('form-check-input')}
                                            id="input-all-notify"
                                            type="checkbox"
                                            checked={checkBox}
                                            onChange={(e) => handleChangeCheckBox(e)}
                                        />
                                        <label
                                            htmlFor="input-all-notify"
                                            className={cx('checkbox-name', 'glo-padR-10')}
                                        >
                                            Choose if you want to off more than one day
                                        </label>
                                    </div>
                                    {checkBox || infoDayoffEdit?.durationEnd ? (
                                        <div className={cx('row')}>
                                            <div className={cx('col-6')}>
                                                <div className="mb-4">
                                                    <label className="form-label">Date End</label>
                                                    <input
                                                        ref={inputDueDate}
                                                        type="date"
                                                        className="form-control form-control-lg"
                                                        id="milestone-create-duedate"
                                                        min={moment(validDate)
                                                            .format('YYYY/MM/DD')
                                                            .replaceAll('/', '-')}
                                                        onChange={(e) => handleChangeDateEnd(e)}
                                                        onKeyDown={(e) => {
                                                            e.preventDefault();
                                                        }}
                                                        value={
                                                            infoDayoffEdit
                                                                ? moment(infoDayoffEdit?.dayOffEndUser)
                                                                      .format('YYYY/MM/DD')
                                                                      .replaceAll('/', '-')
                                                                : moment(validDateEnd)
                                                                      .format('YYYY/MM/DD')
                                                                      .replaceAll('/', '-')
                                                        }
                                                    ></input>
                                                </div>
                                                {checkValidDateEnd ? (
                                                    <div className={cx('txt-valid-password')}>
                                                        <ul>
                                                            <li>Date end is not void </li>
                                                        </ul>
                                                    </div>
                                                ) : null}
                                            </div>
                                            <div className={cx('col-6')}>
                                                <div className="mb-4">
                                                    <label className="form-label">Duration End</label>
                                                    <select
                                                        className="form-select form-select-lg "
                                                        aria-label=".form-select-lg example"
                                                        id="hn-create-assignee"
                                                        onChange={(e) => handleDurationEnd(e.target.value)}
                                                        value={infoDayoffEdit?.durationEnd || validDurationEnd}
                                                    >
                                                        <option value="">---</option>
                                                        {listDuration.map((options) => {
                                                            return (
                                                                <option value={options.value} key={options.value}>
                                                                    {options.name}
                                                                </option>
                                                            );
                                                        })}
                                                    </select>
                                                </div>
                                                {checkValidDurationEnd ? (
                                                    <div className={cx('txt-valid-password')}>
                                                        <ul>
                                                            <li>Duration end is not void </li>
                                                        </ul>
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div>
                                    ) : null}
                                    <div className={cx('row')}>
                                        <div className={cx('col-6')}>
                                            <div className="mb-4">
                                                <label className="form-label">Type off</label>
                                                <select
                                                    className="form-select form-select-lg "
                                                    aria-label=".form-select-lg example"
                                                    id="hn-create-assignee"
                                                    onChange={(e) => handleTypeOff(e.target.value)}
                                                    value={infoDayoffEdit?.typeDayOff || validTypeOff}
                                                >
                                                    {typeOff.map((options) => {
                                                        return (
                                                            <option value={options.value} key={options.value}>
                                                                {options.name}
                                                            </option>
                                                        );
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                        <div className={cx('col-6')}></div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </DialogContent>
                    <DialogActions className={cx('create-project-action')}>
                        <div className={cx('mb-4', 'profile-control')}>
                            <button
                                type="text"
                                className={cx('hn-btn-cancel')}
                                onClick={handleCloseDialog}
                                size="medium"
                            >
                                Cancel
                            </button>
                            {checkBntAdd === true && (
                                <>
                                    <button
                                        type="button"
                                        className={cx('btn', 'btn-profile-update', 'glo-marR-5')}
                                        onClick={handleBlack}
                                    >
                                        Back
                                    </button>
                                    <button
                                        type="button"
                                        className={cx('btn', 'btn-profile-update')}
                                        onClick={handleSend}
                                    >
                                        {isEditDayoff ? 'Save' : 'Send'}
                                    </button>
                                </>
                            )}
                        </div>
                    </DialogActions>
                </BootstrapDialog>
            </form>
        </div>
    );
};

export default DetailDayOff;
