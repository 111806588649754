import api from '~/utils/main';

export const updateTask = async (idStatus, idTask) => {
    const body = {
        legend: idStatus,
    };

    try {
        const res = await api.patch(`task/${idTask}/change-status`, body);
        return res;
    } catch (error) {
        console.log(error);
    }
};
