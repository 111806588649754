import classNames from 'classnames/bind';
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useRef } from 'react';
import { AiOutlineDownCircle, AiOutlineIssuesClose } from 'react-icons/ai';
import { BsReply } from 'react-icons/bs';
import styles from '../../Milestone.module.scss';
const cx = classNames.bind(styles);

const MilestoneControlTask = () => {
    const [dropdownControlIsOpen, setDropdownControlIsOpen] = useState(false);
    const [dropdownControl, setDropdownControl] = useState('none');

    const ref = useRef();

    useEffect(() => {
        if (dropdownControlIsOpen) {
            showDropdownControl();
        } else {
            hiddenDropdownControl();
        }
    }, [dropdownControlIsOpen]);

    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            if (dropdownControlIsOpen && ref.current && !ref.current.contains(e.target)) {
                setDropdownControlIsOpen(false);
            }
        };
        document.addEventListener('mousedown', checkIfClickedOutside);
        return () => {
            document.removeEventListener('mousedown', checkIfClickedOutside);
        };
    }, [dropdownControlIsOpen]);

    const showDropdownControl = () => {
        setDropdownControl('block');
    };

    const hiddenDropdownControl = () => {
        setDropdownControl('none');
    };

    return (
        <div>
            <div>
                <div className={cx('dropdown-filter')} ref={ref}>
                    <AiOutlineDownCircle className={cx('dropdown-toggle', 'milestone-item-right-icon')}
                        onClick={() => { setDropdownControlIsOpen(!dropdownControlIsOpen); }} aria-expanded="false" />
                    <ul className={cx('dropdown-menu', 'menu-task-milestone')} style={{ display: dropdownControl }}>
                        <li>
                            <div className={cx('milestone-task-item-li')}>
                                <AiOutlineIssuesClose />
                                <span className={cx('sp-item-li')}>Close</span>
                            </div>
                        </li>
                        <li>
                            <div className={cx('milestone-task-item-li')}>
                                <BsReply />
                                <span className={cx('sp-item-li')}>Reply</span>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default MilestoneControlTask