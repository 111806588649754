import classNames from 'classnames/bind';

import Button from '~/components/Button';
import styles from './Menu.module.scss';

const cx = classNames.bind(styles);

function MenuItem({ data, idTask, handleChangeTypeTask }) {
    const classes = cx('menu-item');

    return (
        <Button className={classes} leftIcon={data.icon} onClick={() => handleChangeTypeTask(data.id, idTask)}>
            <span className={cx('menu-item__text')}>{data.title}</span>
        </Button>
    );
}

export default MenuItem;
