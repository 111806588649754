import React, { createContext } from 'react';
import { useMemo } from 'react';
import { useState } from 'react';
// import projectsAPI from '~/services/projectsAPI';

// export const GlobalScrollRequirementChecklist = createContext();
export const GlobalScrollRequirementChecklist = createContext({
    scrollTop: 0,
    setScrollTop: () => { },
});

export const RequirementCheckListScroll = ({ children }) => {
    const [scrollTop, setScrollTop] = useState(0);

    const valueScroll = useMemo(
        () => ({ scrollTop, setScrollTop }),
        [scrollTop]
    );



    return <GlobalScrollRequirementChecklist.Provider value={valueScroll}>{children}</GlobalScrollRequirementChecklist.Provider>;
};
