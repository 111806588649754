import classNames from 'classnames/bind';
import { memo } from 'react';
import { useState } from 'react';
import styles from '../../pages/Home/components/DetailTask/DetailTask.module.scss';
import * as detailTaskService from '~/services/detailTaskService';
import { useContext } from 'react';
import { GlobalStateProject } from '~/ProjectState/projectState';
const cx = classNames.bind(styles);

function EditPoint(props) {
    const { dataTask, setIsShowInputPoint, setDataTask, setLoadingInput } = props;
    const state = useContext(GlobalStateProject);
    const [valuePoint, setValuePoint] = useState(dataTask.point);
    const [callback, setCallback] = state.projectsAPI.callback;

    const handleChangePoint = (e) => {
        setValuePoint(e.target.value);
    };

    const updatePoint = async (taskId, point) => {
        const res = await detailTaskService.updatePoint(taskId, point);
        return res;
    };

    const handleChangePointEvent = async (e) => {
        if (e.code === 'Enter') {
            const rs = await updatePoint(dataTask.taskId, e.target.value);
            setLoadingInput(true);

            if (rs.status === 204) {
                setDataTask({ ...dataTask, point: e.target.value });
                setIsShowInputPoint(false);
                setLoadingInput(false);
                setCallback(!callback);
            }
        }
    };

    return (
        <input
            className={cx('input-edit-point')}
            value={valuePoint}
            autoFocus
            onChange={(e) => handleChangePoint(e)}
            onKeyPress={handleChangePointEvent}
        />
    );
}

export default memo(EditPoint);
