import classNames from 'classnames/bind';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React, { useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import styles from '../AddUserProject/AddUserProject.module.scss';
import { Editor } from 'react-draft-wysiwyg';
import { convertToRaw, EditorState } from 'draft-js';
import Qs from 'qs';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { AiOutlineDelete } from 'react-icons/ai';
import { toast } from 'react-toastify';
import api from '~/utils/main';

const cx = classNames.bind(styles);

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 15,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const initialState = {
    userIds: [],
};

const AddUserProject = ({ openDialogAddUser, setOpenDialogAddUser, idProject, setIdProject, getListProject }) => {
    const [isCheckAllUser, setIsCheckAllUser] = useState(false);
    const [isCheckUser, setIsCheckUser] = useState([]);
    const [listUser, setListUser] = useState([]);
    const [searchValue, setSearchValue] = React.useState('');

    const token = useSelector((state) => state.token);

    useEffect(() => {
        idProject !== '' && getListUserAddProject();
    }, [idProject]);

    const getListUserAddProject = async () => {
        const response = await api.get(`projects/${idProject}/init-users`).then((response) => {
            setListUser(response.data.data);
        });
    };

    const filtered = listUser?.userOutProjects?.filter((dt) => {
        if (
            dt.name.toLowerCase().includes(searchValue.toLowerCase()) ||
            dt.name.toLowerCase().includes(searchValue.toLowerCase())
        )
            return dt;
    });

    const handleCloseCreateProjectDialog = () => {
        setOpenDialogAddUser(false);
        // setIdProject('');
        setIsCheckUser([]);
        setIsCheckAllUser(false);
    };

    const handleSearchFilter = (e) => {
        setSearchValue(e.target.value);
    };

    const handleSelectAllUser = (e) => {
        setIsCheckAllUser(!isCheckAllUser);
        setIsCheckUser(listUser.userOutProjects.map((item) => item.id));
        if (isCheckAllUser) {
            setIsCheckUser([]);
        }
    };

    const handleClickUser = (e) => {
        const { checked } = e.target;
        const userId = +e.target.dataset.userid;
        setIsCheckUser([...isCheckUser, userId]);
        if (!checked) {
            setIsCheckUser(isCheckUser.filter((item) => item !== userId));
        }
    };

    const handleAddUserProject = async (e) => {
        e.preventDefault();

        const body = {
            id: idProject,
            userIds: isCheckUser,
        };

        const res = await api.post(`projects/add-users`, body);
        if (res.status === 201) {
            getListUserAddProject();
            setIsCheckUser([]);
            getListProject();
            setIsCheckAllUser(false);
        }
    };

    const handleRemoveUserProject = async (e, id) => {
        e.preventDefault();

        const response = await toast.promise(
            api.delete(`projects`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                data: {
                    projectId: idProject,
                    userIds: [id],
                },
            }),
            {
                pending: 'Delete user is pending',
                success: 'Delete user successfully',
                error: 'Delete user failed',
            },
        );

        if (response.status === 204) {
            getListUserAddProject();
            setIsCheckUser([]);
            setIsCheckAllUser(false);
        }
    };

    return (
        <div className={cx('header-create-task')}>
            <form>
                <BootstrapDialog
                    open={openDialogAddUser}
                    onClose={handleCloseCreateProjectDialog}
                    // maxWidth="lg"
                    maxWidth="1700px"
                    minwidth="1500px"
                    fullWidth
                    aria-labelledby="customized-dialog-title"
                >
                    <BootstrapDialogTitle
                        id="customized-dialog-title"
                        onClose={handleCloseCreateProjectDialog}
                        className={cx('custom-title-control')}
                    >
                        <div className={cx('dialog-add-project-title')}>
                            <h2>Add User</h2>
                        </div>
                    </BootstrapDialogTitle>
                    <DialogContent dividers>
                        <div className={cx('dialog-add-project-content')}>
                            <div className={cx('row', 'mb-3', 'justify-content-end')}>
                                <div className={cx('col-12 col-lg-4 col-xl-3')}>
                                    <div className={cx('row')}>
                                        <label htmlFor="search-user" className={cx('col-12 col-lg-4 col-xl-3 col-xxl-2', 'form-label', 'lbl-search')}>
                                            Search:
                                        </label>
                                        <div className={cx('col-12 col-lg-8 col-xl-9 col-xxl-10')}>
                                            <input
                                                type="search"
                                                placeholder="Search user..."
                                                className={cx('form-control', 'form-control-lg', 'ip-search-user')}
                                                id="search-user"
                                                value={searchValue}
                                                onChange={handleSearchFilter}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={cx('row', 'mb-3')}>
                                <div className={cx('col-12 col-md-8', 'user-table-res', 'mb-table-user-res')}>
                                    <table className={cx('project-add_table')}>
                                        <thead>
                                            <tr className={cx('project-add_title-table')}>
                                                {/* check all */}
                                                <th className={cx('project-add_check-user')}>
                                                    <input
                                                        type="checkbox"
                                                        name="selectAllUser"
                                                        id="selectAllUser"
                                                        onChange={handleSelectAllUser}
                                                        checked={isCheckAllUser}
                                                    />
                                                </th>
                                                <th className={cx('project-add_title-username')}>User Name</th>
                                                <th className={cx('project-add_title-shortname')}>Short Name</th>
                                                <th className={cx('project-add_title-email')}>Email</th>
                                            </tr>
                                        </thead>
                                        <tbody className={cx('project-list-user-tb')}>
                                            {filtered?.map((listUP, index) => {
                                                return (
                                                    <tr className={cx('project-add_item-body-tr')} key={index}>
                                                        <td className={cx('project-add_item-check-user')}>
                                                            <input
                                                                type="checkbox"
                                                                name={listUP.name}
                                                                data-userid={listUP.id}
                                                                onChange={handleClickUser}
                                                                checked={isCheckUser.includes(listUP.id)}
                                                            />
                                                        </td>
                                                        <td className={cx('project-add_item-username')}>
                                                            {listUP.name} &nbsp;({listUP.userType})
                                                        </td>
                                                        <td className={cx('project-add_item-shortname')}>
                                                            {listUP.shortName}
                                                        </td>
                                                        <td className={cx('project-add_item-email')}>{listUP.email}</td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                                <div className={cx('col-12 col-md-4', 'user-table-res')}>
                                    <table className={cx('project-add_user-in-table')}>
                                        <thead>
                                            <tr className={cx('project-user_title-in-table')}>
                                                <th className={cx('project-username-title-in-pro')}>
                                                    User(s) in this Project
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className={cx('project-list-user-tb')}>
                                            {listUser &&
                                                listUser?.userInProjects?.map((listInUP, index) => {
                                                    return (
                                                        <tr className={cx('project_item-body-in-pro')} key={index}>
                                                            <td className={cx('project_item-username-in-pro')}>
                                                                {listInUP.name}
                                                            </td>
                                                            <td
                                                                className={cx('project_item-delete-user')}
                                                                onClick={(e) => handleRemoveUserProject(e, listInUP.id)}
                                                            >
                                                                <AiOutlineDelete
                                                                    className={cx('project-icon-delete')}
                                                                />
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions className={cx('create-project-action')}>
                        <button onClick={handleCloseCreateProjectDialog} className={cx('hn-btn-cancel')} size="medium">
                            Cancel
                        </button>
                        <button
                            type="submit"
                            onClick={handleAddUserProject}
                            className={cx('hn-btn-submit')}
                            size="medium"
                        >
                            Add
                        </button>
                    </DialogActions>
                </BootstrapDialog>
            </form>
        </div>
    );
};

export default AddUserProject;
