import React, { createContext } from 'react';
import projectsAPI from '~/services/projectsAPI';

export const GlobalStateProject = createContext();

export const ProjectState = ({ children }) => {
    const state = {
        projectsAPI: projectsAPI(),
    };

    return <GlobalStateProject.Provider value={state}>{children}</GlobalStateProject.Provider>;
};
