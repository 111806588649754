/* eslint-disable jsx-a11y/alt-text */
import classNames from 'classnames/bind';
import Dialog from '@mui/material/Dialog';
import React, { memo, useEffect, useState, useContext } from 'react';
import styles from './KanbanBug.module.scss';
import { BsFillBookmarkFill } from 'react-icons/bs';
import { IoMdClose } from 'react-icons/io';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { DragDropContext } from 'react-beautiful-dnd';
import AddBugDialog from './AddBugDialog';
import Button from '~/components/Button';
import initital from './initiaKanban';
import api from '~/utils/main';
import Column from './Element';
import { GlobalStateProject } from '~/ProjectState/projectState';

const cx = classNames.bind(styles);

const Container = styled.div`
    display: flex;
    border-radius: 6px;
    padding: 10px;
`;

const ListGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 8px;
`;

//1: New, 2: Fixing, 3: Fix Done, 4: Testing, 5: Test Fail, 6: Close
// rule BE
const ruleParent = {
    1: [1],
    2: [2],
    3: [3, 4],
    4: [3, 4, 5, 6],
    5: [4, 5],
    6: [4, 6],
};
const ruleChild = {
    1: [1, 2],
    2: [1, 2, 3],
    3: [2, 3],
    4: [4],
    5: [2, 3, 4],
    6: [6],
};

function KanbanBug(props) {
    const {
        open,
        setOpen,
        nameProject,
        idTask,
        getTranckBug,
        refId,
        idProject,
        setTypeTask,
        kanBanRefId,
        kanBanInfo,
        setKanBanInfo,
    } = props;

    const stateGlobal = useContext(GlobalStateProject);
    const [callback, setCallback] = stateGlobal.projectsAPI.callback;

    const [datakanbanBug, setDatakanbanBug] = useState(initital);
    const [checkDialogDetail, setDialogDetail] = useState(false);
    const [users, setUsers] = useState('');
    const [lists, setLists] = useState('');
    const [listTaskChild, setListTaskChild] = useState([]);

    const getAssignTo = async () => {
        const res = await api.get(`task/init-update`, {
            params: {
                easycaseId: idTask,
            },
        });
        if (res.data.status === 'success') {
            setUsers(res.data.data.userUpdates.map((x) => ({ ...x, selected: false })));
        }
    };

    const onDragEnd = async (result) => {
        const { destination, source, draggableId } = result;

        const start = lists.columns[source.droppableId];
        const finish = lists.columns[destination.droppableId];

        if (!destination) {
            return;
        }

        if (destination.droppableId === source.droppableId) {
            return;
        }
        if (kanBanRefId === 0 || kanBanInfo.typeId === 6) {
            // check rule
            if (!ruleParent[source.droppableId].includes(finish.id)) {
                toast.error(`You can not change status of this task`, {
                    position: 'bottom-left',
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                return;
            }
        } else {
            // check rule
            if (!ruleChild[source.droppableId].includes(finish.id)) {
                toast.error(`You can not change status of this task`, {
                    position: 'bottom-left',
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                return;
            }
        }
        const startTaskIds = Array.from(start.taskIds);
        startTaskIds.splice(source.index, 1);
        const newStart = {
            ...start,
            taskIds: startTaskIds,
        };

        const finishTaskIds = Array.from(finish.taskIds);
        finishTaskIds.splice(destination.index, 0, start.taskIds[source.index]);

        const newFinish = {
            ...finish,
            taskIds: finishTaskIds,
        };

        const newList = {
            ...lists,
            columns: {
                ...lists.columns,
                [newStart.id]: newStart,
                [newFinish.id]: newFinish,
            },
        };

        setLists(newList);

        const body = {
            easycaseId: kanBanInfo.typeId !== 6 ? idTask : kanBanInfo.refId,
            bugId: +draggableId,
            fromStatus: +result.source.droppableId,
            fromPriority: result.source.index + 1,
            toStatus: +result.destination.droppableId,
            toPriority: result.destination.index + 1,
            ...(kanBanInfo.typeId === 6 ? { createdByTask: idTask } : {}),
        };

        const res = await toast.promise(
            api.patch(`checking-bug/move-bug?easycaseId=${kanBanInfo.typeId !== 6 ? idTask : kanBanInfo.refId}`, body),
            {
                pending: 'Move to check bug is pending',
                success: 'Move to check bug successfully',
                error: {
                    render({ data }) {
                        return data.response.data.errorMessage;
                    },
                },
            },
        );

        if (res.status === 204) {
            setCallback(!callback);
            convertDataKanBan();
        }
    };

    useEffect(() => {
        if (idTask !== '') {
            convertDataKanBan();
            getAssignTo();
            setDialogDetail(false);
            getChildTask();
        }
    }, [open, idTask]);

    const convertDataKanBan = async () => {
        const res = await api.get(`checking-bug?easycaseId=${idTask}`);

        if (res.data.status === 'success') {
            const rs = Object.entries(datakanbanBug.columns).map(([columnId, column], index) => {
                const newColumn = { ...column };
                res.data.data.map((item, ind) => {
                    if (column.title === item.status) {
                        newColumn.taskIds = item.items;
                    }
                });
                const cloneDatakanbanBug = { ...datakanbanBug };
                cloneDatakanbanBug.columns[columnId] = newColumn;
                setLists(cloneDatakanbanBug);
            });
        }
    };

    const handleClose = () => {
        setOpen(false);
        setKanBanInfo({});
    };
    const handleButtonAdd = () => {
        setDialogDetail(true);
    };

    const getChildTask = async () => {
        const validDataChild = [];
        const res = await api.get(`${process.env.REACT_APP_BASE_URL}task/${idTask}/references`, {
            params: {
                id: idTask,
                projectId: idProject,
            },
        });
        if (res.data.status === 'success') {
            res.data.data.forEach((data) => {
                if (data.refId !== 0 && data.status === 'Resolved') {
                    validDataChild.push({ ...data, selected: false });
                }
            });
        }
        if (validDataChild.length) {
            setListTaskChild(validDataChild);
        }
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="1700px"
            minwidth="1500px"
            className={cx('glo-index-dialog')}
        >
            <div className={cx('glo-dialog', 'glo-dialog-kanban')}>
                <div className={cx('glo-header-kanban')}>
                    <div className={cx('row', 'glo-wrapper')}>
                        <div className={cx('col-8', 'wrapper-form')}>
                            <div className={cx('wrapper-form-project', 'glo-text')}>
                                <BsFillBookmarkFill className={cx('wrapper-form__icon')} />
                                {nameProject !== '' && (
                                    <span className={cx('wrapper-form__name')}>&nbsp;{nameProject}</span>
                                )}
                            </div>
                        </div>
                        <div className={cx('col-4')}>
                            <div className={cx('d-flex justify-content-end', 'wrapper-icon')}>
                                <button className={cx('btn-icon-item')} onClick={handleClose}>
                                    <IoMdClose className={cx('icon-item')} />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    {!checkDialogDetail ? (
                        <>
                            {(kanBanRefId === 0 || kanBanInfo.typeId == 6) && (
                                <div className={cx('row', 'glo-row')}>
                                    <div className={cx('glo-button-add-kanban')}>
                                        <Button className="btn-form__save" onClick={() => handleButtonAdd()} radius8px>
                                            Add
                                        </Button>
                                    </div>
                                </div>
                            )}

                            <div className={cx('glo-body-kanban')}>
                                <div className={cx('glo-body-kanban-content')}>
                                    <Container>
                                        <DragDropContext onDragEnd={onDragEnd}>
                                            <ListGrid>
                                                {lists !== '' &&
                                                    lists.columnOrder.map((columnId, index) => {
                                                        const column = lists.columns[columnId];
                                                        const tasks = column.taskIds.map((list) => list);
                                                        return (
                                                            <Column
                                                                key={index}
                                                                column={column}
                                                                tasks={tasks}
                                                                users={users}
                                                                open={open}
                                                                setOpen={setOpen}
                                                                convertDataKanBan={convertDataKanBan} //load laij trang
                                                                setUsers={setUsers}
                                                                listTaskChild={listTaskChild}
                                                                setListTaskChild={setListTaskChild}
                                                                idProject={idProject}
                                                                setTypeTask={setTypeTask}
                                                                kanBanRefId={kanBanRefId}
                                                            />
                                                        );
                                                    })}
                                            </ListGrid>
                                        </DragDropContext>
                                    </Container>
                                </div>
                            </div>
                        </>
                    ) : (
                        <AddBugDialog
                            idTask={idTask}
                            checkDialogDetail={checkDialogDetail}
                            setDialogDetail={setDialogDetail}
                            users={users}
                            setUsers={setUsers}
                            getTranckBug={getTranckBug}
                            convertDataKanBan={convertDataKanBan}
                            refId={refId}
                            idProject={idProject}
                            listTaskChild={listTaskChild}
                            setListTaskChild={setListTaskChild}
                            kanBanInfo={kanBanInfo}
                        />
                    )}
                </div>
            </div>
        </Dialog>
    );
}

export default memo(KanbanBug);
