import Button from '~/components/Button';
import classNames from 'classnames/bind';
import { useContext, useState, useEffect } from 'react';
import styles from './KanbanBug.module.scss';
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import '~/pages/Home/components/DetailTask/FormEdit.scss';
import Fancybox from './components/fancybox';
import { useSelector } from 'react-redux';
import moment from 'moment';
import draftToHtml from 'draftjs-to-html';
import { toast } from 'react-toastify';
import { isFulfilled } from '@reduxjs/toolkit';
import api from '~/utils/main';
import { MdAddCircleOutline } from 'react-icons/md';
import { AiOutlineDelete } from 'react-icons/ai';
import * as _ from 'lodash';
import { GlobalStateProject } from '~/ProjectState/projectState';

const cx = classNames.bind(styles);

const imageTypeRegex = /image\/(png|jpg|jpeg)/gm;

function AddBugDialog(props) {
    const { idTask, setDialogDetail, users, convertDataKanBan, refId, listTaskChild, kanBanInfo } = props;
    const token = useSelector((state) => state.token);
    const state = useContext(GlobalStateProject);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [imageFiles, setImageFiles] = useState([]);
    const [files, setFiles] = useState([]);
    const [images, setImages] = useState([]);
    const [title, setTitle] = useState();
    const [checkTitle, setCheckTitle] = useState(false);
    const [valuePicker, setValuePicker] = useState(null);

    const [callback, setCallback] = state.projectsAPI.callback;

    const [assignUser, setAssignUser] = useState(1);
    const [checkValue, setCheckValue] = useState(false);
    const auth = useSelector((state) => state.auth);

    const [filesTarget, setFileTarget] = useState([]);
    const [imagesTaget, setImagesTaget] = useState([]);

    const [checkAssignBug, setCheckAssignBug] = useState(false);

    const [arAssignBug, setArAssignBug] = useState([]);

    const [inputList, setInputList] = useState([]);

    const onEditorStateChange = (editorState) => {
        return setEditorState(editorState);
    };

    useEffect(() => {
        const images = [],
            fileReaders = [];
        let isCancel = false;
        if (imageFiles.length) {
            imageFiles.forEach((file) => {
                const fileReader = new FileReader();
                fileReaders.push(fileReader);
                fileReader.onload = (e) => {
                    const { result } = e.target;
                    if (result) {
                        images.push(result);
                    }
                    if (images.length === imageFiles.length && !isCancel) {
                        setImages(images);
                    }
                };
                fileReader.readAsDataURL(file);
            });
        }
        return () => {
            isCancel = true;
            fileReaders.forEach((fileReader) => {
                if (fileReader.readyState === 1) {
                    fileReader.abort();
                }
            });
        };
    }, [imageFiles]);

    const changeHandleImage = (e) => {
        setImagesTaget(e.target.files);

        const { files } = e.target;
        const validImageFiles = [];
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            if (file.type.match(imageTypeRegex)) {
                validImageFiles.push(file);
            }
        }

        if (validImageFiles.length) {
            setImageFiles(validImageFiles);
            return;
        }
    };

    const changeHandlerFile = (e) => {
        setFileTarget(e.target.files);
        const { files } = e.target;

        const validFiles = [];
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            validFiles.push(file);
        }
        if (validFiles.length) {
            setFiles(validFiles);
            return;
        }
    };

    const handleChangeTitle = (e) => {
        setCheckTitle(false);
        setTitle(e.target.value);
    };

    const handleChangeDate = (e) => {
        const value = moment(new Date(e.target.value)).format('YYYY-MM-DD');
        setValuePicker(value);
    };

    const checkValueNull = (value) => {
        if (value === '' || value === undefined || value === null) {
            setCheckValue(true);
            return true;
        }
        return false;
    };

    const handleAssignTo = (e) => {
        setAssignUser(e.target.value);
    };

    const clickButtonImage = () => {
        document.getElementById('list_file').click();
    };
    const clickButtonFile = () => {
        document.getElementById('filexcel').click();
    };

    useEffect(() => {
        // getChildTask();
    }, [idTask]);

    const handleInputChange = (e, index, nameEvent) => {
        var list = _.cloneDeep(inputList);
        var currentObject = list[index];

        if (nameEvent === 'task') {
            var nameAssignBug = '';

            for (let index = 0; index < currentObject.listTaskChild.length; index++) {
                if (currentObject.listTaskChild[index].id === Number(e.target.value)) {
                    currentObject.listTaskChild[index].selected = true;
                    currentObject.idTask = Number(e.target.value);

                    nameAssignBug = currentObject.listTaskChild[index].assignName;
                } else {
                    currentObject.listTaskChild[index].selected = false;
                }
            }
            for (let index = 0; index < currentObject.users.length; index++) {
                if (currentObject.users[index].name === nameAssignBug) {
                    currentObject.users[index].selected = true;
                    currentObject.assignTask = currentObject.users[index].id;
                } else {
                    currentObject.users[index].selected = false;
                }
            }
        }

        if (nameEvent === 'bug') {
            for (let index = 0; index < currentObject.users.length; index++) {
                if (currentObject.users[index].id === Number(e.target.value)) {
                    currentObject.users[index].selected = true;

                    currentObject.assignTask = Number(e.target.value);
                } else {
                    currentObject.users[index].selected = false;
                }
            }
        }
        if (nameEvent === 'title') {
            currentObject.titleTask = e.target.value;
        }

        setInputList(list);
    };

    const handleRemoveClick = (index) => {
        const list = [...inputList];
        list.splice(index, 1);
        setInputList(list);
    };

    const handleAddAssignTask = () => {
        setCheckAssignBug(true);
        listTaskChild.forEach((data) => {
            data.selected = false;
        });
        users.forEach((data) => {
            data.selected = false;
        });
        setInputList([
            ...inputList,
            {
                listTaskChild: _.cloneDeep(listTaskChild),
                users: _.cloneDeep(users),
                idTask: '',
                assignTask: '',
                titleTask: '',
            },
        ]);
    };

    const handleAddCard = async () => {
        if (checkValueNull(title)) {
            setCheckTitle(true);
            return;
        }
        var bodyFormData = new FormData();
        var arAssignBug = [];
        arAssignBug = inputList.map((item) => ({
            assignTo: item.assignTask,
            easycaseId: item.idTask,
            title: item.titleTask,
        }));

        bodyFormData.append('EasycaseId', idTask);
        bodyFormData.append('Title', title);
        bodyFormData.append('Label', null);
        bodyFormData.append('Description', draftToHtml(convertToRaw(editorState.getCurrentContent())) || '');
        bodyFormData.append('AssignTo', assignUser);
        bodyFormData.append('DueDate', valuePicker ? valuePicker : '');
        bodyFormData.append('Completed', 0);
        bodyFormData.append('Status', 1);
        kanBanInfo.typeId === 6 && bodyFormData.append('RefId', kanBanInfo.refId);

        const listFiles = [...imagesTaget, ...filesTarget];

        listFiles.forEach((file) => {
            bodyFormData.append('Files', file);
        });
        arAssignBug.forEach((agn, idx) => {
            bodyFormData.append(`AssignBugs[${idx}].EasycaseId`, agn.easycaseId);
            bodyFormData.append(`AssignBugs[${idx}].Title`, agn.title);
            bodyFormData.append(`AssignBugs[${idx}].AssignTo`, agn.assignTo);
        });

        const res = await toast.promise(
            api.post(`checking-bug`, bodyFormData, {
                headers: {
                    'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>',
                },
            }),
            {
                pending: 'Add bug is pending',
                success: 'Add bug successfully',
                error: {
                    render({ data }) {
                        return data.response.data.errorMessage;
                    },
                },
            },
        );
        if (res.data.status === 'success') {
            setDialogDetail(false);
            convertDataKanBan();
            setCallback(!callback);
        }
    };

    return (
        <div className={cx('glo-scroll-dialog')}>
            <div>
                <div className={cx('row', 'glo-margin-title')}>
                    <div className={cx('col-12 col-sm-1')}>
                        <label htmlFor="hn-create-project" className="form-label">
                            Title<i className={cx('hn-color-red')}>*</i>
                        </label>
                    </div>
                    <div className={cx('col-12 col-sm-11')}>
                        <input
                            type="text"
                            className="form-control form-control-lg"
                            id="hn-create-title"
                            placeholder="Title"
                            onChange={(e) => handleChangeTitle(e)}
                        />
                    </div>
                </div>
                <div className={cx('row', 'glo-margin-title')}>
                    {checkTitle && <span className={cx('hn-color-red', 'glo-padding-10')}>Title not null</span>}
                </div>
            </div>
            <div className={cx('row', 'glo-row', 'mb-3', 'glo-padd-editor')}>
                <label className={cx('form-label', 'glo-no-padding')}>Description</label>
                <Editor
                    editorState={editorState}
                    onEditorStateChange={onEditorStateChange}
                    wrapperClassName="wrapper-class wrapper-class-addKanbanBug res-description"
                    editorClassName="editor-class"
                    toolbarClassName="toolbar-class"
                    placeholder="Enter your description...."
                />
            </div>
            <div className={cx('mt-4')}>
                <div className={cx('row', 'glo-row')}>
                    <div className={cx('col-12 col-md-6', 'glo-padding-10')}>
                        <div className={cx('row', 'glo-row')}>
                            <div className={cx('col-12 col-md-4 col-lg-3')}>
                                <label htmlFor="hn-create-project" className="form-label">
                                    Assign to:
                                </label>
                            </div>
                            <div className={cx('col-12 col-md-8 col-lg-9')}>
                                <select
                                    className="form-select form-select-lg mb-3"
                                    aria-label=".form-select-lg example"
                                    id="selectUser"
                                    onChange={(e) => handleAssignTo(e)}
                                >
                                    {users?.length !== 0 &&
                                        users?.map((user) => {
                                            return (
                                                <option
                                                    value={user.id}
                                                    key={user.id}
                                                    dangerouslySetInnerHTML={{
                                                        __html: user.name,
                                                    }}
                                                ></option>
                                            );
                                        })}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className={cx('col-12 col-md-6')}>
                        <div className={cx('row', 'glo-row')}>
                            <div className={cx('col-12 col-md-4 col-lg-3')}>
                                <label htmlFor="hn-create-project" className="form-label">
                                    Due Date:
                                </label>
                            </div>
                            <div className={cx('col-12 col-md-8 col-lg-9')}>
                                <input
                                    type="date"
                                    className="form-control form-control-lg"
                                    id="duedate"
                                    value={moment(valuePicker).format('YYYY/MM/DD').replaceAll('/', '-')}
                                    min={moment(new Date()).format('YYYY/MM/DD').replaceAll('/', '-')}
                                    onChange={(e) => handleChangeDate(e)}
                                    onKeyDown={(e) => {
                                        e.preventDefault();
                                    }}
                                ></input>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {refId === 0 && (
                <div>
                    <div className={cx('mt-4', 'glo-padding-18')}>
                        <div>Assignee bug</div>
                        <div className={cx('row', 'glo-row')}></div>
                    </div>
                    <div className={cx('mt-4', 'glo-padding-18')}></div>
                </div>
            )}
            <div className={cx('mt-4', 'add-bug-assign')}>
                <label className={cx('lbl-assign-bug')}>Assign Bug</label>
                <div className={cx('assign-bug-icon-add')}>
                    <MdAddCircleOutline onClick={handleAddAssignTask} />
                </div>
            </div>
            {checkAssignBug && (
                <>
                    {inputList?.map((x, i) => {
                        return (
                            <div key={i}>
                                <div className={cx('mt-4')}>
                                    <div className={cx('row', 'glo-row')}>
                                        <div className={cx('col-12 col-lg-4', 'glo-padding-10')}>
                                            <div className={cx('row', 'glo-row')}>
                                                <div className={cx('col-12 col-lg-3')}>
                                                    <label htmlFor="hn-create-project" className="form-label">
                                                        Name Task
                                                    </label>
                                                </div>
                                                <div className={cx('col-12 col-lg-9')}>
                                                    {/* {x.listTaskChild?.length > 0 && ( */}
                                                    <select
                                                        className="form-select form-select-lg mb-3"
                                                        name="idTask"
                                                        onChange={(e) => handleInputChange(e, i, 'task')}
                                                        value={
                                                            x.listTaskChild?.find((child) => child.selected === true)
                                                                ?.id
                                                        }
                                                    >
                                                        <option>----</option>
                                                        {x.listTaskChild.map((data) => {
                                                            return (
                                                                <option value={data.id} key={data.id}>
                                                                    {data.title}
                                                                </option>
                                                            );
                                                        })}
                                                    </select>
                                                    {/* )} */}
                                                </div>
                                            </div>
                                        </div>
                                        <div className={cx('col-12 col-lg-4', 'glo-padding-10')}>
                                            <div className={cx('row', 'glo-row')}>
                                                <div className={cx('col-12 col-lg-3')}>
                                                    <label htmlFor="hn-create-project" className="form-label">
                                                        Assign bug
                                                    </label>
                                                </div>
                                                <div className={cx('col-12 col-lg-9')}>
                                                    <select
                                                        className="form-select form-select-lg mb-3"
                                                        id={`nameAssignBug${i}`}
                                                        name="assignBug"
                                                        onChange={(e) => handleInputChange(e, i, 'bug')}
                                                        value={x.users?.find((us) => us.selected === true)?.id}
                                                    >
                                                        <option>----</option>
                                                        {x.users?.length !== 0 &&
                                                            x.users?.map((user) => {
                                                                return (
                                                                    <option value={user.id} key={user.id}>
                                                                        <span
                                                                            dangerouslySetInnerHTML={{
                                                                                __html: user.name,
                                                                            }}
                                                                        ></span>
                                                                    </option>
                                                                );
                                                            })}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={cx('col-12 col-lg-4', 'glo-padding-10')}>
                                            <div className={cx('row', 'glo-row')}>
                                                <div className={cx('col-12 col-lg-3')}>
                                                    <label htmlFor="title-add-assign-bug" className="form-label">
                                                        Title bug
                                                    </label>
                                                </div>
                                                <div className={cx('col-12 col-lg-8')}>
                                                    <input
                                                        type="text"
                                                        className="form-control form-control-lg"
                                                        name="titleTask"
                                                        value={x.titleTask}
                                                        placeholder="Title Task"
                                                        onChange={(e) => handleInputChange(e, i, 'title')}
                                                    />
                                                </div>
                                                <div
                                                    className={cx(
                                                        'col-12 col-sm-1',
                                                        'glo-padding-10',
                                                        'res-delete-assign-bug',
                                                    )}
                                                >
                                                    <AiOutlineDelete
                                                        onClick={() => handleRemoveClick(i)}
                                                        className={cx('glo-btn-delete-assing-bug-pc')}
                                                    />
                                                    <button
                                                        onClick={() => handleRemoveClick(i)}
                                                        className={cx(
                                                            'btn-delete-text-assign-bug',
                                                            'glo-btn-delete-assing-bug-mb',
                                                        )}
                                                        size="medium"
                                                    >
                                                        Delete assign bug
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </>
            )}

            {/* <div className={cx('mt-4')}>
                <div className={cx('row', 'glo-row', 'glo-padd')}>
                    <div className={cx('')}>
                        <div className={cx('row', 'glo-row')}>
                            <label className={cx('form-label', 'glo-no-padding')}>Image</label>
                            <button className={cx('glo-button-image')} onClick={clickButtonImage}>
                                add images
                            </button>
                            <input
                                onChange={changeHandleImage}
                                type="file"
                                id="list_file"
                                name="list_file[]"
                                accept=".jpg, .jpeg, .png"
                                className={cx('glo-no-padding', 'list-file')}
                                multiple
                            />
                        </div>
                        <div className={cx('mt-3')}>
                            {images.length > 0 ? (
                                <div className={cx('row', 'glo-row')}>
                                    {images.map((image, idx) => {
                                        return (
                                            <Fancybox key={idx}>
                                                <p className={cx('glo-img-fancybox')}>
                                                    <a data-fancybox="gallery" href={image}>
                                                        <img src={image} alt="" width="80px" height="80px" />
                                                    </a>
                                                </p>
                                            </Fancybox>
                                        );
                                    })}
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
            <div className={cx('mt-4')}>
                <div className={cx('row', 'glo-row', 'mb-3', 'glo-padd')}>
                    <div className={cx('')}>
                        <div className={cx('row', 'glo-row')}>
                            <label className={cx('form-label', 'glo-no-padding')}>File</label>
                            <button className={cx('glo-button-image')} onClick={clickButtonFile}>
                                add files
                            </button>
                            <input
                                type="file"
                                id="filexcel"
                                name="myfilexcel"
                                onChange={changeHandlerFile}
                                className={cx('glo-no-padding', 'glo-filexcel')}
                                multiple
                            />
                        </div>
                        <div className={cx('mt-3')}>
                            {files.length > 0 ? (
                                <div className={cx('row', 'glo-row')}>
                                    {files.map((file, idx) => {
                                        return <p key={idx} className={cx('txt-name-file')}>{file.name}</p>;
                                    })}
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div> */}
            <div className={cx('row', 'glo-row')}>
                <div className={cx('glo-button-add-kanban', 'mb-3')}>
                    <Button className="btn-form__save" radius8px onClick={() => handleAddCard()}>
                        Add
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default AddBugDialog;
