import axios from 'axios';

export const getHoliday = async () => {
    const BASE_CALENDAR_URL = 'https://www.googleapis.com/calendar/v3/calendars';
    const BASE_CALENDAR_ID_FOR_PUBLIC_HOLIDAY = 'holiday@group.v.calendar.google.com';
    const CALENDAR_REGION = 'en.vietnamese';
    const API_KEY = process.env.REACT_APP_API_GOOGLE_CALENDAR_HOLIDAY;

    try {
        const res = await axios.get(
            `${BASE_CALENDAR_URL}/${CALENDAR_REGION}%23${BASE_CALENDAR_ID_FOR_PUBLIC_HOLIDAY}/events?key=${API_KEY}`,
        );

        return res;
    } catch (error) {
        console.log(error);
    }
};
