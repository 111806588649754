import { tokenAction } from '.././actions';

const token = localStorage.getItem('accessToken') || '';

const tokenReducer = (state = token, action) => {
    switch (action.type) {
        case tokenAction.GET_TOKEN:
            return action.payload;
        default:
            return state;
    }
};

export default tokenReducer;
