// import './App.css';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import styled from 'styled-components';
// import Column from './Column';
import initital from './initialData';
import { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import Column from './Element';
import { GlobalStateProject } from '~/ProjectState/projectState';
import styles from './Board.module.scss';
import Filters from './components/Filters';
import classNames from 'classnames/bind';
import Qs from 'qs';
import api from '~/utils/main';
import { toast } from 'react-toastify';
import * as updateTaskService from '~/services/updateTaskService';
import * as kanbanTaskService from '~/services/kanbanTaskService';

const cx = classNames.bind(styles);

const Container = styled.div`
    display: flex;
`;

const ListGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 8px;
`;

// rule BE
const ruleDnD = {
    1: [2, 3, 10, 5, 7],
    2: [3, 10, 5, 7],
    3: [2, 10],
    5: [2, 3, 10, 7],
    7: [8, 9, 10],
    8: [3, 5, 10],
    9: [3, 7, 8, 10],
};

function Board({ handleClickOpen }) {
    const stateGlobal = useContext(GlobalStateProject);
    const [projectId, setProjectId] = stateGlobal.projectsAPI.projectId;
    const [callbackKanban, setCallbackKanban] = stateGlobal.projectsAPI.callbackKanban;
    const [callback, setCallback] = stateGlobal.projectsAPI.callback;
    const [projects, setProjects] = stateGlobal.projectsAPI.projects;
    const [state, setState] = useState(JSON.parse(JSON.stringify(initital)));
    const [callbackCpn, setCallbackCpn] = useState(false);
    const [lists, setLists] = useState({});
    const [listStatus, setListStatus] = useState([]);
    const [listPriority, setListPriority] = useState([]);
    const [assignToList, setAssignToList] = useState([]);
    const token = useSelector((state) => state.token);

    // console.log('projects', projects);

    const getData = () => {
        const newColumn = { ...state };
        const initial =
            (Object.entries(lists).length !== 0 ? Object.entries(lists) : false) || Object.entries(state.columns);

        const rs = initial.map(([columnId, column], index) => {
            projects.map((project, idx) => {
                project.taskItems.map((task, idx) => {
                    if (task.legend == columnId || task.legend == column.subId) {
                        newColumn.columns[columnId].taskIds.push(task);
                    }
                    task.taskAdditionalItems?.map((childTask, idx) => {
                        if (childTask.legend == columnId || childTask.legend == column.subId) {
                            newColumn.columns[columnId].taskIds.push(childTask);
                        }
                    });
                });
            });
            return column;
        });
        // rs();
        setLists(newColumn);
    };

    useEffect(() => {
        getData();
    }, [projectId]);

    const updateTask = async (idStatus, idTask) => {
        const res = await updateTaskService.updateTask(idStatus, idTask);

        if (res.status === 204) {
            toast.success(`Change status successfully! 🚀`, {
                position: 'bottom-left',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setCallback(!callback);
        }
    };

    const onDragEnd = (result) => {
        const { destination, source, draggableId } = result;

        if (!destination) {
            return;
        }
        if (destination.droppableId === source.droppableId) {
            return;
        }
        const start = lists.columns[source.droppableId];
        const finish = lists.columns[destination.droppableId];

        // check rule
        if (!ruleDnD[source.droppableId].includes(finish.id)) {
            toast.error(`You can not change status of this task`, {
                position: 'bottom-left',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }

        // Moving from one list to another
        const startTaskIds = Array.from(start.taskIds);
        startTaskIds.splice(source.index, 1);
        const newStart = {
            ...start,
            taskIds: startTaskIds,
        };

        const finishTaskIds = Array.from(finish.taskIds);
        finishTaskIds.splice(destination.index, 0, start.taskIds[source.index]);

        const newFinish = {
            ...finish,
            taskIds: finishTaskIds,
        };

        const newList = {
            ...lists,
            columns: {
                ...lists.columns,
                [newStart.id]: newStart,
                [newFinish.id]: newFinish,
            },
        };

        setLists(newList);

        updateTask(destination.droppableId, draggableId);
    };

    return (
        <>
            {/* <div className={cx('wrapper-filter')}>
                <Filters
                    listStatus={listStatus}
                    listPriority={listPriority}
                    assignToList={assignToList}
                    setListStatus={setListStatus}
                    setListPriority={setListPriority}
                    setAssignToList={setAssignToList}
                />
            </div> */}

            {/* <Container>
                <DragDropContext onDragEnd={onDragEnd}>
                    <ListGrid>
                        {list !== '' &&
                            list.columnOrder.map((columnId) => {
                                const column = list.columns[columnId];
                                const tasks = column.taskIds.map((list) => list);

                                return (
                                    <Column
                                        key={column.id}
                                        column={column}
                                        tasks={tasks}
                                        handleClickOpen={handleClickOpen}
                                    />
                                );
                            })}
                    </ListGrid>
                </DragDropContext>
            </Container> */}
            <div className={cx('board-colume-kanban-view')}>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Container>
                        <ListGrid>
                            {lists?.columnOrder?.map((columnId) => {
                                const column = lists.columns[columnId];
                                const tasks = column.taskIds.map((list) => list);

                                return (

                                    <Column
                                        key={column.id}
                                        column={column}
                                        tasks={tasks}
                                        handleClickOpen={handleClickOpen}
                                    />
                                );
                            })}
                        </ListGrid>
                    </Container>
                </DragDropContext>
            </div>
        </>
    );
}

export default Board;
