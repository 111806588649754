import classNames from 'classnames/bind';
import styles from './CreateTask.module.scss';
import { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { convertToRaw, EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { MdOutlineNewReleases } from 'react-icons/md';
import { AiFillBug, AiOutlineDeploymentUnit, AiOutlinePlus } from 'react-icons/ai';
import { CgArrowsExchange, CgSearchLoading } from 'react-icons/cg';
import { SiXdadevelopers } from 'react-icons/si';
import { GrDocumentText, GrVmMaintenance } from 'react-icons/gr';
import { TbFileCheck, TbTableExport } from 'react-icons/tb';
import { TiLightbulb } from 'react-icons/ti';
import { VscReport } from 'react-icons/vsc';
import routesConfig from '~/config/routes';

import draftToHtml from 'draftjs-to-html';
import moment from 'moment';
import { toast } from 'react-toastify';
import { GlobalStateProject } from '~/ProjectState/projectState';
import { FaLess } from 'react-icons/fa';
import api from '~/utils/main';
import * as createTaskService from '~/services/createTaskService';
import * as holidayService from '~/services/getHolidayService';
import CountDays from '~/components/CountDays';

const cx = classNames.bind(styles);
// const fileTypes = ['docx', 'xlsx', 'png', 'jpg'];

const initialState = {
    projectId: null,
    title: '',
    assignTo: '',
    assignTask: [],
    message: '',
    dueDate: null,
    priority: '1',
    estimatedHours: 0,
    hours: 0,
    typeId: 2,
    pathFile: '',
    //  point: 0,
};

const MENU_REPORT_TASK = [
    {
        icon: <SiXdadevelopers />,
        title: 'Development',
        id: 2,
    },
    {
        icon: <MdOutlineNewReleases />,
        title: 'Release',
        id: 9,
    },
    {
        icon: <AiFillBug />,
        title: 'Bug',
        id: 1,
    },
    {
        icon: <CgArrowsExchange />,
        title: 'Change Request',
        id: 12,
    },
    {
        icon: <GrDocumentText />,
        title: 'Document',
        id: 14,
    },
    {
        icon: <TbTableExport />,
        title: 'Enhancement',
        id: 3,
    },
    {
        icon: <TiLightbulb />,
        title: 'Idea',
        id: 11,
    },
    {
        icon: <GrVmMaintenance />,
        title: 'Maintenance',
        id: 7,
    },
    {
        icon: <AiOutlinePlus />,
        title: 'Others',
        id: 8,
    },
    {
        icon: <TbFileCheck />,
        title: 'Quality Assurance',
        id: 5,
    },
    {
        icon: <VscReport />,
        title: 'Report',
        id: 13,
    },
    {
        icon: <CgSearchLoading />,
        title: 'Research Do',
        id: 4,
    },
    {
        icon: <AiOutlineDeploymentUnit />,
        title: 'Unit Testing',
        id: 6,
    },
    {
        icon: <CgArrowsExchange />,
        title: 'Update',
        id: 10,
    },
];

const CreateTask = () => {
    const [dataCreate, setDataCreate] = useState(initialState);
    const token = useSelector((state) => state.token);
    const projects = useSelector((state) => state.project);
    const state = useContext(GlobalStateProject);
    const [isCheckAll, setIsCheckAll] = useState(false);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [files, setFiles] = useState([]);
    const [callback, setCallback] = state.projectsAPI.callback;
    const [listHoliday, setListHoliday] = useState([]);
    const {
        projectId,
        title,
        assignTo,
        assignTask,
        message,
        dueDate,
        priority,
        estimatedHours,
        hours,
        typeId,
        pathFile,
        point,
    } = dataCreate;
    const [startDate, setStartDate] = useState(new Date());
    const [valuePicker, setValuePicker] = useState(null);
    const [valuePickerTask, setValuePickerTask] = useState(null);
    const [dataMileStone, setDataMileStone] = useState('');
    const [valueTitle, setValueTitle] = useState(false);
    const [isDueDateChildTask, setIsDueDateChildTask] = useState(false);
    const [isNotPathFile, setIsNotPathFile] = useState(false);

    const ref = useRef();
    const inputTitle = useRef();

    const [customDuedate, setCustomDueDate] = useState(false);
    const [displayItemDropdown, setDisplayItemDropdown] = useState('none');
    const [dropdown, setDropdown] = useState('none');
    const [isDueDateEmpty, setIsDueDateEmpty] = useState(false);
    const [createTaskInfo, setCreateTaskInfo] = useState([]);
    const [unSubmit, setUnSubmit] = useState(false);
    const auth = useSelector((state) => state.auth);
    const { id } = auth.users;
    const navigate = useNavigate();

    const [isPriorityLow, setIsPriorityLow] = useState(true);
    const [isPriorityMedium, setIsPriorityMedium] = useState(true);
    const [isPriorityHight, setIsPriorityHight] = useState(true);
    const [countDate, setCountDate] = useState(null);
    const [isPointAndLevelNull, setIsPointAndLevelNull] = useState(false);

    const location = useLocation();

    const projectIDRequireTask = location.state?.projectID;
    const projectNameRequireTask = location.state?.projectName;
    const checkListNameRequireTask = location.state?.checklistName;
    const checkListIdRequireTask = location.state?.checklistId;
    const createTaskRequire = location.state?.createTaskRequire;
    const checklistItemId = location.state?.checklistItemId;

    const inputDueDate = useRef();

    const showDislayItemDropDown = () => {
        setDisplayItemDropdown('block');
    };

    const showDropdownDueDate = () => {
        setDropdown('block');
        setDisplayItemDropdown('none');
    };

    const hiddenDropdown = () => {
        setDropdown('none');
    };

    // useEffect(() => {
    //     if (projects.length !== 0) {
    //         setDataCreate({ ...dataCreate, projectId: projects[0].projectId });
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [projects]);

    useEffect(() => {
        const getHoliday = async () => {
            const res = await holidayService.getHoliday();
            const newArr = [];

            res.data.items.map((ele) => {
                if (
                    !newArr.includes(ele.start.date) &&
                    !newArr.includes(ele.end.date) &&
                    ele.summary !== 'Christmas Day' &&
                    ele.summary !== 'Christmas Eve'
                ) {
                    newArr.push(ele.start.date, ele.end.date);
                }
            });

            if (res.status === 200) {
                setListHoliday(newArr);
            }
        };
        getHoliday();
    }, []);

    useEffect(() => {
        if (customDuedate) {
            showDropdownDueDate();
        } else {
            hiddenDropdown();
        }
    }, [customDuedate]);

    useEffect(() => {
        if (projectId) {
            getInfoCreateTask();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projects, projectId]);

    // useEffect(() => {
    //     const res = await createTaskService.getInfoCreateTask(projectIDRequireTask, projects);
    //     setCreateTaskInfo(res.data.data);
    // }, [createTaskRequire]);

    useEffect(() => {
        if (projectIDRequireTask && createTaskRequire) {
            getInfoCreateTaskFromRequire();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projects, projectIDRequireTask]);

    const getInfoCreateTaskFromRequire = async () => {
        const res = await createTaskService.getInfoCreateTask(projectIDRequireTask, projects);
        if (res.data.status === 'success') {
            setCreateTaskInfo(res.data.data);
            setDataCreate({ ...dataCreate, assignTo: res.data.data.userCreates[0].id });
        }
    };

    const getInfoCreateTask = async () => {
        const res = await createTaskService.getInfoCreateTask(projectId, projects);

        if (res.data.status === 'success') {
            setCreateTaskInfo(res.data.data);
            setDataCreate({ ...dataCreate, assignTo: res.data.data.userCreates[0].id });
        }
    };

    const handleChangeProject = (e) => {
        const initialStateUpdate = {
            projectId: e.target.value,
            title: '',
            assignTo: '',
            assignTask: [],
            message: '',
            dueDate: null,
            priority: '1',
            estimatedHours: 0,
            hours: 0,
            typeId: 2,
            pathFile: '',
            point: 0,
        };
        setDataCreate(initialStateUpdate);
    };

    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            // If the menu is open and the clicked target is not within the menu,
            // then close the menu
            if (customDuedate && ref.current && !ref.current.contains(e.target)) {
                setCustomDueDate(false);
            }
        };

        document.addEventListener('mousedown', checkIfClickedOutside);

        return () => {
            // Cleanup the event listener
            document.removeEventListener('mousedown', checkIfClickedOutside);
        };
    }, [customDuedate]);

    // const handleChangeSelect = (value) => {
    //     setDataCreate({ ...dataCreate, projectId: value });
    // };

    const handleChangeTitle = (value) => {
        setValueTitle(false);
        setDataCreate({ ...dataCreate, title: value });
    };

    // const handleDescription = (value) => {
    //     setDataCreate({ ...dataCreate, message: value });
    // };

    const handlePriority = (value) => {
        setDataCreate({ ...dataCreate, priority: value });
    };

    const handleEstimated = (value) => {
        setDataCreate({ ...dataCreate, estimatedHours: value });
    };

    const handleHours = (value) => {
        setDataCreate({ ...dataCreate, hours: value });
    };

    const handleAssignTo = (value) => {
        setDataCreate({ ...dataCreate, assignTo: value });
    };

    // const getTotalPointChildTask = () => {
    //     let totalPointChildTask = 0;
    //     assignTask.map((ele) => {
    //         return (totalPointChildTask += ele.point.point);
    //     });
    //     setTotalPointChildTask(totalPointChildTask);
    //     return totalPointChildTask;
    // };

    // useEffect(() => {
    //     if (assignTask.length >= 1) {
    //         const getTotalPoint = getTotalPointChildTask();
    //         setDataCreate({ ...dataCreate, point: getTotalPoint });
    //     } else {
    //         setDataCreate({ ...dataCreate, point: pointParentTask });
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [assignTask]);

    // useEffect(() => {
    //     if (totalPointChildTask < pointParentTask) {
    //         setDataCreate({ ...dataCreate, point: pointParentTask });
    //     }
    // }, [totalPointChildTask]);

    // const filterPoint = (point) => {
    //     return listPoint.find((ele) => ele.point === point);
    // };

    const handleCheck = (e, id, name) => {
        const { checked } = e.target;

        let valueDueDate = moment(valuePicker).format('YYYY-MM-DD');

        setDataCreate({
            ...dataCreate,
            assignTask: [
                ...assignTask,
                { title, assignId: id, dueDate: valueDueDate, name, level: 'N', typeId: 2, isStarted: false },
            ],
        });

        if (!checked) {
            const newAssignTask = [...assignTask];
            const rs = newAssignTask.filter((item) => item.assignId !== id);
            setDataCreate({ ...dataCreate, assignTask: rs });
        }
    };

    const checkDate = (valuePicker, assignTask) => {
        const rs = assignTask.every((data) => {
            return moment(valuePicker).isAfter(data.dueDate) || moment(valuePicker).isSame(data.dueDate);
        });

        return rs;
    };

    useEffect(() => {
        if (isPointAndLevelNull) {
            const checkPointAndLevelNotNull = assignTask.every((info) => info.point && info.level);

            checkPointAndLevelNotNull && setIsPointAndLevelNull(false);
        }
    }, [assignTask]);

    const handleCreateTask = async (e) => {
        setUnSubmit(true);
        e.preventDefault();

        if (title === '') {
            setValueTitle(true);
            inputTitle.current.focus();
            setUnSubmit(false);
            return;
        }

        const isPointAndLevelNotNull = assignTask.every((info) => {
            return info.point && info.level;
        });

        if (!isPointAndLevelNotNull) {
            setIsPointAndLevelNull(true);
            setUnSubmit(false);
            return;
        }

        if (valuePicker === null) {
            setIsDueDateEmpty(true);
            inputDueDate.current.focus();
            setUnSubmit(false);
            return;
        }

        if (checkDate(valuePicker, assignTask) !== true) {
            setIsDueDateChildTask(true);
            setUnSubmit(false);
            return;
        }

        const assignTaskNoName = assignTask.map((task) => {
            const { name, ...assginTask } = task;

            return assginTask;
        });

        const body = {
            projectId: projectId || projectIDRequireTask,
            title,
            assignTo: assignTo !== '' ? assignTo : id,
            assignInfos: assignTaskNoName,
            message: draftToHtml(convertToRaw(editorState.getCurrentContent())) || '',
            dueDate: valuePicker,
            priority,
            estimatedHours: +estimatedHours,
            hours: +hours,
            typeId,
            milestoneId: 0 || +dataMileStone,
            pathFile,
        };

        const res = await api.post(`task/create`, body);

        if (res.data.status === 'success') {
            setCallback(!callback);
            setUnSubmit(false);
            toast.success(`Create task successfully! 🚀`, {
                position: 'bottom-left',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            if (createTaskRequire) {
                const idTaskReq = res.data.data.map((taskSl) => {
                    const taskSelect = taskSl.id;
                    return taskSelect;
                });

                const reIdTaskRequire = idTaskReq.reduce((a, b) => Object.assign(a, b));

                const bodyTaskRequire = {
                    checklistItemId: checklistItemId,
                    task: reIdTaskRequire,
                };

                const resTaskRequire = await api.patch(`check-list/update-task`, bodyTaskRequire);

                if (resTaskRequire.status === 201) {
                    const dataTask = res.data.data;
                    navigate(routesConfig.createRequirementChecklist, {
                        state: {
                            projectID: projectIDRequireTask,
                            projectName: projectNameRequireTask,
                            checklistName: checkListNameRequireTask,
                            checklistId: checkListIdRequireTask,
                            checklistItemId: checklistItemId,
                            createTaskRequire: false,
                        },
                    });
                }
            } else {
                navigate(routesConfig.home);
            }
        } else {
            setUnSubmit(false);
        }
    };

    const onEditorStateChange = (editorState) => {
        return setEditorState(editorState);
    };

    const getListPoint = (date) => {
        if (date <= 1) {
            return 1;
        } else if (date <= 2) {
            return 2;
        } else if (date <= 4) {
            return 3;
        } else if (date <= 7) {
            return 5;
        } else if (date <= 12) {
            return 8;
        } else if (date <= 18) {
            return 13;
        } else {
            return 21;
        }
    };

    const handleChangeDate = (e) => {
        const value = moment(new Date(e.target.value)).format('YYYY-MM-DD');
        setValuePicker(value);

        var start = moment(moment().format('YYYY-MM-DD'), 'YYYY-MM-DD');
        var end = moment(value, 'YYYY-MM-DD');

        // const rs = moment.duration(end.diff(start)).asDays();

        // const rs = CountDays(start, end, listHoliday);

        // const point = getListPoint(rs);

        // setCountDate(rs);
        // setPointParentTask(point);
        // setDataCreate({ ...dataCreate, point });

        if (assignTask.length > 0) {
            setDataCreate({ ...dataCreate, assignTask: [] });
        }
    };

    const handleChangeDateTask = (e, id) => {
        // var start = moment(moment().format('YYYY-MM-DD'), 'YYYY-MM-DD');
        // var end = moment(e.target.value, 'YYYY-MM-DD');

        // const rs = moment.duration(end.diff(start)).asDays();

        // const rs = CountDays(start, end, listHoliday);

        // const point = getListPoint(rs);
        // const rsPoint = filterPoint(point);

        setDataCreate((prevState) => {
            const newState = prevState.assignTask.map((assign) => {
                if (assign.assignId === id) {
                    return { ...assign, dueDate: e.target.value };
                }
                return assign;
            });

            return { ...prevState, assignTask: newState };
        });
    };

    const checkOptionMilestone = (event) => {
        setDataMileStone(event.target.value);
    };

    // const handleChangePoint = (event) => {
    //     setDataCreate({ ...dataCreate, point: event.target.value });
    // };

    const handleChangePointChildTask = (event, id) => {
        //  const rsPoint = filterPoint(+event.target.value);

        setDataCreate((prevState) => {
            const newState = prevState.assignTask.map((assign) => {
                if (assign.assignId === id) {
                    return { ...assign, point: +event.target.value };
                }
                return assign;
            });

            return { ...prevState, assignTask: newState };
        });
    };

    const handleChangeLevelChildTask = (event, id) => {
        setDataCreate((prevState) => {
            const newState = prevState.assignTask.map((assign) => {
                if (assign.assignId === id) {
                    return { ...assign, level: event.target.value };
                }
                return assign;
            });

            return { ...prevState, assignTask: newState };
        });
    };

    const handleChangeInputTitle = (e, id) => {
        setDataCreate((prevState) => {
            const newState = prevState.assignTask.map((assign) => {
                if (assign.assignId === id) {
                    return { ...assign, title: e.target.value };
                }
                return assign;
            });
            // console.log(newState);

            return { ...prevState, assignTask: newState };
        });
    };

    const handleChangeTypeIdChildTask = (event, id) => {
        setDataCreate((prevState) => {
            const newState = prevState.assignTask.map((assign) => {
                if (assign.assignId === id) {
                    return { ...assign, typeId: Number(event.target.value) };
                }
                return assign;
            });

            return { ...prevState, assignTask: newState };
        });
    };

    const handleSelectTypeId = (e) => {
        setDataCreate({ ...dataCreate, typeId: e.target.value });
    };

    const handleCancel = () => {
        navigate(routesConfig.home);
    };

    const handleChecked = (id) => {
        assignTask.some((assign) => {
            return assign.assignId === id;
        });
    };

    const handleChangeFile = (event) => {
        setDataCreate({ ...dataCreate, pathFile: event.target.value });
    };

    const handleChangeStarted = (e, assignId) => {
        setDataCreate((prev) => {
            const cloneAssignTask = [...dataCreate.assignTask];

            const index = cloneAssignTask.findIndex((ele) => ele.assignId === assignId);
            cloneAssignTask[index].isStarted = e.target.checked;

            return { ...prev, assignTask: cloneAssignTask };
        });
    };

    return (
        <div className={cx('wrapper')}>
            <div className={cx('dialog-create-task-content')}>
                <form method="">
                    <p className={cx('mt-5', 'mb-5', 'create-task-title')}>CREATE TASK</p>
                    <div className="mb-4 mt-4">
                        <div className="row">
                            <div className="col-12 col-md-6 col-xl-3">
                                <label htmlFor="hn-create-project" className={cx('form-label', 'form-label-title')}>
                                    Project<i className={cx('hn-color-red')}>*</i>
                                </label>
                                {createTaskRequire ? (
                                    <select
                                        className={cx('form-select', 'form-select-lg', 'mb-3', 'res-option-in-select')}
                                        id="hn-create-project"
                                        onChange={(e) => handleChangeProject(e)}
                                        autoFocus
                                    >
                                        <option value={projectIDRequireTask}>{projectNameRequireTask}</option>
                                    </select>
                                ) : (
                                    <select
                                        className={cx('form-select', 'form-select-lg', 'mb-3', 'res-option-in-select')}
                                        // aria-label=".form-select-lg example"
                                        id="hn-create-project"
                                        // onChange={(e) => handleChangeSelect(e.target.value)}
                                        onChange={(e) => handleChangeProject(e)}
                                        autoFocus
                                    >
                                        <option value="no-project">Select project</option>
                                        {projects.map((project) => (
                                            <option
                                                value={project.projectId}
                                                key={project.projectId}
                                                // defaultValue={projects[0].projectName}
                                            >
                                                {project.projectName}
                                            </option>
                                        ))}
                                        {/* <option defaultValue="Company website">Company website</option>
                                <option value="Project Management">Project Management</option> */}
                                    </select>
                                )}
                            </div>
                            <div className="col-12 col-md-6 col-xl-3">
                                <label htmlFor="hn-create-title" className={cx('form-label', 'form-label-title')}>
                                    Title<i className={cx('hn-color-red')}>*</i>
                                </label>
                                <input
                                    ref={inputTitle}
                                    type="text"
                                    value={title}
                                    className="form-control form-control-lg mb-3"
                                    id="hn-create-title"
                                    placeholder="Title"
                                    onChange={(e) => handleChangeTitle(e.target.value)}
                                />
                                {valueTitle ? (
                                    <span className={cx('error-message')}>The title must not be blank</span>
                                ) : (
                                    ''
                                )}
                            </div>

                            <div className="col-12 col-md-6 col-xl-6">
                                <label htmlFor="create-title__file" className={cx('form-label', 'form-label-title')}>
                                    File path
                                </label>
                                <input
                                    type="text"
                                    className="form-control form-control-lg"
                                    id="create-title__file"
                                    placeholder="Enter path file name"
                                    onChange={(e) => handleChangeFile(e)}
                                />
                            </div>
                        </div>
                    </div>

                    {/* <hr className="mt-4 mb-4" /> */}
                    <div className="mb-4">
                        <div className="row">
                            <div className="col-12">
                                <label htmlFor="hn-create-description" className={cx('form-label', 'form-label-title')}>
                                    Description
                                </label>
                                <Editor
                                    editorState={editorState}
                                    onEditorStateChange={onEditorStateChange}
                                    wrapperClassName="wrapper-class res-description"
                                    editorClassName="editor-class"
                                    toolbarClassName="toolbar-class"
                                    placeholder="Enter your comment...."
                                />
                            </div>
                        </div>
                    </div>

                    <div className="mb-4">
                        <div className="row">
                            <div className="col-12 col-sm-6 col-xl-3">
                                <label htmlFor="hn-create-assignee" className={cx('form-label', 'form-label-title')}>
                                    Assignee To<i className={cx('hn-color-red')}>*</i>
                                </label>
                                <select
                                    className="form-select form-select-lg mb-3"
                                    aria-label=".form-select-lg example"
                                    id="hn-create-assignee"
                                    onChange={(e) => handleAssignTo(e.target.value)}
                                    // defaultValue={id}
                                >
                                    {createTaskInfo.length !== 0 &&
                                        createTaskInfo.userCreates.map((user) => {
                                            return (
                                                <option
                                                    value={user.id}
                                                    key={user.id}
                                                    // selected={user.id === id}
                                                    dangerouslySetInnerHTML={{
                                                        __html: user.name,
                                                    }}
                                                ></option>
                                            );
                                        })}
                                </select>
                            </div>
                            <div className="col-12 col-sm-6 col-xl-3">
                                <label htmlFor="hn-create-duedate" className={cx('form-label', 'form-label-title')}>
                                    Due Date<i className={cx('hn-color-red')}>*</i>
                                </label>
                                <input
                                    ref={inputDueDate}
                                    type="date"
                                    className="form-control form-control-lg"
                                    id="milestone-create-duedate"
                                    min={moment(new Date()).format('YYYY/MM/DD').replaceAll('/', '-')}
                                    onChange={(e) => handleChangeDate(e)}
                                    onKeyDown={(e) => {
                                        e.preventDefault();
                                    }}
                                ></input>
                                {countDate !== null && (
                                    <span className={cx('note-message')}>
                                        {countDate >= 2 ? countDate + ' days' : countDate + ' day'}
                                    </span>
                                )}
                                {isDueDateEmpty === true && valuePicker === null && (
                                    <span className={cx('error-message')}>The title must not be blank</span>
                                )}
                            </div>
                            {/* <div className="col-12 col-sm-6 col-xl-3">
                                <label htmlFor="input-point" className={cx('form-label', 'form-label-title')}>
                                    Point<i className={cx('hn-color-red')}>*</i>
                                </label>
                                {assignTask.length >= 2 ? (
                                    <input
                                        value={point}
                                        type="number"
                                        className={cx('form-control form-control-lg mb-3', 'disabled-input')}
                                        id="input-point"
                                        disabled={true}
                                    />
                                ) : (
                                    <select
                                        className="form-select form-select-lg mb-3"
                                        aria-label=".form-select-lg example"
                                        id="input-point"
                                        onChange={(e) => handleChangePoint(e)}
                                        value={point !== 0 && point}
                                        disabled={valuePicker === null}
                                    >
                                        <option value={0} className={cx('option-select')}>
                                            Select point
                                        </option>

                                        {listPoint.map((ele, index) => (
                                            <option value={ele.point} key={index} className={cx('option-select')}>
                                                {ele.point} {'(' + ele.title + ')'}
                                            </option>
                                        ))}
                                    </select>
                                )}
                            </div> */}
                        </div>
                    </div>

                    <div className="mb-4">
                        <label htmlFor="hn-create-assignee" className={cx('form-label', 'form-label-title')}>
                            Assignee Task
                        </label>
                        <div className="row">
                            {/* <div className="col-4">
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        id="input-all-notify"
                                        type="checkbox"
                                        onChange={(e) => handleChangeCheckAll(e)}
                                    />
                                    <label htmlFor="input-all-notify" className="form-check-label">
                                        All
                                    </label>
                                </div>
                            </div> */}
                            {createTaskInfo.length !== 0 &&
                                createTaskInfo?.userCreates?.map((data, index) => (
                                    <div
                                        className={cx(
                                            'col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4',
                                            'hn-assign-task',
                                            'mb-2',
                                        )}
                                        key={index}
                                    >
                                        <div className={cx('form-check', 'hn-assign-task_item')}>
                                            <input
                                                checked={assignTask.some((ele) => ele.assignId === data.id)}
                                                disabled={valuePicker === null}
                                                className="form-check-input"
                                                type="checkbox"
                                                value=""
                                                id={'checkBoxAssigneeTask' + data.id}
                                                onChange={(e) => handleCheck(e, data.id, data.name)}
                                            />
                                            <label
                                                className={cx('form-check-label', 'lbl-name_item-assign-task')}
                                                htmlFor={'checkBoxAssigneeTask' + data.id}
                                            >
                                                <span
                                                    dangerouslySetInnerHTML={{
                                                        __html: data.name,
                                                    }}
                                                />
                                            </label>
                                        </div>
                                    </div>
                                ))}
                            <div className="mb-4 mt-4">
                                {dataCreate.length !== 0 &&
                                    dataCreate.assignTask.map((data, index) => (
                                        <div className={cx('row', 'res-assign-task')} key={index}>
                                            <div className={cx('col-12 col-xl-2')}>
                                                <p
                                                    className={cx('mr-2')}
                                                    dangerouslySetInnerHTML={{
                                                        __html: data.name,
                                                    }}
                                                />
                                            </div>
                                            <div className={cx('col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2')}>
                                                <input
                                                    type="text"
                                                    className={cx(
                                                        'form-control',
                                                        'input-to-assign-task',
                                                        'form-control-lg',
                                                        'custom-border-input',
                                                        'mb-3',
                                                    )}
                                                    // style={{ display: inputAssignTask }}
                                                    placeholder="Enter title"
                                                    id={'assignId' + data.assignId}
                                                    onChange={(e) => handleChangeInputTitle(e, data.assignId)}
                                                    style={{ width: '100%' }}
                                                />
                                            </div>
                                            <div className={cx('col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2')}>
                                                <input
                                                    type="date"
                                                    className={cx('form-control form-control-lg', 'mb-3')}
                                                    id={'dueDate' + data.id}
                                                    value={data.dueDate}
                                                    min={moment(new Date()).format('YYYY/MM/DD').replaceAll('/', '-')}
                                                    max={moment(valuePicker).format('YYYY/MM/DD').replaceAll('/', '-')}
                                                    onChange={(e) => handleChangeDateTask(e, data.assignId)}
                                                    onKeyDown={(e) => {
                                                        e.preventDefault();
                                                    }}
                                                />
                                            </div>
                                            <div className={cx('col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2')}>
                                                <input
                                                    className={cx('form-control form-control-lg', 'mb-3')}
                                                    placeholder="Enter point"
                                                    type="number"
                                                    step="0.25"
                                                    min="0"
                                                    onChange={(e) => handleChangePointChildTask(e, data.assignId)}
                                                />
                                            </div>
                                            <div className={cx('col-12 col-sm-6 col-md-4 col-lg-2 col-xl-1')}>
                                                <select
                                                    className={cx('form-select form-select-lg', 'mb-3')}
                                                    aria-label=".form-select-lg example"
                                                    id="input-level"
                                                    defaultValue={data.level}
                                                    onChange={(e) => handleChangeLevelChildTask(e, data.assignId)}
                                                    // value={data.point.point}
                                                >
                                                    {/* <option value={0} className={cx('option-select')}>
                                                        Select level
                                                    </option> */}
                                                    {['E', 'N', 'S'].map((level, index) => (
                                                        <option
                                                            value={level}
                                                            key={index}
                                                            className={cx('option-select')}
                                                        >
                                                            {level}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className={cx('col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2')}>
                                                <select
                                                    className={cx('form-select form-select-lg', 'mb-3')}
                                                    id="hn-create-assignee"
                                                    // value="Development"
                                                    onChange={(e) => handleChangeTypeIdChildTask(e, data.assignId)}
                                                >
                                                    {MENU_REPORT_TASK.map((option, index) => (
                                                        <option key={index} value={option.id}>
                                                            {option.title}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>

                                            <div className={cx('col-12 col-sm-6 col-md-4 col-lg-3 col-xl-1')}>
                                                <input
                                                    type="checkbox"
                                                    id={`radio-started-${data.assignId}`}
                                                    checked={data.isStarted}
                                                    // value={data.isStarted}
                                                    onChange={(e) => handleChangeStarted(e, data.assignId)}
                                                />
                                                <label
                                                    htmlFor={`radio-started-${data.assignId}`}
                                                    className={cx('input-started')}
                                                >
                                                    Started
                                                </label>
                                            </div>
                                        </div>
                                    ))}
                            </div>
                            {isPointAndLevelNull && (
                                <span className={cx('error-message')}>The point and level must not be blank</span>
                            )}

                            {isDueDateChildTask && (
                                <span className={cx('error-message')}>
                                    Due date of child tasks less than or equal to the due date of the parent task
                                </span>
                            )}
                        </div>
                    </div>

                    <div className="mb-4">
                        <div className="row">
                            <div className="col-12 col-md-8 col-lg-6 col-xl-4">
                                <label htmlFor="hn-create-assignee" className={cx('form-label', 'form-label-title')}>
                                    Priority<i className={cx('hn-color-red')}>*</i>
                                </label>
                                <div className="row" onChange={(e) => handlePriority(e.target.value)}>
                                    <div className="col-sm-4">
                                        <input
                                            id="Low"
                                            type="radio"
                                            name="Low"
                                            checked={priority === '2'}
                                            value="2"
                                            className={cx('hn-margin-radio')}
                                            onChange={(e) => setIsPriorityLow(e.target.checked)}
                                        />
                                        <label htmlFor="Low">Low</label>
                                    </div>
                                    <div className="col-sm-4">
                                        <input
                                            id="Medium"
                                            type="radio"
                                            name="Medium"
                                            checked={priority === '1'}
                                            value="1"
                                            className={cx('hn-margin-radio')}
                                            onChange={(e) => setIsPriorityMedium(e.target.checked)}
                                        />
                                        <label htmlFor="Medium">Medium</label>
                                    </div>
                                    <div className="col-sm-4">
                                        <input
                                            type="radio"
                                            name="High"
                                            id="High"
                                            checked={priority === '0'}
                                            value="0"
                                            className={cx('hn-margin-radio')}
                                            onChange={(e) => setIsPriorityHight(e.target.checked)}
                                        />
                                        <label htmlFor="High">High</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="mb-4">
                        <div className="row">
                            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                                <label htmlFor="hn-create-assignee" className={cx('form-label', 'form-label-title')}>
                                    Task Type<i className={cx('hn-color-red')}>*</i>
                                </label>
                                <select
                                    className="form-select form-select-lg mb-3"
                                    id="hn-create-assignee"
                                    // value="Development"
                                    onChange={(e) => handleSelectTypeId(e)}
                                >
                                    {' '}
                                    {MENU_REPORT_TASK.map((option, index) => (
                                        <option key={index} value={option.id}>
                                            {option.title}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                                <label htmlFor="hn-create-duedate" className={cx('form-label', 'form-label-title')}>
                                    Milestone
                                </label>
                                <select
                                    className="form-select form-select-lg mb-3"
                                    aria-label=".form-select-lg example"
                                    id="hn-create-assignee"
                                    onChange={(e) => checkOptionMilestone(e)}
                                >
                                    {createTaskInfo.length !== 0 &&
                                        createTaskInfo.milestoneCreate.map((milestone) => (
                                            <option value={milestone.id} key={milestone.id}>
                                                {milestone.name}
                                            </option>
                                        ))}
                                    <option value="0">No Milestone</option>
                                </select>
                            </div>
                            {/* <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                                <label htmlFor="input-point" className={cx('form-label', 'form-label-title')}>
                                    Point<i className={cx('hn-color-red')}>*</i>
                                </label>
                                <select
                                    className="form-select form-select-lg mb-3"
                                    aria-label=".form-select-lg example"
                                    id="input-point"
                                    onChange={(e) => handleChangePoint(e)}
                                >
                                    <option value={0} className={cx('option-select')}>
                                        Select point
                                    </option>
                                    {listPoint.map((point, index) => (
                                        <option value={point} key={index} className={cx('option-select')}>
                                            {point}
                                        </option>
                                    ))}
                                </select>
                            </div> */}
                        </div>
                    </div>

                    <div className="mb-4">
                        <div className="row">
                            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                                <label
                                    htmlFor="hn-create-estimated-hour"
                                    className={cx('form-label', 'form-label-title')}
                                >
                                    Estimated Hour(s)
                                </label>
                                <input
                                    value={estimatedHours}
                                    type="number"
                                    className="form-control form-control-lg mb-3"
                                    id="hn-create-title"
                                    onChange={(e) => handleEstimated(e.target.value)}
                                ></input>
                            </div>
                            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                                <label htmlFor="hn-create-hour-spent" className={cx('form-label', 'form-label-title')}>
                                    Hour(s) Spent
                                </label>
                                <input
                                    value={hours}
                                    type="number"
                                    className="form-control form-control-lg mb-3"
                                    id="hn-create-hour-spent"
                                    onChange={(e) => handleHours(e.target.value)}
                                ></input>
                            </div>
                        </div>
                    </div>

                    {/* <div className="mb-4"> */}
                    {/* <div className={cx('hn-upload-file-attachment')}> */}
                    {/* <label htmlFor="hn-create-hour-spent" className={cx('form-label', 'form-label-title')}>
                            Attachment(s)
                        </label> */}
                    {/* <FileUpload value={files} onChange={setFiles} /> */}
                    {/* </div> */}
                    {/* </div> */}
                    {/* <div className="mb-4">
                        <label htmlFor="hn-create-assignee" className={cx('form-label', 'form-label-title')}>
                            Notify via Email
                        </label>
                        <div className="row">
                            {createTaskInfo.length !== 0 &&
                                createTaskInfo.userCreates.map((data, index) => (
                                    <div className="col-4" key={index}>
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                value=""
                                                id={'checkBoxNotifyEmail' + data.id}
                                            />
                                            <label className="form-check-label" htmlFor={'checkBoxNotifyEmail' + data.id}>
                                                <span
                                                    dangerouslySetInnerHTML={{
                                                        __html: data.name,
                                                    }}
                                                />
                                            </label>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </div> */}
                    <div className={cx('mt-4', 'mb-4', 'create-task-action')}>
                        <button
                            type="submit"
                            onClick={handleCreateTask}
                            className={cx('hn-btn-submit')}
                            size="medium"
                            disabled={unSubmit ? true : false}
                        >
                            Create Task
                        </button>
                        <button className={cx('hn-btn-cancel')} size="medium" onClick={handleCancel}>
                            Cancel
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default CreateTask;
