import classNames from 'classnames/bind';
import Dialog from '@mui/material/Dialog';
import { memo, useEffect, useRef, useState } from 'react';

import styles from './FormCreatePathFile.module.scss';
import { IoMdClose } from 'react-icons/io';
import * as detailTaskService from '~/services/detailTaskService';
import api from '~/utils/main';

const cx = classNames.bind(styles);

function FormCreatePathFile(props) {
    const {
        taskId,
        openCreatePathFile,
        setOpenCreatePathFile,
        setCallbackCreatePathFile,
        callbackCreatePathFile,
        bugId,
        getDetailTask,
    } = props;
    const [pathFile, setPathFile] = useState('');

    const inputRef = useRef();

    const handleClose = () => {
        setOpenCreatePathFile(false);
    };

    const handleChangePathFile = (e) => {
        setPathFile(e.target.value);
    };

    const handleCreatePathFile = async () => {
        let res;

        if (!bugId) {
            res = await detailTaskService.createPathFile(taskId, pathFile);
        } else {
            const body = {
                pathFile: pathFile,
                bugCardId: bugId,
            };

            res = await api.post(`checking-bug/app`, body);
        }

        if (res.status === 201) {
            setOpenCreatePathFile(false);
            getDetailTask(bugId);
        }

        if (res.status === 204) {
            setOpenCreatePathFile(false);
            setCallbackCreatePathFile(!callbackCreatePathFile);
        }
    };

    return (
        <Dialog open={openCreatePathFile} onClose={handleClose} maxWidth="md">
            <div className={cx('d-flex', 'wrapper-btn-close')}>
                <h2>Add File</h2>
                <button className={cx('btn-icon-item')} onClick={handleClose}>
                    <IoMdClose className={cx('icon-item')} />
                </button>
            </div>
            <div className={cx('wrapper')}>
                <label>Path File:</label>
                <input
                    ref={inputRef}
                    className={cx('input-path-file')}
                    placeholder="Enter path file...."
                    onChange={(e) => handleChangePathFile(e)}
                    autoFocus
                />
            </div>

            <div className={cx('d-flex', 'wrapper-btn-create')}>
                <button
                    className={cx('btn-icon-create-item')}
                    disabled={pathFile === ''}
                    onClick={handleCreatePathFile}
                >
                    <span>Add</span>
                </button>
            </div>
        </Dialog>
    );
}

export default memo(FormCreatePathFile);
