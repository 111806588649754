import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames/bind';
import styles from '../Profile.module.scss';
import imageProfile from '../../../assets/images/user/pexels-pixabay-261777.jpg';
import { IconButton } from '@mui/material';
import { MdCameraAlt } from 'react-icons/md';
import api from '~/utils/main';
import { toast } from 'react-toastify';
const cx = classNames.bind(styles);

const ChangePassword = () => {
    const auth = useSelector((state) => state.auth);
    const { userEmail } = auth.users;

    const [currentPassword, setCurrenPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [valid, setValidity] = useState(true);
    const [compare, setCompare] = useState(true);
    const [validCurrentPw, setValidCurrentPw] = useState(false);

    useEffect(() => {
        const regExp = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
        if (newPassword) {
            setCompare(true);
            if (newPassword.match(regExp)) {
                setValidity(true);
            } else {
                setValidity(false);
            }
        }
    }, [newPassword]);

    function comparePassword() {
        if (newPassword !== confirmPassword) {
            setCompare(false);
        } else {
            setCompare(true);
        }
    }

    const handleCurrentPassword = (e) => {
        setValidCurrentPw(false);
        setCurrenPassword(e.target.value);
    }

    const handleClearPassword = (e) => {
        e.preventDefault();
        setValidity(true);
        setCurrenPassword('');
        setNewPassword('');
        setConfirmPassword('');
    }

    const handleChangePassword = async (e) => {
        e.preventDefault();

        comparePassword();

        const body = {
            email: userEmail,
            currentPassword: currentPassword,
            newPassword: newPassword,
        };

        if (newPassword === confirmPassword) {
            const res = await toast.promise(api.post(`admin/me/change-password`, body), {
                pending: 'Change password pending',
                success: 'Change password successfully',
                error: 'Change password failed',
            }).then((res) => {
                if (res.status === 204) {
                    setCurrenPassword('');
                    setNewPassword('');
                    setConfirmPassword('');
                }
            }).catch((err) => {
                if (err.response.data?.errorCode === "InvalidPassword") {
                    setValidCurrentPw(true);
                }
            })
        }
    }

    return (
        <form className={cx('form-change-top')}>
            <div className={cx('row', 'change-password-content')}>
                <p className={cx('mt-4', 'mb-4', 'profile-title')}>Change Password</p>
                <div className="mb-4">
                    <label htmlFor="currentPassword" className="form-label">Current Password</label>
                    <input type="password" className="form-control form-control-lg" id="currentPassword"
                        value={currentPassword}
                        onChange={(e) => handleCurrentPassword(e)} />
                </div>
                {validCurrentPw ? (
                    <div className={cx('txt-valid-password')}>
                        <ul>
                            <li>Current password is not correct </li>
                        </ul>
                    </div>
                ) : null}
                <div className="mb-4">
                    <label htmlFor="newPassword" className="form-label">New Password</label>
                    <input type="password" className="form-control form-control-lg" id="newPassword" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
                </div>
                {!valid ? (
                    <div className={cx('txt-valid-password')}>
                        <ul>
                            <li>Password should be minimum 8 characters in length</li>
                            <li>Password a mix of uppercase, lowercase, digits and special characters</li>
                        </ul>
                    </div>
                ) : null}
                <div className="mb-4">
                    <label htmlFor="confirmPassword" className="form-label">Password Confirmation</label>
                    <input type="password" className="form-control form-control-lg" id="confirmPassword" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
                </div>
                {!compare ? (
                    <div className={cx('txt-valid-password')}>
                        <ul>
                            <li>Passwords do not match</li>
                        </ul>
                    </div>
                ) : null}
                <div className={cx('mb-4', 'profile-control')}>
                    <button type="submit" className={cx('btn', 'btn-profile-update')} onClick={handleChangePassword}>
                        Change Password
                    </button>
                    <button type="submit" className={cx('btn', 'btn-profile-cancel')} onClick={handleClearPassword}>
                        Clear
                    </button>
                </div>
            </div>
        </form>
    )
}

export default ChangePassword