import { Fragment, memo, useState } from 'react';
import styles from '../KanbanBug.module.scss';
import classNames from 'classnames/bind';
import moment from 'moment';
import draftToHtml from 'draftjs-to-html';
import { toast } from 'react-toastify';
import api from '~/utils/main';
import { EditorState, convertToRaw } from 'draft-js';
import { useSelector } from 'react-redux';
import EditForm from '../../EditorForm/EditorForm';
import EditFormKanBan from '../EditFormKanBan/EditFormKanBan';
const cx = classNames.bind(styles);

function Comment(props) {
    const { info, getAllComment, idTaskDetail } = props;

    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const token = useSelector((state) => state.token);
    const [editContent, setEditContent] = useState('');

    const handleEditComment = (id) => {
        document.getElementsByClassName(`comment-item-btn__${id}`)[0].style.display = 'none';
        document.getElementsByClassName(`content-editor__${id}`)[0].style.display = 'none';
        document.getElementsByClassName(`comment-item__save${id}`)[0].style.display = 'block';
        document.getElementsByClassName(`wrapper-form__edit${id}`)[0].style.display = 'block';
    };

    const handleDeleteComment = async (e, id) => {
        e.preventDefault();
        const response = await toast.promise(api.delete(`checking-bug/${idTaskDetail}/comment/${id}`), {
            pending: 'Delete comment is pending',
            success: 'Delete comment successfully',
            error: 'Delete comment failed',
        });
        if (response.status === 204) {
            getAllComment();
        }
    };

    const handleSaveEditComment = async (e, id, comment) => {
        if (editContent !== '') {
            const response = await toast.promise(
                api.put(`checking-bug/${idTaskDetail}/comment/${id}`, {
                    comment: editContent,
                }),
                {
                    pending: 'Update comment is pending',
                    success: 'Update comment successfully',
                    error: 'Update comment failed',
                },
            );
            if (response.status === 204) {
                document.getElementsByClassName(`comment-item-btn__${id}`)[0].style.display = 'block';
                document.getElementsByClassName(`content-editor__${id}`)[0].style.display = 'block';
                document.getElementsByClassName(`comment-item__save${id}`)[0].style.display = 'none';
                document.getElementsByClassName(`wrapper-form__edit${id}`)[0].style.display = 'none';
                getAllComment();
            }
        }
    };

    return (
        <Fragment>
            <div className={cx('row d-flex', 'comment-item', 'hn-comment-row')}>
                <div className={cx('d-flex col-12', 'info-detail')}>
                    <img
                        className={cx('avatar-task__user')}
                        src="https://i1.wp.com/avatar-management--avatars.us-west-2.prod.public.atl-paas.net/initials/LD-3.png?ssl=1"
                        alt={info.photo}
                    />
                    <br />
                    <div className={cx('glo-inform-comment')}>
                        <span
                            className={cx('comment-item__name')}
                            dangerouslySetInnerHTML={{ __html: info.user.name }}
                        />
                        <span className={cx('comment-item__time')}>{moment(info.dtCreated).format('lll')}</span>
                    </div>
                </div>
            </div>
            <div className={cx('row', 'comment-item__content', 'hn-comment-row-content')}>
                <EditFormKanBan
                    name={info.comment}
                    onChange={() => {}}
                    setEditContent={setEditContent}
                    editContent={editContent}
                    id={info.id}
                />
                <div className={cx('comment-item__save', `comment-item__save${info.id}`)}>
                    <div className={cx('d-flex justify-content-end')}>
                        <span
                            className={cx('comment-item-btn__save')}
                            onClick={(e) => handleSaveEditComment(e, info.id, info.comment)}
                        >
                            Save
                        </span>
                    </div>
                </div>
                <div
                    className={cx('content-editor', `content-editor__${info.id}`)}
                    dangerouslySetInnerHTML={{ __html: info.comment }}
                ></div>
                <div className={cx(`comment-item-btn comment-item-btn__${info.id}`)}>
                    <div className={cx('d-flex justify-content-end')}>
                        <span className={cx('comment-item-btn__edit')} onClick={() => handleEditComment(info.id)}>
                            Edit
                        </span>
                        <span className={cx('comment-item-btn__edit')} onClick={(e) => handleDeleteComment(e, info.id)}>
                            Delete
                        </span>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default memo(Comment);
