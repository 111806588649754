import { Droppable } from 'react-beautiful-dnd';
import React, { useState } from 'react';
import styled from 'styled-components';
import Button from '~/components/Button';
import classNames from 'classnames';
import styles from './KanbanBug.module.scss';
import Task from './ListItem';
const cx = classNames.bind(styles);

const ColumnHeader = styled.div`
    padding: 12px;
    background: #f4f3f3;
`;

const TaskList = styled.div`
    padding: 8px;
    transition: background-color 0.2s ease;
    background: #f4f3f3;
    flex-grow: 1;
    min-height: 400px;
    max-height: 600px;
    overflow-y: auto;
    overflow-x: hidden;
`;

const Container = styled.div`
    margin: 1px;
    border: 1px solid lightgrey;
    border-radius: 2px;
    width: 300px;
    min-height: 600px;
    box-shadow: 0 2px 6px 0 hsl(0deg 0% 0% / 20%);
    display: flex;
    flex-direction: column;
`;

function Column({
    column,
    convertDataKanBan,
    users,
    setUsers,
    tasks,
    open,
    setOpen,
    listTaskChild,
    setListTaskChild,
    idProject,
    typeTask,
    setTypeTask,
    kanBanRefId,
}) {
    return (
        <Container className={cx(`glo-height-droppable`)}>
            <ColumnHeader>
                <Button className={cx(`list-project__item-status__${column.title.split(' ').join('')}`)} radius8px>
                    {/* <Button radius8px> */}
                    {column.title} {tasks?.length}
                </Button>
            </ColumnHeader>
            {/* <Content className={cx(`glo-height-content`)}>
                
            </Content> */}
            <Droppable droppableId={column.id.toString()} type="TASK">
                {/* {(provided, snapshot) => (
                    <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={{
                            background: snapshot.isDraggingOver,
                        }}
                    >
                        <div style={{ height: '5px' }}></div>
                        {tasks.map((task, index) => (
                            <Task
                                key={task.id}
                                task={task}
                                index={index}
                                convertDataKanBan={convertDataKanBan}
                                users={users}
                                setUsers={setUsers}
                                open={open}
                                setOpen={setOpen}
                                listTaskChild={listTaskChild}
                                setListTaskChild={setListTaskChild}
                                idProject={idProject}
                                typeTask={typeTask}
                                setTypeTask={setTypeTask}
                                kanBanRefId={kanBanRefId}
                            />
                        ))}
                        {provided.placeholder}
                    </div>
                )} */}
                {(provided, snapshot) => (
                    <TaskList
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={{
                            background: snapshot.isDraggingOver,
                        }}
                    >
                        {tasks.map((task, index) => (
                            <Task
                                key={task.id}
                                task={task}
                                index={index}
                                convertDataKanBan={convertDataKanBan}
                                users={users}
                                setUsers={setUsers}
                                open={open}
                                setOpen={setOpen}
                                listTaskChild={listTaskChild}
                                setListTaskChild={setListTaskChild}
                                idProject={idProject}
                                typeTask={typeTask}
                                setTypeTask={setTypeTask}
                                kanBanRefId={kanBanRefId}
                            />
                        ))}
                        {provided.placeholder}
                    </TaskList>
                )}
            </Droppable>
        </Container>
    );
}

export default Column;
