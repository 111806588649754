// Lib
import classNames from 'classnames/bind';

// Components
import styles from './DefaultLayout.module.scss';
import Sidebar from '~/layouts/components/Sidebar';
import Header from '~/layouts/components/Header';
import { useState } from 'react';
import { useContext } from 'react';
import { GlobalStateProject } from '~/ProjectState/projectState';

const cx = classNames.bind(styles);

function DefaultLayout({ children }) {
    const state = useContext(GlobalStateProject);
    const [isShowMenu, setIsShowMenu] = state.projectsAPI.isShowMenu;

    return (
        <div className={cx('wrapper')}>
            <Header />
            <div className={cx('container-body')}>
                <div className={cx('wrapper-body')}>
                    <div className={cx('row', 'glo-row')}>
                        <div className={cx('glo-layout-sidebar')}>
                            <Sidebar />
                        </div>
                        <div
                            className={cx('glo-layout-content')}
                            style={
                                isShowMenu
                                    ? { marginLeft: `calc(24rem + 20px)`, width: `calc(100% - 24rem - 20px)` }
                                    : {}
                            }
                        >
                            <div className={cx('spacer-header')}></div>
                            <div className={cx('content', 'glo-width-content')}>{children}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DefaultLayout;
