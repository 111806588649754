import { Button } from '@mui/material';
import classNames from 'classnames/bind';
import React, { memo, useEffect, useRef, useState } from 'react';
// import { AiOutlineCaretDown, AiOutlineSetting } from 'react-icons/ai';
import { BiArchive } from 'react-icons/bi';
import { BsReply } from 'react-icons/bs';
import { FaRegWindowClose } from 'react-icons/fa';
import { MdInput, MdOutlineChangeHistory, MdOutlineDriveFileMove, MdToc } from 'react-icons/md';
import styles from './ControlTask.module.scss';
import ControlTaskMoveMilestone from './ControlTaskMoveMilestone';
import ControlTaskMoveProjectDialog from './ControlTaskMoveProjectDialog';
import Swal from 'sweetalert2';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { TbEqual } from 'react-icons/tb';
import api from '~/utils/main';

const cx = classNames.bind(styles);

const ControlTask = (props) => {
    const { status, setStatus, updateTask, caseNo, idTask, titleTask, projectId, firstMount, setFirstMount, userId } =
        props;
    // const projects = useSelector((state) => state.project);
    const token = useSelector((state) => state.token);
    const [dropdownControlIsOpen, setDropdownControlIsOpen] = useState(false);
    const [dropdownControl, setDropdownControl] = useState('none');
    const [mountMilestone, setMountMilestone] = useState(false);
    const [dataMileStone, setDataMileStone] = useState([]);

    const [openDialogControlTask, setOpenDialogControlTask] = useState(false);
    const [openDialogMoveMilestone, setOpenDialogMoveMilestone] = useState(false);
    const auth = useSelector((state) => state.auth);
    const { id } = auth.users;

    const handleClickOpenDialogMoveProject = () => {
        setOpenDialogControlTask(true);
        setDropdownControl('none');
    };

    const handleClickOpenDialogMoveMilestone = () => {
        setOpenDialogMoveMilestone(true);
        setDropdownControl('none');
        setMountMilestone(true);
        // setFirstMount(true);
        // getIdProjectMilestion(id);
    };

    useEffect(() => {
        if (dropdownControlIsOpen === true && mountMilestone === true) {
            const getMilestone = async () => {
                const res = await api.get(`milestones/project/${projectId}`);
                if (res.data.status === 'success') {
                    setDataMileStone(res.data.data);
                }
            };
            getMilestone();
        }
    }, [dropdownControlIsOpen, mountMilestone]);

    const ref = useRef();
    useEffect(() => {
        if (dropdownControlIsOpen) {
            showDropdownControl();
        } else {
            hiddenDropdownControl();
        }
    }, [dropdownControlIsOpen]);

    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            if (dropdownControlIsOpen && ref.current && !ref.current.contains(e.target)) {
                setDropdownControlIsOpen(false);
            }
        };
        document.addEventListener('mousedown', checkIfClickedOutside);
        return () => {
            document.removeEventListener('mousedown', checkIfClickedOutside);
        };
    }, [dropdownControlIsOpen]);

    const showDropdownControl = () => {
        setDropdownControl('block');
    };

    const hiddenDropdownControl = () => {
        setDropdownControl('none');
    };

    const handleStatus = (id, name, idTask) => {
        setStatus(id);
        setDropdownControl('none');
        Swal.fire({
            title: 'Are you sure?',
            text: `You want to ${name} the task #${caseNo} ?`,
            icon: 'warning',
            width: '360px',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'OK',
        }).then((result) => {
            if (result.isConfirmed) {
                updateTask(id, idTask);
                Swal.fire(`${name}`, `${name} task successfully`, 'success');
            }
        });
    };

    return (
        <div className={cx('wrapp-control-dropdown')}>
            <div className={cx('dropdown-filter', 'dropdown-filter-relative')} ref={ref}>
                <button
                    className={cx('btn', 'btn-secondary', 'dropdown-toggle', 'btn-dropdown-filter')}
                    onClick={() => {
                        setFirstMount(true);
                        setDropdownControlIsOpen(!dropdownControlIsOpen);
                    }}
                    type="button"
                    aria-expanded="false"
                >
                    <MdToc style={{ fontSize: '1.6rem' }} />
                </button>
                {firstMount && (
                    <ul
                        className={cx('dropdown-menu', 'drop-main-menu')}
                        style={{ display: dropdownControl }}
                        id="res-scroll-control"
                    >
                        {status?.map((itemStatus) => (
                            <li key={itemStatus.id}>
                                <Button
                                    className={cx('btn-control-dropdown')}
                                    onClick={() => handleStatus(itemStatus.id, itemStatus.name, idTask)}
                                >
                                    {itemStatus.name}
                                </Button>
                            </li>
                        ))}
                        {userId === id ? (
                            <li>
                                <Button
                                    className={cx('btn-control-dropdown')}
                                    onClick={handleClickOpenDialogMoveProject}
                                >
                                    Move to Project
                                </Button>
                                <ControlTaskMoveProjectDialog
                                    idTask={idTask}
                                    titleTask={titleTask}
                                    projectId={projectId}
                                    openDialogControlTask={openDialogControlTask}
                                    setOpenDialogControlTask={setOpenDialogControlTask}
                                />
                            </li>
                        ) : (
                            ''
                        )}

                        <li>
                            <Button
                                className={cx('btn-control-dropdown')}
                                onClick={() => handleClickOpenDialogMoveMilestone(projectId)}
                            >
                                Move to Milestone
                            </Button>
                            <ControlTaskMoveMilestone
                                projectId={projectId}
                                setFirstMount={setFirstMount}
                                openDialogMoveMilestone={openDialogMoveMilestone}
                                setOpenDialogMoveMilestone={setOpenDialogMoveMilestone}
                                dataMileStone={dataMileStone}
                                setDataMileStone={setDataMileStone}
                                idTask={idTask}
                            />
                        </li>
                    </ul>
                )}
            </div>
        </div>
    );
};

export default memo(ControlTask);
