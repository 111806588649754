import classNames from 'classnames/bind';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React, { useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import styles from '../CreateProject/CreateProjectDialog.module.scss';
import api from '~/utils/main';
import Moment from 'moment';
import DOMPurify from 'dompurify';

const cx = classNames.bind(styles);

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 15,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const InformationProject = ({
    openDialogInformationProject,
    setOpenDialogInformationProject,
    idProject,
    getListProject,
}) => {
    const [dataCreate, setDataCreate] = useState([]);

    const handleCloseInformationProjectDialog = () => {
        setOpenDialogInformationProject(false);
    };

    useEffect(() => {
        idProject !== '' && getProject();
    }, [openDialogInformationProject]);

    const getProject = async () => {
        api.get(`projects/${idProject}`).then((res) => {
            setDataCreate(res.data.data);
        });
    };
    const createMarkup = (html) => {
        return {
            __html: DOMPurify.sanitize(html),
        };
    };

    return (
        <div className={cx('header-create-task')}>
            <form>
                <BootstrapDialog
                    open={openDialogInformationProject}
                    onClose={handleCloseInformationProjectDialog}
                    // maxWidth="xl"
                    maxWidth="1700px"
                    minwidth="1500px"
                    fullWidth
                    aria-labelledby="customized-dialog-title"
                >
                    <BootstrapDialogTitle
                        id="customized-dialog-title"
                        onClose={handleCloseInformationProjectDialog}
                        className={cx('custom-title-control')}
                    >
                        <div className={cx('dialog-create-project-title')}>
                            <h2>Information Project</h2>
                        </div>
                    </BootstrapDialogTitle>
                    <DialogContent dividers>

                        <div className={cx('')}>
                            <div className={cx('row')}>
                                <div className={cx('col-12 col-md-6 mb-3')}>
                                    <div className={cx('form-group row')}>
                                        <label htmlFor="project-name" className="col-lg-4 col-xxl-2 col-form-label">
                                            Project Name
                                        </label>
                                        <div className="col-lg-8 col-xxl-10">
                                            <input
                                                type="text"
                                                className="form-control form-control-lg"
                                                id="project-name"
                                                value={dataCreate?.name}
                                                readOnly
                                            ></input>
                                        </div>
                                    </div>
                                </div>
                                <div className={cx('col-12 col-md-6 mb-3')}>
                                    <div className={cx('form-group row')}>
                                        <label htmlFor="project-short-name" className="col-lg-4 col-xxl-2 col-form-label">
                                            Short Name
                                        </label>
                                        <div className="col-lg-8 col-xxl-10">
                                            <input
                                                type="text"
                                                className="form-control form-control-lg"
                                                id="create-by"
                                                value={dataCreate?.shortName}
                                                readOnly
                                            ></input>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={cx('form-group', 'row', 'mb-3')}>
                                <label htmlFor="project-default-assign-to" className="col-form-label">
                                    Default Assign To
                                </label>
                                <div className={cx('row', 'width-assign-to')}>
                                    {dataCreate?.assignTos?.map((userInProject) => {
                                        return (
                                            <div className={cx('col-sm-4 col-lg-3 col-xl-2')}>
                                                <input
                                                    key={userInProject.id}
                                                    type="text"
                                                    className={cx('form-control form-control-lg mb-3')}
                                                    id="project-name"
                                                    value={userInProject.userName}
                                                    readOnly
                                                ></input>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>

                            <div className={cx('row')}>
                                <div className={cx('col-12 col-md-6 mb-3')}>
                                    <div className={cx('form-group row')}>
                                        <label htmlFor="project-default-assign-to" className="col-lg-4 col-xxl-2 col-form-label">
                                            Created by
                                        </label>
                                        <div className="col-lg-8 col-xxl-10">
                                            <input
                                                type="text"
                                                className="form-control form-control-lg"
                                                id="create-by"
                                                value={
                                                    dataCreate?.createBy +
                                                    ' ' +
                                                    Moment(dataCreate.dtCreated).format('DD-MM-YYYY')
                                                }
                                                readOnly
                                            ></input>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {dataCreate?.description !== '' && dataCreate?.description !== '<p></p>' && (
                                <div className="mb-3">
                                    <div className="row">
                                        <div className="col-12">
                                            <label
                                                htmlFor="hn-create-description"
                                                className={cx('form-label', 'form-label-title')}
                                            >
                                                Description
                                            </label>
                                            <div
                                                className={cx('glo-border-description-project', 'height-des-project')}
                                                dangerouslySetInnerHTML={createMarkup(dataCreate?.description)}
                                            ></div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </DialogContent>
                    <DialogActions className={cx('create-project-action')}>
                        <button
                            onClick={handleCloseInformationProjectDialog}
                            className={cx('hn-btn-cancel')}
                            size="medium"
                        >
                            Cancel
                        </button>
                    </DialogActions>
                </BootstrapDialog>
            </form>
        </div>
    );
};

export default InformationProject;
