import { useState } from 'react';
import classNames from 'classnames/bind';
import Switch from '@mui/material/Switch';

import styles from './SwitchView.module.scss';

const cx = classNames.bind(styles);

function SwitchView(props) {
    const { checkedSwitch, setCheckedSwitch } = props;
    // const [checked, setChecked] = useState('list');

    const handleChangeViewList = (e) => {
        setCheckedSwitch('list');
        if (!e.target.checked) {
            setCheckedSwitch('kanban');
        }
    };

    const handleChangeViewKanban = (e) => {
        setCheckedSwitch('kanban');
        if (!e.target.checked) {
            setCheckedSwitch('list');
        }
    };

    return (
        <div className={cx('wrapper', 'd-flex')}>
            <div>
                <Switch
                    checked={checkedSwitch === 'list'}
                    onChange={(e) => handleChangeViewList(e)}
                    inputProps={{ 'aria-label': 'controlled' }}
                />{' '}
                <label className={cx('switch-text')}>List View</label>
            </div>
            <div className={cx('kanban-view-wrapper')}>
                <Switch
                    checked={checkedSwitch === 'kanban'}
                    onChange={(e) => handleChangeViewKanban(e)}
                    inputProps={{ 'aria-label': 'controlled' }}
                />
                <label className={cx('switch-text')}>Kanban View</label>
            </div>
        </div>
    );
}

export default SwitchView;
