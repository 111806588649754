export const tokenAction = {
    GET_TOKEN: 'GET_TOKEN',
};

export const dispatchToken = (token) => {
    return {
        type: tokenAction.GET_TOKEN,
        payload: token,
    };
};
