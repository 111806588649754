import { authAction } from '../actions';

const initialState = {
    users: JSON.parse(localStorage.getItem('user')) || '',
    isAuthenticated: localStorage.getItem('accessToken') ? true : false,
};

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case authAction.LOGIN:
            return {
                users: action.payload,
                isAuthenticated: true,
            };
        case authAction.LOGOUT:
            return {
                users: '',
                isAuthenticated: false,
            };
        default:
            return state;
    }
};

export default authReducer;
