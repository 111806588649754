const routes = {
    home: '/',
    createTask: '/create-task',
    login: '/login',
    summary: '/summary',
    board: '/board',
    profile: '/profile',
    user: '/user',
    userCreate: '/user-create',
    userEdit: '/user-edit',
    milestone: '/milestone',
    project: '/project',
    'tracking-bug': '/tracking-bug',
    notFound: '/*',
    detailTask: '/task/:id',
    detailBug: 'bug/:id',
    requirementChecklist: '/requirement-checklist',
    createRequirementChecklist: '/create-requirement-checklist',
    reviewForm: '/review-form',
    questionReview: '/review-member/:id',
    weekendReport: '/weekend-report'
    // test: '/test',
};

export default routes;
