import React, { useEffect, useState } from 'react';
import { Pagination } from '@mui/material';
import classNames from 'classnames/bind';
import styles from '../../Admin.module.scss';
import ReactLoading from 'react-loading';
import * as userService from '~/services/userService';
import DetailDayOff from './DetailDayOff';
import { useSelector } from 'react-redux';
import useDebounce from '~/hooks/useDebounce';
import Swal from 'sweetalert2';

const cx = classNames.bind(styles);

const DayOff = () => {
    const [listDayOff, setListDayOff] = useState([]);
    const [listDetail, setListDetail] = useState([]);
    const [loadingData, setLoadingData] = useState(false);
    const [checkCallBacks, setCheckCallBacks] = useState(false);
    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(12);
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(0);
    const [userId, setUserId] = useState(0);
    const [openDialogDetail, setOpenDialogDetail] = useState(false);
    const [year, setYear] = useState(new Date().getFullYear());
    const auth = useSelector((state) => state.auth);
    const [isUsersActive, setInUsersActive] = useState(true);

    const debouncedSearch = useDebounce(year, 800);

    useEffect(() => {
        getListDayOff();
    }, [page, debouncedSearch, isUsersActive]);

    const getListDayOff = async () => {
        setLoadingData(true);
        const params = {
            Offset: offset,
            Limit: limit,
            Year: debouncedSearch,
            Isactive: isUsersActive,
        };
        const res = await userService.getListDayOff(params);
        if (res.status === 200) {
            setListDayOff(res.data.result.listDayoffAllUsers);
            setTotalPage(Math.ceil(res.data.result.totalCount / 12));
            setLoadingData(false);
        }
    };

    const handleChange = (value) => {
        setOffset((value - 1) * limit);
        setPage(value);
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    };
    useEffect(() => {
        if (checkCallBacks === true) {
            getListDetailDayOff();
        }
    }, [checkCallBacks]);

    const getListDetailDayOff = async () => {
        const res = await userService.getListDetailDayOff(userId, year);
        if (res.status === 200) {
            setListDetail(res.data.result);
            setCheckCallBacks(false);
        }
    };

    const handleOpenDialogDetail = async (userId) => {
        setOpenDialogDetail(true);
        setUserId(userId);
        setCheckCallBacks(true);
    };

    const handleChangeYear = (e) => {
        setYear(e.target.value);
    };

    const handleUpdateRemainingDaysoff = async () => {
        Swal.fire({
            title: 'Are you sure?',
            text: `You want to update remaining days off of ${year - 1} years?`,
            icon: 'warning',
            width: '360px',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'OK',
        }).then(async (result) => {
            if (result.isConfirmed) {
                const body = {
                    year,
                };
                const res = await userService.updateRemainingDaysOff(body);
                if (res.status === 200) {
                    getListDayOff();
                    Swal.fire('Success!', `Update remaining days off of ${year - 1} years successfully`, 'success');
                } else {
                    Swal.fire('Failure!', `${res}`, 'error');
                }
            }
        });
    };

    return (
        <div className={cx('wrapper-day-off')}>
            <div className={cx('list-user-table-content', 'mt-3')}>
                <div className={cx('list-user-table')}>
                    <div className={cx('row')}>
                        <div className={cx('d-flex', 'col-4')}>
                            <input
                                className={cx('form-control form-control-lg', 'form-input-search')}
                                value={year}
                                type="number"
                                onChange={(e) => handleChangeYear(e)}
                            />

                            <div className={cx('d-flex', 'align-items-center', 'wrap-input-radio')}>
                                <input
                                    id="input-radio-user-active"
                                    type="radio"
                                    checked={isUsersActive}
                                    onChange={() => setInUsersActive(true)}
                                />
                                <label htmlFor="input-radio-user-active">Users Active</label>
                            </div>

                            <div className={cx('d-flex', 'align-items-center', 'wrap-input-radio')}>
                                <input
                                    id="input-radio-user-inactive"
                                    type="radio"
                                    checked={!isUsersActive}
                                    onChange={() => setInUsersActive(false)}
                                />
                                <label htmlFor="input-radio-user-inactive">Users InActive</label>
                            </div>
                        </div>
                        {(auth.users.userType === 2 || auth.users.userType === 1) && year > 2022 && (
                            <div className={cx('col-8', 'd-flex', 'justify-content-end')}>
                                <button className={cx('btn-update-remaining')} onClick={handleUpdateRemainingDaysoff}>
                                    Update Remaining days off {year - 1} years
                                </button>
                            </div>
                        )}
                    </div>
                    <div className={cx('list-user_table')}>
                        <div className={cx('list-user_title-table')}>
                            <div className={cx('list-dayOff_title-table-username')}>User Name</div>
                            <div className={cx('list-dayOff_title-table-shortname')}>Days Off</div>
                            <div className={cx('list-user_title-table-email')}>Remaining Days</div>
                        </div>

                        {loadingData === true ? (
                            <ReactLoading type="spin" color="#ccc" height={'50px'} width={'50px'} />
                        ) : (
                            <>
                                {listDayOff.map((item, index) => {
                                    return (
                                        <div
                                            className={cx('list-user_item-body-tr', 'glo-cursor-pointer')}
                                            key={index}
                                            onClick={(e) => handleOpenDialogDetail(item.userId)}
                                        >
                                            <div className={cx('list-dayOff_title-table-username')}>
                                                <span
                                                    dangerouslySetInnerHTML={{
                                                        __html: item.userName,
                                                    }}
                                                />
                                            </div>
                                            <div className={cx('list-dayOff_title-table-shortname')}>
                                                {item.dayOffNum}
                                            </div>
                                            <div className={cx('list-user_title-item-body-email')}>
                                                {item.remainingDays}
                                            </div>
                                        </div>
                                    );
                                })}
                                {listDayOff.length === 0 && (
                                    <span style={{ color: '#dc3545', textAlign: 'center' }}>
                                        No data days off of {year} years
                                    </span>
                                )}
                            </>
                        )}
                    </div>
                </div>
                <div className={cx('list-user-pagination')}>
                    <Pagination
                        count={totalPage}
                        variant="outlined"
                        color="primary"
                        page={page}
                        onChange={(e, value) => handleChange(value)}
                    />
                </div>
            </div>
            <DetailDayOff
                openDialogDetail={openDialogDetail}
                setOpenDialogDetail={setOpenDialogDetail}
                listDetail={listDetail}
                setlistDetail={setListDetail}
                setCheckCallBacks={setCheckCallBacks}
                getListDetailDayOff={getListDetailDayOff}
                getListDayOff={getListDayOff}
                userId={userId}
                setUserId={setUserId}
            />
        </div>
    );
};

export default DayOff;
