import api from '~/utils/main';
import { toast } from 'react-toastify';

export const getInfo = async (id) => {
    try {
        const res = await api.get(`admin/user/${id}`);
        return res;
    } catch (error) {
        console.log(error);
    }
};

export const postDayOff = async (body) => {
    try {
        const res = await toast.promise(api.post(`admin/user/dayoff`, body), {
            pending: 'Send day off is pending',
            success: 'Send day off successfully',
            error: 'Send day off failed',
        });
        return res;
    } catch (error) {
        console.log(error);
    }
};

export const getListDayOff = async (params) => {
    try {
        const res = await api.get(`admin/user/dayoff`, { params });
        return res;
    } catch (error) {
        console.log(error);
    }
};

export const getListDetailDayOff = async (userId, year) => {
    try {
        const params = {
            userId,
            year,
        };
        const res = await api.get(`admin/user/dayoff/${userId}`, { params });
        return res;
    } catch (error) {
        console.log(error);
    }
};

export const getRemainingDays = async () => {
    try {
        const res = await api.get(`admin/user/dayoff-remaining`);
        return res;
    } catch (error) {
        console.log(error);
    }
};

export const getListStatistical = async (params) => {
    try {
        const res = await api.get(`admin/user/statistical`, { params });
        return res;
    } catch (error) {
        console.log(error);
    }
};

export const getDayOffUser = async () => {
    try {
        const res = await api.get(`admin/user/statistical-dayoff-user`);
        return res;
    } catch (error) {
        console.log(error);
    }
};

export const updateDayOffUser = async (id, params) => {
    try {
        const res = await toast.promise(api.patch(`admin/user/dayoff/${id}`, params), {
            pending: 'Update day off is pending',
            success: 'Update day off successfully',
            error: 'Update day off failed',
        });
        return res;
    } catch (error) {
        console.log(error);
    }
};

export const getSummaryOfUser = async () => {
    try {
        const res = await api.get(`admin/user/summary`);
        return res;
    } catch (error) {
        console.log(error);
    }
};

export const updateRemainingDaysOff = async (body) => {
    try {
        const res = await api.post(`admin/user/update-remaining-daysoff-users`, body);
        return res;
    } catch (error) {
        return error.response.data.errorMessage;
    }
};

export const deleteQuestions = async (params) => {
    try {
        const res = await api.delete(`review-member/delete-questions`, { params });
        return res;
    } catch (error) {
        return error.response.data.errorMessage;
    }
};

export const deleteGroupQuestions = async (params) => {
    try {
        const res = await api.delete(`review-member/delete-group-questions`, { params });
        return res;
    } catch (error) {
        return error.response.data.errorMessage;
    }
};

export const updateGroupQuestions = async (params) => {
    try {
        const res = await toast.promise(api.patch(`review-member/edit-group-questions`, params), {
            pending: 'Update group questions is pending',
            success: 'Update group questions successfully',
            error: 'Update group questions failed',
        });
        return res;
    } catch (error) {
        console.log(error);
    }
};

export const updateQuestions = async (params) => {
    try {
        const res = await toast.promise(api.patch(`review-member/edit-questions`, params), {
            pending: 'Update questions is pending',
            success: 'Update questions successfully',
            error: 'Update questions failed',
        });
        return res;
    } catch (error) {
        console.log(error);
    }
};


export const updateOrderNumber = async (body) => {
    try {
        const res = await toast.promise(api.patch(`review-member/update-info-question`, body), {
            pending: 'Update Info Question is pending',
            success: 'Update Info Question successfully',
            error: 'Update Info Question failed',
        });
        return res;
    } catch (error) {
        console.log(error);
    }
};

export const getProjectsReport = async () => {
    try {
        const res = await api.get(`task/projects-status`);
        return res;
    } catch (error) {
        console.log(error);
    }
};
