import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import styles from '../Profile.module.scss';
import moment from 'moment';
import { useRef } from 'react';
import * as userService from '~/services/userService';
import { DialogActions, DialogContent } from '@mui/material';
import PropTypes from 'prop-types';
import { BootstrapDialog, listDuration, typeOff, BootstrapDialogTitle } from '../../../components/DayOffCommon';

const cx = classNames.bind(styles);

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const DialogDayOff = (props) => {
    const { openDialogDayOff, setOpenDialogDayOff, listDetail, checkAddOrUpdate, setCallback } = props;
    const inputDueDate = useRef();
    const [checkValidDate, setCheckValidDate] = useState(false);
    const [checkValidDateEnd, setCheckValidDateEnd] = useState(false);
    const [checkValidReason, setCheckValidReason] = useState(false);
    const [checkValidDuration, setCheckValidDuration] = useState(false);
    const [checkValidDurationEnd, setCheckValidDurationEnd] = useState(false);
    const [checkBox, setCheckBox] = useState(false);
    const [validDate, setValidDate] = useState('');
    const [validDateEnd, setValidDateEnd] = useState('');
    const [validReason, setValidReason] = useState('');
    const [validDuration, setValidDuration] = useState('');
    const [validDurationEnd, setValidDurationEnd] = useState('');
    const [remainingDays, setRemainingDays] = useState('');
    const [validTypeOff, setValidTypeOff] = useState(typeOff[0].value);

    useEffect(() => {
        getRemainingDays();
        if (checkAddOrUpdate === 'Update') {
            setValidDate(listDetail.dayOffUser);
            setValidReason(listDetail.reason);
            setValidDuration(listDetail.duration);
            if (listDetail.dayOffEndUser != null) {
                setCheckBox(true);
                setValidDateEnd(listDetail.dayOffEndUser);
                setValidDurationEnd(listDetail.durationEnd);
            }
        } else {
            handleClear();
        }
    }, [listDetail]);

    const handleCloseDialog = () => {
        setOpenDialogDayOff(false);
    };

    const handleChangeDate = (e) => {
        setCheckValidDate(false);
        const value = moment(new Date(e.target.value)).format('YYYY-MM-DD');
        setValidDate(value);
    };

    const handleChangeDateEnd = (e) => {
        setCheckValidDateEnd(false);
        const value = moment(new Date(e.target.value)).format('YYYY-MM-DD');
        setValidDateEnd(value);
    };

    const handleTypeOff = (value) => {
        setValidTypeOff(value);
    };

    const handleDuration = (value) => {
        setCheckValidDuration(false);
        setValidDuration(value);
    };

    const handleDurationEnd = (value) => {
        setCheckValidDurationEnd(false);
        setValidDurationEnd(value);
    };

    const handleReason = (value) => {
        setCheckValidReason(false);
        setValidReason(value);
    };

    const handleClear = () => {
        setValidDate('');
        setValidReason('');
        setValidDuration('');
        setCheckBox(false);
        setValidDateEnd('');
        setValidDurationEnd('');
        setValidTypeOff(typeOff[0].value);
    };

    const handleSend = async () => {
        var checkvalue = true;
        if (validDate === '') {
            setCheckValidDate(true);
            checkvalue = false;
        }
        if (validDuration === '') {
            setCheckValidDuration(true);
            checkvalue = false;
        }

        if (checkBox) {
            if (validDurationEnd === '') {
                setCheckValidDurationEnd(true);
                checkvalue = false;
            }
            if (validDateEnd === '') {
                setCheckValidDateEnd(true);
                checkvalue = false;
            }
        }
        if (validReason === '') {
            setCheckValidReason(true);
            checkvalue = false;
        }

        if (checkvalue) {
            const data = {
                DayOffUser: validDate,
                Duration: +validDuration,
                Reason: validReason,
                TypeDayOff: +validTypeOff,
                ...(validDateEnd ? { DayOffEndUser: validDateEnd } : {}),
                ...(validDurationEnd ? { DurationEnd: +validDurationEnd } : {}),
            };
            const res = await userService.postDayOff(data);
            if (res.status === 200) {
                handleClear();
                setCallback(true);
                setTimeout(() => getRemainingDays(), 700);
            }
        }
    };
    const getRemainingDays = async () => {
        const res = await userService.getRemainingDays();
        if (res.status === 200) {
            setRemainingDays(res.data.result);
        }
    };

    const handleChangeCheckBox = (e) => {
        const { checked } = e.target;
        setCheckBox(checked);
    };

    const handleUpdate = async () => {
        const data = {
            id: listDetail.id,
            DayOffUser: validDate,
            Duration: +validDuration,
            Reason: validReason,
            TypeDayOff: +validTypeOff,
            ...(validDateEnd ? { DayOffEndUser: validDateEnd } : {}),
            ...(validDurationEnd ? { DurationEnd: +validDurationEnd } : {}),
        };
        const res = await userService.updateDayOffUser(listDetail.id, data);
        if (res.status === 200) {
            setTimeout(() => getRemainingDays(), 700);
            setCallback(true);
        }
    };

    return (
        <form className={cx('form-change-top')}>
            <BootstrapDialog
                open={openDialogDayOff}
                onClose={handleCloseDialog}
                maxWidth="1700px"
                minWidth="1500px"
                fullWidth
                aria-labelledby="customized-dialog-title"
            >
                <BootstrapDialogTitle
                    id="customized-dialog-title"
                    onClose={handleCloseDialog}
                    className={cx('custom-title-control')}
                >
                    <div className={cx('dialog-create-project-title')}>
                        <h2>Day Off</h2>
                    </div>
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <div className={cx('wrapper-content')}>
                        <div className={cx('row')}>
                            <div className={cx('col-6')}>
                                <div className="mb-4">
                                    <label className="form-label">Date Start</label>
                                    <input
                                        ref={inputDueDate}
                                        type="date"
                                        className="form-control form-control-lg"
                                        id="milestone-create-duedate"
                                        // min={moment(new Date()).format('YYYY/MM/DD').replaceAll('/', '-')}
                                        onChange={(e) => handleChangeDate(e)}
                                        onKeyDown={(e) => {
                                            e.preventDefault();
                                        }}
                                        value={moment(validDate).format('YYYY/MM/DD').replaceAll('/', '-')}
                                    ></input>
                                </div>
                                {checkValidDate ? (
                                    <div className={cx('txt-valid-password')}>
                                        <ul>
                                            <li>Date Start is not void </li>
                                        </ul>
                                    </div>
                                ) : null}
                            </div>
                            <div className={cx('col-6')}>
                                <div className="mb-4">
                                    <label className="form-label">Duration Start</label>
                                    <select
                                        className="form-select form-select-lg "
                                        aria-label=".form-select-lg example"
                                        id="hn-create-assignee"
                                        onChange={(e) => handleDuration(e.target.value)}
                                        value={validDuration}
                                    >
                                        <option value="">---</option>
                                        {listDuration.map((options) => {
                                            return (
                                                <option value={options.value} key={options.value}>
                                                    {options.name}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>
                                {checkValidDuration ? (
                                    <div className={cx('txt-valid-password')}>
                                        <ul>
                                            <li>Duration Start off is not void </li>
                                        </ul>
                                    </div>
                                ) : null}
                            </div>
                        </div>

                        <div className="mb-4">
                            <input
                                className={cx('form-check-input')}
                                id="input-all-notify"
                                type="checkbox"
                                checked={checkBox}
                                onChange={(e) => handleChangeCheckBox(e)}
                            />
                            <label htmlFor="input-all-notify" className={cx('checkbox-name', 'padding-left-10')}>
                                Choose if you want to off more than one day
                            </label>
                        </div>
                        {checkBox ? (
                            <div className={cx('row')}>
                                <div className={cx('col-6')}>
                                    <div className="mb-4">
                                        <label className="form-label">Date End</label>
                                        <input
                                            ref={inputDueDate}
                                            type="date"
                                            className="form-control form-control-lg"
                                            id="milestone-create-duedate"
                                            min={moment(validDate).format('YYYY/MM/DD').replaceAll('/', '-')}
                                            onChange={(e) => handleChangeDateEnd(e)}
                                            onKeyDown={(e) => {
                                                e.preventDefault();
                                            }}
                                            value={moment(validDateEnd).format('YYYY/MM/DD').replaceAll('/', '-')}
                                        ></input>
                                    </div>
                                    {checkValidDateEnd ? (
                                        <div className={cx('txt-valid-password')}>
                                            <ul>
                                                <li>Date end is not void </li>
                                            </ul>
                                        </div>
                                    ) : null}
                                </div>
                                <div className={cx('col-6')}>
                                    <div className="mb-4">
                                        <label className="form-label">Duration End</label>
                                        <select
                                            className="form-select form-select-lg "
                                            aria-label=".form-select-lg example"
                                            id="hn-create-assignee"
                                            onChange={(e) => handleDurationEnd(e.target.value)}
                                            value={validDurationEnd}
                                        >
                                            <option value="">---</option>
                                            {listDuration.map((options) => {
                                                return (
                                                    <option value={options.value} key={options.value}>
                                                        {options.name}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                    {checkValidDurationEnd ? (
                                        <div className={cx('txt-valid-password')}>
                                            <ul>
                                                <li>Duration end is not void </li>
                                            </ul>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        ) : null}
                        <div className={cx('row')}>
                            <div className={cx('col-6')}>
                                <div className="mb-4">
                                    <label className="form-label">Type off</label>
                                    <select
                                        className="form-select form-select-lg "
                                        aria-label=".form-select-lg example"
                                        id="hn-create-assignee"
                                        onChange={(e) => handleTypeOff(e.target.value)}
                                        value={validTypeOff}
                                    >
                                        {typeOff.map((options) => {
                                            return (
                                                <option value={options.value} key={options.value}>
                                                    {options.name}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>
                            </div>
                            <div className={cx('col-6')}>
                                <div className="mb-4">
                                    <label htmlFor="confirmPassword" className="form-label">
                                        Remaining Days
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control form-control-lg"
                                        value={remainingDays}
                                        readOnly
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="mb-4">
                            <label className="form-label">Reason</label>
                            <textarea
                                className="form-control form-control-lg"
                                rows="5"
                                value={validReason}
                                onChange={(e) => handleReason(e.target.value)}
                            />
                        </div>
                        {checkValidReason ? (
                            <div className={cx('txt-valid-password')}>
                                <ul>
                                    <li>Reason is not void</li>
                                </ul>
                            </div>
                        ) : null}
                    </div>
                </DialogContent>
                <DialogActions className={cx('create-project-action')}>
                    <div className={cx('mb-4', 'profile-control')}>
                        <div className={cx('mb-4', 'profile-control')}>
                            {checkAddOrUpdate === 'Add' ? (
                                <button type="button" className={cx('btn', 'btn-profile-update')} onClick={handleSend}>
                                    Send
                                </button>
                            ) : (
                                <button
                                    type="button"
                                    className={cx('btn', 'btn-profile-update')}
                                    onClick={handleUpdate}
                                >
                                    Update
                                </button>
                            )}

                            <button type="button" className={cx('btn', 'btn-profile-cancel')} onClick={handleClear}>
                                Clear
                            </button>
                        </div>
                    </div>
                </DialogActions>
            </BootstrapDialog>
        </form>
    );
};

export default DialogDayOff;
